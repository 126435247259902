import { ObjectFieldTemplateProps } from '@rjsf/utils';

const ObjectFieldTemplate = (templateProps: ObjectFieldTemplateProps) => {
  return (
    <>
      {!!templateProps.title && <div className={`col-span-full mt-7`}>{templateProps.title}</div>}
      {
        templateProps.properties.map((element) => (
          element.content
        ))
      }
    </>
  );
};

export default ObjectFieldTemplate;