import { PassiveReflectorsAssetModel } from './passive-reflectors-asset.model';
import { PassiveReflectorsInspectionModel } from './passive-reflectors-inspection.model';
import { UserModel } from '../../../models/user.model';
import { PassiveReflectorsWorkOrderModel } from './passive-reflectors-work-order.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { CommentModel } from '../../../models/comment.model';
import { FileModel } from '../../../models/file.model';
import { AssetInspectionModel } from '../../../models/base-models/asset-inspection.model';
import { PASSIVE_REFLECTORS_CONDITIONS_LIST } from '../globals';
import { LocationModel } from '../../../models/location.model';

export class PassiveReflectorsAssetInspectionModel extends AssetInspectionModel {
  id: string;
  type: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  asset: PassiveReflectorsAssetModel;
  snapshot: {
    asset: PassiveReflectorsAssetModel;
  };
  structureCondition: any;
  structureFinish: any;
  lighting: any;
  grounding: any;
  antennasAndMount: any;
  iceShieldsWalkways: any;
  guys: any;
  concreteFoundation: any;
  guyedMastAnchor: any;
  attachments: FileModel[];
  workOrders: PassiveReflectorsWorkOrderModel[] = [];
  inspection?: PassiveReflectorsInspectionModel;
  location: LocationModel;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];
  conditions: string[] = [];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);

    if (this.snapshot && this.snapshot.asset) {
      this.snapshot.asset = new PassiveReflectorsAssetModel(this.snapshot.asset);
    }

    this.updateConditions();
  }

  get childModels() {
    return {
      inspectors: UserModel,
      asset: PassiveReflectorsAssetModel,
      attachments: FileModel,
      workOrders: PassiveReflectorsWorkOrderModel,
      inspection: PassiveReflectorsInspectionModel,
      changeHistory: FieldChangeModel,
      location: LocationModel,
      comments: CommentModel,
    };
  }

  updateConditions() {
    this.conditions = [];
    const propertiesToCheck = [
      'antennasAndMount',
      'concreteFoundation',
      'grounding',
      'guyedMastAnchor',
      'guys',
      'iceShieldsWalkways',
      'lighting',
      'structureCondition',
      'structureFinish',
    ];

    propertiesToCheck.forEach((property) => {
      if (this[property]) {
        this.conditions = [
          ...this.conditions,
          ...Object.entries(
            this[property] as Record<string,
              { status: string | { status: string } }>
          )
            .filter(([key, value]) => {
              if (typeof value === 'object') {
                return value.status === 'Unsatisfactory';
              } else {
                return value === 'Unsatisfactory';
              }
            })
            .map(([key]) => key),
        ];
      }
    });


    if (this.conditions.length) {
      this.conditions = this.conditions.map(condition => this.translateCondition(condition));
    }
  }

  private translateCondition(key: string): string {
    const matchingKey = PASSIVE_REFLECTORS_CONDITIONS_LIST.find(item => item.key === key);

    return matchingKey ? matchingKey.value : key;
  }

  get correctiveActions(): any[] {
    if (!this.conditions) {
      return [];
    }

    return this.conditions.map((condition) => ({
      name: condition,
      workOrder: this.workOrders.find(
        (workOrder) => workOrder.condition === condition
      ),
    }));
  }
}