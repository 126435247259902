import { MenuItem as MaterialMenuItem } from '@mui/material';
import { SPINNER } from "../../globals";
import ListItemIcon from "@mui/material/ListItemIcon";
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";

type Props = {
  onClick: Function;
  isLoading?: boolean;
  disabled?: boolean;
  icon: string;
  text: string;
  setMenuOpened?: any;
  className?: string;
}

const MenuItem = (props: Props) => {

  const onClickHandler = () => {
    props.onClick();
    props.setMenuOpened && props.setMenuOpened(false);
  }

  return (
    <MaterialMenuItem onClick={onClickHandler}
                      disabled={!!props.isLoading || !!props.disabled} className={`${props.className} pl-12`}>
      {!!props.isLoading ? <div className={'ml-2'}>{SPINNER}</div> :
        <ListItemIcon className={'pr-6'} style={{minWidth: '0px'}}>
          {/*@ts-ignore*/}
          <FuseSvgIcon size={20}>{props.icon}</FuseSvgIcon>
        </ListItemIcon>
      }
      <ListItemText className={`${!!props.isLoading && 'ml-10'}`}>{props.text}</ListItemText>
    </MaterialMenuItem>
  );
};

export default MenuItem;
