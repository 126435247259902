import List from '@mui/material/List';
import {styled} from '@mui/material/styles';
import clsx from 'clsx';
import {useDispatch} from 'react-redux';
import FuseNavItem from '../FuseNavItem';
import {useContext, useEffect} from 'react';
import DepartmentContext from '../../../../app/DepartmentContext';
import {DepartmentHelper} from '../../../../app/shared/helpers/department.helper';

const StyledList = styled(List)(({theme}) => ({
    '& .fuse-list-item': {
        '&:hover': {
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0,0,0,.04)',
        },
        '&:focus:not(.active)': {
            backgroundColor:
                theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.06)' : 'rgba(0,0,0,.05)',
        },
    },
    '& .fuse-list-item-text': {
        margin: 0,
    },
    '& .fuse-list-item-text-primary': {
        lineHeight: '20px',
    },
    '&.active-square-list': {
        '& .fuse-list-item, & .active.fuse-list-item': {
            width: '100%',
            borderRadius: '0',
        },
    },
    '&.dense': {
        '& .fuse-list-item': {
            paddingTop: 0,
            paddingBottom: 0,
            height: 32,
        },
    },
}));

function FuseNavVerticalLayout1(props) {
    const {navigation, layout, active, dense, className, onItemClick} = props;
    let navigationList = navigation;
    const dispatch = useDispatch();
    const {department} = useContext(DepartmentContext);

    function handleItemClick(item) {
        onItemClick?.(item);
    }

    useEffect(() => {
    }, []);
    if (DepartmentHelper.isAllDepartments(department)) {
        navigationList = navigationList.filter(item => item.id === 'dashboard-page')
    }

    return (
        <StyledList
            className={clsx(
                'navigation whitespace-nowrap px-12 py-0',
                `active-${active}-list`,
                dense && 'dense',
                className
            )}
        >
            {navigationList.map((_item) => (
                <FuseNavItem
                    key={_item.id}
                    type={`vertical-${_item.type}`}
                    item={_item}
                    nestedLevel={0}
                    onItemClick={handleItemClick}
                />
            ))}
        </StyledList>
    );
}

export default FuseNavVerticalLayout1;
