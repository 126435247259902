import { DataType } from './types';
import { FIBER_TYPE_CONFIG } from './fiber/type-config';
import { UPS_TYPE_CONFIG } from './ups/type-config';
import { GENERATORS_TYPE_CONFIG } from './generators/type-config';
import { TELECOM_TOWERS_TYPE_CONFIG } from './telecom-towers/type-config';
import { PASSIVE_REFLECTORS_TYPE_CONFIG } from './passive-reflectors/type-config';

export const TYPE_CONFIG: DataType[] = [
  FIBER_TYPE_CONFIG,
  UPS_TYPE_CONFIG,
  GENERATORS_TYPE_CONFIG,
  TELECOM_TOWERS_TYPE_CONFIG,
  PASSIVE_REFLECTORS_TYPE_CONFIG
];