import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

const useQueryParams = (paramsObj: any) => {

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    setUseFilterParams(paramsObj);
  }, []);

  for (const entry of searchParams.entries()) {
    paramsObj[entry[0]] = entry[1];
  }

  const setUseFilterParams = (paramsObj: any) => {
    Object.keys(paramsObj).forEach(key => paramsObj[key] === undefined || paramsObj[key] === '' || paramsObj[key]==='false' ? delete paramsObj[key] : {});
    setSearchParams(paramsObj);
  };

  return [paramsObj, setUseFilterParams];
};

export default useQueryParams;
