import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  title: string;
  linkTo: string;
  hoverText: string;
}

const CardTitle = (props: Props) => {

  return (
    <Typography className={`flex mb-11`}>
        <span className={`${props.linkTo && 'cursor-pointer'} flex gap-x-4 text-xl font-bold`}
              onClick={() => {
                props.linkTo && window.open(`${props.linkTo}`);
              }}>
          {props.title}
          {!!props.linkTo &&
              <Tooltip followCursor={true} title={props.hoverText ? props.hoverText : ''}
                       placement='top-start'
                       className={`my-auto`}
              >
                {/*@ts-ignore*/}
                 <FuseSvgIcon size={19} color={'primary'}>material-outline:open_in_new</FuseSvgIcon>
              </Tooltip>
          }
        </span>
    </Typography>

  );
};

export default CardTitle;
