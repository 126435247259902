import { FieldTemplateProps } from '@rjsf/utils';

const FieldTemplate = (props: FieldTemplateProps) => {
  return (
    <div className={props.classNames} style={props.style}>
      {props.description}
      {props.children}
      {props.errors}
      {props.help}
    </div>
  );
};

export default FieldTemplate;