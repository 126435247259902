import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import Inspections from "./Inspections";

i18next.addResourceBundle('en', 'inspections', en);

const InspectionsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'inspections',
      element: <Inspections />,
    },
  ],
};

export default InspectionsConfig;
