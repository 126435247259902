import { GeneratorsAssetModel } from './generators-asset.model';
import { GeneratorsInspectionModel } from './generators-inspection.model';
import { UserModel } from '../../../models/user.model';
import { GeneratorsWorkOrderModel } from './generators-work-order.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { CommentModel } from '../../../models/comment.model';
import { FileModel } from '../../../models/file.model';
import { AssetInspectionModel } from '../../../models/base-models/asset-inspection.model';
import { GENERATORS_CONDITIONS_LIST } from '../globals';
import { LocationModel } from '../../../models/location.model';

export class GeneratorsAssetInspectionModel extends AssetInspectionModel {
  id: string;
  type: string;
  name?: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  asset: GeneratorsAssetModel;
  snapshot: {
    asset: GeneratorsAssetModel;
  };
  generalInspection: any;
  battery: any;
  engine: any;
  generatorNoLoad: any;
  fluids: any;
  control: any;
  mounting: any;
  automaticTransferSwitches: any;
  utilityPower: any;
  generatorWithLoad: any;
  regulatorDate: string;
  attachments: FileModel[];
  workOrders: GeneratorsWorkOrderModel[] = [];
  inspection?: GeneratorsInspectionModel;
  location: LocationModel;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];
  additionalConditions?: string[];
  conditions: string[] = [];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
    if (this.snapshot && this.snapshot.asset) {
      this.snapshot.asset = new GeneratorsAssetModel(this.snapshot.asset);
    }

    this.updateConditions();
  }

  get childModels() {
    return {
      inspectors: UserModel,
      asset: GeneratorsAssetModel,
      attachments: FileModel,
      workOrders: GeneratorsWorkOrderModel,
      inspection: GeneratorsInspectionModel,
      changeHistory: FieldChangeModel,
      location: LocationModel,
      comments: CommentModel,
    };
  }

  updateConditions() {
    this.conditions = [];
    const propertiesToCheck = [
      'generalInspection',
      'battery',
      'engine',
      'control',
      'mounting',
      'automaticTransferSwitches',
    ];

    propertiesToCheck.forEach((property) => {
      if (this[property]) {
        this.conditions = [
          ...this.conditions,
          ...Object.entries(
            this[property] as Record<string,
              { status: string | { status: string } }>
          )
            .filter(([key, value]) => {
              if (typeof value === 'object') {
                return value.status === 'Unsatisfactory';
              } else {
                return value === 'Unsatisfactory';
              }
            })
            .map(([key]) => key),
        ];
      }
    });

    if (this.additionalConditions && this.additionalConditions.length > 0) {
      this.conditions = [
        ...this.conditions,
        ...this.additionalConditions,
      ];
    }

    if (this.conditions.length) {
      this.conditions = this.conditions.map(condition => this.translateCondition(condition));
    }
  }

  private translateCondition(key: string): string {
    const matchingKey = GENERATORS_CONDITIONS_LIST.find(item => item.key === key);

    return matchingKey ? matchingKey.value : key;
  }

  get correctiveActions(): any[] {
    if (!this.conditions) {
      return [];
    }

    return this.conditions.map((condition) => ({
      name: condition,
      workOrder: this.workOrders.find(
        (workOrder) => workOrder.condition === condition
      ),
    }));
  }
}
