import React, { memo, useState, useEffect } from 'react';
import { Card, CircularProgress, Typography } from '@mui/material';
import { FieldChangeModel } from '../../models/field-change.model';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ChangeLogTimeline from '../ChangeLogTimeline/ChangeLogTimeline';
import CommentForm from '../ChangeLogTimeline/CommentForm';
import { CommentModel } from '../../models/comment.model';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});

type Props = {
  title: string;
  isLoading: boolean;
  className?: string;
  changeHistory?: FieldChangeModel[];
  comments?: CommentModel[];
  commentAddedHandler?: Function;
  commentFormIsDisabled?: boolean;
  formOnFocusHandler?: Function;
  formOnFocusLeftHandler?: Function;
}

const ChangeLogCard = (props: Props) => {

  const [changeHistory, setChangeHistory] = useState([]);

  useEffect(() => {
    if (!props.comments || !props.comments.length) {
      setChangeHistory(props.changeHistory);
      return;
    }

    const changeHistoryComments = props.comments.map(comment => new FieldChangeModel(
      {
        changedBy: comment.createdBy ? (comment.createdBy.id ? comment.createdBy.id : comment.createdBy) : '',
        field: 'activityComment',
        oldValue: '',
        newValue: comment.text,
        changedAt: comment.createdAt
      }));

    if (!props.changeHistory || !props.changeHistory.length) {
      setChangeHistory([...changeHistoryComments].sort((field1, field2) => {
        return collator.compare(field1.changedAt, field2.changedAt)
      }));
      return;
    }

    setChangeHistory([...props.changeHistory, ...changeHistoryComments].sort((field1, field2) => {
      return collator.compare(field1.changedAt, field2.changedAt)
    }));
  }, [props.comments, props.changeHistory]);

  return (
    <Card className={`shadow overflow-hidden rounded-md px-24 pb-24 pt-16 ${props.className}`}>
      <Typography className={`flex mb-12 text-xl font-bold`}>
        {/*@ts-ignore*/}
        <FuseSvgIcon size={22} className={'mr-12 mt-3'}>material-outline:history</FuseSvgIcon>
        {props.title}
      </Typography>
      {
        props.isLoading &&
          <div className={'w-full p-12 text-center'}>
              <CircularProgress className={'m-auto'}/>
          </div>
      }
      {
        !props.isLoading && (!changeHistory || (!!changeHistory && changeHistory.length === 0)) &&
          <p className={'text-14'}>N/A - No Changes Were Made</p>
      }
      {
        !props.isLoading && !!changeHistory && !!changeHistory.length &&
          <ChangeLogTimeline changeHistory={changeHistory}/>
      }
      {
        !props.isLoading &&
          <CommentForm isLoading={props.isLoading} commentAddedHandler={props.commentAddedHandler} formIsDisabled={props.commentFormIsDisabled}
                       formOnFocusHandler={props.formOnFocusHandler} formOnFocusLeftHandler={props.formOnFocusLeftHandler}
          />
      }
    </Card>
  );
};

export default memo(ChangeLogCard);
