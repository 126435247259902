import ApiClient from './api-client/api-client';
import { UserModel } from '../shared/models/user.model';
import { ROLE_TYPE } from '../shared/globals';

const USERS_ENDPOINT = '/users';

const getLoggedInUser = (): Promise<UserModel> => {
  return ApiClient.get(`${USERS_ENDPOINT}/me`)
    .then(response => response.data);
};

const getUsers = (): Promise<UserModel[]> => {
  return ApiClient.get(USERS_ENDPOINT)
    .then(response => response.data)
    .then(response => response.map(item => new UserModel(item)));
};

const getInspectors = (department: string): Promise<UserModel[]> => {
  return ApiClient.get(`${USERS_ENDPOINT}?role=Inspector&department=${department}`)
    .then(response => response.data)
    .then(response => response.map(item => new UserModel(item)));
};

const getUserByEternalId = (externalId: string): Promise<UserModel> => {
  return ApiClient.get(`${USERS_ENDPOINT}/externalId/${externalId}`)
    .then(response => response.data)
    .then(response => new UserModel(response));
};

const getCurrentUser = (): UserModel => {
  const user = localStorage.getItem('current_user');

  if (!user) {
    return;
  }

  return JSON.parse(user);
}

const getCurrentUserIsAdmin = (): boolean => {
  const user = getCurrentUser();

  if (!user || !user.role || !user.role.length) {
    return false;
  }

  return user.role.includes(ROLE_TYPE.admin);
}

const getCurrentUserDepartment = (): string[] => {
  const user = getCurrentUser();

  if (!user || !user.department) {
    return [];
  }

  return user.department;
}

const UsersService = {
  getLoggedInUser,
  getUsers,
  getInspectors,
  getUserByEternalId,
  getCurrentUser,
  getCurrentUserIsAdmin,
  getCurrentUserDepartment
};

export default UsersService;
