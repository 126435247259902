import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Map from '../Map/Map';
import MapView from '../Map/layers/MapView/MapView';
import Points from '../Map/layers/Points/Points';
import { MapPointModel } from '../../models/map-point.model';
import MapViewPadding from '../Map/layers/MapView/MapViewPadding';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { Button, Tooltip } from '@mui/material';

type Props = {
  points: MapPointModel[];
  leftPadding?: number;
  topPadding?: number;
  rightPadding?: number;
  bottomPadding?: number;
  clickedPointId?: string;
  hoveredPointId?: string;
  onPointClickHandler?: Function;
  mapOpened: boolean;
}

const AssetsMap = ({
                     points,
                     leftPadding,
                     topPadding,
                     rightPadding,
                     bottomPadding,
                     clickedPointId,
                     hoveredPointId,
                     onPointClickHandler,
                     mapOpened
                   }: Props) => {

  const [satelliteView, setSatelliteView] = useState(false);

  const handleMapViewToggled = () => {
    setSatelliteView(!satelliteView);
  };

  const mapAnimation = {
    hidden: {opacity: 0, scale: 0.5},
    show: {opacity: 1, scale: 1},
    exit: {opacity: 0},
  };

  const changeMapViewButton =
    <Tooltip followCursor={true} title={!!satelliteView ? 'Base View' : 'Satellite View'} placement="top-start">
      <Button
        style={{
          position: 'absolute',
          right: '0',
          bottom: '0',
        }}
        className={`p-8 mb-24 mr-28 hidden sm:flex`}
        variant='contained'
        color={!!satelliteView ? 'inherit' : 'primary'}
        onClick={handleMapViewToggled}
        size={'small'}
      >
        {/*@ts-ignore*/}
        <FuseSvgIcon size={24}>material-outline:layers</FuseSvgIcon>
      </Button>
    </Tooltip>;

  return (
    <>
      {mapOpened &&
          <AnimatePresence>
              <motion.div className={`fixed top-0 left-0 w-full h-full`}
                          variants={mapAnimation}
                          initial='hidden'
                          animate='show'
                          exit='exit'
                          key='fullscreen-map'>
                  <Map>
                      <MapView basemap={!!satelliteView ? 'satellite' : 'gray-vector'}/>
                      <MapViewPadding top={topPadding} left={leftPadding} right={rightPadding} bottom={bottomPadding}/>
                      <Points points={points} clickedPointId={clickedPointId} hoveredPointId={hoveredPointId}
                              onPointClickHandler={onPointClickHandler}/>
                  </Map>
                {changeMapViewButton}
              </motion.div>
          </AnimatePresence>
      }
    </>
  );
};

export default AssetsMap;
