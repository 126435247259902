import { BaseModel } from './base.model';

export class FileModel extends BaseModel {

  id: string;
  path: string;
  name: string;
  originalName: string;
  encoding: string;
  size: number;
  mimeType: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
