import { FileModel } from '../models/file.model';
import { ViewerImageModel } from '../models/viewer-image.model';
import FilesService from '../../services/files.service';

export class FileHelper {

  static isFileImage(file: FileModel): boolean {
    if (!file || !file.mimeType) {
      return;
    }

    const [fileType] = file.mimeType.split('/');
    return fileType === 'image';
  }

  static getViewerImagesFromAttachments(attachments: FileModel[]): ViewerImageModel[] {
    if (!attachments) {
      return [];
    }

    return attachments.filter(attachment => FileHelper.isFileImage(attachment))
      .map(image => new ViewerImageModel(image));
  }

  static getFilesFromAttachments(attachments: FileModel[]): FileModel[] {
    if (!attachments) {
      return [];
    }

    return attachments.filter(attachment => !FileHelper.isFileImage(attachment));
  }

  static toFileModels(files: File[]): FileModel[] {
    if (!files) {
      return [];
    }

    return files.map(file => new FileModel({
      name: file.name,
      originalName: file.name,
      size: file.size,
      mimeType: file.type,
    }));
  }

  static prepareModelsFilesForUpload(model: any) {
    model.files = FileHelper.toFileModels(model.files);
  }

  static async uploadFiles(model: any, files: File[] = []){
    await FilesService.uploadFilesWithPreSignedUrls(model.preSignedUrls, files);
    delete model.preSignedUrls;

    return model;
  }

}