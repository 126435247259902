import { FiberAssetInspectionModel } from './fiber-asset-inspection.model';
import { AssetModel } from '../../../models/base-models/asset.model';
import { LocationModel } from '../../../models/location.model';

export class FiberAssetModel extends AssetModel {
  id: string;
  externalId: string;
  type: string;
  name: string;
  assetType: string;
  notes: string;
  assetInspections: FiberAssetInspectionModel[];
  comparingAsset: FiberAssetModel;
  location: LocationModel;

  get childModels() {
    return {
      location: LocationModel,
    };
  }

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
