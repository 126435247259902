const get = (param: string) => {
  return process.env[`REACT_APP_${param}`];
};

const COGNITO_AUTH = `${get('COGNITO_AUTH')}${get('ORIGIN')}`;

const Environment = {
  get,
  COGNITO_AUTH,
};

export default Environment;
