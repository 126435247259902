import { MapAssetInterface } from '../interfaces/map-asset.interface';
import { MapPointModel } from '../map-point.model';
import { FiberWorkOrderModel } from '../../types/fiber/models/fiber-work-order.model';
import { InspectionHelper } from '../../helpers/inspection.helper';

export class WorkOrderMapModel extends FiberWorkOrderModel implements MapAssetInterface {
  getMapPoint(): MapPointModel {
    if (!this.location || !this.location.longitude || !this.location.latitude) {
      return;
    }

    // TODO: get color directly from model
    return new MapPointModel({
      id: this.id,
      location: this.location,
      color: InspectionHelper.getWorkOrderMapPointColor(this.status)
    });
  }
}
