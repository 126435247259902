import React from 'react';
import { FieldChangeModel } from '../../models/field-change.model';
import { UserModel } from '../../models/user.model';

type Props = {
  changeHistoryField: FieldChangeModel;
  users: UserModel[];
  action: string;
  value: string;
  userName: string;
}

const AssignedToComponent = (props: Props) => {

  return <>
    {
      !props.userName && <div className={'flex flex-wrap items-start mb-5'}>
            <label className={`font-bold text-12 mr-3`}>{props.value}</label>
            <label className={`text-12 mr-3 whitespace-nowrap`}>{`got ${props.action}.`}</label>
        </div>
    }
    {
      props.userName && <div className={'flex flex-wrap items-start mb-5'}>
            <label className={`font-bold text-12 mr-3`}>{props.userName}</label>
            <label className={`text-12 mr-3 whitespace-nowrap`}>{`${props.action}`}</label>
            <label className={`font-bold text-12 mr-3`}>{`${props.value}.`}</label>
        </div>
    }
  </>;
}

export default AssignedToComponent;