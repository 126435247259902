import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import AssetInspection from './AssetInspection';

i18next.addResourceBundle('en', 'assetPage', en);

const AssetInspectionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'asset-inspection/:id',
      element: <AssetInspection />,
    },
  ],
};

export default AssetInspectionConfig;
