import React from 'react';
import InspectionCard from '../../../shared/components/InspectionCard/InspectionCard';
import Chip from '../../../shared/components/Chip/Chip';
import { DUE_DATE_STATUSES, WORK_ORDER_STATUSES } from '../../../shared/globals';
import CardHeading from '../../../shared/components/CardHeading/CardHeading';
import StatusStrip from '../../../shared/components/StatusStrip/StatusStrip';

type Props = {
  dataModel: any;
  mapOpened: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
  onHoveredCardClickHandler?: Function;
  onNotSelectedCardClickHandler?: Function;
  isInspectionList?: boolean;
  contentElementClassName?: string;
  workOrderData: any;
}

const WorkOrderCard = (props: Props) => {

  const cardSelectedClass = () => {
    if (props.isSelected === null) {
      return '';
    }

    return props.isSelected ? 'shadow-xl my-16' : 'opacity-60 hover:opacity-100';
  }

  const cardLinkTo = (): string => {
    if (props.isSelected === false) {
      return '';
    }

    return `/escalation/${props.workOrderData.id}`;
  };

  const onCardClickHandler = () => {
    if (props.isSelected === false) {
      props.onNotSelectedCardClickHandler(props.workOrderData.id);
    }
  };

  const onHoveredCardClickHandler = () => {
    if (!props.isHovered) {
      return;
    }

    props.onHoveredCardClickHandler(props.workOrderData.id);
  };

  return (
    <span onClick={onCardClickHandler}>
      <InspectionCard
        linkTo={`${cardLinkTo()}`}
        statusAreaElements={[
          <StatusStrip
            key={'statusComponent'}
            status={props.workOrderData['dueDateStatus']}
            condensed={true}
            statusObjects={DUE_DATE_STATUSES} />,
        ]}
        className={`${props.mapOpened ? '' : 'md:flex-row'}`}
        cardClassName={`${cardSelectedClass()}`}
        contentElements={props.dataModel.workOrderInfo}
        headingAreaElements={[
          <CardHeading
            key={'workOrderHeading'}
            heading={props.dataModel.cardHeading}
            cardIsHovered={props.isHovered}
            onButtonClickHandler={onHoveredCardClickHandler}
            className={props.mapOpened ? '' : 'w-full'}
          />
        ]}
        status={props.workOrderData.status}
        statusObjects={WORK_ORDER_STATUSES}
        contentElementClassName={`w-full gap-6 align-middle grid ${props.contentElementClassName}`}
        infoAreaChildren={[
          <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
                label={props.dataModel.dateTime}
          />
        ]}
        actionAreaChildren={[]}
        smallView={props.mapOpened}
        smallHeadingWidth={props.mapOpened}
        workOrderHeading={!props.mapOpened}
      />
    </span>
  );
}

export default WorkOrderCard;
