import { UpsAssetInspectionModel } from './ups-asset-inspection.model';
import { AssetModel } from '../../../models/base-models/asset.model';
import { LocationModel } from '../../../models/location.model';

export class UpsAssetModel extends AssetModel {
  id: string;
  externalId: string;
  type: string;
  assetType: string;
  tierRating: string;
  upsManufacturer: string;
  upsMake?: string; //UPS
  upsModelNumber?: string; //UPS
  upsSerialNumber?: string; //UPS
  partNumber: string;
  size?: string; //UPS
  voltage: string;
  voltageType: {
    value: string;
    otherValue?: string;
  };
  phase?: string; //UPS
  numberOfAirFilters?: string; //UPS
  numberOfFans?: string; //UPS
  totalChassisSlots?: string; //UPS
  chassisSlotsUsed?: string; //UPS
  supportedByGenerator?: string; //UPS
  externalMaitenanceBypass?: string; //UPS
  sizeOfAirFilters?: string; //UPS
  loadValue?: string; //UPS
  manufacturedDate: string;
  rack: string;
  rackUnitNumber: string;
  equipmentLife: string;
  endOfLife: string;
  conditionRating: string;
  batteryAmpHours: string;
  batteryRuntime: string;
  monitoringPGE: string;
  yearOfConstruction: string;
  serialNumber: string;
  batteryStringNumber?: string; //VRLA
  batteryMake?: string; //VRLA
  batteryModel?: string; //VRLA
  modulePartNumber?: string; //VRLA
  dateCode?: string; //VRLA
  internalExternalString?: string; //VRLA
  numberOfBatteriesPerString?: string; //VRLA
  numberOfModulesPerString?: string; //VRLA
  numberOfBatteriesPerModule?: string; //VRLA
  notes: string;
  assetInspections: UpsAssetInspectionModel[];
  comparingAsset: UpsAssetModel;
  location: LocationModel;

  get childModels() {
    return {
      location: LocationModel,
    };
  }

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
