import { ViewModelFactory } from './view-model.factory';
import { TypeHelper } from '../helpers/type.helper';
import { BaseViewModel } from '../models/base-view-models/base-view.model';
import { BaseModel } from '../models/base.model';

export class InspectionViewModelFactory implements ViewModelFactory {

  createViewModel<T extends BaseViewModel, M extends BaseModel>(model: M): T {
    const ViewModel = TypeHelper.getTypeConfig(model).InspectionViewModel;

    return new ViewModel(model) as T;
  }

  mapViewModels<T extends BaseViewModel, M extends BaseModel>(models: M[]): T[] {
    return models.map((model) => this.createViewModel<T, M>(model));
  }

}