export class FormHelper {

  static generateFormData(object): FormData {
    const formData = new FormData();
    const entries = Object.entries(object);
    entries.forEach(entry => {
      FormHelper.addToPayload(formData, entry[1], entry[0], entry[0]);
    });
    return formData;
  }

  private static addToPayload(formData: FormData, values: any, fieldName: string, nestedFieldName: string) {
    if (!values && values !== 0 && fieldName) {
      //formData.append(fieldName, '');
      return formData;
    }

    if (Array.isArray(values) && values.length === 0) {
      //formData.append(fieldName + '[]', '');
      return formData;
    }

    if (values instanceof File) {
      formData.append(fieldName, values, values.name);
    } else if (typeof values == 'object') {
      for (const key in values) {
        if (typeof values[key] == 'object') {
          FormHelper.addToPayload(formData, values[key], fieldName, nestedFieldName + '[' + key + ']');
        } else {
          formData.append(nestedFieldName + '[' + key + ']', values[key]);
        }
      }
    } else {
      formData.append(nestedFieldName, values);
    }

    return formData;
  }

}