import React, { useState } from 'react';
import { Dialog as MaterialDialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

type Props = {
  title: any;
  text: string;
  children: JSX.Element[] | JSX.Element;
  actionsContent: JSX.Element[] | JSX.Element;
  onClose: Function;
}

const Dialog = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <MaterialDialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      PaperProps={{
        style: {borderRadius: 5}
      }}
    >
      <DialogTitle id="responsive-dialog-title" className={'px-20 pt-12 pb-8'}>
        {props.title}
      </DialogTitle>
      <div className='h-1 border-b-1 border-gray-500'></div>
      <DialogContent className={'flex flex-col items-center gap-16 pt-16 pb-20 px-20 my-0'}>
        <DialogContentText className={'text-gray-800'}>
          {props.text}
        </DialogContentText>
        {props.children}
      </DialogContent>
      <div className='h-1 border-b-1 border-gray-500'></div>
      <DialogActions className={'w-full items-center justify-end flex px-20 py-12'}>
        {props.actionsContent}
      </DialogActions>
    </MaterialDialog>
  );
};

export default Dialog;
