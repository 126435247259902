import React from 'react';
import { Skeleton } from '@mui/material';

type Props = {
  className?: string;
};

const CorrectiveActionSkeleton = ({className}: Props) => {

  return (
    <div className={`w-full py-4 px-12 ${className}`}>
      <div className={'flex justify-between items-center'}>
        <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '20px'}}/>
        <Skeleton variant='rounded' animation={'wave'} width={120} sx={{fontSize: '14px', borderRadius: '15px'}}/>
      </div>
      <Skeleton variant='text' animation={'wave'} width={270} sx={{fontSize: '22px'}}/>
    </div>
  );
};

export default CorrectiveActionSkeleton;
