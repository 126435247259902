import { RJSFSchema, UiSchema } from '@rjsf/utils';
import {
  FIRE_THREAT_LIST,
  PLACEMENT_MATERIAL_DATA,
  PLACEMENT_TYPE_DATA,
  POLE_TYPE_LIST,
} from '../globals';

export const FIBER_ASSET_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['externalId'],
  properties: {
    externalId: {
      type: 'string',
      title: 'SAP Equipment ID',
    },
    poleType: {
      type: 'string',
      title: 'Pole Type',
    },
    fireThreat: {
      type: 'string',
      title: 'Fire Threat',
    },
    telecomWorkCenter: {
      type: 'string',
      title: 'Telecom Work Center',
    },
    placement: {
      type: 'string',
      title: 'Placement',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const FIBER_ASSET_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-16',
  'ui:inline': true,
  externalId: {
    'ui:placeholder': 'SAP Equipment ID',
  },
  poleType: {
    'ui:placeholder': 'Pole Type',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: POLE_TYPE_LIST,
    },
  },
  fireThreat: {
    'ui:placeholder': 'Fire Threat',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: FIRE_THREAT_LIST,
    },
  },
  telecomWorkCenter: {
    'ui:placeholder': 'Telecom Work Center',
  },
  placement: {
    'ui:classNames':
      'sm:col-span-2 md:col-span-2 grid sm:grid-cols-2 md:grid-cols-2 gap-x-12 gap-y-16',
    'ui:widget': 'DependencyMultiSelectWidget',
    'ui:options': {
      selectList: PLACEMENT_TYPE_DATA,
      multiselectList: PLACEMENT_MATERIAL_DATA,
    },
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-3',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};
