import { ViewerImageModel } from '../models/viewer-image.model';
import ImagesService from '../../services/images.service';
import { Buffer } from 'buffer';

export class ImageHelper {

  static loadThumbnailsForImageViewer(images: ViewerImageModel[], callback: Function): void {
    images.forEach(image => {
      ImagesService.getImageThumbnail(image)
        .then(res => {
          image.thumbnailObj = res;
          callback(images);
        });
    });
  }

  static async convertBlobToBase64(blob, mimeType): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64WithDataUrlPrefix = reader.result;

        if (typeof base64WithDataUrlPrefix === 'string') {
          resolve(base64WithDataUrlPrefix);
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  static convertSvgToBase64(svg): string {
    console.log(svg)
    return `data:image/svg+xml;base64,`;
  }
}
