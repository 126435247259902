import { DispatchingDocumentModel } from '../../../models/base-models/dispatching-document.model';

export class TelecomTowersDispatchingDocumentModel extends DispatchingDocumentModel {
  inspectionId: string;
  inspectorExternalId: string;
  dueDate: string;
  siteName: string;
  siteNumber: string;
  streetAddress: string;
  city: string;
  state: string;
  zip: string;
  comment: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
