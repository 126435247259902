import { BaseModel } from './base.model';
import { FileModel } from './file.model';
import moment from "moment";

export class CorrectiveActionModel extends BaseModel{
  notes: string;
  attachments: FileModel[];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  };

  get childModels() {
    return {
      attachments: FileModel,
    };
  }

  get dateTime(): string {
    return this.createdAt ? moment(this.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }
}