import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

type Props = {
  label: string;
  value: any;
  onChange?: Function;
  className?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const TimePicker = (props: Props) => {

  const [time, setTime] = useState(props.value ? props.value : null);

  useEffect(() => {
    if (!props.value) {
      setTime(null);
      return;
    }

    const momentObj = moment().startOf('day').add(props.value, 'hours');
    const dateObj = momentObj.toDate();

    setTime(dateObj);
  }, [props.value]);

  const timeChangedHandler = (newTime) => {
    setTime(newTime);

    const momentObj = moment(new Date(newTime));
    const decimalValue = momentObj.diff(moment().startOf('day'), 'hours', true);
    props.onChange(decimalValue);
  };

  return (
    <div className={`${props.className}`}>
      <MobileTimePicker
        label={props.label}
        value={time ? time : null}
        onChange={timeChangedHandler}
        renderInput={(params) =>
          <TextField
            size={'small'}
            fullWidth {...params}
            required={props.required}
            error={!!props.error}
            helperText={props.helperText}
            FormHelperTextProps={{style: {margin: '0px 2px'}}}
          />}
      />
    </div>
  );
};

export default TimePicker;
