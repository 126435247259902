import React from 'react';
import { Button as MuiButton, Tooltip } from "@mui/material";

type Props = {
  className?: string;
  color?: any;
  onClick: any;
  disabled: any;
  text?: string;
  hoverText?: string;
  children?: JSX.Element[] | JSX.Element;
  size?: any;
}

const Button = (props: Props) => {

  const onClickHandler = () => {
    props.onClick()
  }

  return (
    <Tooltip followCursor={true} title={props.hoverText ? props.hoverText : ''} placement="top-start">
      <MuiButton
        onTouchStart={(event) => event.stopPropagation()}
        onMouseDown={(event) => event.stopPropagation()}
        size={props.size ? props.size : 'small'}
        onClick={(event) => {
          event.stopPropagation()
          event.preventDefault()
          onClickHandler()
        }}
        className={`text-center px-16 ${props.className}`}
        variant='contained'
        color={props.color ? props.color : 'primary'}
        disabled={props.disabled}
      >
        {props.children}
        {props.text}
      </MuiButton>
    </Tooltip>
  );
};

export default Button;
