import React from 'react';
import { Skeleton } from '@mui/material';

const AssetInspectionSkeleton = () => {
  return (
    <div className={'flex flex-row items-center justify-between py-12 px-20 border-b border-gray-400'}>
      <div className={'flex items-center gap-x-12 p-2'}>
        <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '15px'}}/>
        <Skeleton variant='rounded' animation={'wave'} width={70} sx={{fontSize: '15px', borderRadius: '15px'}}/>
      </div>

      <div className={'flex items-center gap-x-12 p-2'}>
        <Skeleton variant='rounded' animation={'wave'} width={70} sx={{fontSize: '15px', borderRadius: '15px'}}/>
        <Skeleton variant='rounded' animation={'wave'} width={80} sx={{fontSize: '17px', borderRadius: '15px'}}/>
      </div>
    </div>
  );
};

export default AssetInspectionSkeleton;
