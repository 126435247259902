import { FiberWorkOrderModel } from '../models/fiber-work-order.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import moment from 'moment/moment';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import { InspectionHelper } from '../../../helpers/inspection.helper';
import { WorkOrderViewModel } from '../../../models/base-view-models/work-order-view.model';

export class FiberWorkOrderViewModel extends WorkOrderViewModel {

  private workOrder: FiberWorkOrderModel;

  constructor(workOrder: FiberWorkOrderModel) {
    super(workOrder);
    this.workOrder = workOrder;
  }

  get cardHeading(): InspectionCardHeadingModel {
    return {
      label: 'Escalation',
      value: this.returnValue(this.workOrder.code),
    };
  };

  get workOrderInfo(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Route Info', value: this.returnValue(this.workOrder.routeInfo)},
      {label: 'Condition', value: this.returnValue(this.workOrder.condition)},
      {label: 'Created By', value: this.returnValue(this.workOrder.createdBy ? this.workOrder.createdBy.name : 'N/A')},
      {
        label: 'Assigned To',
        value: this.workOrder.assignedTo && this.workOrder.assignedTo.length ? this.workOrder.assignedTo.map((inspector) => inspector.name).join(', ') : 'N/A',
      }
    ];

    fields.push(this.getExternalId);

    fields.push({label: 'Scheduled Start Date', value: this.startDate});

    fields.push({label: 'Due Date', value: this.getDueDate});

    return fields;
  };

  get workOrderAccordionInfo(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Code', value: this.returnValue(this.workOrder.code)},
    ];

    fields.push(this.getExternalId);

    return fields;
  };

  get getDueDate() {
    return this.workOrder.dueDate ? moment(`${this.workOrder.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get getCompletionDate() {
    return this.workOrder['completionDate'] ? moment(`${this.workOrder['completionDate']}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get startDate() {
    return this.workOrder.scheduledStartDate ? moment(`${this.workOrder.scheduledStartDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get dateTime(): string {
    return this.workOrder.createdAt ? moment(this.workOrder.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get linkTo(): string {
    return `/escalation/${this.workOrder.id}`;
  }

  get lastTimeStatusChanged(): string {
    if (!this.workOrder.changeHistory || !this.workOrder.changeHistory.length) {
      return;
    }
    const statusChanges = this.workOrder.changeHistory.filter(changeHistoryField => changeHistoryField.field === 'status');

    if (!statusChanges || !statusChanges.length) {
      return;
    }

    const lastTimeStatusChanged = statusChanges.pop().changedAt;

    if (!lastTimeStatusChanged) {
      return;
    }

    return lastTimeStatusChanged;
  }

  get workOrderStatusColor(): number[] {
    return InspectionHelper.getWorkOrderMapPointColor(this.workOrder.status);
  }

  get getExternalId(): InspectionCardItemModel {

    if (!!this.workOrder.sapExternalId) {
      return {label: 'SAP Work Order #', value: this.returnValue(this.workOrder.sapExternalId)};
    }

    return {label: 'Remedy Work Order #', value: this.returnValue(this.workOrder.externalId)};
  }

  get componentHeader(): string {
    return this.returnValue(this.workOrder.code);
  }

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Condition', value: this.returnValue(this.workOrder.condition)},
    ];

    fields.push(this.getExternalId);

    fields.push({label: 'Scheduled Start Date', value: this.startDate});
    fields.push({label: 'Due Date', value: this.getDueDate});
    fields.push({label: 'Completion Date', value: this.getCompletionDate});
    fields.push({label: 'Created By', value: this.returnValue(this.workOrder.createdBy ? this.workOrder.createdBy.name : 'N/A')});
    fields.push({
      label: 'Assigned To',
      value: this.workOrder.assignedTo && this.workOrder.assignedTo.length ? this.workOrder.assignedTo.map((inspector) => inspector.name).join(', ') : 'N/A',
    });
    fields.push({label: 'Work Assignee', value: this.returnValue(this.workOrder.workAssignee)});
    fields.push({label: 'Notes', value: this.returnValue(this.workOrder.notes), colSpan: 'sm:col-span-3'});

    return fields;
  }

}
