import { WidgetProps } from '@rjsf/utils';
import UserSelect from '../../UserSelect/UserSelect';

const UserSelectWidget = (props: WidgetProps) => {
  return (
    <UserSelect
      id={'inspector-select'}
      className={'w-full'}
      label={props.label}
      userIdKey={'id'}
      onChange={(event) => props.onChange(event.target.value)}
      userExternalId={props.value ? props.value : ''}
      required={props.required}
      error={!!props.rawErrors}
    />
  );
};

export default UserSelectWidget;
