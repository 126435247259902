import { ImageModel } from './image.model';

export class ViewerImageModel extends ImageModel {

  originalObj: string;
  thumbnailObj: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}