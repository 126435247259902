import { DATA_TYPE, DataType } from '../types';
import { PassiveReflectorsInspectionModel } from './models/passive-reflectors-inspection.model';
import { PassiveReflectorsAssetInspectionModel } from './models/passive-reflectors-asset-inspection.model';
import { PassiveReflectorsAssetModel } from './models/passive-reflectors-asset.model';
import { PassiveReflectorsWorkOrderModel } from './models/passive-reflectors-work-order.model';
import { PassiveReflectorsInspectionViewModel } from './view-models/passive-reflectors-inspection.view-model';
import { PassiveReflectorsAssetInspectionViewModel } from './view-models/passive-reflectors-asset-inspection.view-model';
import { PassiveReflectorsAssetViewModel } from './view-models/passive-reflectors-asset.view-model';
import { PassiveReflectorsWorkOrderViewModel } from './view-models/passive-reflectors-work-order.view-model';
import { PASSIVE_REFLECTORS_INSPECTION_STATUSES } from './statuses/passive-reflectors-inspection.statuses';
import {PASSIVE_REFLECTORS_ASSET_INSPECTION_STATUSES } from './statuses/passive-reflectors-asset-inspection.statuses';
import { PASSIVE_REFLECTORS_WORK_ORDER_STATUSES } from './statuses/passive-reflectors-work-order.statuses';
import {
  PASSIVE_REFLECTORS_INSPECTION_FORM_SCHEMA,
  PASSIVE_REFLECTORS_INSPECTION_FORM_UI_SCHEMA
} from './forms/passive-reflectors-inspection.form';
import {
  PASSIVE_REFLECTORS_ASSET_INSPECTION_FORM_SCHEMA,
  PASSIVE_REFLECTORS_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/passive-reflectors-asset-inspection.form';
import {
  PASSIVE_REFLECTORS_ASSET_FORM_SCHEMA,
  PASSIVE_REFLECTORS_ASSET_FORM_UI_SCHEMA
} from './forms/passive-reflectors-asset.form';
import {
  PASSIVE_REFLECTORS_DISPATCHING_FORM_SCHEMA,
  PASSIVE_REFLECTORS_DISPATCHING_FORM_UI_SCHEMA
} from './forms/passive-reflectors-dispatching.form';
import {
  PASSIVE_REFLECTORS_WORK_ORDER_FORM_SCHEMA,
  PASSIVE_REFLECTORS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/passive-reflectors-work-order.form';
import {
  PASSIVE_REFLECTORS_MASS_WORK_ORDER_FORM_SCHEMA,
  PASSIVE_REFLECTORS_MASS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/passive-reflectors-mass-work-order.form';
import { PassiveReflectorsDispatchingDocumentModel } from './models/passive-reflectors-dispatching-document.model';
import {
  PASSIVE_REFLECTORS_INSPECTIONS_FILTER_FORM_SCHEMA,
  PASSIVE_REFLECTORS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/passive-reflectors-inspections-filter.form';
import {
  PASSIVE_REFLECTORS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
  PASSIVE_REFLECTORS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/passive-reflectors-asset-inspections-filter.form';
import {
  PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_SCHEMA,
  PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/passive-reflectors-work-orders-filter.form';

export const PASSIVE_REFLECTORS_TYPE_CONFIG: DataType = {
  type: DATA_TYPE.passiveReflectors,
  InspectionModel: PassiveReflectorsInspectionModel,
  AssetInspectionModel: PassiveReflectorsAssetInspectionModel,
  AssetModel: PassiveReflectorsAssetModel,
  WorkOrderModel: PassiveReflectorsWorkOrderModel,
  DispatchingDocumentModel: PassiveReflectorsDispatchingDocumentModel,
  AssetViewModel: PassiveReflectorsAssetViewModel,
  WorkOrderViewModel: PassiveReflectorsWorkOrderViewModel,
  AssetInspectionViewModel: PassiveReflectorsAssetInspectionViewModel,
  InspectionViewModel: PassiveReflectorsInspectionViewModel,
  InspectionForm: {
    schema: PASSIVE_REFLECTORS_INSPECTION_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_INSPECTION_FORM_UI_SCHEMA
  },
  AssetInspectionForm: {
    schema: PASSIVE_REFLECTORS_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  AssetForm: {
    schema: PASSIVE_REFLECTORS_ASSET_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_ASSET_FORM_UI_SCHEMA
  },
  DispatchingForm: {
    schema: PASSIVE_REFLECTORS_DISPATCHING_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_DISPATCHING_FORM_UI_SCHEMA
  },
  WorkOrderForm: {
    schema: PASSIVE_REFLECTORS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_WORK_ORDER_FORM_UI_SCHEMA
  },
  MassWorkOrderForm: {
    schema: PASSIVE_REFLECTORS_MASS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_MASS_WORK_ORDER_FORM_UI_SCHEMA
  },
  InspectionsFilterForm: {
    schema: PASSIVE_REFLECTORS_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  AssetInspectionsFilterForm: {
    schema: PASSIVE_REFLECTORS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  WorkOrdersFilterForm: {
    schema: PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_SCHEMA,
    uiSchema: PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
  },
  inspectionStatuses: PASSIVE_REFLECTORS_INSPECTION_STATUSES,
  assetInspectionStatuses: PASSIVE_REFLECTORS_ASSET_INSPECTION_STATUSES,
  workOrderStatuses: PASSIVE_REFLECTORS_WORK_ORDER_STATUSES
}