import { ThemeProvider } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Hidden from '@mui/material/Hidden';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { selectFuseCurrentLayoutConfig, selectToolbarTheme } from 'app/store/fuse/settingsSlice';
import { selectFuseNavbar } from 'app/store/fuse/navbarSlice';
import NavbarToggleButton from '../../shared-components/NavbarToggleButton';
import UserMenu from '../../shared-components/UserMenu';
import Logo from 'app/theme-layouts/shared-components/Logo';
import Select from '../../../shared/components/Select/Select';
import {DEPARTMENTS} from '../../../shared/globals';
import DepartmentContext from '../../../DepartmentContext';
import UsersService from '../../../services/users.service';
import {URLHelper} from '../../../shared/helpers/url.helper';
import {useNavigate} from 'react-router-dom';

function ToolbarLayout1(props) {
    const navigate = useNavigate();
    const config = useSelector(selectFuseCurrentLayoutConfig);
    const navbar = useSelector(selectFuseNavbar);
    const toolbarTheme = useSelector(selectToolbarTheme);
    const {department, updateDepartment} = useContext(DepartmentContext);

    const [departmentList, setDepartmentList] = useState(DEPARTMENTS);

    useEffect(() => {
        const currentUserDepartment = UsersService.getCurrentUserDepartment();

        if (!currentUserDepartment || !currentUserDepartment.length) {
            setDepartmentList([]);
        }

        setDepartmentList(DEPARTMENTS.filter(departmentItem =>
            currentUserDepartment.includes(departmentItem.key))
        );
    }, []);

    const departmentChangedHandler = (e) => {
        updateDepartment(e.target.value);

        const paramsModel = {
            department: e.target.value,
        };

        const path = URLHelper.getUrlQuery(`/`, paramsModel);

        navigate(path);
    };

    return (
        <ThemeProvider theme={toolbarTheme}>
            <AppBar
                id="fuse-toolbar"
                className={clsx('flex relative z-20 shadow-md', props.className)}
                color="default"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? toolbarTheme.palette.background.paper
                            : toolbarTheme.palette.background.default,
                }}
                position="static"
            >
                <Toolbar className={'p-0 min-h-64 md:min-h-68'}>
                    <div className={'flex flex-1 md:ml-4 pl-16 pr-8'}>
                        {config.navbar.display && config.navbar.position === 'left' && (
                            <>
                                <Hidden lgDown>
                                    {(config.navbar.style === 'style-3' ||
                                        config.navbar.style === 'style-3-dense') && (
                                        <NavbarToggleButton className={'w-40 h-40 p-0 mx-0'}/>
                                    )}

                                    {config.navbar.style === 'style-1' && !navbar.open && (
                                        <NavbarToggleButton className={'w-40 h-40 p-0 mx-0'}/>
                                    )}
                                </Hidden>

                                <Hidden lgUp>
                                    <NavbarToggleButton className={'w-40 h-40 p-0 mx-0 md:mx-8'}/>
                                </Hidden>

                                {config.navbar.style === 'style-1' && !navbar.open && (
                                    <span className={'ml-5 flex items-center'}>
                                        <Logo/>
                                    </span>
                                )}

                            </>
                        )}

                    </div>

                    <div className={'flex items-center px-4 h-full overflow-x-auto w-full justify-between'}>
                        <Select
                            id={'department-select'}
                            label={'Asset Type'}
                            value={department}
                            className={'w-144 md:w-160'}
                            list={departmentList}
                            onChange={departmentChangedHandler}
                            isFilled={true}
                        />
                        {/*<NotificationPanelToggleButton/>*/}
                        <UserMenu/>
                    </div>

                    {config.navbar.display && config.navbar.position === 'right' && (
                        <>
                            <Hidden lgDown>
                                {!navbar.open && <NavbarToggleButton className={'w-40 h-40 p-0 mx-0'}/>}
                            </Hidden>

                            <Hidden lgUp>
                                <NavbarToggleButton className={'w-40 h-40 p-0 mx-0 sm:mx-8'}/>
                            </Hidden>
                        </>
                    )}
                </Toolbar>
            </AppBar>
        </ThemeProvider>
    );
}

export default memo(ToolbarLayout1);
