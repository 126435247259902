import VectorTileLayer from 'esri/layers/VectorTileLayer';
import MapImageLayer from 'esri/layers/MapImageLayer';
import FeatureLayer from 'esri/layers/FeatureLayer';
import GeometryEngine from 'esri/geometry/geometryEngine';
import Legend from 'esri/widgets/Legend/LegendViewModel';
import Point from 'esri/geometry/Point';
import WebMercatorUtils from 'esri/geometry/support/webMercatorUtils';
import Polyline from 'esri/geometry/Polyline';
import SpatialReference from 'esri/geometry/SpatialReference';
import Extent from 'esri/geometry/Extent';
import Polygon from 'esri/geometry/Polygon';
import Sketch from 'esri/widgets/Sketch';
import SketchViewModel from 'esri/widgets/Sketch/SketchViewModel';
import Popup from 'esri/widgets/Popup';
import Query from 'esri/rest/support/Query';

export class MapModulesModel {
  map: any;
  mapView: any;
  Map: any;
  MapView: any;
  VectorTileLayer: VectorTileLayer;
  MapImageLayer: MapImageLayer;
  FeatureLayer: FeatureLayer;
  GraphicsLayer: any;
  Graphic: any;
  geometryEngine: GeometryEngine;
  Legend: Legend;
  Point: Point;
  webMercatorUtils: WebMercatorUtils;
  containerNode: Node;
  Polyline: Polyline;
  SpatialReference: SpatialReference;
  Query: Query;
  Extent: Extent;
  Polygon: Polygon;
  Sketch: Sketch;
  SketchViewModel: SketchViewModel;
  QueryTask: Function;
  Popup: Popup;
  updateContext: Function;

  constructor(attributes?: any) {
    if (!attributes) {
      return;
    }

    Object.assign(this, attributes);
  }
}
