import React from 'react';

type Props = {
  label: string;
  className?: string;
  bgColor: string;
}

const Chip = (props: Props) => {
  return (
    <span className={`text-10 py-3 px-9 rounded-full whitespace-nowrap ${props.className} ${props.bgColor}`}>
      {props.label}
    </span>
  );
};

export default Chip;
