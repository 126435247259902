import React from 'react';
import { FileModel } from '../../models/file.model';
import AttachmentsListItem from './AttachmentsListItem';

type Props = {
  files: FileModel[];
  itemIcon?: JSX.Element | Function;
  onItemClick?: Function;
  onItemIconButtonClick?: Function;
  actionsAlwaysVisible?: boolean;
}

const AttachmentsList = ({files, itemIcon, onItemClick, onItemIconButtonClick, actionsAlwaysVisible}: Props) => {
  return (
    <>
      {
        files && files.map((file, index) =>
          <AttachmentsListItem key={index + file.id} file={file} itemIcon={itemIcon} onItemClick={onItemClick}
                               onItemIconButtonClick={onItemIconButtonClick} index={index} actionsAlwaysVisible={actionsAlwaysVisible} />,
        )
      }
    </>
  );
};

export default AttachmentsList;