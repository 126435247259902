import { DATA_TYPE, DataType } from '../types';
import { TelecomTowersInspectionModel } from './models/telecom-towers-inspection.model';
import { TelecomTowersAssetInspectionModel } from './models/telecom-towers-asset-inspection.model';
import { TelecomTowersAssetModel } from './models/telecom-towers-asset.model';
import { TelecomTowersWorkOrderModel } from './models/telecom-towers-work-order.model';
import { TelecomTowersInspectionViewModel } from './view-models/telecom-towers-inspection.view-model';
import { TelecomTowersAssetInspectionViewModel } from './view-models/telecom-towers-asset-inspection.view-model';
import { TelecomTowersAssetViewModel } from './view-models/telecom-towers-asset.view-model';
import { TelecomTowersWorkOrderViewModel } from './view-models/telecom-towers-work-order.view-model';
import { TELECOM_TOWERS_INSPECTION_STATUSES } from './statuses/telecom-towers-inspection.statuses';
import { TELECOM_TOWERS_ASSET_INSPECTION_STATUSES } from './statuses/telecom-towers-asset-inspection.statuses';
import { TELECOM_TOWERS_WORK_ORDER_STATUSES } from './statuses/telecom-towers-work-order.statuses';
import {
  TELECOM_TOWERS_INSPECTION_FORM_SCHEMA,
  TELECOM_TOWERS_INSPECTION_FORM_UI_SCHEMA
} from './forms/telecom-towers-inspection.form';
import {
  TELECOM_TOWERS_ASSET_INSPECTION_FORM_SCHEMA,
  TELECOM_TOWERS_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/telecom-towers-asset-inspection.form';
import {
  TELECOM_TOWERS_ASSET_FORM_SCHEMA,
  TELECOM_TOWERS_ASSET_FORM_UI_SCHEMA
} from './forms/telecom-towers-asset.form';
import {
  TELECOM_TOWERS_DISPATCHING_FORM_SCHEMA,
  TELECOM_TOWERS_DISPATCHING_FORM_UI_SCHEMA
} from './forms/telecom-towers-dispatching.form';
import {
  TELECOM_TOWERS_WORK_ORDER_FORM_SCHEMA,
  TELECOM_TOWERS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/telecom-towers-work-order.form';
import {
  TELECOM_TOWERS_MASS_WORK_ORDER_FORM_SCHEMA,
  TELECOM_TOWERS_MASS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/telecom-towers-mass-work-order.form';
import { TelecomTowersDispatchingDocumentModel } from './models/telecom-towers-dispatching-document.model';
import {
  TELECOM_TOWERS_INSPECTIONS_FILTER_FORM_SCHEMA,
  TELECOM_TOWERS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/telecom-towers-inspections-filter.form';
import {
  TELECOM_TOWERS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
  TELECOM_TOWERS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/telecom-towers-asset-inspections-filter.form';
import {
  TELECOM_TOWERS_WORK_ORDERS_FILTER_FORM_SCHEMA,
  TELECOM_TOWERS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/telecom-towers-work-orders-filter.form';

export const TELECOM_TOWERS_TYPE_CONFIG: DataType = {
  type: DATA_TYPE.telecomTowers,
  InspectionModel: TelecomTowersInspectionModel,
  AssetInspectionModel: TelecomTowersAssetInspectionModel,
  AssetModel: TelecomTowersAssetModel,
  WorkOrderModel: TelecomTowersWorkOrderModel,
  DispatchingDocumentModel: TelecomTowersDispatchingDocumentModel,
  AssetViewModel: TelecomTowersAssetViewModel,
  WorkOrderViewModel: TelecomTowersWorkOrderViewModel,
  AssetInspectionViewModel: TelecomTowersAssetInspectionViewModel,
  InspectionViewModel: TelecomTowersInspectionViewModel,
  InspectionForm: {
    schema: TELECOM_TOWERS_INSPECTION_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_INSPECTION_FORM_UI_SCHEMA
  },
  AssetInspectionForm: {
    schema: TELECOM_TOWERS_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  AssetForm: {
    schema: TELECOM_TOWERS_ASSET_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_ASSET_FORM_UI_SCHEMA
  },
  DispatchingForm: {
    schema: TELECOM_TOWERS_DISPATCHING_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_DISPATCHING_FORM_UI_SCHEMA
  },
  WorkOrderForm: {
    schema: TELECOM_TOWERS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_WORK_ORDER_FORM_UI_SCHEMA
  },
  MassWorkOrderForm: {
    schema: TELECOM_TOWERS_MASS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_MASS_WORK_ORDER_FORM_UI_SCHEMA
  },
  InspectionsFilterForm: {
    schema: TELECOM_TOWERS_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  AssetInspectionsFilterForm: {
    schema: TELECOM_TOWERS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  WorkOrdersFilterForm: {
    schema: TELECOM_TOWERS_WORK_ORDERS_FILTER_FORM_SCHEMA,
    uiSchema: TELECOM_TOWERS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
  },
  inspectionStatuses: TELECOM_TOWERS_INSPECTION_STATUSES,
  assetInspectionStatuses: TELECOM_TOWERS_ASSET_INSPECTION_STATUSES,
  workOrderStatuses: TELECOM_TOWERS_WORK_ORDER_STATUSES
}