import { useContext, useEffect } from 'react';
import { MapsLibraryContext } from '../../components/EsriMapsContextLoader';
import { MapModulesModel } from '../../../../models/map-modules.model';

type Props = {
  basemap?: string;
}

const MapView = (props: Props) => {
  let {
    map,
    mapView,
    Map,
    MapView,
    containerNode,
    VectorTileLayer,
    updateContext,
  }: MapModulesModel = useContext(MapsLibraryContext) as MapModulesModel;

  useEffect(() => {
    if (!Map) {
      return;
    }

    map = new Map({
      basemap: !!props.basemap ? props.basemap : 'gray-vector',
    });

    mapView = MapView({
      map: map,
      container: containerNode,
      zoom: 13,
    });

    updateContext(prev => new MapModulesModel({...prev, map, mapView}));
  }, [Map]);

  useEffect(() => {

    if (!map) {
      return;
    }

    map.basemap = !!props.basemap ? props.basemap : 'gray-vector';

    updateContext(prev => new MapModulesModel({...prev, map}));
  }, [props.basemap]);

  return (
    <></>
  );
};

export default MapView;
