import React, { useRef, useState } from 'react';
import { Card, CircularProgress, IconButton, SvgIcon, Tooltip, Typography } from '@mui/material';
import DueDateChart from '../../../shared/components/DueDateChart/DueDateChart';
import { ReactComponent as Download } from '../../../../assets/icons/material_download.svg';
import html2canvas from 'html2canvas';

type Props = {
  report: any[];
  title?: string;
  pngTitle?: string;
  linkTo?: string;
  queryParams?: any;
}

const DueDateReportingCard = (props: Props) => {

  const [isDownloading, setIsDownloading] = useState(false);
  const chartRef = useRef(null);

  const timestamp = new Date().toISOString();

  const handleExportClick = () => {
    if (chartRef.current) {
      setIsDownloading(true);
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `${props.pngTitle}-${timestamp}.png`;
        link.click();
      }).then(() => setIsDownloading(false));
    }
  };

  // const [inspectionsReport, setInspectionsReport] = useState([]);
  //
  // useEffect(() => {
  //   if (!props.inspectionsReport || !props.inspectionsReport.length) {
  //     return;
  //   }
  //   setInspectionsReport(InspectionHelper.getInspectionReporting(props.inspectionsReport));
  // }, [props.inspectionsReport]);

  return (
    <Card className={'shadow overflow-hidden rounded-2xl px-22 p-16 pb-4 mb-2'}>
      <div className={'flex justify-between'}>
        <Typography className={'text-lg font-medium tracking-tight leading-6 truncate flex'}>
          {props.title}
        </Typography>
        <Tooltip title={'Export Chart as PNG'} placement='top-start' followCursor={true}>
          <IconButton className={`ml-3`} onClick={handleExportClick} size={'small'}>
            {
              isDownloading ?
                <CircularProgress color={'inherit'} size={20} thickness={4}/> :
                <SvgIcon fontSize={'small'} className={'bg-white rounded'} color={'inherit'}>
                  <Download/>
                </SvgIcon>
            }
          </IconButton>
        </Tooltip>
      </div>
      {/*<div className={'flex flex-col md:flex-row w-full justify-between gap-y-12 gap-x-20'}>*/}
      {/*  <DueDateReportingInfoCards inspectionsReport={inspectionsReport} className={'mb-20 md:mb-2 pt-24 pb-36'}/>*/}

      <div className={'w-full overflow-x-scroll'} ref={chartRef}>
        <DueDateChart data={props.report} linkTo={props.linkTo} queryParams={props.queryParams}/>
      </div>

      {/*</div>*/}
    </Card>
  );
};

export default DueDateReportingCard;
