import axiosInstance from './axiosInstance';

const uploadToPreSignedUrl = (preSignedUrl: string, file): Promise<void> => {
  const headers = {
    'Content-Type': file.type,
  };

  return axiosInstance.put(preSignedUrl, file, {headers});
};

const FileUploadClient = {uploadToPreSignedUrl};

export default FileUploadClient;