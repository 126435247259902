import React, { useEffect, useState } from 'react';
import {
  Card,
  Typography,
} from '@mui/material';
import CorrectiveActionSkeleton from './CorrectiveActionSkeleton';
import CorrectiveActionItem from './CorrectiveActionItem';
import { CorrectiveActionModel } from '../../../shared/models/corrective-action.model';

type Props = {
  correctiveActions: CorrectiveActionModel[];
  isLoading: boolean;
  className?: string;
}

const CorrectiveActionDetailsCard = (props: Props) => {

  const [correctiveActions, setCorrectiveActions] = useState([]);

  useEffect(() => {
    if (!props.correctiveActions) {
      setCorrectiveActions([]);
      return;
    }

    setCorrectiveActions(props.correctiveActions);
  }, [props.correctiveActions]);

  return (
    <Card className={`shadow overflow-hidden rounded-md px-24 pb-24 pt-16 ${props.className}`}>
      <Typography className='text-xl font-bold mb-12'>Corrective Action Details</Typography>
      {
        !!props.isLoading &&
          <div className={`border border-gray-400 rounded-md flex flex-col`}>
              <CorrectiveActionSkeleton className={'border-b border-gray-400'}/>
              <CorrectiveActionSkeleton />
          </div>
      }
      {
        !props.isLoading && (!correctiveActions || (correctiveActions && correctiveActions.length === 0)) &&
          <p className={'text-14'}>N/A - No Corrective Action Added</p>
      }
      {
        !props.isLoading && correctiveActions && correctiveActions.length > 0 &&
          <div className={'flex flex-col border border-gray-400 rounded-md'}>
            {correctiveActions.map((correctiveAction, index) =>
              <CorrectiveActionItem key={index} correctiveAction={correctiveAction}
                                    className={`${index !== correctiveActions.length - 1 ? 'border-b' : ''}`}/>
            )}
          </div>
      }
    </Card>
  );
};

export default CorrectiveActionDetailsCard;
