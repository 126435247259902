import { WidgetProps } from '@rjsf/utils';
import UserMultiSelect from '../../UserMultiSelect/UserMultiSelect';

const UserExternalIdMultiSelectWidget = (props: WidgetProps) => {  
  return (
    <UserMultiSelect
      id={'inspectors-select'}
      className={'w-full sm:col-span-2'}
      label={props.label}
      onChange={(event) => props.onChange(event)}
      usersIds={props.value && props.value.length ? props.value : []}
    />
  );
};

export default UserExternalIdMultiSelectWidget;
