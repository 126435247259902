import React from 'react';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Card,
  TableContainer,
  TablePagination,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URLHelper } from '../../../shared/helpers/url.helper';
import { INSPECTORS_TABLE_COLUMNS } from '../../../shared/globals';
import StraightIcon from '@mui/icons-material/Straight';

type Props = {
  rows: any;
  queryParams: any;
};

const InspectorsTable = (props: Props) => {

  const navigate = useNavigate();

  const [tableData, setTableData] = useState(props.rows);
  const [sortField, setSortField] = useState('inspections');
  const [order, setOrder] = useState('desc');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleSortingChange = (key: string) => {
    const sortOrder =
      key === sortField && order === 'desc' ? 'asc' : 'desc';
    setSortField(key);

    setOrder(sortOrder);
    handleSorting(key, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const routeChange = (linkTo: string, queryParams) => {
    const path = URLHelper.getUrlQuery(linkTo, queryParams);

    navigate(path);
  }

  const tableCell = (key, index, linkTo, text, queryParams) =>
    <TableCell key={key} component="th" scope="row"
               onClick={() => routeChange(linkTo, queryParams)}
               className={`${index === 0 ? 'pl-0 text-left' : 'pr-7 text-right hover:bg-gray-300 cursor-pointer'} w-1/6`}>
      <Typography className={'whitespace-nowrap'}>{text}</Typography>
    </TableCell>;

  return (
    <Card className={'shadow overflow-hidden rounded-2xl px-22 p-16 pb-4 mb-2'}>
      <Typography className={'text-lg font-medium tracking-tight leading-6 truncate mb-6'}>
        Inspector Stats
      </Typography>

      <TableContainer>
        <Table className={'w-full min-w-full'} size={'small'}>
          <TableHead>
            <TableRow>
              {INSPECTORS_TABLE_COLUMNS.map(({key, label}: any, index: any) => (
                <TableCell key={key} onClick={() => handleSortingChange(key)}
                           className={`${index === 0 ? 'pl-0 text-left' : 'pr-3 text-right'} w-1/6`}>
                  <Typography
                    color='text.secondary'
                  >
                     <span
                       className={`flex items-center ${index === 0 ? 'justify-start' : 'justify-end'} cursor-pointer text-12 font-semibold whitespace-nowrap hover:bg-gray-300`}>
                       <TableSortLabel
                         active={sortField === key}
                         className={`${sortField === key ? '' : 'hidden'}`}
                         direction={sortField === key ? (order === 'desc' ? 'asc' : 'desc') : 'desc'}
                         onClick={() => handleSortingChange(key)}
                         IconComponent={StraightIcon}
                         sx={{
                           '& .MuiTableSortLabel-icon': {
                             padding: 0,
                             margin: 0,
                             height: '16px',
                             width: sortField === key ? '14px' : '0px'
                           },
                         }}
                       />
                       {label}
                     </span>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: any) => (
              <TableRow key={index} className={'hover:bg-gray-100'}>
                {Object.entries(row).map(([key, value], objectItemIndex) => {
                    switch (key) {
                      case 'inspectorName': {
                        return <TableCell key={key} component="th" scope="row"
                                          className={`pl-0 text-left w-1/6`}>
                          <Typography className={'whitespace-nowrap'}>{value.toString()}</Typography>
                        </TableCell>;
                      }
                      case 'inspectorExternalId': {
                        return <TableCell key={key} className={`hidden`}>
                        </TableCell>;
                      }
                      case 'inspections': {
                        const queryParams = {...props.queryParams, 'inspector': row['inspectorExternalId']};
                        return tableCell(key, objectItemIndex, '/inspections', value.toString(), queryParams);
                      }
                      case 'inspectedAssets': {
                        const queryParams = {...props.queryParams, 'inspector': row['inspectorExternalId']};
                        return tableCell(key, objectItemIndex, '/asset-inspections', value.toString(), queryParams);
                      }
                      case 'conditionsFound': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition-found': 'true',
                          'inspector': row['inspectorExternalId']
                        };
                        return tableCell(key, objectItemIndex, '/asset-inspections', value.toString(), queryParams);
                      }
                      case 'escalationsCreated': {
                        const queryParams = {...props.queryParams, 'created-by': row['inspectorExternalId']};
                        return tableCell(key, objectItemIndex, '/escalations', value.toString(), queryParams);
                      }
                      case 'WOsCreated': {
                        const queryParams = {...props.queryParams, 'created-by': row['inspectorExternalId']};
                        return tableCell(key, objectItemIndex, '/escalations', value.toString(), queryParams);
                      }
                      default: {
                        return (
                          <TableCell key={key} component="th" scope="row"
                                     className={`${objectItemIndex === 0 ? 'pl-0 text-left' : 'pr-0 text-right hover:bg-gray-300 cursor-pointer'} w-1/6`}>
                            <Typography className={'whitespace-nowrap'}>{value.toString()}</Typography>
                          </TableCell>
                        )
                      }
                    }
                  }
                )}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

      <TablePagination
        className={'overflow-hidden'}
        SelectProps={{
          inputProps: {'aria-label': 'rows per page'},
          native: true,
        }}
        nextIconButtonProps={{size: 'small'}}
        backIconButtonProps={{size: 'small'}}
        rowsPerPageOptions={[5]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

export default InspectorsTable;
