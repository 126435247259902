import { WidgetProps } from '@rjsf/utils';
import DatePicker from '../../DatePicker/DatePicker';
import moment from 'moment/moment';

const DateWidget = (props: WidgetProps) => {  
  return (
    <DatePicker
      value={props.value ? props.value : ''}
      label={props?.label}
      onChange={(value) =>
        props.onChange(value ? moment(value).format('YYYY-MM-DD') : undefined)
      }
      required={props.required}
      error={!!props.rawErrors}
    />
  );
};

export default DateWidget;
