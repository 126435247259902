import { DataComponentModel } from '../interfaces/data-component.model';
import { InspectionCardItemModel } from '../inspection-card-item.model';
import { InspectionCardHeadingModel } from '../inspection-card-heading.model';
import { BaseViewModel } from './base-view.model';
import { WorkOrderModel } from '../base-models/work-order.model';

export abstract class WorkOrderViewModel extends BaseViewModel implements DataComponentModel {

  protected constructor(workOrder: WorkOrderModel) {
    super(workOrder);
  }

  get readyForQa(): boolean {
    throw new Error('Method not implemented.');
  }

  get additionalItemField(): InspectionCardItemModel {
    throw new Error('Method not implemented.');
  }

  get buttonAction(): Function {
    throw new Error('Method not implemented.');
  }

  get buttonText(): string {
    throw new Error('Method not implemented.');
  }

  abstract get componentHeader(): string;

  abstract get dateTime(): string;

  abstract get detailCardFields(): InspectionCardItemModel[];

  get headingItemFields(): InspectionCardItemModel[] {
    throw new Error('Method not implemented.');
  }

  get infoCardFields(): InspectionCardItemModel[] {
    throw new Error('Method not implemented.');
  }

  abstract get linkTo(): string;

  get listItemFieldsClassName(): string {
    throw new Error('Method not implemented.');
  }

  get listItemFields(): InspectionCardItemModel[] {
    throw new Error('Method not implemented.');
  }

  get listItemHeading(): InspectionCardHeadingModel {
    throw new Error('Method not implemented.');
  }

  get secondaryListItemFields(): InspectionCardItemModel[] {
    throw new Error('Method not implemented.');
  }

  get secondaryListItemFieldsClassName(): string {
    throw new Error('Method not implemented.');
  }

  abstract get lastTimeStatusChanged(): string;

  get previousNextListItem(): any {
    throw new Error('Method not implemented.');
  }

  get additionalAttachmentsTitle(): any {
    throw new Error('Method not implemented.');
  }

  get componentHeaderTitle(): string {
    throw new Error('Method not implemented.');
  }
}