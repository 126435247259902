import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import AssetInspections from './AssetInspections';

i18next.addResourceBundle('en', 'assetInspectionsPage', en);

const AssetInspectionsConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'asset-inspections',
      element: <AssetInspections />,
    },
  ],
};

export default AssetInspectionsConfig;
