import React, { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import InspectionsService from '../../services/inspections.service';
import InspectionHeadingCard from '../../shared/components/InspectionCard/InspectionHeadingCard';
import AssetsMap from '../../shared/components/AssetsMap/AssetsMap';
import { CircularProgress } from '@mui/material';
import AssetInspectionCard from '../asset-inspections/components/AssetInspectionCard';
import InspectionModal from './components/InspectionModal';
import ChangeLogModal from './components/ChangeLogModal';
import { NotificationMessage } from '../../shared/components/NotificationMessage/NotificationMessage';
import { useDispatch } from 'react-redux';
import { CommentModel } from '../../shared/models/comment.model';
import MapContext from '../../MapContext';
import MassWorkOrderModal from './components/MassWorkOrderModal';
import { InspectionModel } from '../../shared/models/base-models/inspection.model';
import { InspectionViewModelFactory } from '../../shared/view-model-factories/inspection-view-model.factory';
import { InspectionViewModel } from '../../shared/models/base-view-models/inspection-view.model';
import { AssetInspectionViewModel } from '../../shared/models/base-view-models/asset-inspection-view.model';
import { AssetInspectionViewModelFactory } from '../../shared/view-model-factories/asset-inspection-view-model.factory';
import { AssetInspectionModel } from '../../shared/models/base-models/asset-inspection.model';
import { AssetInspectionMapModel } from '../../shared/models/map-assets/asset-inspection-map.model';
import { MapHelper } from '../../shared/helpers/map.helper';
import DepartmentContext from '../../DepartmentContext';

const InspectionContent = () => {

  const {updateDepartment} = useContext(DepartmentContext);
  const params = useParams();
  const dispatch = useDispatch();

  const {mapOpened, updateMapOpened} = useContext(MapContext);
  const [isLoading, setIsLoading] = useState(true);
  const [inspection, setInspection] = useState(new InspectionModel());
  const [inspectionView, setInspectionView] = useState(null as InspectionViewModel);
  const [refs, setRefs] = useState({} as any);
  const [clickedItemId, setClickedItemId] = useState([]);
  const [clickedPointId, setClickedPointId] = useState('');
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [hoveredPointId, setHoveredPointId] = useState('');
  const [mapPoints, setMapPoints] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openMassWorkOrderModal, setOpenMassWorkOrderModal] = useState(false);
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false);

  const headerCardRef = useRef(null);
  const assetsContainerRef = useRef(null);

  const [headerCardHeight, setHeaderCardHeight] = useState(0);
  const [assetsContainerWidth, setAssetsContainerWidth] = useState(0);

  const [assetInspectionsView, setAssetInspectionsView] = useState([] as AssetInspectionViewModel[]);

  useEffect(() => {
    setIsLoading(true);
    InspectionsService.getInspection(params.id)
      .then(result => {
        if (!result) {
          window.location.href = '/dashboard';
        }
        updateDepartment(result.type);

        setRefs(
          result.assetInspections.reduce((acc, value) => {
            acc[value.id] = React.createRef();
            return acc;
          }, {}),
        );

        setInspection(result);
        if (result.assetInspections && result.assetInspections.length) {
          setAssetInspectionsView(
            new AssetInspectionViewModelFactory().mapViewModels<AssetInspectionViewModel, AssetInspectionModel>(result.assetInspections))
        }
        setInspectionView(new InspectionViewModelFactory().createViewModel<InspectionViewModel, InspectionModel>(result));

        const mapAssets = result.assetInspections.map(assetInspection => new AssetInspectionMapModel(assetInspection));
        setMapPoints(MapHelper.getAssetMapPoints(mapAssets));
        setIsLoading(false);
      });
  }, [params.id]);

  const inspectionUpdatedHandler = (inspection: InspectionModel, comments: CommentModel[] = []) => {
    setInspection(inspection);
    setInspectionView(new InspectionViewModelFactory().createViewModel<InspectionViewModel, InspectionModel>(inspection));
  };

  const commentAddedHandler = async (commentText: string) => {
    const body = {
      text: commentText,
    };

    return InspectionsService.addComment(inspection.id, body)
      .then((res) => {
        dispatch(NotificationMessage('Comment added successfully', 'success'));
        let updatedInspection = inspection;
        updatedInspection.comments = [...inspection.comments, res];
        setInspection(updatedInspection);
        setInspectionView(new InspectionViewModelFactory().createViewModel<InspectionViewModel, InspectionModel>(updatedInspection));
      })
      .catch(() => {
        dispatch(NotificationMessage('Error saving comment', 'error'));
      });
  };

  const inspectionMassWorkOrderHandler = (inspection: InspectionModel) => {
    setInspection(inspection);
  };

  const handleMapChange = () => {
    updateMapOpened(!mapOpened);
  };

  const openEditModalHandler = () => {
    setOpenEditModal(true);
  };

  const onEditModalCloseHandler = () => {
    setOpenEditModal(false);
  };

  const openMassWorkOrderModalHandler = () => {
    setOpenMassWorkOrderModal(true);
  };

  const onMassWorkOrderModalCloseHandler = () => {
    setOpenMassWorkOrderModal(false);
  };

  const openChangeLogModalHandler = () => {
    setOpenChangeLogModal(true);
  };

  const onChangeLogModalCloseHandler = () => {
    setOpenChangeLogModal(false);
  };

  useLayoutEffect(() => {
    setHeaderCardHeight(headerCardRef.current ? headerCardRef.current.clientHeight : 0);
    setAssetsContainerWidth(assetsContainerRef.current.clientWidth);
  }, [headerCardRef.current]);

  const onItemHoverHandler = (id: string) => {
    if (!id) {
      return;
    }

    setHoveredItemId([id]);
    setHoveredPointId(id);
  };

  const onPointClickHandler = (id: string) => {

    setClickedPointId(id);

    const assetInspections = inspection.assetInspections.filter(assetInspection => assetInspection.id === id);
    if (!assetInspections || !assetInspections.length) {
      setClickedItemId([]);
      return;
    }

    setClickedItemId(assetInspections.map(assetInspection => assetInspection.id));
    handleListScroll(assetInspections[0].id);
  };

  const onItemClickHandler = (id: string) => {
    if (!id) {
      return;
    }

    setClickedPointId(id);

    setClickedItemId([id]);
    handleListScroll(id);
  };

  const isListItemSelected = (listItemId) => {
    if (!clickedItemId.length) {
      return null;
    }

    return !!clickedItemId.some(clickedItemId => clickedItemId === listItemId);
  };

  const onNotSelectedCardClickHandler = (id: string) => {
    setClickedItemId([id]);
    setClickedPointId(id);
  };

  const handleListScroll = (id) => refs[id].current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });

  const variantsItem = {
    hidden: {opacity: 0, y: 20},
    show: {opacity: 1, y: 0},
  };

  return (
    <>
      <AssetsMap points={mapPoints} topPadding={headerCardHeight + 50} leftPadding={assetsContainerWidth / 2 + 50}
                 rightPadding={20} bottomPadding={20} clickedPointId={clickedPointId} mapOpened={mapOpened}
                 hoveredPointId={hoveredPointId} onPointClickHandler={onPointClickHandler} />
      <div
        className={`h-full flex flex-col relative w-full p-14 lg:p-28 block
         overflow-auto pointer-events-auto sm:overflow-hidden sm:pointer-events-none`}>
        <motion.div variants={variantsItem} className='mb-12 mt-2 pointer-events-auto px-2'>
          {
            // TODO: Remove inspectionStatus parameter
            !isLoading &&
            <div ref={headerCardRef}>
              <InspectionHeadingCard
                dataModel={inspectionView}
                mapOpened={mapOpened}
                handleMapChange={handleMapChange}
                itemsWrapperClassName={`${mapOpened ? '' : 'md:flex-row'}`}
                openEditModal={openEditModalHandler}
                openMassWorkOrderModal={openMassWorkOrderModalHandler}
                buttonLabel={'Download Report'}
                elementClassName={`w-full grid sm:grid-cols-4 gap-8 align-middle`}
                openChangeLogModal={openChangeLogModalHandler}
                hasChangeHistory={!!inspectionView.changeLogHistory && !!inspectionView.changeLogHistory.length}
                onSave={inspectionUpdatedHandler}
                inspectionStatus={inspection.status}
                inspection={inspection} />
            </div>
          }
        </motion.div>
        <motion.div
          className={`px-2 rounded-lg ${isLoading ? 'h-full' : 'h-auto'} 
          sm:overflow-auto sm:pointer-events-auto ${mapOpened && 'w-full sm:w-2/5'}`}
          transition={{type: 'all'}} ref={assetsContainerRef} onMouseLeave={() => {
          setHoveredItemId([]);
          setHoveredPointId('');
        }}>
          {
            isLoading &&
            <div className={'w-full h-full flex text-center'}>
              <CircularProgress className={'m-auto'} size={52} thickness={4} />
            </div>
          }
          {
            !inspection['read-only'] && !isLoading && inspection.assetInspections.length === 0 &&
            <p className={'text-16'}>No Items Found</p>
          }
          {
            //  TODO: Needs refactoring, base inspection model doesn't necessarily need to have asset inspections
            !isLoading && assetInspectionsView && !!assetInspectionsView.length &&
            assetInspectionsView
              .map((itemView: AssetInspectionViewModel, index) => {
                const assetInspectionData = inspection.assetInspections[index];
                return (
                  <motion.div key={index} variants={variantsItem} className='mb-8' onMouseEnter={() => {
                    onItemHoverHandler(assetInspectionData.id);
                  }} ref={refs[assetInspectionData.id]}>
                    <AssetInspectionCard
                      dataModel={itemView}
                      isInspectionList={true}
                      isHovered={mapOpened && !clickedPointId && hoveredItemId && !!hoveredItemId.some(hoveredItemId => hoveredItemId === assetInspectionData.id)}
                      assetInspectionData={assetInspectionData}
                      mapOpened={mapOpened}
                      isSelected={isListItemSelected(assetInspectionData.id)}
                      onHoveredCardClickHandler={onItemClickHandler}
                      onNotSelectedCardClickHandler={onNotSelectedCardClickHandler}
                      contentElementClassName={`${mapOpened ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4' : `${itemView.secondaryListItemFieldsClassName}`}`}
                    />
                  </motion.div>
                );
              })
          }
        </motion.div>
      </div>
      {openEditModal &&
        <InspectionModal onClose={onEditModalCloseHandler}
                         onSave={inspectionUpdatedHandler}
                         inspection={inspection} />
      }
      {openMassWorkOrderModal &&
        <MassWorkOrderModal onClose={onMassWorkOrderModalCloseHandler}
                            onSave={inspectionMassWorkOrderHandler}
                            inspection={inspection} />
      }
      {openChangeLogModal &&
        <ChangeLogModal onClose={onChangeLogModalCloseHandler} isLoading={isLoading}
                        dataModel={inspectionView}
                        commentAddedHandler={commentAddedHandler}
        />
      }
    </>
  );
};

export default memo(InspectionContent);
