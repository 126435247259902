import React, { useState } from 'react';
import { CorrectiveActionModel } from '../../../shared/models/corrective-action.model';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import ModelAttachments from '../../../shared/components/ModelAttachments/ModelAttachments';
import CorrectiveActionForm from './CorrectiveActionForm';
import WorkOrdersService from "../../../services/work-orders.service";
import { NotificationMessage } from "../../../shared/components/NotificationMessage/NotificationMessage";
import { useDispatch } from "react-redux";

type Props = {
  onSave: Function;
  onClose: Function;
  workOrderId?: string;
}

const CorrectiveActionModal = (props: Props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [correctiveAction, setCorrectiveAction] = useState(new CorrectiveActionModel());

  const handleSubmit = async () => {
    setIsLoading(true);

    WorkOrdersService.createCorrectiveAction(props.workOrderId, correctiveAction)
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Corrective Action created successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage( 'Error saving Corrective Action', 'error'));
        setIsLoading(false);
      });
  };

  const correctiveActionChangedHandler = (correctiveActionChanges) => {
    setCorrectiveAction(prevState => new CorrectiveActionModel({...prevState, ...correctiveActionChanges}));
  };

  return (
    <Modal onClose={props.onClose} title={'Add Corrective Action'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSubmit}
                 text={'Submit'}
               />
             </div>
           }
    >
      <CorrectiveActionForm correctiveAction={correctiveAction} className={'px-24 py-16'}
                     onChanged={correctiveActionChangedHandler}
      />
      <ModelAttachments attachments={correctiveAction.attachments} onChanged={correctiveActionChangedHandler}
                        className={`w-full mb-20 px-24`}
      />
    </Modal>
  );
};

export default CorrectiveActionModal;
