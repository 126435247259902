import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '../../../shared/components/Dialog/Dialog';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import WorkOrdersService from '../../../services/work-orders.service';

type Props = {
  onDelete: Function;
  onClose: Function;
  workOrderId?: string;
}

const DeleteWorkOrderDialog = (props: Props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    WorkOrdersService.deleteWorkOrder(props.workOrderId)
      .then(res => {
        setIsLoading(false);
        props.onClose();
      })
      .then(() => {
        dispatch(NotificationMessage('Escalation deleted successfully', 'success'));
      })
      .then(() => {
        setTimeout(() => props.onDelete(), 225);
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error deleting Escalation';
        dispatch(NotificationMessage(message, 'error'));
        setIsLoading(false);
      });
  };

  const handleClose = () => {
    props.onClose();
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <Dialog
      title={'Are you sure?'}
      text={'This action will delete Escalation and it cannot be undone.'}
      actionsContent={[
        <LoadingButton
          key={'cancelAction'}
          disabled={false}
          color={'inherit'}
          onClick={handleClose}
          text={'Cancel'}
        />,
        <LoadingButton
          key={'submitAction'}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
          text={'Confirm'}
        />
      ]} onClose={props.onClose}>
      <></>
    </Dialog>
  );
};

export default DeleteWorkOrderDialog;
