import { FiberAssetInspectionModel } from './fiber-asset-inspection.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { CommentModel } from '../../../models/comment.model';
import { UserModel } from '../../../models/user.model';
import { AddressModel } from './address.model';
import { InspectionModel } from '../../../models/base-models/inspection.model';

export class FiberInspectionModel extends InspectionModel {
  id: string;
  routeInfo: string;
  externalId: string;
  type: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  dueDate: string;
  assignedTo: UserModel[];
  assetInspections: FiberAssetInspectionModel[];
  comments?: CommentModel[];
  address: AddressModel;
  changeHistory?: FieldChangeModel[];
  readonly: boolean;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

  get childModels() {
    return {
      assetInspections: FiberAssetInspectionModel,
      changeHistory: FieldChangeModel,
      assignedTo: UserModel,
      comments: CommentModel,
      address: AddressModel,
    };
  }

}
