import { createContext, useContext, useEffect, useState } from 'react';
import DepartmentContext from './DepartmentContext';
import UsersService from './services/users.service';
import {DepartmentHelper} from './shared/helpers/department.helper';

const InspectorsContext = createContext();

export const InspectorsProvider = ({ children }) => {

    const [inspectorsDepartment, setInspectorsDepartment] = useState('');
    const [inspectors, setInspectors] = useState([]);

    const {department} = useContext(DepartmentContext);

    useEffect(() => {
        if (!department || department === inspectorsDepartment) {
            return;
        }

        setInspectorsDepartment(department);
    }, [department]);

    useEffect(() => {
        if (!inspectorsDepartment || DepartmentHelper.isAllDepartments(inspectorsDepartment)) {
            setInspectors([]);
            return;
        }

        UsersService.getInspectors(inspectorsDepartment)
            .then((res) => {
                setInspectors(res);
            });
    }, [inspectorsDepartment]);

    return (
        <InspectorsContext.Provider value={{ inspectors }}>
            {children}
        </InspectorsContext.Provider>
    );
};

export default InspectorsContext;
