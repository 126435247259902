import { Menu as MaterialMenu, MenuList } from '@mui/material';

type Props = {
  menuItems: JSX.Element[];
  open: boolean;
  anchorEl: null | HTMLElement;
  onClose: (event: {}, reason: "backdropClick" | "escapeKeyDown") => void;
}

const Menu = (props: Props) => {

  return (
    <MaterialMenu open={props.open} anchorEl={props.anchorEl} onClose={props.onClose}>
      <MenuList dense className={'py-0'}>
        {props.menuItems}
      </MenuList>
    </MaterialMenu>
  );
};

export default Menu;
