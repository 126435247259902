import { StatusType } from '../../types';

export const PASSIVE_REFLECTORS_WORK_ORDER_STATUS = {
  waitingAssignment: 'waiting-assignment',
  assigned: 'assigned',
  pending: 'pending',
  planning: 'planning',
  inProgress: 'in-progress',
  completed: 'completed',
  closed: 'closed',
  canceled: 'canceled',
};

export const PASSIVE_REFLECTORS_WORK_ORDER_STATUSES: StatusType[] = [
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.waitingAssignment,
    value: 'Waiting Assignment',
    color: 'gray-500',
    bgColor: 'bg-gray-500',
    bgColorRGB: [158, 158, 158],
    buttonBgColor: '#9E9E9E',
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.assigned,
    value: 'Assigned',
    color: 'orange-600',
    bgColor: 'bg-orange-600',
    bgColorRGB: [251, 140, 0],
    buttonBgColor: '#FB8C00',
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.pending,
    value: 'Pending',
    color: 'orange-800',
    bgColor: 'bg-orange-800',
    bgColorRGB: [239, 108, 0],
    buttonBgColor: '#EF6C00',
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.planning,
    value: 'Planning',
    color: 'blue-400',
    bgColor: 'bg-blue-400',
    bgColorRGB: [66, 165, 245],
    buttonBgColor: '#42A5F5',
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.inProgress,
    value: 'In Progress',
    color: 'blue-600',
    bgColor: 'bg-blue-600',
    buttonBgColor: '#1E88E5',
    bgColorRGB: [30, 136, 229],
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.completed,
    value: 'Completed',
    color: 'green-400',
    bgColor: 'bg-green-400',
    buttonBgColor: '#66BB6A',
    bgColorRGB: [102, 187, 106],
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.closed,
    value: 'Closed',
    color: 'green-600',
    bgColor: 'bg-green-600',
    buttonBgColor: '#43A047',
    bgColorRGB: [67, 160, 71],
  },
  {
    key: PASSIVE_REFLECTORS_WORK_ORDER_STATUS.canceled,
    value: 'Canceled',
    color: 'red-600',
    bgColor: 'bg-red-600',
    buttonBgColor: '#DC2626',
    bgColorRGB: [220, 38, 38],
  },
];

export const WORK_ORDER_COMPLETED_STATUSES = [PASSIVE_REFLECTORS_WORK_ORDER_STATUS.completed, PASSIVE_REFLECTORS_WORK_ORDER_STATUS.closed, PASSIVE_REFLECTORS_WORK_ORDER_STATUS.canceled];

export const WORK_ORDER_NOT_COMPLETED_STATUSES = PASSIVE_REFLECTORS_WORK_ORDER_STATUSES.filter(status => !WORK_ORDER_COMPLETED_STATUSES.includes(status.key)).map(status => status.key);