import React, { useEffect, useState } from 'react';
import { FieldChangeModel } from '../../models/field-change.model';
import { UserModel } from '../../models/user.model';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Chip from '../Chip/Chip';
import { FieldChangeHelper } from '../../helpers/field-change.helper';

type Props = {
  changeHistoryField: FieldChangeModel;
  users: UserModel[];
}

const StatusComponent = ({changeHistoryField, users}: Props) => {

  const [userName, setUserName] = useState('');

  useEffect(() => {
    if (!changeHistoryField.changedBy) {
      setUserName('');
      return;
    }

    const user = users.find(user => user.id === changeHistoryField.changedBy);
    setUserName(user && user.name ? user.name : 'N/A');

  }, [changeHistoryField]);

  const oldStatusObject = FieldChangeHelper.getStatusObject(changeHistoryField.oldValue);
  const newStatusObject = FieldChangeHelper.getStatusObject(changeHistoryField.newValue);

  return (
    <div className={'flex flex-col items-start mb-5'}>
    {
      !!userName ?
        <div className={'flex flex-wrap items-start mb-5'}>
          <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>{userName}</label>
          <label className={`text-12 mr-3 whitespace-nowrap`}>changed the</label>
          <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>Status</label>
        </div>  :
        <div className={'flex flex-wrap items-start mb-5'}>
          <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>Status</label>
          <label className={`text-12 mr-3 whitespace-nowrap`}>changed</label>
        </div>
    }
      <div className={'flex flex-col md:flex-row items-start md:items-center'}>
        <Chip bgColor={oldStatusObject ? oldStatusObject.bgColor : ''} label={oldStatusObject ? oldStatusObject.value : ''} className={'text-white'} />
        {/*@ts-ignore*/}
        <FuseSvgIcon size={15} className={`${(!oldStatusObject || !newStatusObject) ? 'hidden' : ''} my-auto mx-3`}>material-outline:arrow_right_alt</FuseSvgIcon>
        <Chip bgColor={newStatusObject ? newStatusObject.bgColor : ''} label={newStatusObject ? newStatusObject.value : ''} className={'text-white'} />
      </div>
  </div>);
}

export default StatusComponent;