import React from 'react';
import StatusChip from '../../../shared/components/StatusChip/StatusChip';
import { Skeleton } from '@mui/material';
import Chip from '../../../shared/components/Chip/Chip';
import StatusClosedTimeChip from '../../../shared/components/StatusClosedTimeChip/StatusClosedTimeChip';
import { FiberInspectionViewModel } from '../../../shared/types/fiber/view-models/fiber-inspection.view-model';
import { DataComponentModel } from '../../../shared/models/interfaces/data-component.model';
import { ASSET_INSPECTION_STATUSES, DUE_DATE_STATUSES, INSPECTION_STATUS } from '../../../shared/globals';
import { FiberAssetInspectionModel } from '../../../shared/types/fiber/models/fiber-asset-inspection.model';

type Props = {
  dataModel: DataComponentModel;
  // TODO: Remove assetInspection from props. Currently needed for statuses and inspection
  assetInspection: FiberAssetInspectionModel;
  isLoading: boolean;
}

const AssetInspectionHeader = (props: Props) => {

  return (
    props.assetInspection && !props.isLoading ?
      <div>
        <div className={'sm:flex items-center gap-x-12 mb-4 sm:mb-0'}>
          <p className={'font-bold text-20 sm:text-28'}>{props.dataModel.componentHeader}</p>
          <span className={'flex flex-wrap gap-4 sm:gap-6'}>
            <StatusChip className={'h-20 text-white'} status={props.assetInspection.status}
                      statusObjects={ASSET_INSPECTION_STATUSES}/>
            <StatusChip className={'h-20 text-white'} status={props.assetInspection['dueDateStatus']}
                      statusObjects={DUE_DATE_STATUSES}/>
          </span>
        </div>
        <div className={'flex items-start gap-x-4'}>
          <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
                label={props.dataModel.dateTime}
          />
          {
            !!new FiberInspectionViewModel(props.assetInspection.inspection).lastTimeStatusChanged && props.assetInspection.inspection.status === INSPECTION_STATUS.completed &&
              <StatusClosedTimeChip lastTimeStatusChanged={new FiberInspectionViewModel(props.assetInspection.inspection).lastTimeStatusChanged}/>
          }
        </div>
      </div>
      : (
        <>
          <div className={'block sm:hidden'}>
            <div className={'sm:flex items-center gap-x-12 mb-4'}>
              <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '20px'}}/>
              <span className={'flex flex-wrap gap-4'}>
                <Skeleton variant='rounded' animation={'wave'} width={100} sx={{fontSize: '15px', borderRadius: '15px'}}/>
                <Skeleton variant='rounded' animation={'wave'} width={80} sx={{fontSize: '15px', borderRadius: '15px'}}/>
              </span>
            </div>
            <Skeleton variant='rounded' animation={'wave'} width={110} sx={{fontSize: '15px', borderRadius: '15px'}}/>
          </div>
          <div className={'hidden sm:block mb-2'}>
            <div className={'sm:flex items-center gap-x-8'}>
              <Skeleton variant='text' animation={'wave'} width={200} sx={{fontSize: '26px'}}/>
              <span className={'flex flex-wrap gap-6'}>
                <Skeleton variant='rounded' animation={'wave'} width={120} sx={{fontSize: '14px', borderRadius: '15px'}}/>
                <Skeleton variant='rounded' animation={'wave'} width={90} sx={{fontSize: '14px', borderRadius: '15px'}}/>
              </span>
            </div>
            <Skeleton variant='rounded' animation={'wave'} width={120} sx={{fontSize: '13px', borderRadius: '15px'}}/>
          </div>
        </>
      )
  );
}

export default AssetInspectionHeader;
