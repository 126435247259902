import React, { useRef } from 'react';
import Button from '../Button/Button';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  onFilesAdded?: Function;
  className?: string;
}

const AddAttachmentButton = ({onFilesAdded, className}: Props) => {

  const onFilesAddedHandler = (e) => {
    const files = Array.from(e.currentTarget.files);
    onFilesAdded(files);
    inputRef.current.value = null;
  };

  const inputRef = useRef<any>();

  return (
    <>
      <div className={'flex items-center'}>
        <Button disabled={false} size={'medium'} onClick={() => inputRef.current.click()} className={'gap-x-6'}>
          {/*@ts-ignore*/}
          <FuseSvgIcon size={18}>material-outline:attach_file</FuseSvgIcon>
          <p>Add Attachment</p>
        </Button>
      </div>
      <input
        ref={inputRef}
        type='file'
        multiple
        style={{display: 'none'}}
        onChange={onFilesAddedHandler}
      />
    </>
  );
};

export default AddAttachmentButton;