import React from 'react';
import { Skeleton } from '@mui/material';

const DetailsCardElementSkeleton = () => {
  return (
    <div className={`w-full m-auto col-span-1`}>
      <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '14px'}} />
      <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '14px'}} />
    </div>
  );
};

export default DetailsCardElementSkeleton;
