import React from 'react';
import { Link as RouterLink } from 'react-router-dom'
import InspectionCardElement from './components/InspectionCardElement';
import Card from '@mui/material/Card';
import { InspectionCardItemModel } from '../../models/inspection-card-item.model';
import { CardActionArea } from '@mui/material';
import StatusCardElement from "./components/StatusCardElement";
import { InspectionStatusType } from "../../globals";

type Props = {
  className?: string;
  linkTo?: string;
  statusAreaElements?: JSX.Element[];
  headingAreaElements: JSX.Element[];
  contentElements: InspectionCardItemModel[];
  contentElementClassName: string;
  infoAreaChildren?: JSX.Element[];
  actionAreaChildren?: JSX.Element[];
  smallView?: boolean;
  headingSmallView?: boolean;
  smallHeadingWidth?: boolean;
  workOrderHeading?: boolean;
  cardClassName?: string;
  status?: string;
  statusObjects?: InspectionStatusType[];
}

const InspectionCard = (props: Props) => {

  return (
    <Card className={`flex shadow overflow-hidden ${props.cardClassName}`}>
      {
        props.statusAreaElements
      }
      <CardActionArea component={RouterLink} to={props.linkTo}>
        <div
          className={`flex flex-col ${props.className} w-full gap-y-12 gap-x-4 p-12`}>
          <div
            className={`flex ${props.headingSmallView ? 'flex-col sm:flex-row sm:items-center' : 'flex-row items-center'} flex-wrap justify-between ${props.smallView && !props.headingSmallView ? 'w-full' : 'md:w-216'} ${props.headingSmallView && 'pl-8 md:min-w-192'} ${props.smallHeadingWidth ? 'sm:flex-col sm:items-start md:flex-row md:items-center' : ''} ${props.workOrderHeading && 'md:w-120 lg:w-92'}`}>
            {
              props.headingAreaElements
            }
            <div
              className={`w-auto flex items-center flex-row gap-x-6 mt-5 sm:mt-0 ${!props.smallView && 'md:hidden'}`}>
              <span className={``}>
               {
                 props.infoAreaChildren
               }
              </span>
              <span className={`hidden place-self-end sm:block ${props.smallView ? ' md:hidden lg:block' : ''}`}>
              {
                props.actionAreaChildren
              }
              </span>
            </div>
          </div>
          <div className={`pl-2 md:pl-0 ${props.contentElementClassName}`}>
            <StatusCardElement condensed={true} clickable={true} status={props.status}
                               statusObjects={props.statusObjects}/>
            {props.contentElements && props.contentElements.length && props.contentElements.map((item) => (
              <InspectionCardElement key={item.label} item={item}
                                     condensed={true} clickable={true}/>
            ))}
          </div>
          <div className={`w-auto flex flex-row justify-between gap-x-12 items-center`}>
            <span className={`hidden ${!props.smallView && 'md:flex'} flex-col justify-around items-center`}>
              {
                props.infoAreaChildren
              }
            </span>
            <span
              className={`w-full block sm:hidden md:block ${props.smallView && 'lg:hidden'}`}>
              {
                props.actionAreaChildren
              }
            </span>
          </div>
        </div>
      </CardActionArea>
    </Card>
  );
}

export default InspectionCard;
