import React, { useState } from 'react';
import { TextField } from '@mui/material';
import LoadingButton from '../LoadingButton/LoadingButton';

type Props = {
  isLoading?: boolean;
  className?: string;
  commentAddedHandler: Function;
  formIsDisabled?: boolean;
  formOnFocusHandler?: Function;
  formOnFocusLeftHandler?: Function;
}

const CommentForm = (props: Props) => {

  const [newComment, setNewComment] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const clearComment = () => {
    setNewComment('');
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    await props.commentAddedHandler(newComment);

    setIsLoading(false);
    clearComment();
  };

  const handleNewCommentChange = (e) => {
    setNewComment(e.target.value);
  };

  const isButtonDisabled = () => {
    return newComment.length == 0 || isLoading || props.isLoading;
  };

  return (
    <div className={`${props.className}`}>
      <div className={'w-full'}>
        <TextField
          onFocus={() => !!props.formOnFocusHandler && props.formOnFocusHandler()}
          onBlur={() => !!props.formOnFocusLeftHandler && props.formOnFocusLeftHandler()}
          disabled={props.formIsDisabled}
          label='Comment'
          placeholder='Comment'
          multiline variant='outlined'
          className={'w-full mt-20 mb-8'}
          value={newComment}
          onChange={handleNewCommentChange}
        />
        <div className={`grid sm:justify-items-end`}>
          <LoadingButton
            disabled={isButtonDisabled() || props.formIsDisabled}
            isLoading={isLoading}
            onClick={handleSubmit}
            text={'Submit'}
            className={'px-14'}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentForm;
