import React from 'react';
import InspectionCard from '../../../shared/components/InspectionCard/InspectionCard';
import { ASSET_INSPECTION_STATUSES, DUE_DATE_STATUSES } from '../../../shared/globals';
import CardHeading from '../../../shared/components/CardHeading/CardHeading';
import StatusStrip from '../../../shared/components/StatusStrip/StatusStrip';
import Chip from '../../../shared/components/Chip/Chip';
import { DataComponentModel } from '../../../shared/models/interfaces/data-component.model';
import { AssetInspectionModel } from "../../../shared/models/base-models/asset-inspection.model";

type Props = {
  dataModel: DataComponentModel;
  mapOpened: boolean;
  isSelected?: boolean;
  isHovered?: boolean;
  onHoveredCardClickHandler?: Function;
  onNotSelectedCardClickHandler?: Function;
  isInspectionList?: boolean;
  contentElementClassName?: string;
  date?: string;
  assetInspectionData: AssetInspectionModel;
}

const AssetInspectionCard = (props: Props) => {

  const cardSelectedClass = () => {
    if (props.isSelected === null) {
      return '';
    }

    return props.isSelected ? 'shadow-xl my-16' : 'opacity-60 hover:opacity-100';
  }

  const cardLinkTo = (): string => {
    if (props.isSelected === false) {
      return '';
    }

    return props.dataModel.linkTo;
  };

  const onCardClickHandler = () => {
    if (props.isSelected === false) {
      props.onNotSelectedCardClickHandler(props.assetInspectionData.id);
    }
  };

  const onHoveredCardClickHandler = () => {
    if (!props.isHovered) {
      return;
    }

    props.onHoveredCardClickHandler(props.assetInspectionData.id);
  };

  return (
    <span onClick={onCardClickHandler}>
      <InspectionCard
        linkTo={`${cardLinkTo()}`}
        statusAreaElements={!!props.assetInspectionData['dueDateStatus'] ? [
          <StatusStrip
            key={'statusComponent'}
            status={props.assetInspectionData['dueDateStatus']}
            condensed={true}
            statusObjects={DUE_DATE_STATUSES} />,
        ] : []}
        className={`${props.mapOpened ? '' : 'md:flex-row'}`}
        cardClassName={`${cardSelectedClass()}`}
        contentElements={!!props.isInspectionList ? props.dataModel.secondaryListItemFields : props.dataModel.listItemFields}
        headingAreaElements={[
          <CardHeading
            key={'assetInspectionHeading'}
            heading={props.dataModel.listItemHeading}
            cardIsHovered={props.isHovered}
            onButtonClickHandler={onHoveredCardClickHandler}
            className={'w-full'}
          />
        ]}
        status={props.assetInspectionData.status}
        statusObjects={ASSET_INSPECTION_STATUSES}
        contentElementClassName={`w-full gap-6 align-middle grid ${props.contentElementClassName}`}
        infoAreaChildren={[
          <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
                label={props.dataModel.dateTime}
          />
          // !!props.date ? [<DateChipCardElement key={'dateChip'} date={props.date} condensed={true} clickable={true} />] : []
          ]}
        actionAreaChildren={[]}
        smallView={props.mapOpened}
        smallHeadingWidth={props.mapOpened}
      />
    </span>
  );
}

export default AssetInspectionCard;
