import React from "react";
import { Tooltip } from '@mui/material';
import moment from 'moment';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  lastTimeStatusChanged: any;
  className?: string;
}

const StatusClosedTimeChip = (props: Props) => {

  const now = new Date();

  const timeDifference = moment(now).diff(props.lastTimeStatusChanged, 'days');
  const remainingDays = ((30 - timeDifference) < 1) ? 0 : (30 - timeDifference);

  const closingDay = moment(props.lastTimeStatusChanged).add(30, 'days').calendar('MM/DD/YYYY');

  return (
    <Tooltip followCursor={true} title={'Status will change to Closed on ' + closingDay} placement='top-start'>
        <span className={`flex py-3 pl-4 pr-9 rounded-full whitespace-nowrap font-semibold bg-gray-300 gap-x-3`}>
         {/*@ts-ignore*/}
          <FuseSvgIcon size={12} className={'my-auto'}>material-outline:access_time</FuseSvgIcon>
        <label className={'text-10 my-auto'}>{remainingDays + (remainingDays === 1 ? ' day' : ' days')}</label>
      </span>
    </Tooltip>
  );
}

export default StatusClosedTimeChip;
