import { DATA_TYPE, DataType } from '../types';
import { GeneratorsInspectionModel } from './models/generators-inspection.model';
import { GeneratorsAssetInspectionModel } from './models/generators-asset-inspection.model';
import { GeneratorsAssetModel } from './models/generators-asset.model';
import { GeneratorsWorkOrderModel } from './models/generators-work-order.model';
import { GeneratorsInspectionViewModel } from './view-models/generators-inspection.view-model';
import { GeneratorsAssetInspectionViewModel } from './view-models/generators-asset-inspection.view-model';
import { GeneratorsAssetViewModel } from './view-models/generators-asset.view-model';
import { GeneratorsWorkOrderViewModel } from './view-models/generators-work-order.view-model';
import { GENERATORS_INSPECTION_STATUSES } from './statuses/generators-inspection.statuses';
import { GENERATORS_ASSET_INSPECTION_STATUSES } from './statuses/generators-asset-inspection.statuses';
import { GENERATORS_WORK_ORDER_STATUSES } from './statuses/generators-work-order.statuses';
import { GENERATORS_INSPECTION_FORM_SCHEMA, GENERATORS_INSPECTION_FORM_UI_SCHEMA } from './forms/generators-inspection.form';
import {
  GENERATORS_ASSET_INSPECTION_FORM_SCHEMA,
  GENERATORS_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/generators-asset-inspection.form';
import {
  GENERATORS_ASSET_FORM_SCHEMA,
  GENERATORS_ASSET_FORM_UI_SCHEMA
} from './forms/generators-asset.form';
import {
  GENERATORS_DISPATCHING_FORM_SCHEMA,
  GENERATORS_DISPATCHING_FORM_UI_SCHEMA
} from './forms/generators-dispatching.form';
import { GENERATORS_WORK_ORDER_FORM_UI_SCHEMA, GENERATORS_WORK_ORDER_FORM_SCHEMA } from './forms/generator-work-order.form';
import { GENERATORS_MASS_WORK_ORDER_FORM_SCHEMA, GENERATORS_MASS_WORK_ORDER_FORM_UI_SCHEMA } from './forms/generator-mass-work-order.form';
import { GeneratorsDispatchingDocumentModel } from './models/generators-dispatching-document.model';
import {
  GENERATORS_INSPECTIONS_FILTER_FORM_SCHEMA,
  GENERATORS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/generators-inspections-filter.form';
import {
  GENERATORS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
  GENERATORS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/generators-asset-inspections-filter.form';
import {
  GENERATORS_WORK_ORDERS_FILTER_FORM_SCHEMA,
  GENERATORS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/generators-work-orders-filter.form';

export const GENERATORS_TYPE_CONFIG: DataType = {
  type: DATA_TYPE.generators,
  InspectionModel: GeneratorsInspectionModel,
  AssetInspectionModel: GeneratorsAssetInspectionModel,
  AssetModel: GeneratorsAssetModel,
  WorkOrderModel: GeneratorsWorkOrderModel,
  DispatchingDocumentModel: GeneratorsDispatchingDocumentModel,
  AssetViewModel: GeneratorsAssetViewModel,
  WorkOrderViewModel: GeneratorsWorkOrderViewModel,
  AssetInspectionViewModel: GeneratorsAssetInspectionViewModel,
  InspectionViewModel: GeneratorsInspectionViewModel,
  InspectionForm: {
    schema: GENERATORS_INSPECTION_FORM_SCHEMA,
    uiSchema: GENERATORS_INSPECTION_FORM_UI_SCHEMA
  },
  AssetInspectionForm: {
    schema: GENERATORS_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: GENERATORS_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  AssetForm: {
    schema: GENERATORS_ASSET_FORM_SCHEMA,
    uiSchema: GENERATORS_ASSET_FORM_UI_SCHEMA
  },
  DispatchingForm: {
    schema: GENERATORS_DISPATCHING_FORM_SCHEMA,
    uiSchema: GENERATORS_DISPATCHING_FORM_UI_SCHEMA
  },
  WorkOrderForm: {
    schema: GENERATORS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: GENERATORS_WORK_ORDER_FORM_UI_SCHEMA
  },
  MassWorkOrderForm: {
    schema: GENERATORS_MASS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: GENERATORS_MASS_WORK_ORDER_FORM_UI_SCHEMA
  },
  InspectionsFilterForm: {
    schema: GENERATORS_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: GENERATORS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  AssetInspectionsFilterForm: {
    schema: GENERATORS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: GENERATORS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  WorkOrdersFilterForm: {
    schema: GENERATORS_WORK_ORDERS_FILTER_FORM_SCHEMA,
    uiSchema: GENERATORS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
  },
  inspectionStatuses: GENERATORS_INSPECTION_STATUSES,
  assetInspectionStatuses: GENERATORS_ASSET_INSPECTION_STATUSES,
  workOrderStatuses: GENERATORS_WORK_ORDER_STATUSES
}