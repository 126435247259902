import ApiClient from './api-client/api-client';
import { ImageModel } from '../shared/models/image.model';
import { ImageParamsInterface } from '../shared/models/query-params-interfaces/image-params.interface';
import { ImageHelper } from '../shared/helpers/imageHelper';

const IMAGES_ENDPOINT = '/images';

const getImages = (queryParams: ImageParamsInterface = {}): Promise<ImageModel[]> => {
  return ApiClient.get(`${IMAGES_ENDPOINT}`, queryParams)
    .then(response => response.data)
    .then(data => data.map(image => new ImageModel(image)));
};

const getImageThumbnail = (image: ImageModel): Promise<string> => {
  return ApiClient.get(`${IMAGES_ENDPOINT}/thumbnail/${image.id}`, {}, {responseType: 'blob'})
    .then(res => {
      return ImageHelper.convertBlobToBase64(res.data, image.mimeType);
    })
    .catch(err => 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAwIDEwMDAiPgogIDxkZWZzPgogICAgPHN0eWxlPgogICAgICAuY2xzLTEgewogICAgICAgIGZpbGw6ICM5Y2EzYWY7CiAgICAgIH0KCiAgICAgIC5jbHMtMiB7CiAgICAgICAgZmlsbDogIzExMTgyNzsKICAgICAgfQogICAgPC9zdHlsZT4KICA8L2RlZnM+CiAgPHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iMTAwMCIgaGVpZ2h0PSIxMDAwIi8+CiAgPHBhdGggaWQ9ImVycm9yX0ZJTEwwX3dnaHQ0MDBfR1JBRDBfb3BzejQ4IiBkYXRhLW5hbWU9ImVycm9yIEZJTEwwIHdnaHQ0MDAgR1JBRDAgb3BzejQ4IiBjbGFzcz0iY2xzLTIiIGQ9Im00OTkuOTksNTc1YzYuNzEtLjEyLDEyLjA2LTUuNjUsMTEuOTQtMTIuMzctLjEyLTYuNzEtNS42NS0xMi4wNi0xMi4zNy0xMS45NC02LjcxLjEyLTEyLjA2LDUuNjUtMTEuOTQsMTIuMzcuMDUsMy4xNSwxLjMzLDYuMTUsMy41Niw4LjM4LDIuMzIsMi4zNSw1LjUxLDMuNjQsOC44MSwzLjU2Wm0tMTAuMTItNTcuMzhoMjIuNXYtOTQuODhoLTIyLjV2OTQuODhabTEwLjIyLDEzMi4zOGMtMjAuNjgsMC00MC4xMi0zLjk0LTU4LjMxLTExLjgxcy0zNC4xLTE4LjYzLTQ3LjcyLTMyLjI1LTI0LjM4LTI5LjU0LTMyLjI1LTQ3Ljc1LTExLjgxLTM3LjY3LTExLjgxLTU4LjM3LDMuOTQtNDAuMTYsMTEuODEtNTguMzcsMTguNjMtMzQuMDcsMzIuMjUtNDcuNTdjMTMuNjItMTMuNSwyOS41NC0yNC4xOSw0Ny43NS0zMi4wNiwxOC4yMS03Ljg4LDM3LjY3LTExLjgxLDU4LjM3LTExLjgxczQwLjE2LDMuOTQsNTguMzcsMTEuODFjMTguMjEsNy44OCwzNC4wNywxOC41Niw0Ny41NywzMi4wNiwxMy41LDEzLjUsMjQuMTksMjkuMzgsMzIuMDYsNDcuNjJzMTEuODEsMzcuNzIsMTEuODEsNTguNC0zLjk0LDQwLjEyLTExLjgxLDU4LjMxYy03Ljg4LDE4LjE5LTE4LjU2LDM0LjA4LTMyLjA2LDQ3LjY2LTEzLjUsMTMuNTgtMjkuMzcsMjQuMzMtNDcuNjIsMzIuMjVzLTM3LjcyLDExLjg4LTU4LjQsMTEuODhabS4wOS0yMi41YzM1LjM3LDAsNjUuNDQtMTIuNDQsOTAuMTktMzcuMzFzMzcuMTItNTUsMzcuMTItOTAuMzgtMTIuMzUtNjUuNDQtMzcuMDUtOTAuMTktNTQuODUtMzcuMTItOTAuNDUtMzcuMTItNjUuMzEsMTIuMzUtOTAuMTksMzcuMDVjLTI0Ljg3LDI0LjctMzcuMzEsNTQuODUtMzcuMzEsOTAuNDVzMTIuNDQsNjUuMzEsMzcuMzEsOTAuMTljMjQuODgsMjQuODcsNTUsMzcuMzEsOTAuMzgsMzcuMzFaIi8+Cjwvc3ZnPg==');
};

const getImage = (image: ImageModel): Promise<string> => {
  return ApiClient.get(`${IMAGES_ENDPOINT}/${image.id}`, {}, {responseType: 'blob'})
    .then(res => ImageHelper.convertBlobToBase64(res.data, image.mimeType));
};

const ImagesService = {
  getImages,
  getImageThumbnail,
  getImage,
};

export default ImagesService;
