export class StringHelper {

  static stringToBoolean(string: string): boolean {
    return string === 'true';
  };

  static booleanToString(boolean: boolean): string  {
    if (boolean) {
      return 'true';
    }
    return 'false';
  };

  static toUnderscore(s: string) {
    return s.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  /*static toCamel(s: string) {
    return s.replace(' ', '')
      .replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
          .replace('-', '')
          .replace('_', '');
      });
  }*/

  static toCamel(s: string) {
    const stringWithoutSpaces = s.replace(/ /g, '');
    return stringWithoutSpaces.charAt(0).toLowerCase() + stringWithoutSpaces.slice(1);
  }
}
