import { WidgetProps } from '@rjsf/utils';
import MultiSelect from '../../MultiSelect/MultiSelect';

interface SelectWidgetProps extends WidgetProps {
  options: {
    optionsList: { key: string; value: string }[];
  };
}

const MultiSelectWidget = (props: SelectWidgetProps) => {  
  return (
    <MultiSelect
      id={props?.label}
      values={props.value ? props.value : []}
      label={`${props.label}${props.required ? '*' : ''}`}
      className={`w-full ${props.className ? props.className : ''}`}
      onChange={(event) => props.onChange(event)}
      items={props.options.optionsList ? props.options.optionsList : []}
    />
  );
};

export default MultiSelectWidget;
