import i18next from 'i18next';
import en from './navigation-i18n/en';
import moment from 'moment/moment';

i18next.addResourceBundle('en', 'navigation', en);

const startDate = moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD');
const endDate = moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD');

const query = `?start-date=${startDate}&end-date=${endDate}`;

const navigationConfig = [
  {
    id: 'dashboard-page',
    title: 'Dashboard',
    translate: 'DASHBOARD',
    type: 'item',
    icon: 'heroicons-outline:home',
    url: `dashboard`,
  },
  // {
  //   id: 'assets-page',
  //   title: 'Assets',
  //   translate: 'ASSETS',
  //   type: 'item',
  //   icon: 'heroicons-outline:collection',
  //   url: 'assets',
  // },
  {
    id: 'inspections-page',
    title: 'Inspections',
    translate: 'INSPECTIONS',
    type: 'item',
    icon: 'material-outline:inventory',
    url: `inspections${query}`,
  },
  {
    id: 'asset-inspections-page',
    title: 'Asset Inspections',
    translate: 'ASSET_INSPECTIONS',
    type: 'item',
    icon: 'material-outline:description',
    url: `asset-inspections${query}`,
  },
  {
    id: 'escalations-page',
    title: 'Escalations',
    translate: 'ESCALATIONS',
    type: 'item',
    icon: 'material-outline:home_repair_service',
    url: `escalations${query}`,
  },
];

export default navigationConfig;
