import React from 'react';
import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Line,
  Tooltip as ChartTooltip,
  ComposedChart,
  Legend
} from 'recharts';
import { URLHelper } from '../../helpers/url.helper';
import { useNavigate } from 'react-router-dom';
import { DUE_DATE_STATUSES } from '../../globals';

type Props = {
  data: any[];
  linkTo?: string;
  queryParams?: any;
}

const DueDateChart = (props: Props) => {
  const navigate = useNavigate();


  const routeChange = (params) => {
    const queryParams = {
      'start-date': '',
      'end-date': '',
      ...params
    };

    const path = URLHelper.getUrlQuery(props.linkTo, queryParams);

    navigate(path);
  }

  let plannedSum = 0;
  let completedSum = 0;

  const data = props.data && props.data.length ? props.data.map((dataItem) => {
    if (!!dataItem.planned) {
      plannedSum += dataItem.planned;
    }

    const completed = dataItem['on-time'] + dataItem['overdue'];

    if (!!completed) {
      completedSum += completed;
    }

    return {
      ...dataItem,
      totalPlanned: plannedSum,
      totalCompleted: completedSum,
    }
  }) : [];

  return (
    <ResponsiveContainer width="100%" minWidth={800} height={300}>
      <ComposedChart
        height={300}
        data={data}
        margin={{left: 0, right: 0, top: 16, bottom: 4}}
      >
        <XAxis dataKey="label" stroke="black" height={30}/>
        <YAxis stroke="black" width={42}/>
        <CartesianGrid strokeDasharray="3 3" vertical={false}/>
        <ChartTooltip cursor={{fillOpacity: 0.3}}
                      contentStyle={{
                        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
                        background: 'white',
                        border: '0px',
                        padding: '10px 12px',
                        borderRadius: '4px',
                        fontSize: '12px'
                      }}
        />
        <Bar dataKey="planned" fill="#9E9E9E" name="Planned" barSize={16}
             className={'cursor-pointer'} onClick={(dataItem) => routeChange({
          'due-date-start': dataItem.dateFrom,
          'due-date-end': dataItem.dateTo
        })}
        />
        {
          DUE_DATE_STATUSES.map(dueDateStatus =>
            <Bar key={dueDateStatus.key} dataKey={dueDateStatus.key} fill={dueDateStatus.color}
                 name={dueDateStatus.value} stackId="a" barSize={16}
                 className={'cursor-pointer'} onClick={(dataItem) => routeChange({
              'due-date-status': dueDateStatus.key,
              'start-date': dataItem.dateFrom,
              'end-date': dataItem.dateTo
            })}
            />
          )
        }
        <Line type="monotone" dataKey="totalPlanned" name={'Total Planned'} stroke="#757575"/>
        <Line type="monotone" dataKey="totalCompleted" name={'Total Completed'} stroke="#2E7D32"/>
        <Legend align="center" verticalAlign="bottom" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default DueDateChart;
