import React from 'react';
import { Skeleton } from '@mui/material';
import { DataComponentModel } from '../../../shared/models/interfaces/data-component.model';

type Props = {
  dataModel: DataComponentModel;
  isLoading: boolean;
}

const AssetHeader = (props: Props) => {

  return (
    props.dataModel && !props.isLoading ?
      <>
        <p className={'text-12 sm:text-16 font-500'}>{props.dataModel.componentHeaderTitle}</p>
        <div className={'sm:flex items-center gap-x-12'}>
          <p className={'font-bold text-20 sm:text-28'}>{props.dataModel.componentHeader}</p>
        </div>
      </>
      : (
        <>
          <div className={'block sm:hidden'}>
            <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '12px'}}/>
            <div className={'sm:flex items-center gap-x-12'}>
              <Skeleton variant='text' animation={'wave'} width={300} sx={{fontSize: '20px'}}/>
            </div>
          </div>
          <div className={'hidden sm:block'}>
            <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '16px'}}/>
            <div className={'sm:flex items-center gap-x-12'}>
              <Skeleton variant='text' animation={'wave'} width={440} sx={{fontSize: '28px'}}/>
            </div>
          </div>
        </>
      )
  );
}

export default AssetHeader;
