import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { MultiSelectItemModel } from '../../models/multi-select-item.model';
import { useEffect, useState } from 'react';
import { CircularProgress, FormControl, IconButton, InputLabel } from '@mui/material';
import FuseSvgIcon from "@fuse/core/FuseSvgIcon";

const getFirstValue = (values: string[], items: MultiSelectItemModel[]) => {
  const firstItemObj = values && values.length > 0 ? items.find(item => item.key == values[0]) : undefined;
  return firstItemObj ? `${firstItemObj.value} ` : undefined;
}

const getNumberOfValues = (values: string[]) => {
  const numberOfValues = values && values.length > 1 ? (' (+' + (values.length - 1) + ')') : '';
  return `${numberOfValues}`;
}

type Props = {
  label: string;
  values: string[];
  id?: string;
  items: MultiSelectItemModel[];
  onChange: Function;
  className?: string;
  isLoading?: boolean;
}

const MultiSelect = (props: Props) => {

  const labelId = props.id + '-label';
  const [firstValue, setFirstValue] = useState(getFirstValue(props.values, props.items));
  const [numberOfValues, setNumberOfValues] = useState(getNumberOfValues(props.values));

  useEffect(() => {
    if (!props.values || !props.items) {
      return;
    }

    setFirstValue(getFirstValue(props.values, props.items));
    setNumberOfValues(getNumberOfValues(props.values));
  }, [props.values, props.items]);

  const onChangeHandler = (event: any) => {
    props.onChange(event.target.value);
    setFirstValue(getFirstValue(event.target.value, props.items));
    setNumberOfValues(getNumberOfValues(event.target.value));
  }

  const handleClearClick = () => {
    props.onChange([]);
    setFirstValue(getFirstValue([], []));
    setNumberOfValues(getNumberOfValues([]));
  };

  return (
    <FormControl className={`${props.className}`} size={'small'}>
      <InputLabel id={labelId}>{props.label}</InputLabel>
      <Select
        disabled={!!props.isLoading}
        labelId={labelId}
        id={props.id}
        className={`${props.className} pr-4 gap-x-0`}
        label={props.label}
        size={'small'}
        multiple
        sx={{
          "& .MuiSelect-iconOutlined": {display: (props.values && props.values.length > 0) || !!props.isLoading ? 'none' : ''},
        }}
        value={props.values}
        renderValue={() => <>
          <label className={`${numberOfValues && 'truncate'} mr-1`}>{firstValue}</label>
          <label>{numberOfValues}</label>
        </>
        }
        endAdornment={
          (!!props.isLoading || (props.values && props.values.length > 0)) &&
            <div className={`${props.isLoading && 'mr-7'}`}>
              {props.isLoading ? <CircularProgress size={14} color={'inherit'} /> :
                <IconButton onClick={handleClearClick} className={''} size={"small"}>
                  {/*@ts-ignore*/}
                  <FuseSvgIcon size={18}>material-outline:clear</FuseSvgIcon>
                </IconButton>
              }
            </div>}
        onChange={onChangeHandler}
        input={<OutlinedInput label={props.label}/>}
        SelectDisplayProps={{style: {display: 'flex', alignItems: 'center'}}}
        MenuProps={{
          style: {
            maxHeight: 256,
          },
        }}
      >
        {props.items.map((option) => (
          <MenuItem
            key={option.key}
            value={option.key}
          >
            {option.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelect;