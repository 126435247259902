import { PaginationModel } from '../shared/models/pagination.model';
import { AssetParamsInterface } from '../shared/models/query-params-interfaces/asset-params.interface';
import BaseContextService from './base-context.service';
import { AssetModel } from '../shared/models/base-models/asset.model';
import { AssetModelMapper } from '../shared/model-mappers/asset-model.mapper';

const ASSETS_ENDPOINT = '/assets';

const getAssets = (queryParams: AssetParamsInterface = {}): Promise<[PaginationModel, AssetModel[]]> => {
  return BaseContextService.fetchList<AssetModel>(ASSETS_ENDPOINT, new AssetModelMapper(), queryParams);
};

const getAsset = (id: string, queryParams: AssetParamsInterface = {}): Promise<AssetModel> => {
  queryParams.expand = queryParams.expand ? queryParams.expand : 'assetInspections';
  return BaseContextService.fetch<AssetModel>(`${ASSETS_ENDPOINT}/${id}`, new AssetModelMapper(), queryParams);
};

const updateAsset = (id: string, asset: AssetModel): Promise<AssetModel> => {
  const queryParams = {expand: 'assetInspections'};
  return BaseContextService.update<AssetModel>(`${ASSETS_ENDPOINT}/${id}`, asset, new AssetModelMapper(), queryParams);
};

const AssetsService = {
  getAssets,
  getAsset,
  updateAsset,
};

export default AssetsService;
