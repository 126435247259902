import { DataComponentModel } from '../interfaces/data-component.model';
import { InspectionCardItemModel } from '../inspection-card-item.model';
import { InspectionCardHeadingModel } from '../inspection-card-heading.model';
import { BaseViewModel } from './base-view.model';
import { InspectionModel } from '../base-models/inspection.model';
import { ChangelogDataInterface } from '../data-model-interfaces/changelog-data.interface';
import { FieldChangeModel } from '../field-change.model';
import { CommentModel } from '../comment.model';
import { InspectionHeadingCardDataInterface } from '../data-model-interfaces/inspection-heading-card-data.interface';
import { UserModel } from '../user.model';

export abstract class InspectionViewModel extends BaseViewModel implements DataComponentModel, ChangelogDataInterface, InspectionHeadingCardDataInterface {

  protected constructor(inspection: InspectionModel) {
    super(inspection);
  }

  abstract get additionalItemField(): InspectionCardItemModel;

  abstract get buttonAction(): Function;

  abstract get buttonText(): string;

  abstract get componentHeader(): string;

  abstract get dateTime(): string;

  abstract get detailCardFields(): InspectionCardItemModel[];

  abstract get headingItemFields(): InspectionCardItemModel[];

  abstract get infoCardFields(): InspectionCardItemModel[];

  abstract get linkTo(): string;

  abstract get listItemFieldsClassName(): string;

  abstract get listItemFields(): InspectionCardItemModel[];

  abstract get listItemHeading(): InspectionCardHeadingModel;

  abstract get changeLogHistory(): FieldChangeModel[];

  abstract get comments(): CommentModel[];

  abstract get isCommentFormDisabled(): boolean;

  abstract get assignedTo(): UserModel[];

  abstract get unhandledConditions(): string[];

  abstract get viewModelId(): string;

  abstract get isInProgress(): boolean;

  abstract get isWaitingQa(): boolean;

  abstract get isInQa(): boolean;

  abstract get isCompleted(): boolean;

  abstract get isClosed(): boolean;

  abstract get isReadonly(): boolean;

  abstract getProp(propertyName: string): any;

  abstract get lastTimeStatusChanged(): string;

  abstract get readyForQa(): boolean;

  get secondaryListItemFields(): InspectionCardItemModel[] {
    throw new Error('Method not implemented.');
  };

  get secondaryListItemFieldsClassName(): string {
    throw new Error('Method not implemented.');
  };

  get previousNextListItem(): any {
    throw new Error('Method not implemented.');
  }

  get additionalAttachmentsTitle(): any {
    throw new Error('Method not implemented.');
  }

  get componentHeaderTitle(): string {
    throw new Error('Method not implemented.');
  }

}