import { createContext, useState } from 'react';

const DepartmentContext = createContext();

export const DepartmentProvider = ({ children }) => {
  const [department, setDepartment] = useState(
    localStorage.getItem('current_department')
  );

  const updateDepartment = (newDepartment) => {
    if (department !== newDepartment) {
      setDepartment(newDepartment);
    }

    if (localStorage.getItem('current_department') !== newDepartment) {
      localStorage.setItem('current_department', newDepartment);
    }
  };

  return (
    <DepartmentContext.Provider value={{ department, updateDepartment }}>
      {children}
    </DepartmentContext.Provider>
  );
};

export default DepartmentContext;
