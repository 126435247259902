import { createRef, forwardRef, useImperativeHandle, useState } from 'react';
import RjsfForm from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import { RegistryWidgetsType, RJSFSchema, UiSchema } from '@rjsf/utils';
import DefaultTemplates from './default.templates';
import DefaultWidgets from './default.widgets';

type Props = {
  formData: {};
  schema: RJSFSchema;
  uiSchema: UiSchema;
  onSubmit?: (data) => any;
  className?: string;
  widgets?: RegistryWidgetsType;
  templates?: {};
  onChange?: (data) => any;
};

export type FormRef = {
  getFormData: () => any;
  submit: () => void;
  formElement: any;
};

const Form = forwardRef((props: Props, ref) => {
  const Templates = { ...DefaultTemplates, ...props.templates };
  const Widgets = { ...DefaultWidgets, ...props.widgets };

  const formRef = createRef<RjsfForm>();
  const [liveValidation, setLiveValidation] = useState(false);
  const [previousFormData, setPreviousFormData] = useState(
    props.formData ? props.formData : {}
  );

  useImperativeHandle(
    ref,
    (): FormRef => ({
      // Expose any functions or values you want from the Form component
      getFormData: () => formRef.current?.state.formData,
      // On React v17+, there is a bubbling problem on formRef.current.submit() method, so this is a workaround.
      submit: () => {
        setLiveValidation(true);
        if (formRef.current) {
          const { onSubmit } = formRef.current.props;
          if (onSubmit) {
            const formData = formRef.current.state.formData;
            const requiredFields = props.schema.required || [];
            const missingFields = requiredFields.filter(
              (field) => !formData[field]
            );
            if (missingFields.length === 0) {
              onSubmit(
                {
                  edit: formRef.current.state.edit,
                  errorSchema: formRef.current.state.errorSchema,
                  errors: formRef.current.state.errors,
                  idSchema: formRef.current.state.idSchema,
                  schema: formRef.current.state.schema,
                  schemaUtils: formRef.current.state.schemaUtils,
                  uiSchema: formRef.current.state.uiSchema,
                  formData: formRef.current.state.formData,
                },
                null
              );
            }
            setPreviousFormData(formRef.current.state.formData);
          }
        }
      },
      formElement: formRef.current?.formElement,
    })
  );

  const handleSubmit = (data) => {
    const { formData } = data;
    props.onSubmit(formData);
  };

  const transformErrors = (errors) => {
    // TODO: remove this filter when forms are completed
    // currently it is used because there is a lot of type errors
    const filteredErrors = errors.filter((error) => error.name !== 'type');

    return filteredErrors.map((error) => {
      if (error.name === 'required') {
        return {
          ...error,
          message: 'Required field',
        };
      }
      return error;
    });
  };

  const handleFormChange = (data) => {
    if (props.onChange) {
      props.onChange(data.formData);
    }
  };

  return (
    <RjsfForm
      schema={props.schema}
      uiSchema={props.uiSchema}
      validator={validator}
      formData={previousFormData}
      showErrorList={false}
      liveValidate={liveValidation}
      onSubmit={handleSubmit}
      transformErrors={transformErrors}
      onError={(e) => console.log(e)}
      templates={Templates}
      widgets={Widgets}
      className={`${props.className ? props.className : ''}`}
      ref={formRef}
      onChange={handleFormChange}
    />
  );
});

export default Form;
