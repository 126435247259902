import FormControl from '@mui/material/FormControl';
import { InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { memo, useCallback, useState } from 'react';
import { debounce } from 'lodash';

type Props = {
  value: string;
  onChange: Function,
  className?: string,
  placeholder?: string,
}

const Search = (props: Props) => {

  const [searchString, setSearchString] = useState(props.value);

  const handleInputChange = (e) => {
    setSearchString(e.target.value);
    debounceSearchString(e.target.value);
  };

  const debounceSearchString = useCallback(
    debounce((value) => props.onChange(value), 500),
    [],
  );

  const defaultPlaceholder = 'Search';

  return (
    <FormControl variant='outlined' className={props.className} size={'small'}>
      <InputLabel htmlFor='search-input'>{props.placeholder ? props.placeholder : defaultPlaceholder}</InputLabel>
      <OutlinedInput
        id='search-input'
        label={props.placeholder ? props.placeholder : defaultPlaceholder}
        value={searchString}
        onChange={handleInputChange}
        endAdornment={
          <InputAdornment position='end'>
            <SearchIcon />
          </InputAdornment>}
      />
    </FormControl>
  );
};

export default memo(Search);
