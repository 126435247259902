import React from 'react';
import { InspectionCardItemModel } from '../../models/inspection-card-item.model';
import { Tooltip } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  item: InspectionCardItemModel;
}

const DetailsCardElement = (props: Props) => {

  return <div className={`w-full m-auto ${props.item.colSpan}`}>
            <span className={'flex items-center'}>
              <label className={`font-light block ${props.item.className ? props.item.className : 'text-12'}`}>
                {props.item.label}
              </label>
              {
                !!props.item && !!props.item.oldValue &&
                  <Tooltip title={`Inspection day value: ${props.item.oldValue}`} placement="right">
                    {/*@ts-ignore*/}
                      <FuseSvgIcon size={11} className={'ml-3 mb-2'} style={{fill: 'gray'}}>material-outline:error</FuseSvgIcon>
                  </Tooltip>
              }
            </span>
            <label className={`font-semibold block`}>
              {props.item.value}
            </label>
  </div>;
}

export default DetailsCardElement;
