import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import WorkOrders from "./WorkOrders";

i18next.addResourceBundle('en', 'escalationPage', en);

const WorkOrdersConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'escalations',
      element: <WorkOrders />,
    },
  ],
};

export default WorkOrdersConfig;
