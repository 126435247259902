import ApiClient from './api-client/api-client';
import FileDownload from 'js-file-download';
import { FileModel } from '../shared/models/file.model';
import { FileParamsInterface } from '../shared/models/query-params-interfaces/files-params.interface';
import FileUploadClient from './file-upload-client/file-upload-client';

const FILES_ENDPOINT = '/files';

const downloadFile = (file: FileModel): Promise<void> => {
  return ApiClient.get(`${FILES_ENDPOINT}/${file.id}`, {}, {responseType: 'blob'})
    .then(response => FileDownload(response.data, file.originalName));
};

const downloadFileWithWatermark = (file: FileModel, modelId: any): Promise<void> => {
  return ApiClient.get(`${FILES_ENDPOINT}/download-with-watermark`, {fileId: file.id, assetInspectionId: modelId.assetInspectionId, workOrderId: modelId.workOrderId}, {responseType: 'blob'})
    .then(response => FileDownload(response.data, file.originalName));
};

const getByIds = (queryParams: FileParamsInterface = {}): Promise<FileModel[]> => {
  return ApiClient.get(`${FILES_ENDPOINT}`, queryParams)
    .then(response => response.data)
    .then(data => data.map(file => new FileModel(file)));
};

const downloadFiles = (files: FileModel[]): Promise<void> => {
  const ids = files.map(file => file.id).toString();
  return ApiClient.get(`${FILES_ENDPOINT}/download`, {ids}, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-attachments-${new Date().toISOString()}.zip`));
};

const downloadFilesWithWatermarks = (modelId: any, files: FileModel[]) => {
  const ids = files.map(file => file.id).toString();
  return ApiClient.get(`${FILES_ENDPOINT}/download-with-watermarks`, {ids: ids, assetInspectionId: modelId.assetInspectionId, workOrderId: modelId.workOrderId}, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-attachments-${new Date().toISOString()}.zip`));
};

const uploadFileWithPreSignedUrl = (preSignedUrl: string, file: File): Promise<void> => {
  return FileUploadClient.uploadToPreSignedUrl(preSignedUrl, file);
};

const uploadFilesWithPreSignedUrls = (preSignedUrls: string[], files: File[] = []): Promise<void[]> => {
  return Promise.all(files.map((file, index) => uploadFileWithPreSignedUrl(preSignedUrls[index], file)));
};

const FilesService = {
  downloadFile,
  downloadFileWithWatermark,
  getByIds,
  downloadFiles,
  downloadFilesWithWatermarks,
  uploadFileWithPreSignedUrl,
  uploadFilesWithPreSignedUrls
};

export default FilesService;
