import { useContext, useEffect } from 'react';
import { MapsLibraryContext } from '../../components/EsriMapsContextLoader';
import { MapModulesModel } from '../../../../models/map-modules.model';

type Props = {
  top?: number,
  bottom?: number,
  left?: number,
  right?: number,
}

const MapViewPadding = ({top, bottom, left, right}: Props) => {
  let {mapView}: MapModulesModel = useContext(MapsLibraryContext) as MapModulesModel;

  useEffect(() => {
    if (!mapView) {
      return;
    }

    mapView.padding = {
      top: top ? top : 0,
      bottom: bottom ? bottom : 0,
      left: left ? left : 0,
      right: right ? right : 0,
    };

  }, [mapView, top, bottom, left, right]);

  return (
    <></>
  );
};

export default MapViewPadding;
