import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { AssetViewModel } from '../../../models/base-view-models/asset-view.model';
import { UpsAssetModel } from '../models/ups-asset.model';

export class UpsAssetViewModel extends AssetViewModel {

  private asset: UpsAssetModel;

  constructor(asset: UpsAssetModel) {
    super(asset);
    this.asset = asset;
  }

  get componentHeaderTitle(): string {
    return `SAP Equipment ID`;
  }

  get componentHeader(): string {
    return `${this.returnValue(this.asset.externalId)}`;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      this.getInfoCardField('SAP Equipment ID', 'externalId'),
      this.getInfoCardField('Equipment Type', 'assetType'),
      this.getInfoCardField('Tier Rating', 'tierRating'),
      this.getInfoCardField('UPS Manufacturer', 'upsManufacturer'),
    ];

    if (this.asset.assetType === 'UPS') {
      fields.push(this.getInfoCardField('UPS Make', 'upsMake'));
      fields.push(this.getInfoCardField('UPS Model #', 'upsModelNumber'));
      fields.push(this.getInfoCardField('UPS Serial #', 'upsModelNumber'));
    }

    fields.push(this.getInfoCardField('Part #', 'partNumber'));

    if (this.asset.assetType === 'UPS') {
      fields.push(this.getInfoCardField('Size (kvA)', 'size'));
    }

    fields.push(this.getInfoCardField('Voltage', 'voltage'));

    fields.push(
      {
        label: 'Voltage Type',
        value: this.returnValue(this.asset['voltageType'] ?
          (!!this.asset['voltageType'].otherValue ? this.asset['voltageType'].otherValue : this.asset['voltageType'].value) : 'N/A'),
        oldValue: this.comparingAssetVoltageType()
      },
    );

    if (this.asset.assetType === 'UPS') {
      fields.push(this.getInfoCardField('Phase', 'phase'));
      fields.push(this.getInfoCardField('# of Air Filters', 'numberOfAirFilters'));
      fields.push(this.getInfoCardField('Number of Fans', 'numberOfFans'));
      fields.push(this.getInfoCardField('Total Chassis Slots', 'totalChassisSlots'));
      fields.push(this.getInfoCardField('Chassis Slots Used', 'chassisSlotsUsed'));
      fields.push(this.getInfoCardField('Supported By Generator', 'supportedByGenerator'));
      fields.push(this.getInfoCardField('External Maitenance Bypass', 'externalMaitenanceBypass'));
      fields.push(this.getInfoCardField('Size of Air Filters', 'sizeOfAirFilters'));
      fields.push(this.getInfoCardField('Load', 'loadValue'));
    }

    fields.push({
      label: 'Manufactured Date',
      value: this.returnValue(this.asset['manufacturedDate'] && moment(`${this.asset['manufacturedDate']}`, 'YYYYMMDD').format('MM/DD/YYYY')),
      oldValue: this.asset.comparingAsset && this.asset.comparingAsset['manufacturedDate'] && !moment(this.asset.comparingAsset['manufacturedDate']).isSame(this.asset['manufacturedDate'], 'day') && this.returnValue(moment(`${this.asset.comparingAsset['manufacturedDate']}`, 'YYYYMMDD').format('MM/DD/YYYY'))
    });
    fields.push(this.getInfoCardField('Rack', 'rack'));
    fields.push(this.getInfoCardField('Rack Unit Number', 'rackUnitNumber'));
    fields.push(this.getInfoCardField('Equipment Life', 'equipmentLife'));
    fields.push(this.getInfoCardField('End of Life', 'endOfLife'));
    fields.push(this.getInfoCardField('Condition Rating', 'conditionRating'));
    fields.push(this.getInfoCardField('Battery Amp Hours', 'batteryAmpHours'));
    fields.push(this.getInfoCardField('Battery Runtime', 'batteryRuntime'));
    fields.push(this.getInfoCardField('Monitoring PGE', 'monitoringPGE'));
    fields.push(this.getInfoCardField('Year of Construction', 'yearOfConstruction'));
    fields.push(this.getInfoCardField('Serial #', 'serialNumber'));

    if (this.asset.assetType === 'VRLA Battery') {
      fields.push(this.getInfoCardField('Battery String #', 'batteryStringNumber'));
      fields.push(this.getInfoCardField('Battery Make', 'batteryMake'));
      fields.push(this.getInfoCardField('Battery Model', 'batteryModel'));
      fields.push(this.getInfoCardField('Module Part Number', 'modulePartNumber'));
      fields.push(this.getInfoCardField('Date Code', 'dateCode'));
      fields.push(this.getInfoCardField('Internal/External String', 'internalExternalString'));
      fields.push(this.getInfoCardField('# of Batteries Per String', 'numberOfBatteriesPerString'));
      fields.push(this.getInfoCardField('# of Module Per String', 'numberOfModulesPerString'));
      fields.push(this.getInfoCardField('# of Batteries Per Module', 'numberOfBatteriesPerModule'));
    }

    fields.push({
      label: 'Location',
      value: this.asset.location ? this.asset.location.locationString : 'N/A',
      colSpan: 'sm:col-span-2',
      oldValue: this.comparingAssetLocation()
    },)

    return fields;
  }

  private getInfoCardField(name: string, field: string): InspectionCardItemModel {
    return {
      label: name,
      value: this.returnValue(this.asset[field]),
      oldValue: this.asset.comparingAsset && this.asset.comparingAsset[field] && (this.asset.comparingAsset[field] !== this.asset[field]) && this.returnValue(this.asset.comparingAsset[field])
    };
  };

  private comparingAssetVoltageType(): any {
    return this.asset.comparingAsset && (this.getComparingAssetVoltageType() !==
      this.returnValue(this.asset.voltageType ? (!!this.asset['voltageType'].otherValue ?
        this.asset['voltageType'].otherValue : this.asset['voltageType'].value) : 'N/A')) && this.getComparingAssetVoltageType();
  };

  private getComparingAssetVoltageType(): string {
    return this.asset.comparingAsset && this.returnValue(this.asset.comparingAsset.voltageType ?
      (!!this.asset.comparingAsset['voltageType'].otherValue ? this.asset.comparingAsset['voltageType'].otherValue : this.asset.comparingAsset['voltageType'].value) : 'N/A');
  };

  private comparingAssetLocation(): string {
    return this.asset.comparingAsset && (this.getComparingAssetLocation() !== (this.asset.location && this.asset.location.locationString)) && this.getComparingAssetLocation();
  };

  private getComparingAssetLocation(): string {
    return this.asset.comparingAsset && this.asset.comparingAsset.location ? `${this.returnValue(this.asset.comparingAsset.location['latitude'])}, ${this.returnValue(this.asset.comparingAsset.location['longitude'])}` : 'N/A';
  };

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Equipment Type', value: this.returnValue(this.asset['assetType'])},
      {label: 'Tier Rating', value: this.returnValue(this.asset['tierRating'])},
      {label: 'UPS Manufacturer', value: this.returnValue(this.asset['upsManufacturer'])},
    ];

    if (this.asset.assetType === 'UPS') {
      fields.push({label: 'UPS Make', value: this.returnValue(this.asset['upsMake'])});
      fields.push({label: 'UPS Model #', value: this.returnValue(this.asset['upsModelNumber'])});
      fields.push({label: 'UPS Serial #', value: this.returnValue(this.asset['upsSerialNumber'])});
    }

    fields.push({label: 'Part #', value: this.returnValue(this.asset['partNumber'])});

    if (this.asset.assetType === 'UPS') {
      fields.push({label: 'Size (kvA)', value: this.returnValue(this.asset['size'])}); //UPS
    }

    fields.push({label: 'Voltage', value: this.returnValue(this.asset['voltage'])});
    fields.push({
      label: 'Voltage Type',
      value: this.returnValue(this.asset['voltageType'] ? (!!this.asset['voltageType'].otherValue ? this.asset['voltageType'].otherValue : this.asset['voltageType'].value) : 'N/A')
    });

    if (this.asset.assetType === 'UPS') {
      fields.push({label: 'Phase', value: this.returnValue(this.asset['phase'])}); //UPS
      fields.push({label: '# of Air Filters', value: this.returnValue(this.asset['numberOfAirFilters'])}); //UPS
      fields.push({label: 'Number of Fans', value: this.returnValue(this.asset['numberOfFans'])}); //UPS
      fields.push({label: 'Total Chassis Slots', value: this.returnValue(this.asset['totalChassisSlots'])}); //UPS
      fields.push({label: 'Chassis Slots Used', value: this.returnValue(this.asset['chassisSlotsUsed'])}); //UPS
      fields.push({label: 'Supported By Generator', value: this.returnValue(this.asset['supportedByGenerator'])}); //UPS
      fields.push({
        label: 'External Maitenance Bypass',
        value: this.returnValue(this.asset['externalMaitenanceBypass'])
      }); //UPS
      fields.push({label: 'Size of Air Filters', value: this.returnValue(this.asset['sizeOfAirFilters'])}); //UPS
      fields.push({label: 'Load', value: this.returnValue(this.asset['loadValue'])}); //UPS
    }

    fields.push({
      label: 'Manufactured Date',
      value: this.returnValue(this.asset['manufacturedDate'] && moment(`${this.asset['manufacturedDate']}`, 'YYYYMMDD').format('MM/DD/YYYY'))
    });
    fields.push({label: 'Rack', value: this.returnValue(this.asset['rack'])});
    fields.push({label: 'Rack Unit Number', value: this.returnValue(this.asset['rackUnitNumber'])});
    fields.push({label: 'Equipment Life', value: this.returnValue(this.asset['equipmentLife'])});
    fields.push({label: 'End of Life', value: this.returnValue(this.asset['endOfLife'])});
    fields.push({label: 'Condition Rating', value: this.returnValue(this.asset['conditionRating'])});
    fields.push({label: 'Battery Amp Hours', value: this.returnValue(this.asset['batteryAmpHours'])});
    fields.push({label: 'Battery Runtime', value: this.returnValue(this.asset['batteryRuntime'])});
    fields.push({label: 'Monitoring PGE', value: this.returnValue(this.asset['monitoringPGE'])});
    fields.push({label: 'Year of Construction', value: this.returnValue(this.asset['yearOfConstruction'])});
    fields.push({label: 'Serial #', value: this.returnValue(this.asset['serialNumber'])});

    if (this.asset.assetType === 'VRLA Battery') {
      fields.push({label: 'Battery String #', value: this.returnValue(this.asset['batteryStringNumber'])}); //VRLA
      fields.push({label: 'Battery Make', value: this.returnValue(this.asset['batteryMake'])}); //VRLA
      fields.push({label: 'Battery Model', value: this.returnValue(this.asset['batteryModel'])}); //VRLA
      fields.push({label: 'Module Part Number', value: this.returnValue(this.asset['modulePartNumber'])}); //VRLA
      fields.push({label: 'Date Code', value: this.returnValue(this.asset['dateCode'])}); //VRLA
      fields.push({label: 'Internal/External String', value: this.returnValue(this.asset['internalExternalString'])}); //VRLA
      fields.push({
        label: '# of Batteries Per String',
        value: this.returnValue(this.asset['numberOfBatteriesPerString'])
      }); //VRLA
      fields.push({label: '# of Module Per String', value: this.returnValue(this.asset['numberOfModulesPerString'])}); //VRLA
      fields.push({
        label: '# of Batteries Per Module',
        value: this.returnValue(this.asset['numberOfBatteriesPerModule'])
      }); //VRLA
    }

    fields.push({
      label: 'Location',
      value: this.asset.location ? this.asset.location.locationString : 'N/A',
      colSpan: 'sm:col-span-2'
    });
    fields.push({label: 'Notes', value: this.returnValue(this.asset['notes']), colSpan: 'sm:col-span-2'});

    return fields;
  }

  get listItemFieldsClassName(): string {
    return ``;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {label: 'Equipment Type', value: this.returnValue(this.asset['assetType'])},
      {label: 'UPS Manufacturer', value: this.returnValue(this.asset['upsManufacturer'])},
      {label: 'Serial #', value: this.returnValue(this.asset['serialNumber'])},
    ];
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get buttonText(): string {
    return '';
  }

  get dateTime(): string {
    return this.asset.createdAt ? moment(this.asset.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get linkTo(): string {
    return `/asset/${this.asset.id}`;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'SAP Equipment ID', value: this.returnValue(this.asset.externalId)};
  }

}
