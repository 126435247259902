import React, { useState } from 'react';
import { Button } from '@mui/material';
import useEffectOnce from '../../hooks/useEffectOnce';

type Props = {
  className?: string;
  statuses: any[];
  selectedStatuses: string[];
  onChange: Function;
}

const StatusFilter = (props: Props) => {

  const [statuses, setStatuses] = useState([]);

  useEffectOnce(() => {
    if (!props.selectedStatuses || statuses === props.selectedStatuses) {
      return;
    }

    setStatuses(props.selectedStatuses);
  }, [props.selectedStatuses]);

  const statusChangeHandler = (key: string) => {
    let newValues: string[];

    if (statuses.find(item => item === key)) {
      newValues = statuses.filter(item => item !== key);
    } else {
      newValues = [...statuses, key];
    }

    setStatuses(newValues);
    props.onChange(newValues);
  };

  return (
    <div className={`flex gap-x-6`}>
      {props.statuses && props.statuses.length && props.statuses.map(status =>
        <Button
          key={status.key}
          style={{
            backgroundColor: !!statuses && statuses.length && !!statuses.some(item => item === status.key) ? status.buttonBgColor : '',
            borderColor: !!statuses && statuses.length && !!statuses.some(item => item === status.key) ? status.buttonBgColor : '',
          }}
          variant={`outlined`}
          onClick={() => statusChangeHandler(status.key)}
          size={'small'}
          className={`pointer-events-auto text-10 my-auto py-4 px-10 rounded-full whitespace-nowrap ${!!statuses && statuses.length && !!statuses.some(item => item === status.key) ? 'text-white shadow' : ''}`}
        >
          {status.value}
        </Button>
      )}
    </div>
  );
}

export default StatusFilter;
