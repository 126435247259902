import { ASSET_INSPECTION_STATUS, WORK_ORDER_COMPLETED_STATUSES, WORK_ORDER_NOT_COMPLETED_STATUSES } from '../globals';

export class AllStatsModel {
  inspectionsStats?: any;
  inspectedAssetsStats?: any;
  conditionsFoundStats?: any;
  escalationStats: {
    escalationsCreatedStats?: any;
    escalationsCompleted: number;
    escalationsNotCompleted: number;
    createdWOsStats?: any;
  };
  inspectorStats: {
    inspectorName: string;
    inspectorExternalId: string;
    inspections: number;
    inspectedAssets: number;
    conditionsFound: number;
    escalationsCreated: number;
    WOsCreated: number;
  }[];

  constructor(values: any = {}) {
    Object.assign(this, values)
  }

  get getEscalationInfoCardDetails(): any[] {
    return [
      {
        key: 'resolved',
        label: 'Resolved',
        value: this.escalationStats && this.escalationStats.escalationsCompleted ? this.escalationStats.escalationsCompleted : 0,
        textColor: 'text-green-400',
        linkTo: '/escalations',
        filterParams: {status: WORK_ORDER_COMPLETED_STATUSES}
      },
      {
        key: 'notResolved',
        label: 'Not Resolved',
        value: this.escalationStats && this.escalationStats.escalationsNotCompleted ? this.escalationStats.escalationsNotCompleted : 0,
        textColor: 'text-orange-400',
        linkTo: '/escalations',
        filterParams: {status: WORK_ORDER_NOT_COMPLETED_STATUSES}
      },
      {
        key: 'missing',
        label: 'Missing',
        value: this.getMissingWO,
        textColor: 'text-red-400',
        linkTo: '/asset-inspections',
        filterParams: {status: ASSET_INSPECTION_STATUS.conditionsFound}
      },
    ];
  };

  get getMissingWO() {
    if (!this.conditionsFoundStats || !this.conditionsFoundStats.all || !this.escalationStats) {
      return 'N/A';
    }

    if (!this.escalationStats.escalationsCreatedStats || !this.escalationStats.escalationsCreatedStats.all) {
      return this.conditionsFoundStats.all ;
    }

    const missing = this.conditionsFoundStats.all - this.escalationStats.escalationsCreatedStats.all;

    if (missing < 0) {
      return 0;
    }

    return missing;
  };
}