import { WidgetProps } from '@rjsf/utils';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import SAPWOSelect from '../../SAPWOSelect/SAPWOSelect';

const SAPWOSelectWidget = (props: WidgetProps) => {
  const [validationError, setValidationError] = useState(null);

  const handleValidation = (value) => {
    const validationSchema = yup.object().shape({
      externalId: yup
        .string()
        .notRequired()
        .matches(
          /^(EC|LC)\d{9}$/,
          'Expected format: ECXXXXXXXXX or LCXXXXXXXXX'
        )
        .nullable()
        .transform((val) => (!!val ? val : null)),
    });

    try {
      validationSchema.validateSync({ externalId: value });
      setValidationError(null);
    } catch (error) {
      setValidationError(error.errors[0]);
    }
  };

  useEffect(() => {
    handleValidation(props.value);
  }, [props.value]);

  return (
    <div>
      <SAPWOSelect
        className={'w-full'}
        label={props.label}
        onChange={(event: any) => {
          props.onChange(event.target.value);
          handleValidation(event.target.value);
        }}
        error={!!validationError}
        helperText={validationError}
        value={props.value ? props.value : ''}
      />
    </div>
  );
};

export default SAPWOSelectWidget;
