import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Button } from "@mui/material";
import { SPINNER } from "../../globals";

type Props = {
  className?: string;
  color?: any;
  onClick: any;
  disabled: boolean;
  text: string;
  size?: any;
  isLoading?: boolean;
  children?: JSX.Element[] | JSX.Element;
}

const LoadingButton = (props: Props) => {

  const buttonContextRef = useRef(null);
  const [buttonContextWidth, setButtonContextWidth] = useState(92);
  const [buttonContextHeight, setButtonContextHeight] = useState(19);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(props.isLoading);
  }, [props.isLoading]);

  const onClickHandler = () => {
    props.onClick(setIsLoading)
  }

  useLayoutEffect(() => {

    if (buttonContextRef.current?.clientWidth === 0) {
      return;
    }

    setButtonContextWidth(buttonContextRef.current?.clientWidth);
    setButtonContextHeight(buttonContextRef.current?.clientHeight);

  }, [buttonContextRef.current]);

  return (
    <Button
      onTouchStart={(event) => event.stopPropagation()}
      onMouseDown={(event) => event.stopPropagation()}
      size={props.size}
      onClick={(event) => {
        event.stopPropagation()
        event.preventDefault()
        onClickHandler()
      }}
      className={`text-center px-16 ${props.className}`}
      variant='contained'
      color={props.color ? props.color : 'primary'}
      disabled={props.disabled || isLoading}
    >
      {!isLoading ?
        <span ref={buttonContextRef} className={'flex items-center gap-x-4'}>
          {props.children}
          {props.text}
        </span> :
        <div role="status" className={'inline-flex items-center text-blue text-center'}
             style={{width: buttonContextWidth, height: buttonContextHeight}}>
          {SPINNER}
        </div>
      }
    </Button>
  );
};

export default LoadingButton;
