export const UPS_FIELDS_STATUSES = [
  {
    value: 'Satisfactory',
    key: 'Satisfactory',
    isDisabled: false,
  },
  {
    value: 'Unsatisfactory',
    key: 'Unsatisfactory',
    isDisabled: false,
  },
  {
    value: 'N/A',
    key: 'N/A',
    isDisabled: true,
  },
];

export const UPS_CORRECTIVE_FOUND_LIST = [
  {
    value: 'UPS Age',
    key: 'UPS Age',
  },
  {
    value: 'Battery Age',
    key: 'Battery Age',
  },
];

export const TIER_RATING_LIST = [
  {
    key: '1',
    value: '1',
  },
  {
    key: '2',
    value: '2',
  },
  {
    key: '3',
    value: '3',
  },
  {
    key: 'N/A',
    value: 'N/A',
  },
];

export const UPS_MANUFACTURER_LIST = [
  {
    key: 'APC',
    value: 'APC',
  },
  {
    key: 'APC Symmetra LX',
    value: 'APC Symmetra LX',
  },
  {
    key: 'C&D',
    value: 'C&D',
  },
  {
    key: 'Cyber power',
    value: 'Cyber power',
  },
  {
    key: 'Deltec',
    value: 'Deltec',
  },
  {
    key: 'Eaton',
    value: 'Eaton',
  },
  {
    key: 'Liberty',
    value: 'Liberty',
  },
  {
    key: 'Liebert',
    value: 'Liebert',
  },
  {
    key: 'Middle Atlantic',
    value: 'Middle Atlantic',
  },
  {
    key: 'Mitsubishi',
    value: 'Mitsubishi',
  },
  {
    key: 'Powerware',
    value: 'Powerware',
  },
  {
    key: 'Sola',
    value: 'Sola',
  },
  {
    key: 'Toshiba',
    value: 'Toshiba',
  },
  {
    key: 'Xtreme',
    value: 'Xtreme',
  },
];

export const VOLTAGE_TYPE_LIST = [
  {
    key: 'VAC',
    value: 'VAC',
    isDisabled: true,
  },
  {
    key: 'Other',
    value: 'Other',
    isDisabled: false,
  },
];

export const PHASE_LIST = [
  {
    key: '1',
    value: '1',
  },
  {
    key: '3',
    value: '3',
  },
];

export const YES_NO_LIST = [
  {
    key: 'Yes',
    value: 'Yes',
  },
  {
    key: 'No',
    value: 'No',
  },
];

export const YES_NO_NA_LIST = [
  {
    key: 'Yes',
    value: 'Yes',
  },
  {
    key: 'No',
    value: 'No',
  },
  {
    key: 'N/A',
    value: 'N/A',
  },
];

export const TRUE_FALSE_LIST = [
  {
    key: 'True',
    value: 'True',
  },
  {
    key: 'False',
    value: 'False',
  },
];

export const INTERNAL_EXTERNAL_LIST = [
  {
    key: 'Internal',
    value: 'Internal',
  },
  {
    key: 'External',
    value: 'External',
  },
];

export const UPS_CONDITIONS_LIST = [
  {
    value: 'Incorrectly Mounted',
    key: 'unitCorrectlyMounted',
  },
  {
    value: 'Incorrectly Plugged Into UPS',
    key: 'equipmentConnectedToUPSProtectedOutlets',
  },
  {
    value: 'Alarms Present',
    key: 'Alarms Present',
  },
  {
    value: 'Incorrectly Grounded',
    key: 'upsChassisGroundedToPrimaryGround',
  },
  {
    value: 'Not connected to Netguardian',
    key: 'upsConnectedToNetguardian',
  },
  {
    value: 'Uninstalled Bypass Switch',
    key: 'bypassSwitchInstalled',
  },
  {
    value: 'Dirty Filters and/or Air Intakes',
    key: 'filterAndAirIntakes',
  },
  {
    value: 'Battery Damage',
    key: 'visibleCorrosionBulgingLeaking',
  },
  {
    value: 'Unsatisfactory UPS Chassis Bond',
    key: 'upsChassisBondedExpandableBattery',
  },
  {
    value: 'UPS Age',
    key: 'UPS Age',
  },
  {
    value: 'Battery Age',
    key: 'Battery Age',
  }
];

export const UPS_OBJECT_KEYS_LIST = [
  {
    value: 'On Battery',
    key: 'onBattery',
  },
  {
    value: 'Fault',
    key: 'fault',
  },
  {
    value: 'Low Battery',
    key: 'lowBattery',
  },
  {
    value: 'Replace Battery',
    key: 'replaceBattery',
  },
  {
    value: 'Is the UPS connected to a Netguardian?',
    key: 'upsConnectedToNetguardian',
  },
  {
    value: 'Telenium Alarm Set Up',
    key: 'teleniumSetUp',
  },
  {
    value: 'UPS Self-Test Recording',
    key: 'upsSelfTestRecording',
  },
  {
    value: 'Is a bypass switch installed?',
    key: 'bypassSwitchInstalled',
  },
  {
    value: 'Filters and Air Intakes',
    key: 'filterAndAirIntakes',
  },
  {
    value: 'Is there another UPS in the room?',
    key: 'anotherUPSInRoom',
  },
  {
    value: 'Is there visible corrosion, bulging, leaking or other damage on the battery connections and batteries?',
    key: 'visibleCorrosionBulgingLeaking',
  },
  {
    value: 'Is the UPS chassis bonded to the chassis of any expandable battery packs?',
    key: 'upsChassisBondedExpandableBattery',
  },
];

export const ISSUE_CAUSE_LIST = [
  {
    value: 'Missing or incomplete data, unknown UPS found onsite',
    key: 'Missing or incomplete data, unknown UPS found onsite',
  },
  {
    value: 'Missing screws, sitting on a shelf unbraced, setting on the ground, etc.',
    key: 'Missing screws, sitting on a shelf unbraced, setting on the ground, etc.',
  },
  {
    value: 'Missing or no ground.',
    key: 'Missing or no ground.',
  },
  {
    value: 'Grounded to rack, external batteries not bonded to UPS',
    key: 'Grounded to rack, external batteries not bonded to UPS',
  },
  {
    value: 'Equipment plugged into non-UPS supported outlets on UPS',
    key: 'Equipment plugged into non-UPS supported outlets on UPS',
  },
  {
    value: 'Battery, fan, capacitor, controller, etc.',
    key: 'Battery, fan, capacitor, controller, etc.',
  },
  {
    value: 'No wiring, no I/O Smart Card installed',
    key: 'No wiring, no I/O Smart Card installed',
  },
  {
    value: 'Dirty environment',
    key: 'Dirty environment',
  },
];