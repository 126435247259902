import React, { useEffect, useState } from 'react';
import WorkOrdersService from '../../../services/work-orders.service';
import { FiberWorkOrderModel } from '../../types/fiber/models/fiber-work-order.model';
import { Skeleton, Tooltip } from '@mui/material';
import { FieldChangeModel } from '../../models/field-change.model';

type Props = {
  changeHistoryField: FieldChangeModel;
  userName: string;
  id: string;
  action: string;
}

const WorkOrderComponent = (props: Props) => {

  const [workOrder, setWorkOrder] = useState(new FiberWorkOrderModel());
  const [isLoading, setIsLoading] = useState(false);

  const isDeleted = () => {
    return props.changeHistoryField.additionalFields && props.changeHistoryField.additionalFields.action && props.changeHistoryField.additionalFields.action === 'deleted';
  };

  useEffect(() => {
    if (!(props.changeHistoryField.additionalFields && props.changeHistoryField.additionalFields.action && props.changeHistoryField.additionalFields.action === 'deleted')) {
      setIsLoading(true);
      WorkOrdersService.getWorkOrder(props.id)
        .then((res) => {
          // TODO: fix this
          setWorkOrder(res as FiberWorkOrderModel);
          setIsLoading(false);
        });
      return;
    }
    setWorkOrder(new FiberWorkOrderModel());
  }, [props.id]);

  return <>
    {
      isLoading &&
        <div className={'flex flex-wrap items-start mb-5'}>
            <Skeleton variant='text' animation={'wave'} width={300} sx={{fontSize: '12px'}} className={'my-auto'}/>
        </div>
    }
    {
      !isLoading && workOrder && workOrder.code &&
        <div className={'flex flex-wrap items-start mb-5'}>
            <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>{props.userName}</label>
            <label className={`text-12 mr-3 whitespace-nowrap`}>{props.action + ' escalation'}</label>
          {
            props.action === 'deleted' ?
              <label className={`font-bold text-12 mr-3`}>
                {workOrder.code ? workOrder.code : 'N/A'}
              </label> :
              <Tooltip title={`Open Escalation Page`} placement="top-start">
                <label className={`font-bold text-12 mr-3 cursor-pointer hover:text-blue`} onClick={() => {
                  window.open(`/escalation/${props.id}`);
                }}>
                  {workOrder.code ? workOrder.code : 'N/A'}
                </label>
              </Tooltip>
          }
            <label className={`text-12 mr-3 whitespace-nowrap`}>{'for condition'}</label>
            <label className={`font-bold text-12`}>
              {workOrder.condition ? workOrder.condition : 'N/A'}
            </label>
        </div>
    }
    {
      !isLoading && isDeleted() &&
        <div className={'flex flex-wrap items-start mb-5'}>
            <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>{props.userName}</label>
            <label className={`text-12 mr-3 whitespace-nowrap`}>{props.action + ' escalation'}</label>
            <label className={`font-bold text-12 mr-3`}>
              {props.changeHistoryField.additionalFields.workOrderCode ? props.changeHistoryField.additionalFields.workOrderCode : 'N/A'}
            </label>
            <label className={`text-12 mr-3 whitespace-nowrap`}>{'for condition'}</label>
            <label className={`font-bold text-12`}>
              {props.changeHistoryField.additionalFields.condition ? props.changeHistoryField.additionalFields.condition : 'N/A'}
            </label>
        </div>
    }
  </>;
}

export default WorkOrderComponent;