import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import Inspection from "./Inspection";

i18next.addResourceBundle('en', 'inspectionPage', en);

const InspectionConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'inspection/:id',
      element: <Inspection />,
    },
  ],
};

export default InspectionConfig;
