import React from 'react';
import InspectionStatusChip from '../../InspectionStatusChip/InspectionStatusChip';
import Chip from '../../Chip/Chip';
import StatusClosedTimeChip from '../../StatusClosedTimeChip/StatusClosedTimeChip';
import { DataComponentModel } from '../../../models/interfaces/data-component.model';
import {
  InspectionHeadingCardDataInterface,
} from '../../../models/data-model-interfaces/inspection-heading-card-data.interface';
import { FIBER_INSPECTION_STATUSES } from '../../../types/fiber/statuses/fiber-inspection.statuses';

type Props = {
  dataModel: DataComponentModel & InspectionHeadingCardDataInterface;
}

const InspectionHeadingCardHeading = (props: Props) => {

  return (
    <div className={'w-360 flex flex-col'}>
      <p className={`text-15 font-500'`}>
        {props.dataModel.listItemHeading.label}
      </p>
      <div className={'flex flex-col sm:flex-row items-start sm:items-center gap-x-10'}>
        <p className={`font-bold text-24`}>
          {props.dataModel.listItemHeading.value}
        </p>
        {
          // TODO: Refactor statuses
        }
        <InspectionStatusChip className={'h-20 text-white'} status={props.dataModel.getProp('status')}
                              statusObjects={FIBER_INSPECTION_STATUSES} />
      </div>
      <div className={'flex flex-wrap items-start gap-4 mt-4'}>
        <Chip className={`my-auto mt-0 font-semibold ${props.dataModel.isReadonly && 'hidden'}`} bgColor={'bg-gray-300'}
              label={props.dataModel.listItemHeading.info}
        />
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={props.dataModel.dateTime}
        />
        {
          !!props.dataModel.lastTimeStatusChanged && props.dataModel.isCompleted &&
          <StatusClosedTimeChip lastTimeStatusChanged={props.dataModel.lastTimeStatusChanged} />
        }
      </div>
    </div>
  );
};

export default InspectionHeadingCardHeading;
