import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tooltip } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  className?: string;
  modelName?: string;
  previousNextItem: any;
  navigateEnabled?: boolean;
}
const PreviousNextNavigator = (props: Props) => {

  const LEFT_ARROW = 'ArrowLeft';
  const RIGHT_ARROW = 'ArrowRight';

  const navigate = useNavigate();

  const keyPressedHandler = (event) => {
    if (!props.navigateEnabled) {
      return;
    }

    switch (event.key) {
      case LEFT_ARROW:
        if (!props.previousNextItem || !props.previousNextItem.previous) {
          break;
        }
        onClickHandler(props.previousNextItem.previous);
        break;
      case RIGHT_ARROW:
        if (!props.previousNextItem || !props.previousNextItem.next) {
          break;
        }
        onClickHandler(props.previousNextItem.next);
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPressedHandler, false);

    return () => {
      document.removeEventListener('keydown', keyPressedHandler, false);
    };
  }, [props.previousNextItem]);

  const onClickHandler = (linkTo: string) => {
    if (!linkTo) {
      return;
    }

    navigate(linkTo);
  };

  const navigationButton = (hoverText: string, link: string, icon: string) => {
    return <Tooltip followCursor={true} title={`${!link ? '' : hoverText}`} placement='top-start'>
      <span>
        <Button
          sx={{minWidth: 0, padding: 0}}
          size={'small'}
          onClick={
            () =>
              onClickHandler(link)
          }
          className={`flex text-center h-full`}
          variant='contained'
          color={'inherit'}
          disabled={!link}
        >
        {/*@ts-ignore*/}
          <FuseSvgIcon size={26} className={'mx-1'}>{icon}</FuseSvgIcon>
        </Button>
        </span>
    </Tooltip>;
  };

  return (
    <div className={`flex gap-x-6 py-1 ${props.className}`}>
      {navigationButton(`Go to the previous ${props.modelName}`, props.previousNextItem && props.previousNextItem.previous, 'material-outline:chevron_left')}
      {navigationButton(`Go to the next ${props.modelName}`, props.previousNextItem && props.previousNextItem.next, 'material-outline:chevron_right')}
    </div>
  );
};

export default PreviousNextNavigator;
