import { AssetInspectionViewModel } from '../../../models/base-view-models/asset-inspection-view.model';
import { FiberAssetInspectionModel } from '../models/fiber-asset-inspection.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { InspectionHelper } from '../../../helpers/inspection.helper';

export class FiberAssetInspectionViewModel extends AssetInspectionViewModel {

  private assetInspection: FiberAssetInspectionModel;

  constructor(assetInspection: FiberAssetInspectionModel) {
    super(assetInspection);
    this.assetInspection = assetInspection;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [];
  }

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Inspection Type', value: this.returnValue(this.assetInspection.inspectionType)},
    ];

    if (this.assetInspection.damageCause && this.assetInspection.damageCause.length) {
      fields.push({
        label: 'Damage Cause', value: this.assetInspection.damageCause ? this.assetInspection.damageCause.join(', ') : 'N/A',
      });
    }

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join(', ') : 'No Trouble Found',
    });

    if (this.assetInspection.barcode) {
      fields.push({label: 'Barcode', value: this.returnValue(this.assetInspection.barcode)});
    }

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Inspected Attributes',
      value: this.assetInspection.inspectedAttributes && this.assetInspection.inspectedAttributes.length ? this.assetInspection.inspectedAttributes.join(', ') : 'N/A',
      colSpan: `${this.assetInspection.inspectedAttributes && this.assetInspection.inspectedAttributes.length && 'sm:col-span-2'}`,
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get workOrderPageDetailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Inspection Type', value: this.returnValue(this.assetInspection.inspectionType)},
    ];

    if (this.assetInspection.damageCause && this.assetInspection.damageCause.length) {
      fields.push({
        label: 'Damage Cause', value: this.assetInspection.damageCause ? this.assetInspection.damageCause.join(', ') : 'N/A',
      });
    }

    fields.push({
      label: 'Due Date',
      value: this.getDueDate,
    });

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join(', ') : 'No Trouble Found',
      colSpan: `${this.assetInspection.conditions && this.assetInspection.conditions.length && 'sm:col-span-2'}`,
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Remedy Work Order #',
      value: this.assetInspection.inspection && this.assetInspection.inspection.externalId ? this.assetInspection.inspection.externalId : 'N/A',
    });

    if (this.assetInspection.barcode) {
      fields.push({label: 'Barcode', value: this.returnValue(this.assetInspection.barcode)});
    }

    fields.push({
      label: 'Inspected Attributes',
      value: this.assetInspection.inspectedAttributes && this.assetInspection.inspectedAttributes.length ? this.assetInspection.inspectedAttributes.join(', ') : 'N/A',
      colSpan: `${this.assetInspection.inspectedAttributes && this.assetInspection.inspectedAttributes.length && 'sm:col-span-2'}`,
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'Equipment Name', value: this.returnValue(this.assetInspection['name'])};
  }

  get linkTo(): string {
    return `/asset-inspection/${this.assetInspection.id}`;
  }

  get listItemFieldsClassName(): string {
    return `md:grid-cols-6`;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {label: 'Remedy Work Order #', value: this.assetInspection.inspection ? this.returnValue(this.assetInspection.inspection.externalId) : 'N/A'},
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'City, County', value:
          (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.city) : 'N/A') + ', '
          + (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.county) : 'N/A'),
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join(', ') : 'No Trouble Found',
      },
      {
        label: 'Due Date',
        value: this.getDueDate,
      },
    ];
  }

  get secondaryListItemFieldsClassName(): string {
    return `sm:grid-cols-4`;
  }

  get secondaryListItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: 'SAP ID',
        value: this.assetInspection.asset ? this.returnValue(this.assetInspection.asset.externalId) : 'N/A'
      },
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join(', ') : 'No Trouble Found',
      },
    ];
  }

  get dateTime(): string {
    return this.assetInspection.createdAt ? moment(this.assetInspection.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get buttonText(): string {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get componentHeader(): string {
    return this.returnValue(this.assetInspection['name']);
  }

  get getDueDate() {
    return this.assetInspection.inspection && this.assetInspection.inspection.dueDate ? moment(`${this.assetInspection.inspection.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get assetInspectionStatusColor(): number[] {
    return InspectionHelper.getAssetInspectionStatusColor(this.assetInspection.status);
  }

  get previousNextListItem(): any {
    return InspectionHelper.getAssetInspectionPreviousNext(this.assetInspection);
  }

  get additionalAttachmentsTitle(): string {
    return '';
  }
}
