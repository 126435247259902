import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { PASSIVE_REFLECTORS_CONDITIONS_LIST } from '../globals';

export const PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    'start-date': {
      type: 'string',
      title: 'Start Date',
    },
    'end-date': {
      type: 'string',
      title: 'End Date',
    },
    'created-by': {
      type: 'string',
      title: 'Created By',
    },
    'assignee-to': {
      type: 'string',
      title: 'Assigned To',
    },
    'condition': {
      type: 'string',
      title: 'With Condition',
    },
  },
};

export const PASSIVE_REFLECTORS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 lg:flex lg:flex-row lg:mr-4 justify-start items-center gap-x-10 lg:gap-x-8 gap-y-12',
  'ui:inline': true,
  'start-date': {
    'ui:placeholder': 'Start Date',
    'ui:classNames': 'lg:w-144',
    'ui:widget': 'date',
  },
  'end-date': {
    'ui:placeholder': 'End Date',
    'ui:classNames': 'lg:w-144',
    'ui:widget': 'date',
  },
  'created-by': {
    'ui:placeholder': 'Inspector',
    'ui:classNames': 'lg:w-192',
    "ui:widget": "UserExternalIdMultiSelectWidget",
  },
  'assignee-to': {
    'ui:placeholder': 'Assigned To',
    'ui:classNames': 'lg:w-192',
    'ui:widget': 'UserExternalIdMultiSelectWidget',
  },
  'condition': {
    'ui:placeholder': 'With Condition',
    'ui:classNames': 'sm:col-span-2 md:col-span-1 lg:w-192',
    'ui:widget': 'MultiSelectWidget',
    'ui:options': {
      optionsList: PASSIVE_REFLECTORS_CONDITIONS_LIST.map((item) => ({
        key: item.value,
        value: item.value
      })),
    },
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};