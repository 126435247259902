import { motion } from 'framer-motion';
import React, { memo, useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FiberWorkOrderModel } from '../../shared/types/fiber/models/fiber-work-order.model';
import WorkOrderHeader from './components/WorkOrderHeader';
import WorkOrdersService from '../../services/work-orders.service';
import { useDispatch } from 'react-redux';
import DetailsCard from '../../shared/components/DetailsCard/DetailsCard';
import AttachmentsCard from '../../shared/components/AttachmentsCard/AttachmentsCard';
import ChangeLogCard from '../../shared/components/ChangeLogCard/ChangeLogCard';
import ToggleButton from '../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Menu from '../../shared/components/Menu/Menu';
import WorkOrderModal from '../asset-inspection/components/WorkOrderModal';
import { NotificationMessage } from '../../shared/components/NotificationMessage/NotificationMessage';
import MenuItem from '../../shared/components/Menu/MenuItem';
import CorrectiveActionModal from '../asset-inspection/components/CorrectiveActionModal';
import CorrectiveActionsDetailsCard from './components/CorrectiveActionDetailsCard';
import { CorrectiveActionModel } from '../../shared/models/corrective-action.model';
import UsersService from '../../services/users.service';
import { InspectionHelper } from '../../shared/helpers/inspection.helper';
import { WORK_ORDER_STATUS } from '../../shared/globals';
import DeleteWorkOrderDialog from './components/DeleteWorkOrderDialog';
import Breadcrumbs from '../../shared/components/Breadcrumbs/Breadcrumbs';
import BreadcrumbsSkeleton from './components/BreadcrumbsSkeleton';
import { WorkOrderModel } from '../../shared/models/base-models/work-order.model';
import { WorkOrderViewModel } from '../../shared/models/base-view-models/work-order-view.model';
import { WorkOrderViewModelFactory } from '../../shared/view-model-factories/work-order-view-model.factory';
import { AssetInspectionViewModelFactory } from '../../shared/view-model-factories/asset-inspection-view-model.factory';
import { AssetInspectionViewModel } from '../../shared/models/base-view-models/asset-inspection-view.model';
import { AssetInspectionModel } from '../../shared/models/base-models/asset-inspection.model';
import { AssetViewModelFactory } from '../../shared/view-model-factories/asset-view-model.factory';
import { AssetViewModel } from '../../shared/models/base-view-models/asset-view.model';
import { AssetModel } from '../../shared/models/base-models/asset.model';
import DepartmentContext from '../../DepartmentContext';

const WorkOrderContent = () => {

  const {updateDepartment} = useContext(DepartmentContext);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [workOrder, setWorkOrder] = useState(new WorkOrderModel());
  const [workOrderView, setWorkOrderView] = useState(null as WorkOrderViewModel);
  const [assetInspectionView, setAssetInspectionView] = useState(null as AssetInspectionViewModel);
  const [assetView, setAssetView] = useState(null as AssetViewModel);

  const [workOrderClosed, setWorkOrderClosed] = useState(true);
  const [workOrderStatusCanChange, setWorkOrderStatusCanChange] = useState(false);
  const [workOrderDeletable, setWorkOrderDeletable] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');

  const [correctiveActions, setCorrectiveActions] = useState([]);
  const [openCorrectiveActionModal, setOpenCorrectiveActionModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [comments, setComments] = useState([]);

  useEffect(() => {
    if (!params.id) {
      return;
    }

    setIsLoading(true);
    WorkOrdersService.getWorkOrder(params.id, {expand: 'assetInspection'})
      .then(result => {
        updateDepartment(result.type);

        result.assetInspection.asset.comparingAsset = result.assetInspection.snapshot && result.assetInspection.snapshot.asset;

        setWorkOrder(result);
        setWorkOrderView(new WorkOrderViewModelFactory().createViewModel<WorkOrderViewModel, WorkOrderModel>(result));
        setAssetInspectionView(new AssetInspectionViewModelFactory().createViewModel<AssetInspectionViewModel, AssetInspectionModel>(result.assetInspection));
        setAssetView(new AssetViewModelFactory().createViewModel<AssetViewModel, AssetModel>(result.assetInspection.asset));
        setComments(result.comments ? result.comments : []);
        setIsLoading(false);
        setBreadcrumbs(InspectionHelper.getWorkOrderBreadcrumbs(result));
        setCorrectiveActions(result.correctiveActions);
      });
  }, [params.id]);

  const workOrderUpdatedHandler = (workOrder: WorkOrderModel) => {
    workOrder.assetInspection.asset.comparingAsset = workOrder.assetInspection.snapshot.asset;
    setWorkOrder(workOrder);
    setWorkOrderView(new WorkOrderViewModelFactory().createViewModel<WorkOrderViewModel, WorkOrderModel>(workOrder));
  };

  useEffect(() => {
    if (!workOrder.status) {
      return;
    }

    const currentUser = UsersService.getCurrentUser();
    const currentUserIsAdmin = UsersService.getCurrentUserIsAdmin();

    setWorkOrderStatusCanChange(InspectionHelper.workOrderStatusCanChange(workOrder, currentUser ? currentUser.id : '') || currentUserIsAdmin);
    setWorkOrderDeletable(InspectionHelper.userIsCreator(workOrder, currentUser ? currentUser.id : '') || currentUserIsAdmin);
    setWorkOrderClosed(InspectionHelper.statusIsClosed(workOrder) && !currentUserIsAdmin);
  }, [workOrder]);

  const correctiveActionCreatedHandler = (correctiveAction: CorrectiveActionModel) => {
    let updatedCorrectiveActions = correctiveActions;

    if (!updatedCorrectiveActions) {
      updatedCorrectiveActions = [];
    }

    updatedCorrectiveActions.push(correctiveAction);

    setCorrectiveActions(updatedCorrectiveActions);
  };

  const workOrderDeletedHandler = () => {
    navigate(`/asset-inspection/${workOrder.assetInspectionId}`);
  };

  const commentAddedHandler = async (commentText: string) => {
    const body = {
      text: commentText,
    };

    return WorkOrdersService.addComment(workOrder.id, body)
      .then((res) => {
        dispatch(NotificationMessage('Comment added successfully', 'success'));
        setComments(comments => [
          ...comments,
          res,
        ]);
      })
      .catch(() => {
        dispatch(NotificationMessage('Error saving comment', 'error'));
      });
  };

  const openCorrectiveActionModalHandler = () => {
    setOpenCorrectiveActionModal(true);
  };

  const onCorrectiveActionModalCloseHandler = () => {
    setOpenCorrectiveActionModal(false);
  };

  const openEditModalHandler = () => {
    setOpenEditModal(true);
  };

  const onEditModalCloseHandler = () => {
    setOpenEditModal(false);
  };

  const openDeleteDialogHandler = () => {
    setOpenDeleteDialog(true);
  };

  const onDeleteDialogCloseHandler = () => {
    setOpenDeleteDialog(false);
  };

  const statusChangeHandler = async (status: string) => {
    setStatusChangeLoading(true);
    setLoadingStatus(status);

    WorkOrdersService.updateWorkOrderStatus(workOrder.id, {
      status: status,
    })
      .then(res => {
        setWorkOrder(res);
        setWorkOrderView(new WorkOrderViewModelFactory().createViewModel<WorkOrderViewModel, WorkOrderModel>(res));
        setStatusChangeLoading(false);
        setLoadingStatus('');
        setMenuOpened(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Status changed successfully', 'success'));
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error saving changes';
        dispatch(NotificationMessage(message, 'error'));
        setStatusChangeLoading(false);
        setLoadingStatus('');
        setMenuOpened(false);
      });
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const menuButton =
    <ToggleButton
      disabled={isLoading || workOrderClosed}
      isActive={menuOpened}
      onClick={showMenu}
      className={'pl-16 pr-12 hover:bg-gray-200'}
      text={'Actions'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={16}>material-outline:arrow_drop_down</FuseSvgIcon>
    </ToggleButton>;

  const menu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'edit'} onClick={openEditModalHandler} setMenuOpened={setMenuOpened}
                  icon={'material-outline:edit'} text={'Edit Details'}/>,
        <MenuItem key={'markAsPending'} onClick={() => statusChangeHandler(WORK_ORDER_STATUS.pending)}
                  isLoading={loadingStatus === WORK_ORDER_STATUS.pending}
                  icon={'material-outline:pending'} text={'Mark as Pending'}
                  disabled={statusChangeLoading || workOrder.status === WORK_ORDER_STATUS.waitingAssignment}
                  className={`${workOrderStatusCanChange && workOrder.status !== WORK_ORDER_STATUS.pending ? '' : 'hidden'}`}/>,
        <MenuItem key={'markAsPlanning'} onClick={() => statusChangeHandler(WORK_ORDER_STATUS.planning)}
                  isLoading={loadingStatus === WORK_ORDER_STATUS.planning}
                  icon={'material-outline:arrow_right_alt'} text={'Mark as Planning'}
                  disabled={statusChangeLoading || workOrder.status === WORK_ORDER_STATUS.waitingAssignment}
                  className={`${workOrderStatusCanChange && workOrder.status !== WORK_ORDER_STATUS.planning ? '' : 'hidden'}`}/>,
        <MenuItem key={'markAsInProgress'} onClick={() => statusChangeHandler(WORK_ORDER_STATUS.inProgress)}
                  isLoading={loadingStatus === WORK_ORDER_STATUS.inProgress}
                  icon={'material-outline:arrow_right_alt'} text={'Mark as In Progress'}
                  disabled={statusChangeLoading || workOrder.status === WORK_ORDER_STATUS.waitingAssignment}
                  className={`${workOrderStatusCanChange && workOrder.status !== WORK_ORDER_STATUS.inProgress ? '' : 'hidden'}`}/>,
        <MenuItem key={'markAsCompleted'} onClick={() => statusChangeHandler(WORK_ORDER_STATUS.completed)}
                  isLoading={loadingStatus === WORK_ORDER_STATUS.completed}
                  icon={'material-outline:done'} text={'Mark as Completed'}
                  disabled={statusChangeLoading || workOrder.status === WORK_ORDER_STATUS.waitingAssignment}
                  className={`${workOrderStatusCanChange && workOrder.status !== WORK_ORDER_STATUS.completed ? '' : 'hidden'}`}/>,
        <MenuItem key={'markAsCanceled'} onClick={() => statusChangeHandler(WORK_ORDER_STATUS.canceled)}
                  isLoading={loadingStatus === WORK_ORDER_STATUS.canceled}
                  icon={'material-outline:cancel'} text={'Mark as Canceled'}
                  disabled={statusChangeLoading || workOrder.status === WORK_ORDER_STATUS.waitingAssignment}
                  className={`${workOrderStatusCanChange && workOrder.status !== WORK_ORDER_STATUS.canceled ? '' : 'hidden'}`}/>,
        <MenuItem key={'delete'} onClick={openDeleteDialogHandler} setMenuOpened={setMenuOpened}
                  icon={'material-outline:delete'} text={'Delete Escalation'}
                  className={`${workOrderDeletable ? '' : 'hidden'}`}/>,
      ]}
    />;

  const detailsCard =
    <DetailsCard items={workOrderView ? workOrderView.detailCardFields : []} className={'md:mb-16'}
                 isLoading={isLoading} linkTo={''} title={'Escalation Details'}
                 contentElementClassName={'w-full grid sm:grid-cols-3 gap-8 align-middle'}
    />;

  const assetDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Asset Info'} isLoading={isLoading}
      linkTo={assetView ? assetView.linkTo: ''}
      hoverText={'Open Asset Page'}
      items={assetView ? assetView.infoCardFields : []}
      contentElementClassName={'w-full grid sm:grid-cols-2 gap-8 align-middle'}
    />;

  const assetInspectionDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Asset Inspection Details'} isLoading={isLoading}
      linkTo={assetInspectionView ? assetInspectionView.linkTo : ''} hoverText={'Open Asset Inspection Page'}
      items={assetInspectionView ? assetInspectionView.workOrderPageDetailCardFields : []}
      contentElementClassName={'w-full grid sm:grid-cols-2 gap-8 align-middle'}
    />;

  const changeLogCard =
    <ChangeLogCard className={''} changeHistory={workOrder.changeHistory} commentFormIsDisabled={workOrderClosed}
                   isLoading={isLoading} title={'Change Log'} comments={comments}
                   commentAddedHandler={commentAddedHandler}
    />;

  const attachmentsCard =
    <AttachmentsCard attachments={workOrder.attachments} isLoading={isLoading} modelId={{workOrderId: workOrder.id}}/>;

  const correctiveActionsCard =
    <CorrectiveActionsDetailsCard correctiveActions={correctiveActions} isLoading={isLoading}
                                  className={'lg:mb-16'}/>;

  return (
    <>
      <motion.div
        className='w-full h-full p-12 lg:p-24'
        initial='hidden'
        animate='show'
      >
        {!isLoading && breadcrumbs && breadcrumbs.length &&
            <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-4 px-3'}/>
        }
        {
          !!isLoading &&
            <BreadcrumbsSkeleton/>
        }
        <motion.div>
          <div className={'flex flex-col md:flex-row md:items-center justify-between gap-8 mt-2 px-3'}>
            <WorkOrderHeader
              dataModel={workOrderView}
              workOrder={workOrder as FiberWorkOrderModel}
              isLoading={isLoading}/>
            <div className={'flex flex-wrap gap-8'}>
              <ToggleButton
                isActive={true}
                size='small'
                onClick={openCorrectiveActionModalHandler}
                className={`bg-orange-600 hover:bg-orange-400 whitespace-nowrap pl-12 pr-16`}
                disabled={isLoading || workOrderClosed}
              >
                <span className={'flex items-center gap-x-4'}>
                  {/*@ts-ignore*/}
                  <FuseSvgIcon size={19}>material-outline:add</FuseSvgIcon>
                  Add Corrective Action
                </span>
              </ToggleButton>
              <div>
                {menuButton}
                {menu}
              </div>
            </div>
          </div>
          <div className='hidden md:grid grid-cols-5 grid-flow-col gap-16 px-3 mt-8'>
            <div className='row-span-6 col-span-3'>
              {detailsCard}
              {correctiveActionsCard}
              {changeLogCard}
            </div>
            <div className='md:col-span-2'>
              {assetDetailsCard}
              {assetInspectionDetailsCard}
              {attachmentsCard}
            </div>
          </div>
          <div className='flex flex-col md:hidden gap-16 px-3 mt-8'>
            {detailsCard}
            {correctiveActionsCard}
            {assetDetailsCard}
            {assetInspectionDetailsCard}
            {attachmentsCard}
            {changeLogCard}
          </div>
        </motion.div>
      </motion.div>
      {openEditModal &&
          <WorkOrderModal onClose={onEditModalCloseHandler} workOrder={workOrder} onSave={workOrderUpdatedHandler}/>
      }
      {openCorrectiveActionModal &&
          <CorrectiveActionModal onClose={onCorrectiveActionModalCloseHandler} onSave={correctiveActionCreatedHandler}
                                 workOrderId={workOrder.id}/>
      }
      {openDeleteDialog &&
          <DeleteWorkOrderDialog onClose={onDeleteDialogCloseHandler} onDelete={workOrderDeletedHandler}
                                 workOrderId={workOrder.id}/>
      }
    </>
  );
};

export default memo(WorkOrderContent);
