import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { AssetViewModel } from '../../../models/base-view-models/asset-view.model';
import { PassiveReflectorsAssetModel } from '../models/passive-reflectors-asset.model';

export class PassiveReflectorsAssetViewModel extends AssetViewModel {

  private asset: PassiveReflectorsAssetModel;

  constructor(asset: PassiveReflectorsAssetModel) {
    super(asset);
    this.asset = asset;
  }

  get componentHeaderTitle(): string {
    return `Equipment Number`;
  }

  get componentHeader(): string {
    return `${this.returnValue(this.asset.equipmentNumber)}`;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [
      this.getInfoCardField('Equipment Number', 'equipmentNumber'),
      this.getInfoCardField('Tower Type', 'towerType'),
      this.getInfoCardField('Tower Manufacturer', 'towerManufacturer'),
      this.getInfoCardField('Tower Tag Information', 'towerTagInformation'),
      this.getInfoCardField('Structure Height', 'structureHeight'),
      this.getInfoCardField('Top of Highest Appurt. (AGL)', 'topOfHighestAppurt'),
      this.getInfoCardField('Ground Elevation', 'groundElevation'),
      this.getInfoCardField('FCCID', 'fccid'),
      {
        label: 'Location',
        value: this.asset.location ? this.asset.location.locationString : 'N/A',
        colSpan: 'sm:col-span-2',
        oldValue: this.comparingAssetLocation()
      },
    ];
  }

  private getInfoCardField(name: string, field: string): InspectionCardItemModel {
    return {
      label: name,
      value: this.returnValue(this.asset[field]),
      oldValue: this.asset.comparingAsset && this.asset.comparingAsset[field] && (this.asset.comparingAsset[field] !== this.asset[field]) && this.returnValue(this.asset.comparingAsset[field])
    };
  };

  private comparingAssetLocation(): string {
    return this.asset.comparingAsset && (this.getComparingAssetLocation() !== (this.asset.location && this.asset.location.locationString)) && this.getComparingAssetLocation();
  };

  private getComparingAssetLocation(): string {
    return this.asset.comparingAsset && this.asset.comparingAsset.location ? `${this.returnValue(this.asset.comparingAsset.location['latitude'])}, ${this.returnValue(this.asset.comparingAsset.location['longitude'])}` : 'N/A';
  };

  get detailCardFields(): InspectionCardItemModel[] {
    return [
      {label: 'Tower Type', value: this.returnValue(this.asset['towerType'])},
      {label: 'Tower Manufacturer', value: this.returnValue(this.asset['towerManufacturer'])},
      {label: 'Tower Tag Information', value: this.returnValue(this.asset['towerTagInformation'])},
      {label: 'Structure Height', value: this.returnValue(this.asset['structureHeight'])},
      {label: 'Top of Highest Appurt. (AGL)', value: this.returnValue(this.asset['topOfHighestAppurt'])},
      {label: 'Ground Elevation', value: this.returnValue(this.asset['groundElevation'])},
      {label: 'FCCID', value: this.returnValue(this.asset['fccid'])},
      {label: 'Location', value: this.asset.location ? this.asset.location.locationString : 'N/A', colSpan: 'sm:col-span-2'},
      {label: 'Notes', value: this.returnValue(this.asset['notes']), colSpan: 'sm:col-span-2'},
    ];
  }

  get listItemFieldsClassName(): string {
    return ``;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {label: 'Tower Type', value: this.returnValue(this.asset['towerType'])},
      {label: 'Tower Manufacturer', value: this.returnValue(this.asset['towerManufacturer'])},
      {label: 'Tower Tag Information', value: this.returnValue(this.asset['towerTagInformation'])},
    ];
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get buttonText(): string {
    return '';
  }

  get dateTime(): string {
    return this.asset.createdAt ? moment(this.asset.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get linkTo(): string {
    return `/asset/${this.asset.id}`;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'Equipment Number', value: this.returnValue(this.asset.equipmentNumber)};
  }

}
