import Chip from '../Chip/Chip';

type Props = {
  status: string;
  className?: string;
  statusObjects: any[];
}

const StatusChip = (props: Props) => {

  const statusObject = props.statusObjects.find(statusObject => statusObject.key === props.status);

  return (
    <Chip className={props.className} bgColor={statusObject ? statusObject.bgColor : ''}
          label={statusObject ? statusObject.value : ''} />
  );
}

export default StatusChip;
