import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { TIER_RATING_LIST, GENSET_MAKE_LIST } from '../globals';

export const GENERATORS_ASSET_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['size'],
  properties: {
    equipmentNumber: {
      type: 'string',
      title: 'Equipment Number',
    },
    size: {
      type: 'number',
      title: 'Size',
    },
    capacityUnits: {
      type: 'string',
      title: 'Capacity Units',
    },
    tierRating: {
      type: 'string',
      title: 'Tier Rating',
    },
    equipmentLife: {
      type: 'string',
      title: 'Equipment Life',
    },
    endOfLife: {
      type: 'string',
      title: 'End of Life',
    },
    loadValue: {
      type: 'string',
      title: 'Load',
    },
    phase: {
      type: 'string',
      title: 'Phase',
    },
    conditionRating: {
      type: 'string',
      title: 'Condition Rating',
    },
    genset: {
      type: 'object',
      title: 'Genset',
      properties: {
        make: {
          type: 'string',
          title: 'Make',
        },
        model: {
          type: 'string',
          title: 'Model',
        },
        serialNumber: {
          type: 'string',
          title: 'Serial #',
        },
        specNumber: {
          type: 'string',
          title: 'Special #',
        },
      },
    },
    engineDetails: {
      type: 'object',
      title: 'Engine',
      properties: {
        make: {
          type: 'string',
          title: 'Make',
        },
        model: {
          type: 'string',
          title: 'Model',
        },
        serialNumber: {
          type: 'string',
          title: 'Serial #',
        },
        specNumber: {
          type: 'string',
          title: 'Special #',
        },
      },
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const GENERATORS_ASSET_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames': 'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  equipmentNumber: {
    'ui:placeholder': 'Equipment Number',
  },
  size: {
    'ui:placeholder': 'Size',
    'ui:widget': 'NumberWidget',
  },
  capacityUnits: {
    'ui:placeholder': 'Capacity Units',
  },
  tierRating: {
    'ui:placeholder': 'Tier Rating',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: TIER_RATING_LIST,
    },
  },
  equipmentLife: {
    'ui:placeholder': 'Equipment Life',
  },
  endOfLife: {
    'ui:placeholder': 'End of Life',
  },
  loadValue: {
    'ui:placeholder': 'Load',
  },
  phase: {
    'ui:placeholder': 'Phase',
  },
  conditionRating: {
    'ui:placeholder': 'Condition Rating',
  },
  genset: {
    make: {
      'ui:placeholder': 'Genset Make',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENSET_MAKE_LIST,
      },
    },
    model: {
      'ui:placeholder': 'Genset Model',
    },
    serialNumber: {
      'ui:placeholder': 'Genset Serial #',
    },
    specNumber: {
      'ui:placeholder': 'Genset Spec #',
    },
    'ui:classNames': 'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  engineDetails: {
    make: {
      'ui:placeholder': 'Engine Make',
    },
    model: {
      'ui:placeholder': 'Engine Model',
    },
    serialNumber: {
      'ui:placeholder': 'Engine Serial #',
    },
    specNumber: {
      'ui:placeholder': 'Engine Spec #',
    },
    'ui:classNames': 'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};