import { DATA_TYPE, DataType } from '../types';
import { FiberInspectionModel } from './models/fiber-inspection.model';
import { FiberAssetInspectionModel } from './models/fiber-asset-inspection.model';
import { FiberAssetModel } from './models/fiber-asset.model';
import { FiberWorkOrderModel } from './models/fiber-work-order.model';
import { FiberInspectionViewModel } from './view-models/fiber-inspection.view-model';
import { FiberAssetInspectionViewModel } from './view-models/fiber-asset-inspection.view-model';
import { FiberAssetViewModel } from './view-models/fiber-asset.view-model';
import { FiberWorkOrderViewModel } from './view-models/fiber-work-order.view-model';
import { FIBER_INSPECTION_STATUSES } from './statuses/fiber-inspection.statuses';
import { FIBER_ASSET_INSPECTION_STATUSES } from './statuses/fiber-asset-inspection.statuses';
import { FIBER_WORK_ORDER_STATUSES } from './statuses/fiber-work-order.statuses';
import { FIBER_INSPECTION_FORM_SCHEMA, FIBER_INSPECTION_FORM_UI_SCHEMA } from './forms/fiber-inspection.form';
import {
  FIBER_ASSET_INSPECTION_FORM_SCHEMA,
  FIBER_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/fiber-asset-inspection.form';
import {
  FIBER_ASSET_FORM_SCHEMA,
  FIBER_ASSET_FORM_UI_SCHEMA
} from './forms/fiber-asset.form';
import {
  FIBER_DISPATCHING_FORM_SCHEMA,
  FIBER_DISPATCHING_FORM_UI_SCHEMA
} from './forms/fiber-dispatching.form';
import { FIBER_WORK_ORDER_FORM_SCHEMA, FIBER_WORK_ORDER_FORM_UI_SCHEMA } from './forms/fiber-work-order.form';
import { FIBER_MASS_WORK_ORDER_FORM_SCHEMA, FIBER_MASS_WORK_ORDER_FORM_UI_SCHEMA } from './forms/fiber-mass-work-order.form';
import { FiberDispatchingDocumentModel } from './models/fiber-dispatching-document.model';
import {
  FIBER_INSPECTIONS_FILTER_FORM_SCHEMA,
  FIBER_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/fiber-inspections-filter.form';
import {
  FIBER_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
  FIBER_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/fiber-asset-inspections-filter.form';
import {
  FIBER_WORK_ORDERS_FILTER_FORM_SCHEMA,
  FIBER_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/fiber-work-orders-filter.form';

export const FIBER_TYPE_CONFIG: DataType = {
  type: DATA_TYPE.fiber,
  InspectionModel: FiberInspectionModel,
  AssetInspectionModel: FiberAssetInspectionModel,
  AssetModel: FiberAssetModel,
  WorkOrderModel: FiberWorkOrderModel,
  DispatchingDocumentModel: FiberDispatchingDocumentModel,
  InspectionViewModel: FiberInspectionViewModel,
  AssetInspectionViewModel: FiberAssetInspectionViewModel,
  AssetViewModel: FiberAssetViewModel,
  WorkOrderViewModel: FiberWorkOrderViewModel,
  InspectionForm: {
    schema: FIBER_INSPECTION_FORM_SCHEMA,
    uiSchema: FIBER_INSPECTION_FORM_UI_SCHEMA
  },
  AssetInspectionForm: {
    schema: FIBER_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: FIBER_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  AssetForm: {
    schema: FIBER_ASSET_FORM_SCHEMA,
    uiSchema: FIBER_ASSET_FORM_UI_SCHEMA
  },
  DispatchingForm: {
    schema: FIBER_DISPATCHING_FORM_SCHEMA,
    uiSchema: FIBER_DISPATCHING_FORM_UI_SCHEMA
  },
  WorkOrderForm: {
    schema: FIBER_WORK_ORDER_FORM_SCHEMA,
    uiSchema: FIBER_WORK_ORDER_FORM_UI_SCHEMA
  },
  MassWorkOrderForm: {
    schema: FIBER_MASS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: FIBER_MASS_WORK_ORDER_FORM_UI_SCHEMA
  },
  InspectionsFilterForm: {
    schema: FIBER_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: FIBER_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  AssetInspectionsFilterForm: {
    schema: FIBER_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: FIBER_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  WorkOrdersFilterForm: {
    schema: FIBER_WORK_ORDERS_FILTER_FORM_SCHEMA,
    uiSchema: FIBER_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
  },
  inspectionStatuses: FIBER_INSPECTION_STATUSES,
  assetInspectionStatuses: FIBER_ASSET_INSPECTION_STATUSES,
  workOrderStatuses: FIBER_WORK_ORDER_STATUSES
}