import React from 'react';
import { Card } from '@mui/material';
import { FileModel } from '../../models/file.model';
import AttachmentsComponent from '../AttachmentsComponent/AttachmentsComponent';

type Props = {
  attachments: FileModel[];
  isLoading?: boolean;
  viewerOpenedHandler?: Function;
  viewerClosedHandler?: Function;
  modelId?: any;
  title?: string;
  className?: string;
}

const AttachmentsCard = (props: Props) => {

  return (
    <Card className={`shadow overflow-hidden rounded-md ${props.className}`}>
      <AttachmentsComponent attachments={props.attachments} isLoading={props.isLoading} className={'px-24 pb-24 pt-16'} viewerOpenedHandler={props.viewerOpenedHandler} viewerClosedHandler={props.viewerClosedHandler}
                            title={!!props.title ? props.title : 'Attachments'} masonryImagesClassName={'w-80 h-80 sm:w-120 sm:h-120 md:w-140 md:h-140 lg:w-full lg:h-full'} modelId={props.modelId} isTitleSeparated={!!props.title}/>
    </Card>
  );
};

export default AttachmentsCard;
