import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { DesktopDatePicker, MobileDatePicker } from '@mui/x-date-pickers';
import moment from 'moment/moment';

type Props = {
  label: string;
  value: any;
  onChange?: Function;
  className?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
};

const DatePicker = (props: Props) => {
  const [date, setDate] = useState(props.value ? props.value : null);

  useEffect(() => {
    setDate(props.value ? props.value : null);
  }, [props.value]);

  const dateChangedHandler = (newDate) => {
    props.onChange(newDate);
    setDate(newDate);
  };

  return (
    <>
      <div className={`hidden sm:block ${props.className}`}>
        <DesktopDatePicker
          PopperProps={{ style: { zIndex: 10000 } }}
          label={props.label}
          value={date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null}
          onChange={dateChangedHandler}
          disabled={props.disabled}
          renderInput={(params) => (
            <TextField
              size={'small'}
              fullWidth
              {...params}
              required={props.required}
              error={!!props.error}
              helperText={props.helperText}
              FormHelperTextProps={{ style: { margin: '0px 2px' } }}
            />
          )}
        />
      </div>
      <div className={`block sm:hidden ${props.className}`}>
        <MobileDatePicker
          label={props.label}
          value={date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : null}
          disabled={props.disabled}
          onChange={dateChangedHandler}
          renderInput={(params) => (
            <TextField
              size={'small'}
              fullWidth
              {...params}
              required={props.required}
              error={!!props.error}
              helperText={props.helperText}
              FormHelperTextProps={{ style: { margin: '0px 2px' } }}
            />
          )}
        />
      </div>
    </>
  );
};

export default DatePicker;
