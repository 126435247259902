export const FIBER_CONDITIONS_LIST = [
  {
    key: 'Non-existing Asset',
    value: 'Non-existing Asset',
  },
  {
    key: 'Bend Radius',
    value: 'Bend Radius',
  },
  {
    key: 'Broken/Damaged',
    value: 'Broken/Damaged',
  },
  {
    key: 'Clearances',
    value: 'Clearances',
  },
  {
    key: 'Corroded',
    value: 'Corroded',
  },
  {
    key: 'Could Not Locate',
    value: 'Could Not Locate',
  },
  {
    key: 'Exposed',
    value: 'Exposed',
  },
  {
    key: 'Grade Problem',
    value: 'Grade Problem',
  },
  {
    key: 'Idle Facilities',
    value: 'Idle Facilities',
  },
  {
    key: 'Illegible',
    value: 'Illegible',
  },
  {
    key: 'Leaning (Rake)',
    value: 'Leaning (Rake)',
  },
  {
    key: 'Limited Access',
    value: 'Limited Access',
  },
  {
    key: 'Loose',
    value: 'Loose',
  },
  {
    key: 'Missing',
    value: 'Missing',
  },
  {
    key: 'Prohibits Operation',
    value: 'Prohibits Operation',
  },
  {
    key: 'Slack/Tension',
    value: 'Slack/Tension',
  },
  {
    key: 'Vegetation',
    value: 'Vegetation',
  },
  {
    key: 'Missing Label',
    value: 'Missing Label',
  },
  {
    key: 'Map Correction',
    value: 'Map Correction',
  },
  {
    key: 'Buddy Pole',
    value: 'Buddy Pole',
  },
  {
    key: 'Documentation Update',
    value: 'Documentation Update',
  },
  {
    key: '3rd Party Cable Attached',
    value: '3rd Party Cable Attached',
  },
  {
    key: 'Other',
    value: 'Other',
  },
];

export const ASSET_INSPECTION_TYPES = [
  {
    key: 'Detailed Inspection',
    value: 'Detailed Inspection',
  },
  {
    key: 'Patrol',
    value: 'Patrol',
  },
  {
    key: 'Outage Related',
    value: 'Outage Related',
  },
];

export const DRAFT_INSPECTION_TYPES = [
  {
    key: 1,
    value: 'Detailed Inspection',
  },
  {
    key: 2,
    value: 'Patrol',
  },
  {
    key: 3,
    value: 'Outage Related',
  },
];

export const DRAFT_INSPECTION_FIRE_THREAT_LIST = [
  {
    key: 1,
    value: 'NFTD',
  },
  {
    key: 2,
    value: '2',
  },
  {
    key: 3,
    value: '3',
  },
];

export const INSPECTED_ATTRIBUTES_LIST = [
  {
    key: 'Amplifier/Repeater',
    value: 'Amplifier/Repeater',
  },
  {
    key: 'Attachment Hardware',
    value: 'Attachment Hardware',
  },
  {
    key: 'Cable',
    value: 'Cable',
  },
  {
    key: 'Cross Arms',
    value: 'Cross Arms',
  },
  {
    key: 'Enclosure/Cabinet',
    value: 'Enclosure/Cabinet',
  },
  {
    key: 'Grounds',
    value: 'Grounds',
  },
  {
    key: 'Guys',
    value: 'Guys',
  },
  {
    key: 'Labels/Markings',
    value: 'Labels/Markings',
  },
  {
    key: 'Lashing',
    value: 'Lashing',
  },
  {
    key: 'Pedestal',
    value: 'Pedestal',
  },
  {
    key: 'Pole',
    value: 'Pole',
  },
  {
    key: 'Slack Storage',
    value: 'Slack Storage',
  },
  {
    key: 'Splice case',
    value: 'Splice case',
  },
  {
    key: 'Standoff Bracket',
    value: 'Standoff Bracket',
  },
  {
    key: 'Tower',
    value: 'Tower',
  },
  {
    key: 'Riser',
    value: 'Riser',
  },
  {
    key: 'Other',
    value: 'Other',
  },
];

export const DAMAGE_CAUSES_LIST = [
  {
    key: 'Dig-In',
    value: 'Dig-In',
  },
  {
    key: 'Equipment Failure',
    value: 'Equipment Failure',
  },
  {
    key: 'Fire',
    value: 'Fire',
  },
  {
    key: 'Lightning',
    value: 'Lightning',
  },
  {
    key: 'Third Party',
    value: 'Third Party',
  },
  {
    key: 'Water Damage',
    value: 'Water Damage',
  },
  {
    key: 'Unknown',
    value: 'Unknown',
  },
  {
    key: 'Rodent Damage',
    value: 'Rodent Damage',
  },
  {
    key: 'Other',
    value: 'Other',
  },
];

export const FIRE_THREAT_LIST = [
  {
    key: 'NFTD',
    value: 'NFTD',
  },
  {
    key: '2',
    value: '2',
  },
  {
    key: '3',
    value: '3',
  },
];

export const POLE_TYPE_LIST = [
  {
    key: 'Transmission',
    value: 'Transmission',
  },
  {
    key: 'Distribution',
    value: 'Distribution',
  },
  {
    key: 'Telecom',
    value: 'Telecom',
  },
  {
    key: 'Non-PGE',
    value: 'Non-PGE',
  },
  {
    key: 'Transmission w/ Distribution Underbuilt',
    value: 'Transmission w/ Distribution Underbuilt',
  },
  {
    key: 'Other',
    value: 'Other',
  },
];

export const PLACEMENT_TYPE = [
  {
    key: 'Aerial',
    value: 'Aerial',
  },
  {
    key: 'Underground',
    value: 'Underground',
  },
];

export const PLACEMENT_MATERIAL = [
  {
    type: 'Aerial',
    key: 'ADSS',
    value: 'ADSS',
  },
  {
    type: 'Aerial',
    key: 'Coax',
    value: 'Coax',
  },
  {
    type: 'Aerial',
    key: 'Copper Cable',
    value: 'Copper Cable',
  },
  {
    type: 'Aerial',
    key: 'Lashed Loose Tube',
    value: 'Lashed Loose Tube',
  },
  {
    type: 'Aerial',
    key: 'OPGW',
    value: 'OPGW',
  },
  {
    type: 'Aerial',
    key: 'Other',
    value: 'Other',
  },
  {
    type: 'Underground',
    key: 'Coax',
    value: 'Coax',
  },
  {
    type: 'Underground',
    key: 'Copper Cable',
    value: 'Copper Cable',
  },
  {
    type: 'Underground',
    key: 'Direct Buried',
    value: 'Direct Buried',
  },
  {
    type: 'Underground',
    key: 'Loose Tube/ADSS',
    value: 'Loose Tube/ADSS',
  },
  {
    type: 'Underground',
    key: 'Other',
    value: 'Other',
  },
];

export const PLACEMENT_TYPE_DATA = {
  label: 'Placement Type',
  options: [
    {
      key: 'Aerial',
      value: 'Aerial',
    },
    {
      key: 'Underground',
      value: 'Underground',
    },
  ],
};

export const PLACEMENT_MATERIAL_DATA = {
  label: 'Material',
  options: [
    {
      type: 'Aerial',
      key: 'ADSS',
      value: 'ADSS',
    },
    {
      type: 'Aerial',
      key: 'Coax',
      value: 'Coax',
    },
    {
      type: 'Aerial',
      key: 'Copper Cable',
      value: 'Copper Cable',
    },
    {
      type: 'Aerial',
      key: 'Lashed Loose Tube',
      value: 'Lashed Loose Tube',
    },
    {
      type: 'Aerial',
      key: 'OPGW',
      value: 'OPGW',
    },
    {
      type: 'Aerial',
      key: 'Other',
      value: 'Other',
    },
    {
      type: 'Underground',
      key: 'Coax',
      value: 'Coax',
    },
    {
      type: 'Underground',
      key: 'Copper Cable',
      value: 'Copper Cable',
    },
    {
      type: 'Underground',
      key: 'Direct Buried',
      value: 'Direct Buried',
    },
    {
      type: 'Underground',
      key: 'Loose Tube/ADSS',
      value: 'Loose Tube/ADSS',
    },
    {
      type: 'Underground',
      key: 'Other',
      value: 'Other',
    },
  ],
};