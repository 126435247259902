import { PassiveReflectorsAssetInspectionModel } from './passive-reflectors-asset-inspection.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { CommentModel } from '../../../models/comment.model';
import { UserModel } from '../../../models/user.model';
import { AddressModel } from './address.model';
import { InspectionModel } from '../../../models/base-models/inspection.model';

export class PassiveReflectorsInspectionModel extends InspectionModel {
  id: string;
  inspectionId: string;
  type: string;
  siteName: string;
  siteNumber: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  dueDate: string;
  assignedTo: UserModel[];
  assetInspections: PassiveReflectorsAssetInspectionModel[];
  comments?: CommentModel[];
  address: AddressModel;
  changeHistory?: FieldChangeModel[];
  readonly: boolean;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

  get childModels() {
    return {
      assetInspections: PassiveReflectorsAssetInspectionModel,
      changeHistory: FieldChangeModel,
      assignedTo: UserModel,
      comments: CommentModel,
      address: AddressModel,
    };
  }

}