import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const TELECOM_TOWERS_WORK_ORDER_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['dueDate'],
  properties: {
    externalId: {
      type: 'string',
      title: 'Remedy Work Order #',
    },
    sapExternalId: {
      type: 'string',
      title: 'SAP Work Order #',
    },
    scheduledStartDate: {
      type: 'string',
      title: 'Start Date',
    },
    assignedTo: {
      type: 'string',
      title: 'Assigned To',
    },
    workAssignee: {
      type: 'string',
      title: 'Work Assignee',
    },
    dueDate: {
      type: 'string',
      title: 'Due Date',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const TELECOM_TOWERS_WORK_ORDER_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-16',
  'ui:inline': true,
  externalId: {
    'ui:placeholder': 'Remedy Work Order #',
    'ui:widget': 'RemedyWOSelectWidget',
  },
  sapExternalId: {
    'ui:placeholder': 'SAP Work Order #',
    'ui:widget': 'SAPWOSelectWidget',
  },
  scheduledStartDate: {
    'ui:placeholder': 'Start Date',
    'ui:widget': 'date',
  },
  assignedTo: {
    'ui:placeholder': 'Assigned To',
    'ui:widget': 'UserMultiSelectWidget',
  },
  workAssignee: {
    'ui:placeholder': 'Work Assignee',
    'ui:widget': 'CreatableUserSelectWidget',
  },
  dueDate: {
    'ui:placeholder': 'Due Date',
    'ui:widget': 'date',
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-3',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};