import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { ISSUE_CAUSE_LIST, UPS_CORRECTIVE_FOUND_LIST, UPS_FIELDS_STATUSES, YES_NO_NA_LIST } from '../globals';

export const UPS_ASSET_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    inspectors: {
      type: 'string',
      title: 'Inspectors',
    },
    issueCause: {
      type: 'string',
      title: 'Issue Cause',
    },
    correctiveFound: {
      type: 'string',
      title: 'Corrective Found',
    },
    unitCorrectlyMounted: {
      type: 'string',
      title: 'Is the unit correctly mounted?',
    },
    equipmentConnectedToUPSProtectedOutlets: {
      type: 'string',
      title: 'Is all equipment connected to UPS protected outlets on the UPS?',
    },
    upsChassisGroundedToPrimaryGround: {
      type: 'string',
      title: 'Is the UPS chassis grounded to a primary ground to the main telecom ground buss?',
    },
    alarms: {
      type: 'object',
      title: 'Alarms',
      properties: {
        onBattery: {
          type: 'string',
          title: 'On Battery',
        },
        fault: {
          type: 'string',
          title: 'Fault',
        },
        lowBattery: {
          type: 'string',
          title: 'Low Battery',
        },
        replaceBattery: {
          type: 'string',
          title: 'Replace Battery',
        },
        upsConnectedToNetguardian: {
          type: 'string',
          title: 'Is the UPS connected to a Netguardian?',
        },
        upsSelfTestRecording: {
          type: 'string',
          title: 'UPS Self-Test Recording',
        },
        teleniumSetUp: {
          type: 'string',
          title: 'Telenium Alarm Set Up',
        },
        bypassSwitchInstalled: {
          type: 'string',
          title: 'Is a bypass switch installed?',
        },
        filterAndAirIntakes: {
          type: 'string',
          title: 'Filters and Air Intakes',
        },
        anotherUPSInRoom: {
          type: 'string',
          title: 'Is there another UPS in the room?',
        },
      },
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
    additionalConditions: {
      type: 'string',
      title: 'Additional Conditions',
    },
  },
};

export const UPS_ASSET_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  inspectors: {
    'ui:placeholder': 'Inspectors',
    'ui:widget': 'UserMultiSelectWidget',
    'ui:classNames': 'sm:col-span-2 lg:col-span-1',
  },
  issueCause: {
    'ui:placeholder': 'Issue Cause',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: ISSUE_CAUSE_LIST,
    },
    'ui:classNames': 'sm:col-span-2',
  },
  correctiveFound: {
    'ui:placeholder': 'Corrective found',
    'ui:widget': 'MultiSelectWidget',
    'ui:options': {
      optionsList: UPS_CORRECTIVE_FOUND_LIST,
    },
  },
  unitCorrectlyMounted: {
    'ui:placeholder': 'Is the unit correctly mounted?',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_FIELDS_STATUSES,
    },
  },
  equipmentConnectedToUPSProtectedOutlets: {
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_FIELDS_STATUSES,
    },
    'ui:placeholder': 'Is all equipment connected to UPS protected outlets on the UPS?',
  },
  upsChassisGroundedToPrimaryGround: {
    'ui:placeholder': 'Is the UPS chassis grounded to a primary ground to the main telecom ground buss?',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_FIELDS_STATUSES,
    },
  },
  alarms: {
    onBattery: {
      'ui:placeholder': 'On Battery',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: YES_NO_NA_LIST,
      },
    },
    fault: {
      'ui:placeholder': 'Fault',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: YES_NO_NA_LIST,
      },
    },
    lowBattery: {
      'ui:placeholder': 'Low Battery',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: YES_NO_NA_LIST,
      },
    },
    replaceBattery: {
      'ui:placeholder': 'Replace Battery',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: YES_NO_NA_LIST,
      },
    },
    upsConnectedToNetguardian: {
      'ui:placeholder': 'Is the UPS connected to a Netguardian?',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: UPS_FIELDS_STATUSES,
      },
    },
    upsSelfTestRecording: {
      'ui:placeholder': 'UPS Self-Test Recording',
    },
    teleniumSetUp: {
      'ui:placeholder': 'Telenium Alarm Set Up',
    },
    bypassSwitchInstalled: {
      'ui:placeholder': 'Is a bypass switch installed?',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: UPS_FIELDS_STATUSES,
      },
    },
    filterAndAirIntakes: {
      'ui:placeholder': 'Filters and Air Intakes',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: UPS_FIELDS_STATUSES,
      },
    },
    anotherUPSInRoom: {
      'ui:placeholder': 'Is there another UPS in the room?',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: YES_NO_NA_LIST,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid md:grid-cols-4 gap-x-12 gap-y-16',
  },
  additionalConditions: {
    'ui:widget': 'MultiSelectChipsWidget',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};