import { BaseModel } from './base.model';

export class LocationModel extends BaseModel {
  latitude: string;
  longitude: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

  get locationString(): string {
    return `${this.returnValue(this.latitude)}, ${this.returnValue(this.longitude)}`;
  }
}
