import { BaseModel } from '../base.model';

export class AssetInspectionModel extends BaseModel {

  // TODO: Temp fix, remove properties later
  inspection?: any;
  dueDateStatus?: any;
  snapshot?: any;
  attachments?: any[];
  comments?: any[];
  changeHistory?: any[];
  workOrders?: any[];
  conditions?: string[];
  asset?: any;
  status: string;
  type?: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}