import { RJSFSchema, UiSchema } from '@rjsf/utils';
import {
  INTERNAL_EXTERNAL_LIST,
  TIER_RATING_LIST,
  TRUE_FALSE_LIST,
  UPS_MANUFACTURER_LIST,
  VOLTAGE_TYPE_LIST,
} from '../globals';

export const VRLA_BATTERY_ASSET_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['externalId'],
  properties: {
    externalId: {
      type: 'string',
      title: 'SAP Equipment ID',
    },
    tierRating: {
      type: 'string',
      title: 'Tier Rating',
    },
    upsManufacturer: {
      type: 'string',
      title: 'UPS Manufacturer',
    },
    partNumber: {
      type: 'string',
      title: 'Part #',
    },
    voltage: {
      type: 'string',
      title: 'Voltage',
    },
    voltageType: {
      type: 'string',
      title: 'Voltage Type',
    },
    manufacturedDate: {
      type: 'string',
      title: 'Manufactured Date',
    },
    rack: {
      type: 'string',
      title: 'Rack',
    },
    rackUnitNumber: {
      type: 'string',
      title: 'Rack Unit Number',
    },
    equipmentLife: {
      type: 'number',
      title: 'Equipment Life',
    },
    endOfLife: {
      type: 'string',
      title: 'End of Life',
    },
    conditionRating: {
      type: 'string',
      title: 'Condition Rating',
    },
    batteryAmpHours: {
      type: 'string',
      title: 'Battery Amp Hours',
    },
    batteryRuntime: {
      type: 'string',
      title: 'Battery Runtime',
    },
    monitoringPGE: {
      type: 'string',
      title: 'Monitoring PGE',
    },
    yearOfConstruction: {
      type: 'string',
      title: 'Year of Construction',
    },
    serialNumber: {
      type: 'number',
      title: 'Serial #',
    },
    batteryStringNumber: {
      type: 'number',
      title: 'Battery String #',
    },
    batteryMake: {
      type: 'string',
      title: 'Battery Make',
    },
    batteryModel: {
      type: 'string',
      title: 'Battery Model',
    },
    modulePartNumber: {
      type: 'string',
      title: 'Module Part Number',
    },
    dateCode: {
      type: 'string',
      title: 'Date Code',
    },
    internalExternalString: {
      type: 'string',
      title: 'Internal/External String',
    },
    numberOfBatteriesPerString: {
      type: 'number',
      title: '# of Batteries Per String',
    },
    numberOfModulesPerString: {
      type: 'number',
      title: '# of Modules Per String',
    },
    numberOfBatteriesPerModule: {
      type: 'number',
      title: '# of Batteries Per Module',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const VRLA_BATTERY_ASSET_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  externalId: {
    'ui:placeholder': 'SAP Equipment ID',
  },
  tierRating: {
    'ui:placeholder': 'Tier Rating',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: TIER_RATING_LIST,
    },
  },
  upsManufacturer: {
    'ui:placeholder': 'UPS Manufacturer',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_MANUFACTURER_LIST,
    },
  },
  partNumber: {
    'ui:placeholder': 'Part #',
  },
  voltage: {
    'ui:placeholder': 'Voltage',
    'ui:widget': 'NumberWidget',
  },
  voltageType: {
    'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
    'ui:placeholder': 'Voltage Type',
    'ui:widget': 'DependencyOtherValueWidget',
    'ui:options': {
      optionsList: VOLTAGE_TYPE_LIST,
    },
  },
  manufacturedDate: {
    'ui:placeholder': 'Manufactured Date',
    'ui:widget': 'date',
  },
  rack: {
    'ui:placeholder': 'Rack',
  },
  rackUnitNumber: {
    'ui:placeholder': 'Rack Unit Number',
  },
  equipmentLife: {
    'ui:placeholder': 'Equipment Life',
    'ui:widget': 'NumberWidget',
  },
  endOfLife: {
    'ui:placeholder': 'End of Life',
    'ui:widget': 'NumberWidget',
  },
  conditionRating: {
    'ui:placeholder': 'Condition Rating',
  },
  batteryAmpHours: {
    'ui:placeholder': 'Battery Amp Hours',
  },
  batteryRuntime: {
    'ui:placeholder': 'Battery Runtime',
  },
  monitoringPGE: {
    'ui:placeholder': 'Monitoring PGE',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: TRUE_FALSE_LIST,
    },
  },
  yearOfConstruction: {
    'ui:placeholder': 'Year of Construction',
  },
  serialNumber: {
    'ui:placeholder': 'Serial #',
    'ui:widget': 'NumberWidget',
  },
  batteryStringNumber: {
    'ui:placeholder': 'Battery String #',
    'ui:widget': 'NumberWidget',
  },
  batteryMake: {
    'ui:placeholder': 'Battery Make',
  },
  batteryModel: {
    'ui:placeholder': 'Battery Model',
  },
  modulePartNumber: {
    'ui:placeholder': 'Module Part Number',
  },
  dateCode: {
    'ui:placeholder': 'Date Code',
    'ui:widget': 'date',
  },
  internalExternalString: {
    'ui:placeholder': 'Internal/External String',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: INTERNAL_EXTERNAL_LIST,
    },
  },
  numberOfBatteriesPerString: {
    'ui:placeholder': '# of Batteries Per String',
    'ui:widget': 'NumberWidget',
  },
  numberOfModulesPerString: {
    'ui:placeholder': '# of Modules Per String',
    'ui:widget': 'NumberWidget',
  },
  numberOfBatteriesPerModule: {
    'ui:placeholder': '# of Batteries Per Module',
    'ui:widget': 'NumberWidget',
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};