import React, { useContext, useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, TextField } from '@mui/material';
import InspectorsContext from '../../../InspectorsContext';

type Props = {
  id?: string;
  error?: boolean;
  helperText?: string;
  inspectionId?: string;
  value: string;
  label: string;
  userIdKey?: string;
  onChange: Function;
  className?: string;
  placeholder?: string;
  formLoadingHandler?: any;
  formLoadedHandler?: any;
  disabled?: boolean;
}

const CreatableUserSelect = (props: Props) => {

  const {inspectors} = useContext(InspectorsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(props.value);
  const [users, setUsers] = useState([]);

  const handleChange = (event, value) => {
    props.onChange(value);
    setValue(value);
  };

  useEffect(() => {
    if (!props.value) {
      return;
    }

    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!inspectors) {
      setUsers([]);
      setIsLoading(false);
      !!props.formLoadedHandler && props.formLoadedHandler();
      return;
    }

    const inspectorNames = inspectors.length ? inspectors.map(inspector => inspector.name) : [];
    setUsers(inspectorNames);
    setIsLoading(false);
    !!props.formLoadedHandler && props.formLoadedHandler();
  }, [inspectors]);

  return (
    <Autocomplete
      id={props.id}
      freeSolo
      componentsProps={{
        popper: {
          sx: {
            zIndex: 10000
          }
        }
      }}
      ListboxProps={{
        style: {
          maxHeight: 225,
        },
      }}
      disableClearable
      value={value}
      disabled={!!isLoading || props.disabled}
      size={'small'}
      options={users}
      className={`${props.className}`}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={props.placeholder}
          label={props.label}
          error={!!props.error}
          helperText={props.helperText}
          disabled={!!isLoading || props.disabled}
          onChange={(event) =>
            props.onChange(event.target.value)
          }
          FormHelperTextProps={{ style: { margin: '0px 2px' }}}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <div className={`mr-5`}>
                    <CircularProgress size={14} color={'inherit'} />
                </div>}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};


export default CreatableUserSelect;
