import { RJSFSchema, UiSchema } from '@rjsf/utils';
import {
  DAYS_LIST,
  GENERATORS_FIELDS_STATUSES,
  FLUIDS_LIST,
  MULTISELECTION_LIST,
} from '../globals';

export const GENERATORS_ASSET_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    name: {
      type: 'string',
      title: 'Name',
    },
    regulatorDate: {
      type: 'string',
      title: 'Regulator Date',
    },
    inspectors: {
      type: 'string',
      title: 'Inspectors',
    },
    generalInspection: {
      type: 'object',
      title: 'General Inspection',
      properties: {
        systemVisualInspectionOveralCondition: {
          type: 'string',
          title: 'System Visual Inspection/Overal Condition',
        },
        hourmeter: {
          type: 'number',
          title: 'Hourmeter',
        },
      },
    },
    battery: {
      type: 'object',
      title: 'Battery',
      properties: {
        voltageType: {
          type: 'string',
          title: 'Battery Voltage Type',
        },
        batteryChargerVoltage: {
          type: 'string',
          title: 'Battery Charger Voltage',
        },
        batteryChargerAmperage: {
          type: 'string',
          title: 'Battery Charger Amperage',
        },
        batteryChargeAlternatorVoltage: {
          type: 'string',
          title: 'Battery Charge Alternator Voltage',
        },
        batteryChargeAlternatorAmperage: {
          type: 'string',
          title: 'Battery Charge Alternator Amperage',
        },
        batteryAge: {
          type: 'string',
          title: 'Battery Age',
        },
        checkedBatteryLevel: {
          type: 'string',
          title: 'Checked Battery Level',
        },
      },
    },
    engine: {
      type: 'object',
      title: 'Engine',
      properties: {
        fuelSystemType: {
          type: 'string',
          title: 'Fuel System Type',
        },
        fuelHosesPipingRegulator: {
          type: 'string',
          title: 'Fuel Hoses/Piping Regulator, etc.',
        },
        fuelPressure: {
          type: 'string',
          title: 'Fuel Pressure',
        },
        fuelLevel: {
          type: 'string',
          title: 'Fuel Level',
        },
        dayTankOperation: {
          type: 'string',
          title: 'Day Tank Operation',
        },
        oilPressure: {
          type: 'string',
          title: 'Oil Pressure',
        },
        coolantTemperature: {
          type: 'string',
          title: 'Coolant Temperature',
        },
        RPM: {
          type: 'number',
          title: 'RPM',
        },
        ignitionSystem: {
          type: 'string',
          title: 'Ignition System (Cap, Rotor, Plugs, etc.)',
        },
        coolingSystem: {
          type: 'string',
          title: 'Cooling System (Coolant, Belts, Hoses, etc.)',
        },
        coolantFreezePoint: {
          type: 'string',
          title: 'Coolant Freeze Point',
        },
        blockHeaterOperation: {
          type: 'string',
          title: 'Block Heater Operation',
        },
        exhaustSystem: {
          type: 'string',
          title: 'Exhaust System (Piping, Muffler, Flex, etc.)',
        },
        inspectLeaksAnyType: {
          type: 'string',
          title: 'Inspect for Leaks of Any Type',
        },
      },
    },
    generatorNoLoad: {
      type: 'object',
      title: '',
      properties: {
        voltage: {
          type: 'object',
          title: 'Generator, No Load - Voltage',
          properties: {
            L1L2: {
              type: 'number',
              title: 'L1-L2',
            },
            L2L3: {
              type: 'number',
              title: 'L2-L3',
            },
            L3L1: {
              type: 'number',
              title: 'L3-L1',
            },
            L1N: {
              type: 'number',
              title: 'L1-N',
            },
            L2N: {
              type: 'number',
              title: 'L2-N',
            },
            L3N: {
              type: 'number',
              title: 'L3-N',
            },
          },
        },
        amperage: {
          type: 'object',
          title: 'Generator, No Load - Amperage',
          properties: {
            A: {
              type: 'number',
              title: 'A',
            },
            B: {
              type: 'number',
              title: 'B',
            },
            C: {
              type: 'number',
              title: 'C',
            },
          },
        },
        frequency: {
          type: 'number',
          title: 'Generator, No Load - Frequency',
        },
      },
    },
    fluids: {
      type: 'object',
      title: 'Fluids',
      properties: {
        engineOil: {
          type: 'string',
          title: 'Engine Oil',
        },
        oilFuelCoolantFilters: {
          type: 'string',
          title: 'Oil, Fuel, Coolant Filters',
        },
        airFilters: {
          type: 'string',
          title: 'Air Filters',
        },
        IGNPointsCondSparkPlugs: {
          type: 'string',
          title: 'IGN Points, Cond, Spark Plugs',
        },
      },
    },
    control: {
      type: 'object',
      title: 'Control',
      properties: {
        metersLampsGauges: {
          type: 'string',
          title: 'Meters, Lamps, Gauges',
        },
        circuitBoardsandRelays: {
          type: 'string',
          title: 'Circuit Boards and Relays',
        },
        controlMounts: {
          type: 'string',
          title: 'Control Mounts',
        },
      },
    },
    mounting: {
      type: 'object',
      title: 'Mounting',
      properties: {
        securedToLevelSurface: {
          type: 'string',
          title: 'Secured To Level Surface',
        },
        properVibrationIsolation: {
          type: 'string',
          title: 'Proper Vibration Isolation',
        },
        weatherEnclosureCondition: {
          type: 'string',
          title: 'Weather Enclosure Condition',
        },
      },
    },
    automaticTransferSwitches: {
      type: 'object',
      title: 'Automatic Transfer Switches',
      properties: {
        windingsExciterRotorStator: {
          type: 'string',
          title: 'Windings-Exciter, Rotor, Stator',
        },
        brushesSlipRings: {
          type: 'string',
          title: 'Brushes, Slip Rings, etc',
        },
        allConductorsProperlySizedAndConnected: {
          type: 'string',
          title: 'All Conductors Properly Sized and Connected',
        },
        breakerProtectingATSOnUtilityAndGen: {
          type: 'string',
          title: 'Breaker Protecting ATS on Utility and Gen',
        },
        allNecessaryCalibrationsAndAdjustmentsComplete: {
          type: 'string',
          title: 'All Necessary Calibrations & Adjustments Complete',
        },
        exerciseClock: {
          type: 'object',
          title: '',
          properties: {
            day: {
              type: 'string',
              title: 'Exercise Clock Day',
            },
            time: {
              type: 'string',
              title: 'Exercise Clock Time',
            },
            to: {
              type: 'string',
              title: 'Exercise Clock To',
            },
          },
        },
        multiSelection: {
          type: 'string',
          title: '',
        },
        secToEngineStart: {
          type: 'number',
          title: 'Sec To Engine Start',
        },
        secToTransfer: {
          type: 'number',
          title: 'Sec To Transfer',
        },
        minToRetransfer: {
          type: 'number',
          title: 'Min To Retransfer',
        },
        secTDN: {
          type: 'number',
          title: 'Sec TDN',
        },
        minDelayStop: {
          type: 'number',
          title: 'Min Delay Stop',
        },
      },
    },
    utilityPower: {
      type: 'object',
      title: '',
      properties: {
        voltage: {
          type: 'object',
          title: 'Utility Power - Voltage',
          properties: {
            L1L2: {
              type: 'number',
              title: 'L1-L2',
            },
            L2L3: {
              type: 'number',
              title: 'L2-L3',
            },
            L3L1: {
              type: 'number',
              title: 'L3-L1',
            },
            L1N: {
              type: 'number',
              title: 'L1-N',
            },
            L2N: {
              type: 'number',
              title: 'L2-N',
            },
            L3N: {
              type: 'number',
              title: 'L3-N',
            },
          },
        },
        amperage: {
          type: 'object',
          title: 'Utility Power - Amperage',
          properties: {
            A: {
              type: 'number',
              title: 'A',
            },
            B: {
              type: 'number',
              title: 'B',
            },
            C: {
              type: 'number',
              title: 'C',
            },
          },
        },
      },
    },
    generatorWithLoad: {
      type: 'object',
      title: '',
      properties: {
        voltage: {
          type: 'object',
          title: 'Generator With Load - Voltage',
          properties: {
            L1L2: {
              type: 'number',
              title: 'L1-L2',
            },
            L2L3: {
              type: 'number',
              title: 'L2-L3',
            },
            L3L1: {
              type: 'number',
              title: 'L3-L1',
            },
            L1N: {
              type: 'number',
              title: 'L1-N',
            },
            L2N: {
              type: 'number',
              title: 'L2-N',
            },
            L3N: {
              type: 'number',
              title: 'L3-N',
            },
          },
        },
        amperage: {
          type: 'object',
          title: 'Generator With Load - Amperage',
          properties: {
            A: {
              type: 'number',
              title: 'A',
            },
            B: {
              type: 'number',
              title: 'B',
            },
            C: {
              type: 'number',
              title: 'C',
            },
          },
        },
        frequency: {
          type: 'number',
          title: 'Generator With Load - Frequency',
        },
      },
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
    additionalConditions: {
      type: 'string',
      title: 'Additional Conditions',
    },
  },
};

export const GENERATORS_ASSET_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  name: {
    'ui:placeholder': 'Inspectors',
  },
  regulatorDate: {
    'ui:placeholder': 'Regulator Date',
    'ui:widget': 'date',
  },
  inspectors: {
    'ui:placeholder': 'Inspectors',
    'ui:widget': 'UserMultiSelectWidget',
    'ui:classNames': 'sm:col-span-2 lg:col-span-1',
  },
  generalInspection: {
    systemVisualInspectionOveralCondition: {
      'ui:placeholder': 'System Visual Inspection/Overal Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    hourmeter: {
      'ui:placeholder': 'Hourmeter',
      'ui:widget': 'NumberWidget',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  battery: {
    voltageType: {
      'ui:placeholder': 'Voltage Type',
      'ui:classNames': 'sm:col-span-2',
    },
    batteryChargerVoltage: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    batteryChargerAmperage: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    batteryChargeAlternatorVoltage: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    batteryChargeAlternatorAmperage: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    batteryAge: {
      'ui:widget': 'DependencyDateWidget',
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    checkedBatteryLevel: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyTextInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  engine: {
    fuelSystemType: {
      'ui:placeholder': 'Fuel System Type',
    },
    fuelHosesPipingRegulator: {
      'ui:placeholder': 'Fuel Hoses/Piping Regulator, etc.',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    fuelPressure: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    fuelLevel: {
      'ui:placeholder': 'Fuel Level',
    },
    dayTankOperation: {
      'ui:placeholder': 'Day Tank Operation',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    oilPressure: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    coolantTemperature: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    RPM: {
      'ui:placeholder': 'RPM',
      'ui:widget': 'NumberWidget',
    },
    ignitionSystem: {
      'ui:placeholder': 'Ignition System (Cap, Rotor, Plugs, etc.)',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    coolingSystem: {
      'ui:placeholder': 'Cooling System (Coolant, Belts, Hoses, etc.)',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    coolantFreezePoint: {
      'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
      'ui:widget': 'DependencyInputWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    blockHeaterOperation: {
      'ui:placeholder': 'Block Heater Operation',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    exhaustSystem: {
      'ui:placeholder': 'Exhaust System (Piping, Muffler, Flex, etc.)',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    inspectLeaksAnyType: {
      'ui:placeholder': 'Inspect for Leaks of Any Type',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  generatorNoLoad: {
    voltage: {
      L1L2: {
        'ui:placeholder': 'L1-L2',
        'ui:widget': 'NumberWidget',
      },
      L2L3: {
        'ui:placeholder': 'L2-L3',
        'ui:widget': 'NumberWidget',
      },
      L3L1: {
        'ui:placeholder': 'L3-L1',
        'ui:widget': 'NumberWidget',
      },
      L1N: {
        'ui:placeholder': 'L1-N',
        'ui:widget': 'NumberWidget',
      },
      L2N: {
        'ui:placeholder': 'L2-N',
        'ui:widget': 'NumberWidget',
      },
      L3N: {
        'ui:placeholder': 'L3-N',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames':
        'md:col-span-4 grid sm:grid-cols-4 md:grid-cols-8 gap-x-12 gap-y-16',
    },
    amperage: {
      A: {
        'ui:placeholder': 'A',
        'ui:widget': 'NumberWidget',
      },
      B: {
        'ui:placeholder': 'B',
        'ui:widget': 'NumberWidget',
      },
      C: {
        'ui:placeholder': 'C',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames': 'md:col-span-4 grid sm:grid-cols-4 gap-x-12 gap-y-16',
    },
    frequency: {
      'ui:placeholder': 'Frequency',
      'ui:widget': 'NumberWidget',
      'ui:classNames': 'md:col-span-4 grid sm:grid-cols-4 gap-x-12 gap-y-16',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid md:grid-cols-4 gap-x-12 gap-y-16',
  },
  fluids: {
    engineOil: {
      'ui:placeholder': 'Engine Oil',
      'ui:widget': 'CheckboxesWidget',
      'ui:options': {
        optionsList: FLUIDS_LIST,
      },
    },
    oilFuelCoolantFilters: {
      'ui:placeholder': 'Oil, Fuel, Coolant Filters',
      'ui:widget': 'CheckboxesWidget',
      'ui:options': {
        optionsList: FLUIDS_LIST,
      },
    },
    airFilters: {
      'ui:placeholder': 'Air Filters',
      'ui:widget': 'CheckboxesWidget',
      'ui:options': {
        optionsList: FLUIDS_LIST,
      },
    },
    IGNPointsCondSparkPlugs: {
      'ui:placeholder': 'IGN Points, Cond, Spark Plugs',
      'ui:widget': 'CheckboxesWidget',
      'ui:options': {
        optionsList: FLUIDS_LIST,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  control: {
    metersLampsGauges: {
      'ui:placeholder': 'Meters, Lamps, Gauges',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    circuitBoardsandRelays: {
      'ui:placeholder': 'Circuit Boards and Relays',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    controlMounts: {
      'ui:placeholder': 'Control Mounts',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  mounting: {
    securedToLevelSurface: {
      'ui:placeholder': 'Secured To Level Surface',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    properVibrationIsolation: {
      'ui:placeholder': 'Proper Vibration Isolation',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    weatherEnclosureCondition: {
      'ui:placeholder': 'Weather Enclosure Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  automaticTransferSwitches: {
    windingsExciterRotorStator: {
      'ui:placeholder': 'Windings-Exciter, Rotor, Stator',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    brushesSlipRings: {
      'ui:placeholder': 'Brushes, Slip Rings, etc',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    allConductorsProperlySizedAndConnected: {
      'ui:placeholder': 'All Conductors Properly Sized and Connected',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    breakerProtectingATSOnUtilityAndGen: {
      'ui:placeholder': 'Breaker Protecting ATS on Utility and Gen',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    allNecessaryCalibrationsAndAdjustmentsComplete: {
      'ui:placeholder': 'All Necessary Calibrations & Adjustments Complete',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: GENERATORS_FIELDS_STATUSES,
      },
    },
    exerciseClock: {
      day: {
        'ui:placeholder': 'Exercise Clock Day',
        'ui:widget': 'SelectWidget',
        'ui:options': {
          optionsList: DAYS_LIST,
        },
      },
      time: {
        'ui:placeholder': '01:00 AM',
      },
      to: {
        'ui:placeholder': '01:00 PM',
      },
      'ui:classNames':
        'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
    },
    multiSelection: {
      'ui:placeholder': '',
      'ui:widget': 'CheckboxesWidget',
      'ui:options': {
        optionsList: MULTISELECTION_LIST,
      },
      'ui:classNames': 'sm:col-span-2 md:col-span-4',
    },
    secToEngineStart: {
      'ui:placeholder': 'Sec To Engine Start',
      'ui:widget': 'NumberWidget',
    },
    secToTransfer: {
      'ui:placeholder': 'Sec To Transfer',
      'ui:widget': 'NumberWidget',
    },
    minToRetransfer: {
      'ui:placeholder': 'Min To Retransfer',
      'ui:widget': 'NumberWidget',
    },
    secTDN: {
      'ui:placeholder': 'Sec TDN',
      'ui:widget': 'NumberWidget',
    },
    minDelayStop: {
      'ui:placeholder': 'Min Delay Stop',
      'ui:widget': 'NumberWidget',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  },
  utilityPower: {
    voltage: {
      L1L2: {
        'ui:placeholder': 'L1-L2',
        'ui:widget': 'NumberWidget',
      },
      L2L3: {
        'ui:placeholder': 'L2-L3',
        'ui:widget': 'NumberWidget',
      },
      L3L1: {
        'ui:placeholder': 'L3-L1',
        'ui:widget': 'NumberWidget',
      },
      L1N: {
        'ui:placeholder': 'L1-N',
        'ui:widget': 'NumberWidget',
      },
      L2N: {
        'ui:placeholder': 'L2-N',
        'ui:widget': 'NumberWidget',
      },
      L3N: {
        'ui:placeholder': 'L3-N',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames':
        'md:col-span-4 grid sm:grid-cols-4 md:grid-cols-8 gap-x-12 gap-y-16',
    },
    amperage: {
      A: {
        'ui:placeholder': 'A',
        'ui:widget': 'NumberWidget',
      },
      B: {
        'ui:placeholder': 'B',
        'ui:widget': 'NumberWidget',
      },
      C: {
        'ui:placeholder': 'C',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames': 'md:col-span-4 grid sm:grid-cols-4 gap-x-12 gap-y-16',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid md:grid-cols-4 gap-x-12 gap-y-16',
  },
  generatorWithLoad: {
    voltage: {
      L1L2: {
        'ui:placeholder': 'L1-L2',
        'ui:widget': 'NumberWidget',
      },
      L2L3: {
        'ui:placeholder': 'L2-L3',
        'ui:widget': 'NumberWidget',
      },
      L3L1: {
        'ui:placeholder': 'L3-L1',
        'ui:widget': 'NumberWidget',
      },
      L1N: {
        'ui:placeholder': 'L1-N',
        'ui:widget': 'NumberWidget',
      },
      L2N: {
        'ui:placeholder': 'L2-N',
        'ui:widget': 'NumberWidget',
      },
      L3N: {
        'ui:placeholder': 'L3-N',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames':
        'md:col-span-4 grid sm:grid-cols-4 md:grid-cols-8 gap-x-12 gap-y-16',
    },
    amperage: {
      A: {
        'ui:placeholder': 'A',
        'ui:widget': 'NumberWidget',
      },
      B: {
        'ui:placeholder': 'B',
        'ui:widget': 'NumberWidget',
      },
      C: {
        'ui:placeholder': 'C',
        'ui:widget': 'NumberWidget',
      },
      'ui:classNames': 'md:col-span-4 grid sm:grid-cols-4 gap-x-12 gap-y-16',
    },
    frequency: {
      'ui:placeholder': 'Frequency',
      'ui:widget': 'NumberWidget',
      'ui:classNames': 'md:col-span-4 grid sm:grid-cols-4 gap-x-12 gap-y-16',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid md:grid-cols-4 gap-x-12 gap-y-16',
  },
  additionalConditions: {
    'ui:widget': 'MultiSelectChipsWidget',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};
