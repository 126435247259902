import React, { useEffect, useState } from 'react';
import InspectionCardElement from './components/InspectionCardElement';
import Card from '@mui/material/Card';
import InspectionHeadingCardHeading from './components/InspectionHeadingCardHeading';
import ToggleButton from '../ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import TextTruncate from 'react-text-truncate';
import Menu from '../Menu/Menu';
import InspectionsService from '../../../services/inspections.service';
import MenuItem from '../Menu/MenuItem';
import CognitoUserService from '../../../services/cognito-user.service';
import InspectionToInProgressDialog from '../../../main/inspection/components/InspectionToInProgressDialog';
import InspectionToQaDialog from '../../../main/inspection/components/InspectionToQaDialog';
import InspectionFailedToQaDialog from '../../../main/inspection/components/InspectionFailedToQaDialog';
import StatusStrip from '../StatusStrip/StatusStrip';
import {
  InspectionHeadingCardDataInterface
} from '../../models/data-model-interfaces/inspection-heading-card-data.interface';
import { DataComponentModel } from '../../models/interfaces/data-component.model';
import { FIBER_DUE_DATE_STATUSES } from '../../types/fiber/statuses/fiber-due-date.statuses';
import { InspectionModel } from '../../models/base-models/inspection.model';

const downloadPdfReport = async (id: string, setIsDownloading: Function, setLoadingCounter: any) => {
  setIsDownloading(true);
  setLoadingCounter((prev) => prev + 1);

  InspectionsService.getInspectionPdfReport(id).then(res => {
    setLoadingCounter((prev) => prev - 1);
    setIsDownloading(false);
  });
};

const exportToCSV = async (id: string, setIsExporting: Function, setLoadingCounter: any) => {
  setIsExporting(true);
  setLoadingCounter((prev) => prev + 1);

  InspectionsService.getInspectionCsvReport(id).then(res => {
    setLoadingCounter((prev) => prev - 1);
    setIsExporting(false);
  });
};

type Props = {
  dataModel: InspectionHeadingCardDataInterface & DataComponentModel;
  mapOpened: boolean;
  handleMapChange: any;
  buttonLabel: string;
  elementClassName: string;
  itemsWrapperClassName: string;
  openEditModal: Function;
  openMassWorkOrderModal: Function;
  openChangeLogModal: Function;
  hasChangeHistory?: boolean;
  onSave?: Function;
  inspectionStatus?: string;
  inspection: InspectionModel;
}

const InspectionHeadingCard = (props: Props) => {

  const [cardCollapsed, setCardCollapsed] = useState(false);

  const [inspectionClosed, setInspectionClosed] = useState(false);
  const [userIsInspector, setUserIsInspector] = useState(false);

  const [loadingCounter, setLoadingCounter] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const [commentsExpanded, setCommentsExpanded] = useState(false);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const [openInspectionToInProgressDialog, setOpenInspectionToInProgressDialog] = useState(false);
  const [openInspectionToQaDialog, setOpenInspectionToQaDialog] = useState(false);

  const handleCardCollapseToggled = () => {
    setCardCollapsed(prevState => !prevState);
    setCommentsExpanded(true);
  };

  const openInspectionToInProgressDialogHandler = () => {
    setOpenInspectionToInProgressDialog(true);
  };

  const onInspectionToInProgressDialogCloseHandler = () => {
    setOpenInspectionToInProgressDialog(false);
  };

  const openInspectionToQaDialogHandler = () => {
    setOpenInspectionToQaDialog(true);
  };

  const onInspectionToQaDialogCloseHandler = () => {
    setOpenInspectionToQaDialog(false);
  };

  useEffect(() => {
    if (!props.dataModel || !props.dataModel.assignedTo) {
      return;
    }

    const inspectorExternalIds = props.dataModel.assignedTo.map(assignee => assignee.externalId);

    CognitoUserService.getUser().then(user => {
      const userExternalId = user.lanId.toLowerCase();

      setUserIsInspector(inspectorExternalIds.some(inspectorExternalId => inspectorExternalId === userExternalId));
      setInspectionClosed(props.dataModel.isClosed);
    });
  }, [props.dataModel]);

  const handleCommentsExpanded = () => {
    setCommentsExpanded(prevState => !prevState);
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const openMapButton =
    <ToggleButton
      onClick={props.handleMapChange}
      isActive={props.mapOpened}
      hoverText={props.mapOpened ? 'Close map' : 'Open map'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={23}>heroicons-outline:map</FuseSvgIcon>
    </ToggleButton>;

  const collapseCardButton =
    <ToggleButton
      onClick={handleCardCollapseToggled}
      isActive={cardCollapsed}
      hoverText={cardCollapsed ? 'Uncollapse' : 'Collapse'}
    >
      {/*@ts-ignore*/}
      {cardCollapsed ? <FuseSvgIcon size={24}>material-outline:unfold_more</FuseSvgIcon> : <FuseSvgIcon size={24}>material-outline:unfold_less</FuseSvgIcon>}
    </ToggleButton>;

  const actionsMenuButton =
    <ToggleButton
      isActive={menuOpened}
      onClick={showMenu}
      className={'pl-16 pr-12 hover:bg-gray-200'}
      text={'Actions'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={16}>material-outline:arrow_drop_down</FuseSvgIcon>
    </ToggleButton>;

  const actionsMenu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'edit'} onClick={props.openEditModal} setMenuOpened={setMenuOpened}
                  icon={'material-outline:edit'} text={'Edit Details'} disabled={inspectionClosed}/>,
        <MenuItem key={'createMassWorkOrder'} onClick={props.openMassWorkOrderModal} setMenuOpened={setMenuOpened}
                  icon={'material-outline:add'} text={'Create Escalations'} disabled={inspectionClosed}
                  className={`${props.dataModel.unhandledConditions.length ? '' : 'hidden'}`}/>,
        <MenuItem key={'download'}
                  onClick={() => downloadPdfReport(props.dataModel.viewModelId, setIsDownloading, setLoadingCounter)}
                  isLoading={isDownloading} icon={'heroicons-outline:download'} text={'Download Report'}/>,
        <MenuItem key={'export'} onClick={() => exportToCSV(props.dataModel.viewModelId, setIsExporting, setLoadingCounter)}
                  isLoading={isExporting} icon={'heroicons-outline:download'} text={'Export Assets CSV'}/>,
        <MenuItem key={'sendToQA'} onClick={openInspectionToQaDialogHandler} setMenuOpened={setMenuOpened} disabled={inspectionClosed}
                  className={`${userIsInspector && props.dataModel.isInProgress ? '' : 'hidden'}`}
                  icon={'material-outline:arrow_right_alt'} text={'Send To QA/QC'} />,
        <MenuItem key={'sendToQAFailed'} onClick={openInspectionToInProgressDialogHandler}
                  setMenuOpened={setMenuOpened} disabled={inspectionClosed}
                  className={`${userIsInspector && (props.dataModel.isWaitingQa || props.dataModel.isInQa) ? '' : 'hidden'}`}
                  icon={'material-outline:close'} text={'Mark QA/QC as Failed'} />,
        <MenuItem key={'changeLog'} onClick={() => props.openChangeLogModal(inspectionClosed)} setMenuOpened={setMenuOpened}
                  icon={'material-outline:history'} text={'Change Log'} />,
      ]}
    />;

  useEffect(() => {
    if (loadingCounter > 0) {
      return;
    }

    setMenuOpened(false);
  }, [loadingCounter]);

  return (
    <>
      <Card
        className={`flex shadow overflow-hidden pr-8 sm:pr-0`}>
        {
          // TODO: Refactor status passing logic. We are currently passing fiber statuses.
        }
        <StatusStrip
          key={'statusComponent'}
          status={props.dataModel.getProp('dueDateStatus')}
          condensed={true}
          statusObjects={FIBER_DUE_DATE_STATUSES}/>
        <div className={`w-full flex flex-col lg:flex-row justify-between gap-16 p-12 lg:p-16 lg:pb-24 lg:items-start`}>
          <div className={'justify-between flex gap-6'}>
            <InspectionHeadingCardHeading
              dataModel={props.dataModel}
            />
            <div
              className={`flex justify-around items-center gap-x-6 ${!!props.dataModel.isReadonly && 'hidden'} lg:hidden`}>
              <div className={'hidden sm:flex'}>
                {actionsMenuButton}
                {actionsMenu}
              </div>
              <div className={'hidden sm:flex'}>
                {openMapButton}
              </div>
              {collapseCardButton}
            </div>
          </div>
          <div className={`${props.elementClassName} ${cardCollapsed && 'hidden'}`}>
            {props.dataModel.headingItemFields.map((item) => (
              <InspectionCardElement key={item.label} item={item}
                                     condensed={false} clickable={false}/>
            ))}
            <div className={`w-full m-auto sm:col-span-4`}>
              <label className={`font-light whitespace-nowrap block text-11`}>
                {props.dataModel.additionalItemField.label}
              </label>
              {commentsExpanded ?
                <span className={`font-semibold`}>
                  {props.dataModel.additionalItemField.value}
                  <label
                    className={`whitespace-nowrap cursor-pointer font-400 ml-6 hover:text-blue-600 italic text-12 my-auto ${!props.dataModel.getProp('comment') && 'hidden'}`}
                    onClick={handleCommentsExpanded}>
                          Show Less
                  </label>
                </span> :
                <TextTruncate
                  line={1}
                  className={`font-semibold sm:whitespace-nowrap flex`}
                  element='label'
                  truncateText='…'
                  text={props.dataModel.additionalItemField.value}
                  textTruncateChild={
                    <label
                      className={`whitespace-nowrap cursor-pointer ml-5 font-400 hover:text-blue-600 italic text-12 my-auto`}
                      onClick={handleCommentsExpanded}>
                      Show More
                    </label>
                  }
                />
              }
            </div>
          </div>
          <div className={`flex lg:flex-col gap-6 items-end ${props.dataModel.isReadonly && 'hidden'}`}>
            <div className={`hidden lg:flex flex-row gap-6 sm:justify-around`}>
              {openMapButton}
              {collapseCardButton}
            </div>
            <div className={`w-full flex sm:hidden lg:flex flex-col justify-around`}>
              {actionsMenuButton}
              {actionsMenu}
            </div>
          </div>
        </div>
      </Card>
      {openInspectionToInProgressDialog &&
          <InspectionToInProgressDialog onClose={onInspectionToInProgressDialogCloseHandler} onSave={props.onSave}
                                        inspection={props.inspection}/>
      }
      {openInspectionToQaDialog && props.dataModel.readyForQa &&
          <InspectionToQaDialog onClose={onInspectionToQaDialogCloseHandler} onSave={props.onSave}
                                inspection={props.inspection}/>
      }
      {openInspectionToQaDialog && !props.dataModel.readyForQa &&
          <InspectionFailedToQaDialog onClose={onInspectionToQaDialogCloseHandler}/>
      }
    </>
  );
};

export default InspectionHeadingCard;
