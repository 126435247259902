import Chip from "../Chip/Chip";
import { InspectionStatusType } from "../../globals";

type Props = {
  status: string;
  statusObjects: InspectionStatusType[];
  className: string;
}

const InspectionStatusChip = (props: Props) => {

  const statusObject = props.statusObjects.find(statusObject => statusObject.key === props.status);

  return (
    <Chip className={props.className} bgColor={statusObject ? statusObject.bgColor : ''} label={statusObject ? statusObject.value : ''}/>
  );
}

export default InspectionStatusChip;
