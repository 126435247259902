import { WidgetProps } from '@rjsf/utils';
import CreatableUserSelect from '../../CreatableUserSelect/CreatableUserSelect';

const CreatableUserSelectWidget = (props: WidgetProps) => {
  return (
    <CreatableUserSelect
      id={'work-assignee'}
      label={props.label}
      className={'w-full'}
      value={props.value ? props.value : ''}
      onChange={props.onChange}
    />
  );
};

export default CreatableUserSelectWidget;
