import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '../../Select/Select';

const DependencyOtherValueWidget = (props: any) => {
  const [selectValue, setSelectValue] = useState('');
  const [textInputValue, setTextInputValue] = useState('');
  const [isFieldDisabled, setFieldDisabled] = useState(true);

  useEffect(() => {
    if (!props.value || !(typeof props.value === 'object')) {
      setSelectValue('');
      setTextInputValue('');
      setFieldDisabled(true);
      return;
    }

    const {value, otherValue} = props.value;

    setSelectValue(value ? value : '');
    setTextInputValue(otherValue ? otherValue : '');
    calculateIsEnabledTextField(value ? value : '');
  }, [props.value, props.options.optionsList]);

  const onSelectChangeHandler = (event: any) => {
    const newValue = event.target.value;
    calculateIsEnabledTextField(newValue);
    setSelectValue(newValue);

    const selectedOption = props.options.optionsList.find(
      (option) => option.value === newValue
    );

    props.onChange({
      value: newValue,
      otherValue: selectedOption && selectedOption.isDisabled ? '' : textInputValue,
    });
  };

  const onTextInputChangeHandler = (event: any) => {
    const newValue = event.target.value;
    calculateIsEnabledTextField(newValue);

    setTextInputValue(newValue);

    props.onChange({
      value: selectValue,
      otherValue: newValue,
    });
  };

  const calculateIsEnabledTextField = (selectedValue: string) => {
    const selectedOption = props.options.optionsList.find(
      (option) => option.value === selectedValue
    );

    setFieldDisabled(!!selectedOption?.isDisabled);
    selectedOption?.isDisabled && setTextInputValue('');
  };

  return (
    <div className='col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16'>
      <Select
        value={selectValue}
        label={`${props.label ? props.label : ''} ${
          props.required ? '*' : ''
        }`}
        className={`w-full`}
        onChange={(event: Event) => onSelectChangeHandler(event)}
        list={props.options.optionsList ? props.options.optionsList : []}
      />

      {
        !isFieldDisabled ? <TextField
          label={`${props.label ? props.label : ''} Other`}
          placeholder={`Value`}
          multiline
          size='small'
          type='number'
          variant='outlined'
          className='w-full'
          value={textInputValue}
          onChange={(event) => onTextInputChangeHandler(event)}
          disabled={isFieldDisabled}
        /> : <></>
      }

    </div>
  );
};

export default DependencyOtherValueWidget;
