import { DataComponentModel } from '../interfaces/data-component.model';
import { InspectionCardItemModel } from '../inspection-card-item.model';
import { InspectionCardHeadingModel } from '../inspection-card-heading.model';
import { BaseViewModel } from './base-view.model';
import { AssetInspectionModel } from '../base-models/asset-inspection.model';

export abstract class AssetInspectionViewModel extends BaseViewModel implements DataComponentModel {

  protected constructor(assetInspection: AssetInspectionModel) {
    super(assetInspection);
  }

  get lastTimeStatusChanged(): string {
    throw new Error('Method not implemented.');
  }

  get readyForQa(): boolean {
    throw new Error('Method not implemented.');
  }

  get componentHeaderTitle(): string {
    throw new Error('Method not implemented.');
  }

  abstract get additionalItemField(): InspectionCardItemModel;

  abstract get buttonAction(): Function;

  abstract get buttonText(): string;

  abstract get componentHeader(): string;

  abstract get dateTime(): string;

  abstract get detailCardFields(): InspectionCardItemModel[];

  abstract get headingItemFields(): InspectionCardItemModel[];

  abstract get infoCardFields(): InspectionCardItemModel[];

  abstract get linkTo(): string;

  abstract get listItemFieldsClassName(): string;

  abstract get listItemFields(): InspectionCardItemModel[];

  abstract get listItemHeading(): InspectionCardHeadingModel;

  abstract get secondaryListItemFields(): InspectionCardItemModel[];

  abstract get secondaryListItemFieldsClassName(): string;

  abstract get previousNextListItem(): any;

  abstract get workOrderPageDetailCardFields(): InspectionCardItemModel[];

  abstract get additionalAttachmentsTitle(): string;
}