import { DATA_TYPE, DataType } from '../types';
import { UpsInspectionModel } from './models/ups-inspection.model';
import { UpsAssetInspectionModel } from './models/ups-asset-inspection.model';
import { UpsAssetModel } from './models/ups-asset.model';
import { UpsWorkOrderModel } from './models/ups-work-order.model';
import { UpsInspectionViewModel } from './view-models/ups-inspection.view-model';
import { UpsAssetInspectionViewModel } from './view-models/ups-asset-inspection.view-model';
import { UpsAssetViewModel } from './view-models/ups-asset.view-model';
import { UpsWorkOrderViewModel } from './view-models/ups-work-order.view-model';
import { UPS_INSPECTION_STATUSES } from './statuses/ups-inspection.statuses';
import { UPS_ASSET_INSPECTION_STATUSES } from './statuses/ups-asset-inspection.statuses';
import { UPS_WORK_ORDER_STATUSES } from './statuses/ups-work-order.statuses';
import {
  UPS_INSPECTION_FORM_SCHEMA,
  UPS_INSPECTION_FORM_UI_SCHEMA
} from './forms/ups-inspection.form';
import {
  UPS_ASSET_INSPECTION_FORM_SCHEMA,
  UPS_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/ups-asset-inspection.form';
import {
  UPS_ASSET_FORM_SCHEMA,
  UPS_ASSET_FORM_UI_SCHEMA
} from './forms/ups-asset.form';
import {
  UPS_DISPATCHING_FORM_SCHEMA,
  UPS_DISPATCHING_FORM_UI_SCHEMA
} from './forms/ups-dispatching.form';
import {
  UPS_WORK_ORDER_FORM_SCHEMA,
  UPS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/ups-work-order.form';
import {
  UPS_MASS_WORK_ORDER_FORM_SCHEMA,
  UPS_MASS_WORK_ORDER_FORM_UI_SCHEMA
} from './forms/ups-mass-work-order.form';
import { UpsDispatchingDocumentModel } from './models/ups-dispatching-document.model';
import {
  UPS_INSPECTIONS_FILTER_FORM_SCHEMA,
  UPS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/ups-inspections-filter.form';
import {
  UPS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
  UPS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/ups-asset-inspections-filter.form';
import {
  UPS_WORK_ORDERS_FILTER_FORM_SCHEMA,
  UPS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
} from './filter-forms/ups-work-orders-filter.form';
import { VRLA_BATTERY_ASSET_FORM_SCHEMA, VRLA_BATTERY_ASSET_FORM_UI_SCHEMA } from './forms/vrla-battery-asset.form';
import {
  VRLA_BATTERY_ASSET_INSPECTION_FORM_SCHEMA,
  VRLA_BATTERY_ASSET_INSPECTION_FORM_UI_SCHEMA
} from './forms/vrla-battery-asset-inspection.form';

export const UPS_TYPE_CONFIG: DataType = {
  type: DATA_TYPE.ups,
  InspectionModel: UpsInspectionModel,
  AssetInspectionModel: UpsAssetInspectionModel,
  AssetModel: UpsAssetModel,
  WorkOrderModel: UpsWorkOrderModel,
  DispatchingDocumentModel: UpsDispatchingDocumentModel,
  AssetViewModel: UpsAssetViewModel,
  WorkOrderViewModel: UpsWorkOrderViewModel,
  AssetInspectionViewModel: UpsAssetInspectionViewModel,
  InspectionViewModel: UpsInspectionViewModel,
  InspectionForm: {
    schema: UPS_INSPECTION_FORM_SCHEMA,
    uiSchema: UPS_INSPECTION_FORM_UI_SCHEMA
  },
  AssetInspectionForm: {
    schema: UPS_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: UPS_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  SecondaryAssetInspectionForm: {
    schema: VRLA_BATTERY_ASSET_INSPECTION_FORM_SCHEMA,
    uiSchema: VRLA_BATTERY_ASSET_INSPECTION_FORM_UI_SCHEMA
  },
  AssetForm: {
    schema: UPS_ASSET_FORM_SCHEMA,
    uiSchema: UPS_ASSET_FORM_UI_SCHEMA
  },
  SecondaryAssetForm: {
    schema: VRLA_BATTERY_ASSET_FORM_SCHEMA,
    uiSchema: VRLA_BATTERY_ASSET_FORM_UI_SCHEMA
  },
  DispatchingForm: {
    schema: UPS_DISPATCHING_FORM_SCHEMA,
    uiSchema: UPS_DISPATCHING_FORM_UI_SCHEMA
  },
  WorkOrderForm: {
    schema: UPS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: UPS_WORK_ORDER_FORM_UI_SCHEMA
  },
  MassWorkOrderForm: {
    schema: UPS_MASS_WORK_ORDER_FORM_SCHEMA,
    uiSchema: UPS_MASS_WORK_ORDER_FORM_UI_SCHEMA
  },
  InspectionsFilterForm: {
    schema: UPS_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: UPS_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  AssetInspectionsFilterForm: {
    schema: UPS_ASSET_INSPECTIONS_FILTER_FORM_SCHEMA,
    uiSchema: UPS_ASSET_INSPECTIONS_FILTER_FORM_UI_SCHEMA
  },
  WorkOrdersFilterForm: {
    schema: UPS_WORK_ORDERS_FILTER_FORM_SCHEMA,
    uiSchema: UPS_WORK_ORDERS_FILTER_FORM_UI_SCHEMA
  },
  inspectionStatuses: UPS_INSPECTION_STATUSES,
  assetInspectionStatuses: UPS_ASSET_INSPECTION_STATUSES,
  workOrderStatuses: UPS_WORK_ORDER_STATUSES
}