import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import WorkOrder from "./WorkOrder";

i18next.addResourceBundle('en', 'escalationPage', en);

const WorkOrderConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'escalation/:id',
      element: <WorkOrder />,
    },
  ],
};

export default WorkOrderConfig;
