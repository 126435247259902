import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select from '../../Select/Select';

const DependencyMultipleInputWidget = (props: any) => {
  const [selectValue, setSelectValue] = useState('');
  const [additionalSelectValue, setAdditionalSelectValue] = useState('');
  const [textInputValue, setTextInputValue] = useState('');
  const [isFieldDisabled, setFieldDisabled] = useState(true);

  useEffect(() => {
    if (!props.value || !(typeof props.value === 'object')) {
      setSelectValue('');
      setAdditionalSelectValue('');
      setTextInputValue('');
      setFieldDisabled(true);
      return;
    }

    const {status, rated, discrepancyAndRemediation} = props.value;

    setSelectValue(status ? status : '');
    setAdditionalSelectValue(rated ? rated : '');
    setTextInputValue(discrepancyAndRemediation ? discrepancyAndRemediation : '');
    calculateAdditionalFieldsEnabled(status ? status : '');
  }, [props.value, props.options.optionsList]);

  const onSelectChangeHandler = (event: any) => {
    const newValue = event.target.value;

    calculateAdditionalFieldsEnabled(newValue);
    setSelectValue(newValue);

    const selectedOption = props.options.optionsList.find(
      (option) => option.value === newValue
    );

    props.onChange({
      status: newValue,
      rated: selectedOption && selectedOption.isDisabled ? '' : additionalSelectValue,
      discrepancyAndRemediation: selectedOption && selectedOption.isDisabled ? '' : textInputValue,
    });
  };

  const onAdditionalSelectChangeHandler = (event: any) => {
    const newValue = event.target.value;

    setAdditionalSelectValue(newValue);

    props.onChange({
      status: selectValue,
      rated: newValue,
      discrepancyAndRemediation: textInputValue,
    });
  };

  const onTextInputChangeHandler = (event: any) => {
    const newValue = event.target.value;

    setTextInputValue(newValue);

    props.onChange({
      status: selectValue,
      rated: additionalSelectValue,
      discrepancyAndRemediation: newValue,
    });
  };

  const calculateAdditionalFieldsEnabled = (selectedValue: string) => {
    const selectedOption = props.options.optionsList.find(
      (option) => option.value === selectedValue
    );

    setFieldDisabled(selectedOption && !!selectedOption.isDisabled);
    selectedOption && selectedOption.isDisabled && setAdditionalSelectValue('');
    selectedOption && selectedOption.isDisabled && setTextInputValue('');
  };

  return (
    <div className='col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16'>
      <Select
        value={selectValue}
        label={`${props.label ? props.label : ''} ${
          props.required ? '*' : ''
        }`}
        className={`w-full`}
        onChange={(event: Event) => onSelectChangeHandler(event)}
        list={props.options.optionsList ? props.options.optionsList : []}
      />

      {
        !isFieldDisabled ?
          <Select
            value={additionalSelectValue}
            label={`Rated`}
            className={`w-full`}
            disabled={isFieldDisabled}
            onChange={(event: Event) => onAdditionalSelectChangeHandler(event)}
            list={props.options.ratedList ? props.options.ratedList : []}
          /> : <></>
      }

      {
        !isFieldDisabled ?
          <TextField
            label={`Discrepancy description and possible remediation`}
            placeholder={`Description and remediation`}
            multiline
            size='small'
            type='number'
            variant='outlined'
            className='w-full'
            value={textInputValue}
            onChange={(event) => onTextInputChangeHandler(event)}
            disabled={isFieldDisabled}
          /> : <></>
      }

    </div>
  );
};

export default DependencyMultipleInputWidget;
