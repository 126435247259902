import { useEffect, useState } from 'react';
import Select from '../../Select/Select';
import MultiSelectList from '../../MultiSelectList/MultiSelectList';

const DependencyMultiSelectWidget = (props: any) => {
  const [selectValue, setSelectValue] = useState('');
  const [multiselectValue, setMultiselectValue] = useState([]);
  const [multiselectOptions, setMultiselectOptions] = useState([]);

  useEffect(() => {
    if (props.value && typeof props.value === 'object') {
      const { type, material } = props.value;

      setSelectValue(type || '');
      setMultiselectValue(material || []);
      setMultiselectOptions(getDefaultMaterialOptions(type) || []);
    } else {
      setSelectValue('');
      setMultiselectValue([]);
    }
  }, [props.value, props.options.selectList]);

  const getDefaultMaterialOptions = (placementType) => {
    const defaultMaterialOptions = props.options.multiselectList.options.filter(
      (material: any) => material.type === placementType
    );

    return defaultMaterialOptions;
  };

  const selectOnChangeHandler = (event: any) => {
    const newValue = event.target.value;
    setMultiselectValue([]);
    setSelectValue(newValue);

    props.onChange({
      type: newValue,
      material: [],
    });
  };

  const multiselectOnChangeHandler = (event: any) => {
    setMultiselectValue(event);

    props.onChange({
      type: selectValue,
      material: event,
    });
  };

  return (
    <div className='col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16'>
      <Select
        value={selectValue}
        label={`${props.options.selectList?.label}${props.required ? '*' : ''}`}
        className={`w-full ${props.className || ''}`}
        onChange={(event: Event) => selectOnChangeHandler(event)}
        list={props.options.selectList?.options || []}
      />

      <MultiSelectList
        className='w-full'
        id={props.options.multiselectList?.label}
        values={multiselectValue}
        label={`${props.options.multiselectList?.label}`}
        items={props.options.multiselectList?.options ? multiselectOptions : []}
        onChange={(event) => multiselectOnChangeHandler(event)}
      />
    </div>
  );
};

export default DependencyMultiSelectWidget;
