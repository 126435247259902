import { MuiChipsInput } from 'mui-chips-input';

const MultiSelectChipsWidget = (props: any) => {
  return (
    <MuiChipsInput
      value={props.value ? props.value : []}
      onChange={(event) => props.onChange(event.map(text => text.startsWith('Other: ') ? text : `Other: ${text}`))}
      placeholder={`${props.label}${props.required ? '*' : ''}`}
      label={`${props.label}${props.required ? '*' : ''}`}
      className={`w-full ${props.className ? props.className : ''}`}
    />
  );
};

export default MultiSelectChipsWidget;
