import { useContext, useEffect, useState } from 'react';
import { MapModulesModel } from '../../../../models/map-modules.model';
import { MapsLibraryContext } from '../../components/EsriMapsContextLoader';
import { MapPointModel } from '../../../../models/map-point.model';
import { MAP_POINT_PROPERTIES } from '../../../../globals';

type Props = {
  points: MapPointModel[];
  clickedPointId?: string;
  hoveredPointId?: string;
  onPointClickHandler?: Function;
}

const Points = (props: Props) => {

  let {
    map,
    mapView,
    Graphic,
    GraphicsLayer,
    MapView,
  }: MapModulesModel = useContext(MapsLibraryContext) as MapModulesModel;

  const [graphicsLayer, setGraphicsLayer] = useState({} as any);
  const [hoveredPointId, setHoveredPointId] = useState('');
  const [previouslyHoveredPoint, setPreviouslyHoveredPoint] = useState(undefined);
  const [previouslyClickedPoint, setPreviouslyClickedPoint] = useState(undefined);

  useEffect(() => {
    if (!map || !props.points) {
      return;
    }

    let updatedGraphicsLayer;

    if (!!graphicsLayer && !!Object.keys(graphicsLayer).length) {
      updatedGraphicsLayer = graphicsLayer;
      updatedGraphicsLayer.graphics.items = [];
    } else {
      updatedGraphicsLayer = new GraphicsLayer();
      map.add(updatedGraphicsLayer);
    }

    props.points.forEach(point => {
      const graphic = new Graphic({
        attributes: {
          ObjectID: point.id,
        },
        geometry: {
          type: 'point',
          longitude: point.location && point.location.longitude,
          latitude: point.location && point.location.latitude,
        },
        symbol: {
          type: MAP_POINT_PROPERTIES.type,
          size: 14,
          color: point.color,
          outline: MAP_POINT_PROPERTIES.outline,
        },
      });

      updatedGraphicsLayer.add(graphic);
    });

    mapView.on("click", (event) => {
      const screenPoint = event.screenPoint;

      mapView.hitTest(screenPoint).then((response) => {

          if (!response.results.length) {
            props.onPointClickHandler('');
            return;
          }

          const graphicPoint = response.results.find(function (result) {
            return result.graphic.layer === updatedGraphicsLayer;
          });

          if (!graphicPoint) {
            props.onPointClickHandler('');
            return;
          }

          props.onPointClickHandler(graphicPoint.graphic.attributes.ObjectID);
        }
      );
    });

    mapView.on("pointer-move", (event) => {
      const screenPoint = {
        x: event.x,
        y: event.y
      };

      mapView.hitTest(screenPoint).then((response) => {
          if (!response.results.length) {
            setHoveredPointId('');
            return;
          }

          const graphicPoint = response.results.find(function (result) {
            return result.graphic.layer === updatedGraphicsLayer;
          });

          if (!graphicPoint) {
            setHoveredPointId('');
          }

          setHoveredPointId(graphicPoint.graphic.attributes.ObjectID);
        }
      );
    });

    mapView.goTo(updatedGraphicsLayer.graphics, {animate: false}).then(response => {
      let zoomView = {};
      zoomView = mapView.extent.expand(2.0);
      mapView.goTo(zoomView);
    });

    setGraphicsLayer(updatedGraphicsLayer);
  }, [map, props.points]);

  useEffect(() => {
    if (previouslyHoveredPoint) {
      const hoveredItemClone = previouslyHoveredPoint.clone();
      hoveredItemClone.symbol.outline = MAP_POINT_PROPERTIES.outline;
      hoveredItemClone.symbol.size = 14;
      graphicsLayer.graphics.remove(previouslyHoveredPoint);
      graphicsLayer.graphics.add(hoveredItemClone);
    }

    let hoveredPoint;

    if (graphicsLayer.graphics && graphicsLayer.graphics.items && props.hoveredPointId) {
      hoveredPoint = graphicsLayer.graphics.items.find(item => item.attributes.ObjectID === props.hoveredPointId);
    }

    if (graphicsLayer.graphics && graphicsLayer.graphics.items && hoveredPointId) {
      hoveredPoint = graphicsLayer.graphics.items.find(item => item.attributes.ObjectID === hoveredPointId);
    }

    if (!hoveredPoint || hoveredPoint === previouslyClickedPoint) {
      setPreviouslyHoveredPoint(undefined);
      return;
    }

    if (mapView && mapView.extent && props.hoveredPointId) {
      if (!mapView.extent.contains(hoveredPoint.geometry)) {
        mapView.goTo(hoveredPoint, {animate: true}).then(response => {
          let zoomView = {};
          zoomView = mapView.extent;
          mapView.goTo(zoomView);
        });
      }
    }

    const newHoveredPoint = hoveredPoint.clone();

    newHoveredPoint.symbol.size = 21;

    newHoveredPoint.symbol.outline = MAP_POINT_PROPERTIES.focusedOutline;

    graphicsLayer.graphics.remove(hoveredPoint);
    graphicsLayer.graphics.add(newHoveredPoint);

    graphicsLayer.graphics.reorder(newHoveredPoint, graphicsLayer.graphics.items.length - 1);

    setPreviouslyHoveredPoint(newHoveredPoint);
  }, [props.hoveredPointId, hoveredPointId]);

  useEffect(() => {
    if (previouslyClickedPoint) {
      const clickedItemClone = previouslyClickedPoint.clone();
      clickedItemClone.symbol.outline = MAP_POINT_PROPERTIES.outline;
      clickedItemClone.symbol.size = 14;
      graphicsLayer.graphics.remove(previouslyClickedPoint);
      graphicsLayer.graphics.add(clickedItemClone);
    }

    if (!props.clickedPointId || !graphicsLayer) {
      setPreviouslyClickedPoint(undefined);
      return;
    }

    let clickedPoint;

    if (graphicsLayer.graphics && graphicsLayer.graphics.items) {
      clickedPoint = graphicsLayer.graphics.items.find(item => item.attributes.ObjectID === props.clickedPointId);
    }

    if (!clickedPoint) {
      return;
    }

    if (mapView && mapView.extent) {
      mapView.goTo(clickedPoint, {animate: true}).then(response => {
        let zoomView = {};
        zoomView = mapView.extent;
        mapView.goTo(zoomView);
      });
    }

    const newClickedPoint = clickedPoint.clone();

    newClickedPoint.symbol.size = 21;

    newClickedPoint.symbol.outline = MAP_POINT_PROPERTIES.focusedOutline;

    graphicsLayer.graphics.remove(clickedPoint);
    graphicsLayer.graphics.add(newClickedPoint);

    graphicsLayer.graphics.reorder(newClickedPoint, graphicsLayer.graphics.items.length - 1);

    setPreviouslyClickedPoint(newClickedPoint);
  }, [props.clickedPointId]);

  return (
    <></>
  );
};

export default Points;
