import Paper from '@mui/material/Paper';
import {useEffect} from 'react';
import AuthService from '../../services/auth-service/auth-service';

function SignOutPage() {
    useEffect(() => {
        AuthService.logout();
    }, []);

    return (
        <div className="flex flex-col flex-auto items-center sm:justify-center min-w-0">
            <Paper
                className="flex items-center w-full sm:w-auto min-h-full sm:min-h-auto rounded-0 py-32 px-16 sm:p-48 sm:rounded-2xl sm:shadow">
                <div className="w-full max-w-320 sm:w-320 mx-auto sm:mx-0">
                    <img className="mx-auto" src="assets/images/logo/logo-dark.svg" alt="logo"/>
                </div>
            </Paper>
        </div>
    );
}

export default SignOutPage;
