import React, { useState } from 'react';
import EsriLoaderReact from 'esri-loader-react';
import Environment from '../../../../../environment';
import { MapModulesModel } from '../../../models/map-modules.model';

export const MapsLibraryContext = React.createContext({});

type Props = {
  children: JSX.Element[]
}

const EsriMapsContextLoader = (props: Props) => {

  const ARCGIS_URL = Environment.get('ARCGIS_URL');

  const [stateObj, setStateObj] = useState(new MapModulesModel());

  const esriLoaderOptions = {
    url: ARCGIS_URL,
    dojoConfig: {
      has: {
        'esri-promise-compatibility': 1,
        'esri-featurelayer-webgl': 1,
      },
    },
  };

  const esriModules = [
    'esri/Map',
    'esri/views/MapView',
    'esri/layers/VectorTileLayer',
    'esri/layers/MapImageLayer',
    'esri/layers/FeatureLayer',
    'esri/layers/GraphicsLayer',
    'esri/Graphic',
    'esri/geometry/geometryEngine',
    'esri/widgets/Legend',
    'esri/geometry/Point',
    'esri/geometry/support/webMercatorUtils',
    'esri/geometry/Polyline',
    'esri/geometry/SpatialReference',
    'esri/tasks/support/Query',
    'esri/geometry/Extent',
    'esri/geometry/Polygon',
    'esri/widgets/Sketch',
    'esri/widgets/Sketch/SketchViewModel',
    'esri/tasks/QueryTask',
    'esri/widgets/Popup',
  ];

  const initMaps = ({
                      loadedModules: [Map, MapView, VectorTileLayer, MapImageLayer, FeatureLayer,
                        GraphicsLayer, Graphic, geometryEngine, Legend, Point, webMercatorUtils, Polyline,
                        SpatialReference, Query, Extent, Polygon, Sketch, SketchViewModel, QueryTask, Popup,
                      ], containerNode,
                    }) => {
    const mapModules = new MapModulesModel({
      map: undefined,
      mapView: undefined,
      Map,
      MapView,
      VectorTileLayer,
      MapImageLayer,
      FeatureLayer,
      GraphicsLayer,
      Graphic,
      geometryEngine,
      Legend,
      Point,
      webMercatorUtils,
      Polyline,
      SpatialReference,
      Query,
      Extent,
      Polygon,
      Sketch,
      SketchViewModel,
      QueryTask,
      containerNode,
      Popup,
      updateContext: setStateObj
    });

    setStateObj(mapModules);
  };

  return (
    <EsriLoaderReact
      options={esriLoaderOptions}
      modulesToLoad={esriModules}
      onReady={initMaps}
      mapContainerClassName={'flex w-full h-full align-center'}
    >
      <MapsLibraryContext.Provider value={stateObj}>
        {props.children}
      </MapsLibraryContext.Provider>
    </EsriLoaderReact>
  );
};

export default EsriMapsContextLoader;
