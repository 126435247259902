import axios from 'axios';

const axiosInstance = axios.create({});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {

    return Promise.reject(error.response.data);
  },
);

export default axiosInstance;
