import React, { useEffect, useState } from 'react';
import {
  Select as MaterialSelect,
  MenuItem,
  InputLabel,
  FormControl,
  IconButton,
  FormHelperText,
  CircularProgress,
} from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  className?: string;
  value: any;
  onChange: Function;
  list: any[];
  id?: string;
  label: string;
  disabled?: boolean;
  nullable?: boolean;
  required?: boolean;
  error?: boolean;
  helperText?: string;
  isLoading?: boolean;
  isFilled?: boolean;
};

const Select = (props: Props) => {
  const labelId = props.id + '-label';

  const [selectedValue, setSelectedValue] = useState('');

  useEffect(() => {
    setSelectedValue(props.value !== undefined && props.value !== null ? props.value : '');
  }, [props.value]);

  const onChangeHandler = (event) => {
    //setSelectedValue(event.target ? event.target.event : '');
    props.onChange(event);
  };

  const handleClearClick = () => {
    setSelectedValue('');
    props.onChange('');
  };

  return (
    <FormControl
      className={`${props.className}`}
      size={'small'}
      required={props.required}
      error={props.error}
      variant={props.isFilled ? 'filled' : 'outlined'}
    >
      <InputLabel id={labelId}>{props.label}</InputLabel>
      <MaterialSelect
        required={props.required}
        labelId={labelId}
        className={`pr-4 gap-x-0`}
        id={props.id ? props.id : ''}
        disabled={props.disabled}
        value={selectedValue}
        label={props.label}
        onChange={onChangeHandler}
        sx={{
          '& .MuiSelect-iconOutlined': {
            display:
              (props.value !== undefined && props.value !== null && !!props.nullable) || !!props.isLoading
                ? 'none'
                : '',
          },
        }}
        endAdornment={
          (!!props.isLoading || (props.value !== undefined && props.value !== null && !!props.nullable)) && (
            <div className={`${props.isLoading && 'mr-7'}`}>
              {props.isLoading ? (
                <CircularProgress size={14} color={'inherit'} />
              ) : (
                <IconButton
                  onClick={handleClearClick}
                  className={''}
                  size={'small'}
                >
                  {/*@ts-ignore*/}
                  <FuseSvgIcon size={18}>material-outline:clear</FuseSvgIcon>
                </IconButton>
              )}
            </div>
          )
        }
        SelectDisplayProps={{
          style: { display: 'flex', alignItems: 'center' },
        }}
        MenuProps={{
          style: {
            maxHeight: 256,
          },
        }}
      >
        {props.list.map((listItem) => (
          <MenuItem key={listItem.key} value={listItem.key}>
            {listItem.value}
          </MenuItem>
        ))}
      </MaterialSelect>
      <FormHelperText style={{ margin: '0px 2px' }}>
        {props.helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default Select;
