export class UserModel {
  id: string;
  externalId: string;
  name: string;
  role: string[];
  department: string[];
  email: string;

  constructor(values: any = {}) {
    Object.assign(this, values)
  }
}