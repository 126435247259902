import React, { useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import useEffectOnce from '../../hooks/useEffectOnce';

type Props = {
  className?: string;
  statuses: any[];
  selectedStatuses: string[];
  onChange: Function;
}

const DueDateStatusFilter = (props: Props) => {

  const [statuses, setStatuses] = useState([]);

  useEffectOnce(() => {
    if (!props.selectedStatuses || statuses === props.selectedStatuses) {
      return;
    }

    setStatuses(props.selectedStatuses);
  }, [props.selectedStatuses]);

  const statusChangeHandler = (key: string) => {
    let newValues: string[];

    if (statuses.find(item => item === key)) {
      newValues = statuses.filter(item => item !== key);
    } else {
      newValues = [...statuses, key];
    }

    setStatuses(newValues);
    props.onChange(newValues);
  };

  return (
    <FormGroup className={`pr-12 ${props.className}`}>
      {
        !!props.statuses && !!props.statuses.length && props.statuses.map(dueDateStatus =>
          <FormControlLabel
            key={dueDateStatus.key}
            className={'whitespace-nowrap text-10 gap-x-1'}
            value={dueDateStatus.key}
            control={<Checkbox disableRipple color='primary' size={'small'}
                               style={{color: dueDateStatus.color, padding: 0}}/>}
            label={dueDateStatus.label}
            labelPlacement='start'
            defaultChecked={false}
            checked={statuses.includes(dueDateStatus.key)}
            onChange={() => statusChangeHandler(dueDateStatus.key)}
          />
        )
      }
    </FormGroup>
  );
}

export default DueDateStatusFilter;
