import { TextField } from '@mui/material';
import { WidgetProps } from '@rjsf/utils';

const NumberWidget = (props: WidgetProps) => {
  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;

    if (!isNaN(inputValue) || inputValue === '' || inputValue === '.') {
      props.onChange(inputValue === '' ? undefined : inputValue);
    }
  };

  return (
    <TextField
      size={'small'}
      value={props.value}
      label={`${props.label}${props.required ? '*' : ''}`}
      error={!!props.rawErrors}
      placeholder={`${props.placeholder}${props.required ? '*' : ''}`}
      className={`w-full ${props.className ? props.className : ''}`}
      onChange={handleInputChange}
    />
  );
};

export default NumberWidget;
