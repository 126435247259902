import ObjectFieldTemplate from './templates/object-field.template';
import FieldTemplate from './templates/field.template';
import FieldErrorTemplate from './templates/field-error.template';

const DefaultTemplates = {
  FieldTemplate,
  ObjectFieldTemplate,
  FieldErrorTemplate
};

export default DefaultTemplates;