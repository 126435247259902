import React, { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import DispatchingService from '../../../services/dispatching.service';
import Form, { FormRef } from '../../../shared/components/Form/Form';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';
import { DispatchingDocumentModel } from '../../../shared/models/base-models/dispatching-document.model';
import { DispatchingDocumentFormFactory } from '../../../shared/form-factories/dispatching-document-form.factory';

type Props = {
  onSave: Function;
  onClose: Function;
  documentType: string;
}

const DispatchInspectionModal = (props: Props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [document, setDocument] = useState(new DispatchingDocumentModel());
  const [form, setForm] = useState(new BaseFormModel());

  const formRef = useRef<FormRef>();

  useEffect(() => {
    if (!props.documentType) {
      return;
    }

    // enable this if there is need for whole model on backend
    // const documentModel = new DispatchingDocumentModelFactory().createModelByType<BaseModel>(props.documentType);
    //
    // if (!!documentModel) {
    //   setDocument(documentModel);
    // }

    const formSchemas = new DispatchingDocumentFormFactory().createFormByType<BaseFormModel>(props.documentType);

    if (!formSchemas) {
      return;
    }

    setForm(formSchemas);
  }, [props.documentType]);

  const handleSubmit = async (document: DispatchingDocumentModel) => {

    setIsLoading(true);

    DispatchingService.dispatchInspection(document, props.documentType)
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Inspection dispatched successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage('Error', 'error'));
        setIsLoading(false);
      });
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  return (
    <Modal onClose={props.onClose} title={'Dispatch Inspection'}
           className={'sm:h-3/5 md:h-auto md:w-3/5 lg:w-1/2'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={'Submit'}
               />
             </div>
           }
    >
      {/*<DispatchInspectionForm onChanged={inspectionChangedHandler} inspection={inspection} className={'px-24 py-16'} />*/}
      {!!form.schema && <Form schema={form.schema} className={'px-24 py-16'}
            uiSchema={form.uiSchema} onSubmit={handleSubmit}
            formData={document} ref={formRef} />}
    </Modal>
  );
};

export default DispatchInspectionModal;
