import ApiClient from './api-client/api-client';
import { ReportParamsInterface } from '../shared/models/query-params-interfaces/report-params.interface';

const INSPECTION_STATS_ENDPOINT = '/reports/inspection-stats';
const WORK_ORDER_STATS_ENDPOINT = '/reports/work-order-stats';
const CONDITIONS_STATS_ENDPOINT = '/reports/condition-stats';

const getInspectionStats = (queryParams: ReportParamsInterface = {}): Promise<any> => {
  return ApiClient.get(INSPECTION_STATS_ENDPOINT, queryParams)
    .then(response => response.data);
};

const getWorkOrderStats = (queryParams: ReportParamsInterface = {}): Promise<any> => {
  return ApiClient.get(WORK_ORDER_STATS_ENDPOINT, queryParams)
    .then(response => response.data);
};

const getConditionStats = (queryParams: ReportParamsInterface = {}): Promise<any> => {
  return ApiClient.get(CONDITIONS_STATS_ENDPOINT, queryParams)
    .then(response => response.data);
};

const ReportsService = {
  getInspectionStats,
  getWorkOrderStats,
  getConditionStats
};

export default ReportsService;
