import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Card, CardActionArea, Skeleton } from '@mui/material';
import { URLHelper } from '../../../shared/helpers/url.helper';

type Props = {
  label: string;
  amount: number;
  linkTo?: string;
  queryParams?: any;
  isLoading?: boolean;
  additionalDetails?: any;
  className?: string;
}

const ItemsCard = (props: Props) => {

  const navigate = useNavigate();

  const cardLinkTo = (): string => {
    return URLHelper.getUrlQuery(props.linkTo, props.queryParams);
  };

  const routeChange = (e, linkTo, filterParams) => {
    e.preventDefault();
    const queryParams = {
      ...props.queryParams,
      ...filterParams
    };

    const path = URLHelper.getUrlQuery(linkTo, queryParams);

    navigate(path);
  }

  return (
    <Card className={`flex flex-auto shadow rounded-2xl overflow-hidden ${props.className}`}>
      <CardActionArea component={RouterLink} to={`${cardLinkTo()}`}>
        <div className={'flex items-start justify-between m-16 mb-0'}>
          <Typography className={'text-lg font-medium tracking-tight leading-6 truncate'}>
            {props.label}
          </Typography>
        </div>
        {!!props.isLoading ?
          <div className={'mx-16 mb-12 mt-2 text-center flex'}>
            <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '50px'}}/>
          </div> :
          <div className={'mx-16 mt-8 mb-16 flex gap-x-8 sm:gap-x-12'}>
            <Typography className={'text-7xl font-bold tracking-tighter leading-tight'}>
              {props.amount}
            </Typography>

            {props.additionalDetails && props.additionalDetails.length ?
              <div className={'flex flex-wrap'}>
                {props.additionalDetails.map(item =>
                  <div key={item.key}
                    className={`flex flex-col mt-3 pt-2 px-8 rounded-lg ${item.textColor} hover:bg-gray-100 cursor-pointer`}
                    onClick={(event) => routeChange(event, item.linkTo, item.filterParams)}>
                    <label className={'text-12 font-medium tracking-tight leading-6 cursor-pointer whitespace-nowrap'}>
                      {item.label}
                    </label>
                    <label
                      className={'text-20 font-bold tracking-tighter leading-tight whitespace-nowrap cursor-pointer'}>
                      {item.value}
                    </label>
                  </div>
                )}
              </div>
              : <></>
            }
          </div>
        }
      </CardActionArea>
    </Card>
  );
};

export default ItemsCard;
