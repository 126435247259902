import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import MasonryImageList from './MasonryImageList/MasonryImageList';
import { CircularProgress } from '@mui/material';
import PrismaZoom from 'react-prismazoom';
import { AnimatePresence, motion } from 'framer-motion';
import { ViewerImageModel } from '../models/viewer-image.model';
import ImagesService from '../../services/images.service';

type Props = {
  images: ViewerImageModel[],
  opened: boolean,
  selectedImageIndex: number,
  onViewerClose: Function,
}
const ImagesViewer = (props: Props) => {

  const LEFT_ARROW = 'ArrowLeft';
  const RIGHT_ARROW = 'ArrowRight';
  const ESCAPE = 'Escape';

  const [selectedImage, setSelectedImage] = useState(new ViewerImageModel());
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [opened, setOpened] = useState(false);

  const keyPressedHandler = (event) => {
    switch (event.key) {
      case LEFT_ARROW:
        onSelectedImageChange(selectedImageIndex - 1);
        break;
      case RIGHT_ARROW:
        onSelectedImageChange(selectedImageIndex + 1);
        break;
      case ESCAPE:
        handleClose();
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', keyPressedHandler, false);

    return () => {
      document.removeEventListener('keydown', keyPressedHandler, false);
    };
  }, [selectedImageIndex]);

  useEffect(() => {
    setOpened(props.opened);
  }, [props.opened]);

  const handleClose = () => {
    props.onViewerClose();
  };

  useEffect(() => {
    if (props.selectedImageIndex == null) {
      return;
    }
    onSelectedImageChange(props.selectedImageIndex);
  }, [props.selectedImageIndex]);

  const handleThumbnailClick = (index: number) => {
    onSelectedImageChange(index);
  };

  const stopPropagatingClose = (event) => {
    event.stopPropagation();
  };

  const getImage = (index: number) => {
    return props.images.length > 0 ? props.images[index] : null;
  };

  const onSelectedImageChange = (index: number) => {
    if (!isIndexValid(index)) {
      return;
    }

    const selectedImage = getImage(index);
    setSelectedImage(selectedImage);
    setSelectedImageIndex(index);

    if (!selectedImage) {
      return;
    }

    if (selectedImage.originalObj) {
      return;
    }

    ImagesService.getImage(selectedImage)
      .then(result => {
        selectedImage.originalObj = result;
        setSelectedImage(new ViewerImageModel(selectedImage));
      });
  };

  const numberOfImages = () => {
    return props.images ? props.images.length : 0;
  };

  const isIndexValid = (index): boolean => {
    return index >= 0 && index < numberOfImages();
  };

  const hasImagesOnLeft = () => {
    return selectedImageIndex > 0;
  };

  const hasImagesOnRight = () => {
    return selectedImageIndex < numberOfImages() - 1;
  };

  const item = {
    hidden: {opacity: 0, scale: 0.5},
    show: {opacity: 1, scale: 1},
    exit: {opacity: 0},
  };

  return (
    ReactDOM.createPortal(
      <AnimatePresence>
        {
          opened && props.images.length > 0 &&
          <motion.div
            variants={item}
            initial='hidden'
            animate='show'
            style={{zIndex: 10000}}
            exit='exit'
            key='image-viewer'
            className={'fixed w-full h-full bg-black z-99 top-0 left-0 bg-opacity-80'} onClick={handleClose}>

            <div className={'block relative w-full h-full pt-10 pb-88 md:pb-136 px-10 overflow-hidden select-none'}>

              <div
                className={'w-full h-full flex justify-around items-center md:items-stretch overflow-hidden rounded'}>

                {/*@ts-ignore*/}
                <FuseSvgIcon
                  className={'fixed right-0 top-0 text-white z-999 h-36 w-36 p-5 cursor-pointer rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 transition-colors shadow-2 m-20 mt-30'}
                  onClick={(e) => {
                    stopPropagatingClose(e);
                    handleClose();
                  }}>material-outline:close</FuseSvgIcon>

                {
                  hasImagesOnLeft() &&
                  /*@ts-ignore*/
                  <FuseSvgIcon
                    className={'fixed left-0 top-1/2 -translate-y-1/2 text-white z-999 h-40 w-40 cursor-pointer rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 transition-colors shadow-2 ml-20'}
                    onClick={(e) => {
                      stopPropagatingClose(e);
                      onSelectedImageChange(selectedImageIndex - 1);
                    }}>material-outline:chevron_left</FuseSvgIcon>}

                {
                  opened && selectedImage?.originalObj ?
                    <PrismaZoom>
                      <img src={selectedImage.originalObj} onClick={stopPropagatingClose}
                           className={'m-auto max-w-full max-h-full shadow-5 object-contain cursor-pointer rounded'}
                           alt='' />
                    </PrismaZoom> :
                    <div className='m-auto'>
                      <CircularProgress disableShrink size={60} className={'text-white'} />
                    </div>

                }

                {
                  hasImagesOnRight() &&
                  /*@ts-ignore*/
                  <FuseSvgIcon
                    className={'fixed right-0 top-1/2 -translate-y-1/2 text-white z-999 h-40 w-40 cursor-pointer rounded-full bg-black bg-opacity-50 hover:bg-opacity-100 transition-colors shadow-2 mr-20'}
                    onClick={(e) => {
                      stopPropagatingClose(e);
                      onSelectedImageChange(selectedImageIndex + 1);
                    }}>material-outline:chevron_right</FuseSvgIcon>}
              </div>
              <div
                className={'w-full md:w-1/2 flex overflow-hidden h-68 md:h-112 mt-10 md:m-auto md:mt-10 p-10 bg-black shadow-4 rounded'}
                onClick={stopPropagatingClose}>
                <MasonryImageList images={props.images}
                                  onImageClick={handleThumbnailClick}
                                  selectedImage={selectedImageIndex}
                                  className='flex gap-12 rounded items-center'
                                  imageClassName='w-40 h-40 md:w-80 md:h-80 border border-2 cursor-pointer'
                                  selectedItemStyle={'border-white'}
                                  unselectedItemStyle={'border-gray-800'} />
              </div>

            </div>
          </motion.div>
        }
      </AnimatePresence>, document.getElementById('image-viewer-root'))

  );
};

export default ImagesViewer;
