import React from 'react';
import { Skeleton } from '@mui/material';

type Props = {
  className?: string;
};

const BreadcrumbsSkeleton = ({className}: Props) => {

  return (
    <div className={'flex flex-wrap gap-x-8 items-center px-3'}>
      <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '18px'}}/>
      /
      <Skeleton variant='text' animation={'wave'} width={150} sx={{fontSize: '18px'}}/>
      /
      <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '18px'}}/>
    </div>
  );
};

export default BreadcrumbsSkeleton;
