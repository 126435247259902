import ApiClient from './api-client/api-client';
import { PaginationModel } from '../shared/models/pagination.model';
import { InspectionParamsInterface } from '../shared/models/query-params-interfaces/inspection-params.interface';
import FileDownload from 'js-file-download';
import { CommentModel } from '../shared/models/comment.model';
import BaseContextService from './base-context.service';
import { InspectionModelMapper } from '../shared/model-mappers/inspection-model.mapper';
import { InspectionModel } from '../shared/models/base-models/inspection.model';

const INSPECTIONS_ENDPOINT: string = '/inspections';

const getInspections = (queryParams: InspectionParamsInterface = {}): Promise<[PaginationModel, InspectionModel[]]> => {
  return BaseContextService.fetchList<InspectionModel>(INSPECTIONS_ENDPOINT, new InspectionModelMapper(), queryParams);
};

const getInspection = (id: string, queryParams: InspectionParamsInterface = {}): Promise<InspectionModel> => {
  return BaseContextService.fetch<InspectionModel>(`${INSPECTIONS_ENDPOINT}/${id}`, new InspectionModelMapper(), queryParams);
};

const updateInspection = (id: string, inspection: InspectionModel): Promise<InspectionModel> => {
  return BaseContextService.update<InspectionModel>(`${INSPECTIONS_ENDPOINT}/${id}`, inspection, new InspectionModelMapper());
};

const generatePdfReports = (body: InspectionParamsInterface = {}): Promise<any> => {
   return ApiClient.post(`${INSPECTIONS_ENDPOINT}/pdf-reports`, body);
};

const getCsvReport = (queryParams: InspectionParamsInterface = {}): Promise<void> => {
  return ApiClient.get(`${INSPECTIONS_ENDPOINT}/csv-report`, queryParams, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-csv-${new Date().toISOString()}.csv`));
};

const getInspectionCsvReport = (id: string, queryParams: InspectionParamsInterface = {}): Promise<void> => {
  return ApiClient.get(`${INSPECTIONS_ENDPOINT}/${id}/csv-report`, queryParams, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-csv-${new Date().toISOString()}.csv`));
};

const getInspectionPdfReport = (id: string, queryParams: InspectionParamsInterface = {}): Promise<void> => {
  return ApiClient.get(`${INSPECTIONS_ENDPOINT}/${id}/pdf-report`, queryParams, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-pdf-${new Date().toISOString()}.pdf`));
};

const updateInspectionStatus = (id: string, body: any): Promise<InspectionModel> => {
  return BaseContextService.update<any>(`${INSPECTIONS_ENDPOINT}/${id}/status`, body, new InspectionModelMapper());
};

const addComment = (inspectionId: string, body: any): Promise<CommentModel> => {
  return ApiClient.post(`${INSPECTIONS_ENDPOINT}/${inspectionId}/comments`, body)
    .then(response => response.data)
    .then(data => new CommentModel(data));
};

const InspectionsService = {
  getInspections,
  getInspection,
  updateInspection,
  generatePdfReports,
  getCsvReport,
  getInspectionCsvReport,
  getInspectionPdfReport,
  updateInspectionStatus,
  addComment,
};

export default InspectionsService;


