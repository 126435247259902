import { BaseModel } from '../base.model';

export class AssetModel extends BaseModel {

  // TODO: Temp fix, remove properties later
  assetInspections?: any[];
  type?: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}