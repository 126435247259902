import React from 'react';
import { Skeleton } from '@mui/material';

type Props = {
  className?: string;
};

const ConditionSkeleton = ({className}: Props) => {

  return (
      <div className={`flex flex-row items-center justify-between py-12 px-20 ${className}`}>
        <div className={'flex items-center gap-x-12 p-2'}>
          <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '16px'}}/>
          <Skeleton variant='rounded' animation={'wave'} width={70} sx={{fontSize: '15px', borderRadius: '15px'}}/>
          <Skeleton className={'hidden sm:flex'} variant='text' animation={'wave'} width={90} sx={{fontSize: '16px'}}/>
        </div>
        <Skeleton className={'hidden sm:flex'} variant='rounded' animation={'wave'} width={90} sx={{fontSize: '17px', borderRadius: '15px'}}/>
      </div>
  );
};

export default ConditionSkeleton;
