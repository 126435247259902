import React, { useContext } from 'react';
import { PieChart as MaterialPieChart, Pie, Cell, Tooltip, ResponsiveContainer } from 'recharts';
import { DEPARTMENT, PIE_CHART_COLORS } from '../../globals';
import { useNavigate } from 'react-router-dom';
import { URLHelper } from '../../helpers/url.helper';
import DepartmentContext from '../../../DepartmentContext';

const PieChart = ({data, queryParams, conditionsFound}) => {

  const {department} = useContext(DepartmentContext);
  const navigate = useNavigate();

  const routeChange = (linkTo: string, condition: string) => {
    const updatedQueryParams = {
      ...queryParams,
      'condition': condition,
    };

    const path = URLHelper.getUrlQuery(linkTo, updatedQueryParams);

    navigate(path);
  }

  const renderLabel = (entry) => {
    const percentage = (entry['occurrence'] / conditionsFound) * 100;

    if (department && department === DEPARTMENT.generators) {
      if (percentage < 4) {
        return;
      }
    }

    if (percentage < 1) {
      return;
    }

    return entry.condition;
  }

  return (
    <ResponsiveContainer width="100%" minWidth={480} height={400} >
      <MaterialPieChart height={400} className={'mx-auto'}>
        <Pie
          data={data}
          dataKey="occurrence"
          nameKey="condition"
          cx="50%"
          cy="50%"
          outerRadius={150}
          fill="#8884d8"
          labelLine={false}
          label={(entry) => renderLabel(entry)}
          className={'cursor-pointer'}
        >
          {data && data.length && data.map((item, index) => (
            <Cell onClick={() => routeChange('/asset-inspections', item.condition)} key={`cell-${index}`}
                  fill={PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]}/>
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
            background: 'white',
            border: '0px',
            padding: '2px 8px',
            borderRadius: '4px',
            fontSize: '12px'
          }}/>
      </MaterialPieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
