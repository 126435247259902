import React, { useState } from 'react';
import { Card, FormControlLabel, FormGroup, Switch } from '@mui/material';
import ToggleButton from '../../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Select from '../../../shared/components/Select/Select';
import moment from 'moment';
import DatePicker from '../../../shared/components/DatePicker/DatePicker';
import Search from '../../../shared/components/Search/Search';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from 'framer-motion';
import MultiSelect from '../../../shared/components/MultiSelect/MultiSelect';
import Menu from '../../../shared/components/Menu/Menu';
import MenuItem from '../../../shared/components/Menu/MenuItem';
import {
  AssetInspectionParamsInterface,
} from '../../../shared/models/query-params-interfaces/asset-inspection-params.interface';
import AssetInspectionsService from '../../../services/asset-inspections.service';

const exportToCSV = async (queryParams: AssetInspectionParamsInterface, setIsExporting: Function) => {
  setIsExporting(true);

  AssetInspectionsService.getCsvReport(queryParams).then(res => {
    setIsExporting(false);
  });

};

const stringToBoolean = (string: string): boolean => {
  return string === 'true';
};

const booleanToString = (boolean: boolean): string => {
  if (boolean) {
    return 'true';
  }

  return 'false';
};

type Props = {
  mapOpened: boolean;
  onMapToggled: any;
  onFilterChangedHandler: Function;
  queryParams: AssetInspectionParamsInterface;
  csvDisabled: boolean;
  className?: string;
}

const AssetsFilterCard = (props: Props) => {

  const [isExporting, setIsExporting] = useState(false);

  const [searchOpened, setSearchOpened] = useState(true);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const paramCondition = props.queryParams.condition ? props.queryParams.condition.split(',') : [];
  //const selectedConditions = paramCondition.filter((paramsItem) => CONDITIONS_LIST.find((listItem) => listItem.value == paramsItem));

  const [conditionFound, setConditionFound] = useState(stringToBoolean(props.queryParams['condition-found']));
  const [workOrderCreated, setWorkOrderCreated] = useState(stringToBoolean(props.queryParams['escalation-created']));

  const handleSearchToggled = () => {
    setSearchOpened(prevState => !prevState);
  };

  const updateFilterParam = (params: { name: string, value: string }[]): void => {
    const paramsModel = {
      ...props.queryParams,
      page: '1',
    } as AssetInspectionParamsInterface;
    params.forEach(param => paramsModel[param.name] = param.value);
    props.onFilterChangedHandler(paramsModel);
  };

  const searchStringChangeHandler = (searchString: string) => {
    const params = [
      {name: 'search', value: searchString},
    ];

    updateFilterParam(params);
  };


  const specificConditionChangedHandler = (values: string[]) => {
    const params = [
      {name: 'condition', value: values.toString()},
    ];

    updateFilterParam(params);
  };

  const startDateChangedHandler = (date: Date) => {
    const params = [
      {name: 'start-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const endDateChangedHandler = (date: string) => {
    const params = [
      {name: 'end-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const conditionFoundChangedHandler = () => {
    const params = [
      {name: 'condition-found', value: booleanToString(!conditionFound)},
    ];

    updateFilterParam(params);
    setConditionFound(!conditionFound);
  };

  const workOrderCreatedChangeHandler = () => {
    const params = [
      {name: 'escalation-created', value: booleanToString(!workOrderCreated)},
    ];

    updateFilterParam(params);
    setWorkOrderCreated(!workOrderCreated);
  };

  const openSearchButton =
    <ToggleButton
      onClick={handleSearchToggled}
      isActive={searchOpened}
      className={'p-8'}
      hoverText={searchOpened ? 'Show Filter' : 'Show Search Bar'}
    >
      {searchOpened ?
        /*@ts-ignore*/
        <FuseSvgIcon size={24}>heroicons-outline:filter</FuseSvgIcon> : <SearchIcon />
      }
    </ToggleButton>;

  const openMapButton =
    <ToggleButton
      onClick={props.onMapToggled}
      isActive={props.mapOpened}
      className={'p-8'}
      hoverText={props.mapOpened ? 'Close Map' : 'Open Map'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={24}>heroicons-outline:map</FuseSvgIcon>
    </ToggleButton>;

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const filterMenuButton =
    <ToggleButton
      isActive={menuOpened}
      onClick={showMenu}
      className={'p-8'}
      hoverText={'Open Menu'}>
      {/*@ts-ignore*/}
      <FuseSvgIcon size={24}>heroicons-outline:cog</FuseSvgIcon>
    </ToggleButton>;

  const filterMenu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'optionExport'} onClick={() => exportToCSV(props.queryParams, setIsExporting)}
                  isLoading={isExporting} icon={'heroicons-outline:download'} text={'Export Assets CSV'} />,
      ]}
    />;

  const searchBar = (className: string) => {
    return <motion.div className={'flex-auto w-full my-auto ' + className}
                       initial={{opacity: 0, y: -40}}
                       animate={{opacity: 1, y: 0, transition: {delay: 0.15}}}>
      <Search placeholder={'Search Assets'} value={props.queryParams.search}
              onChange={searchStringChangeHandler}
              className={'w-full'} />
    </motion.div>;
  };

  return (
    <Card
      className={`shadow rounded-2xl ${searchOpened ? 'h-72' : 'lg:h-72'} overflow-hidden mb-12 mt-2 p-12 gap-12 flex justify-between items-center ${props.className}`}>
      <div className={`gap-16 p-6 flex flex-auto items-center ${!searchOpened && 'flex-col lg:flex-row'}`}>
        <div className={'hidden lg:flex'}>
          {openSearchButton}
        </div>
        {
          searchOpened &&
          searchBar('hidden lg:flex')
        }
        {searchBar(`lg:hidden`)}
        {
          !searchOpened &&
          <motion.div className={`flex flex-col lg:flex-row gap-x-10 gap-y-16 justify-between w-full`}
                      initial={{opacity: 0, y: 40}}
                      animate={{opacity: 1, y: 0, transition: {delay: 0.15}}}>
            <div
              className={`w-full grid grid-cols-2 lg:flex lg:flex-row lg:mr-5 justify-start items-center gap-x-8 gap-y-16`}>
              {/*<DatePicker*/}
              {/*  className={`col-span-2 sm:col-span-1 lg:w-144`}*/}
              {/*  label='Start Date'*/}
              {/*  value={props.queryParams['start-date']}*/}
              {/*  onChange={startDateChangedHandler}*/}
              {/*/>*/}
              {/*<DatePicker*/}
              {/*  className={`col-span-2 sm:col-span-1 lg:w-144`}*/}
              {/*  label='End Date'*/}
              {/*  value={props.queryParams['end-date']}*/}
              {/*  onChange={endDateChangedHandler}*/}
              {/*/>*/}
              {/*<div className={`col-span-2 sm:col-span-1 lg:w-200`}>*/}
              {/*  <MultiSelect*/}
              {/*    id={'condition-select'}*/}
              {/*    className={'w-full'}*/}
              {/*    label='With Condition'*/}
              {/*    onChange={specificConditionChangedHandler}*/}
              {/*    values={selectedConditions}*/}
              {/*    items={CONDITIONS_LIST.map((condition) => ({*/}
              {/*      key: condition.value,*/}
              {/*      value: condition.value,*/}
              {/*      label: condition.value,*/}
              {/*    }))}*/}
              {/*  />*/}
              {/*</div>*/}
            </div>
            {/*<div className={`col-span-2 flex justify-end gap-x-8`}>*/}
            {/*  <FormGroup>*/}
            {/*    <FormControlLabel*/}
            {/*      checked={conditionFound}*/}
            {/*      className={'whitespace-nowrap text-10 m-auto mr-1'}*/}
            {/*      onChange={conditionFoundChangedHandler}*/}
            {/*      control={<Switch color='primary' size='small' />}*/}
            {/*      label='With Condition'*/}
            {/*      labelPlacement='start'*/}
            {/*    />*/}
            {/*    <FormControlLabel*/}
            {/*      checked={workOrderCreated}*/}
            {/*      className={'whitespace-nowrap text-10 m-auto mr-1'}*/}
            {/*      onChange={workOrderCreatedChangeHandler}*/}
            {/*      control={<Switch color='primary' size='small' />}*/}
            {/*      label='Escalation Created'*/}
            {/*      labelPlacement='start'*/}
            {/*    />*/}
            {/*  </FormGroup>*/}
            {/*  <div className={`flex w-auto lg:hidden items-center justify-end gap-x-8`}>*/}
            {/*          <span className={`h-48 w-1 mr-7 bg-gray-500 rounded`}*/}
            {/*                style={{transition: 'height 0.1s ease-out'}} />*/}
            {/*    {filterMenuButton}*/}
            {/*    {filterMenu}*/}
            {/*  </div>*/}
            {/*</div>*/}
          </motion.div>
        }
        {/*<div className={`${searchOpened ? 'h-40 flex' : 'hidden lg:flex h-48'} gap-8 items-center justify-end`}>*/}

        {/*  <span className={`${searchOpened ? 'h-40' : 'h-48'} w-1 mr-7 bg-gray-500 rounded`}*/}
        {/*        style={{transition: 'height 0.1s ease-out'}} />*/}

        {/*  <div>*/}
        {/*    {filterMenuButton}*/}
        {/*    {filterMenu}*/}
        {/*  </div>*/}

        {/*  <div className='hidden lg:block'>*/}
        {/*    {openMapButton}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>

    </Card>
  );
};

export default AssetsFilterCard;
