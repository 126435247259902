import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WorkOrderModal from './WorkOrderModal';
import Button from '../../../shared/components/Button/Button';
import { AssetInspectionModel } from '../../../shared/models/base-models/asset-inspection.model';
import ConditionSkeleton from './ConditionSkeleton';
import { ASSET_INSPECTION_STATUS } from '../../../shared/globals';
import Chip from '../../../shared/components/Chip/Chip';
import { InspectionHelper } from '../../../shared/helpers/inspection.helper';
import { FieldChangeModel } from '../../../shared/models/field-change.model';
import { useNavigate } from 'react-router-dom';
import { WorkOrderModel } from '../../../shared/models/base-models/work-order.model';
import { AssetInspectionModelMapper } from '../../../shared/model-mappers/asset-inspection-model.mapper';
import { AssetInspectionViewModelFactory } from '../../../shared/view-model-factories/asset-inspection-view-model.factory';
import { AssetInspectionViewModel } from '../../../shared/models/base-view-models/asset-inspection-view.model';

type Props = {
  assetInspection: any;
  isLoading: boolean;
  className?: string;
  setAssetInspection?: any;
  setAssetInspectionView?: any;
  assetInspectionClosed?: boolean;
  onModalOpenHandler?: Function;
  onModalClosedHandler?: Function;
}

const WorkOrderDetailsCard = (props: Props) => {

  const navigate = useNavigate();

  const [correctiveActions, setCorrectiveActions] = useState([]);

  const [expanded, setExpanded] = useState<string | false>(false);
  const [openModal, setOpenModal] = useState(false);
  const [workOrder, setWorkOrder] = useState(new WorkOrderModel({}));

  useEffect(() => {
    setCorrectiveActions(props.assetInspection.correctiveActions);
  }, [props.assetInspection]);

  const openModalHandler = (condition: string) => {
    const workOrderData = {
      type: props.assetInspection.type,
      assetInspectionId: props.assetInspection.id,
      notes: props.assetInspection.comment,
      condition,
      attachments: props.assetInspection.attachments,
    };

    if (props.assetInspection.inspection['routeInfo']) {
      workOrderData['routeInfo'] = props.assetInspection.inspection['routeInfo'];
    }

    if (props.assetInspection.inspection['inspectionId']) {
      workOrderData['inspectionId'] = props.assetInspection.inspection['inspectionId'];
    }

    setWorkOrder(new WorkOrderModel(workOrderData));

    setOpenModal(true);
    !!props.onModalOpenHandler && props.onModalOpenHandler();
  };

  const workOrderSavedHandler = (workOrder: WorkOrderModel) => {
    const assetInspection = new AssetInspectionModel({...props.assetInspection});

    assetInspection.workOrders.push(workOrder);

    let changeHistoryStatusChanged = undefined;

    if (assetInspection.status !== ASSET_INSPECTION_STATUS.qaFailed && assetInspection.status !== ASSET_INSPECTION_STATUS.qaPassed) {
      //TODO: change later
      if (assetInspection.conditions.length === assetInspection.workOrders.length) {
        changeHistoryStatusChanged = new FieldChangeModel(
          {
            changedBy: undefined,
            field: 'status',
            oldValue: assetInspection.status,
            newValue: 'escalations-created',
            changedAt: workOrder.createdAt,
          });

        assetInspection.status = 'escalations-created';
      }
    }

    const changeHistoryWorkOrderAdded = new FieldChangeModel(
      {
        changedBy: workOrder.createdBy,
        field: 'workOrders',
        oldValue: [],
        newValue: [workOrder.id],
        changedAt: workOrder.createdAt,
      });

    if (!assetInspection.changeHistory) {
      assetInspection.changeHistory = [];
    }

    assetInspection.changeHistory.push(changeHistoryWorkOrderAdded);

    if (!!changeHistoryStatusChanged) {
      assetInspection.changeHistory.push(changeHistoryStatusChanged);
    }

    props.setAssetInspection(new AssetInspectionModelMapper().mapModel<AssetInspectionModel>(assetInspection));
    props.setAssetInspectionView(new AssetInspectionViewModelFactory().createViewModel<AssetInspectionViewModel, AssetInspectionModel>(assetInspection));
  };

  const openAccordionOrModal = (condition: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    condition.workOrder ? setExpanded(isExpanded ? condition.name : false) : openModalHandler(condition.name);
  };

  const onModalCloseHandler = () => {
    setOpenModal(false);
    !!props.onModalClosedHandler && props.onModalClosedHandler();
  };

  return (
    <>
      <Card className={`shadow overflow-hidden rounded-md px-24 pb-24 pt-16 ${props.className}`}>
        <Typography className='text-xl font-bold mb-20'>Corrective Action Details</Typography>
        {
          !!props.isLoading &&
          <div className={`border border-gray-400 rounded-md flex flex-col`}>
            <ConditionSkeleton className={'border-b border-gray-400'} />
            <ConditionSkeleton className={'border-b border-gray-400'} />
            <ConditionSkeleton />
          </div>
        }
        {
          !props.isLoading && correctiveActions && correctiveActions.length === 0 &&
          <p className={'text-14'}>N/A - No Conditions Found</p>
        }
        {
          !props.isLoading && correctiveActions && correctiveActions.length > 0 &&
          <div className={'hidden sm:flex sm:flex-col border border-gray-400 rounded-md'}>
            {correctiveActions.map((condition, index) =>
              <div
                className={`${index !== correctiveActions.length - 1 ? 'border-b' : ''} border-gray-400 flex justify-between gap-6 py-12 px-20 align-middle ${!condition.workOrder && 'justify-end'}`}
                key={condition.name}
                onClick={() => openAccordionOrModal(condition)}>
                <div className={`w-full gap-12 flex align-middle`}>
                  {condition.workOrder ?
                    <div className={`w-full m-auto`}>
                          <span className={`font-semibold secondary block flex gap-x-6`}>
                            {condition.name}
                            {condition.workOrder &&
                              <Chip className='my-auto font-semibold text-white py-2 text-10'
                                    bgColor={InspectionHelper.getWorkOrderStatusColor(condition.workOrder.status)}
                                    label={InspectionHelper.getWorkOrderStatusValue(condition.workOrder.status)}
                              />
                            }
                            {condition.workOrder && condition.workOrder.code}
                            {condition.workOrder && condition.workOrder.externalSystemWorkOrderId && ` (${condition.workOrder.externalSystemWorkOrderId})`}
                          </span>
                    </div> :
                    <div className={`w-full m-auto`}>
                          <span className={`font-semibold secondary block `}>
                            {condition.name}
                          </span>
                    </div>
                  }
                </div>
                <div className={'flex items-center justify-end'}>
                  {condition.workOrder ?
                    <Button
                      onClick={() =>
                        navigate(`/escalation/${condition.workOrder.id}`)
                    }
                      text={'More Details'}
                      color={'primary'}
                      className={'whitespace-nowrap text-10'}
                      disabled={false}
                      hoverText={'Open Escalation Page'}
                    /> :
                    <Button
                      onClick={() => openModalHandler(condition.name)}
                      text={'Create Escalation'}
                      color={'primary'}
                      className={'whitespace-nowrap text-10'}
                      disabled={props.assetInspectionClosed}
                      hoverText={''}
                    />
                  }
                </div>
              </div>,
            )}
          </div>
        }
        {
          !props.isLoading && correctiveActions && correctiveActions.map((condition) =>
            <Accordion style={{boxShadow: 'none'}} className={'sm:hidden border border-gray-400 '}
                       key={condition.name}
                       expanded={condition.name === expanded}
                       onChange={openAccordionOrModal(condition)}>
              <AccordionSummary className={'pl-28'}
                                expandIcon={condition.workOrder ? <ExpandMoreIcon /> :
                                  <div className={'flex'}>
                                    <Button
                                      onClick={() => openModalHandler(condition.name)}
                                      text={'Create Escalation'}
                                      color={'primary'}
                                      className={'whitespace-nowrap text-10'}
                                      disabled={props.assetInspectionClosed}
                                      hoverText={''}
                                    />
                                  </div>
                                }
                                id={condition.name}
              >
                <div className={`w-full m-auto`}>
                  <div className={'flex items-center gap-x-6 flex-row align-middle'}>
                    <label className={`font-semibold block cursor-pointer`}>
                      {condition.name}
                    </label>
                    {
                      condition.workOrder &&
                      <Chip className='my-auto mt-0 font-semibold text-white py-2'
                            bgColor={InspectionHelper.getWorkOrderStatusColor(condition.workOrder.status)}
                            label={InspectionHelper.getWorkOrderStatusValue(condition.workOrder.status)}
                      />
                    }
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails className={'pl-28 pt-0'}>
                <div
                  className={`flex flex-col gap-8 ${!condition.workOrder && 'justify-end'}`}>
                  {condition.workOrder && condition.workOrder.workOrderAccordionInfo && condition.workOrder.workOrderAccordionInfo.map((item) =>
                    <div key={item.label} className={`w-full m-auto`}>
                      <label className={`font-light block whitespace-nowrap text-11`}>
                        {item.label}
                      </label>
                      <span className={`font-semibold secondary block whitespace-nowrap text-12`}>
                        {item.value}
                     </span>
                    </div>,
                  )}
                  <div className={'flex items-center justify-end'}>
                    <Button
                      onClick={() =>
                       navigate(`/escalation/${condition.workOrder.id}`)
                      }
                      text={'More details'}
                      color={'primary'}
                      className={'whitespace-nowrap text-10'}
                      disabled={false}
                      hoverText={'Open Escalation Page'}
                    />
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>,
          )
        }
      </Card>
      {openModal &&
        <WorkOrderModal onClose={onModalCloseHandler} workOrder={workOrder} onSave={workOrderSavedHandler}/>
      }
    </>
  );
};

export default WorkOrderDetailsCard;
