import { BaseModel } from '../../../models/base.model';

export class AddressModel extends BaseModel {
  city: string;
  county: string;
  streetAddress: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

  get addressString(): string {
    return `${this.returnValue(this.streetAddress)}, ${this.returnValue(this.city)}, ${this.returnValue(this.county)}`;
  }

}
