import { DEPARTMENT, DEPARTMENTS } from '../globals';

export class DepartmentHelper {
  static getDepartmentValue(department: string): string {
    if(this.isAllDepartments(department)) {
      return;
    }

    const departmentObj = DEPARTMENTS.find(departmentObj => departmentObj.key === department);

    if (!departmentObj) {
      return;
    }

    return departmentObj.value;
  }

  static isAllDepartments(department: string): boolean {
    return DEPARTMENT.all === department;
  }
}
