import {styled} from '@mui/material/styles';
import {useTranslation} from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import DashboardContent from './DashboardContent';
import {useContext} from 'react';
import DepartmentContext from '../../DepartmentContext';
import {DepartmentHelper} from '../../shared/helpers/department.helper';
import AllDashboardContent from './AllDashboardContent';

const Root = styled(FusePageSimple)(({theme}) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
    },
    '& .FusePageSimple-toolbar': {},
    '& .FusePageSimple-content': {},
    '& .FusePageSimple-sidebarHeader': {},
    '& .FusePageSimple-sidebarContent': {},
}));

const DashboardPage = () => {
    const {t} = useTranslation('dashboardPage');
    const {department} = useContext(DepartmentContext);

    return (
        <Root
            content={
                <div className="w-full h-full overflow-hidden">
                    {DepartmentHelper.isAllDepartments(department) ?
                        <AllDashboardContent /> :
                        <DashboardContent department={department}/>
                    }
                </div>
            }
            scroll="content"
        />
    );
}

export default DashboardPage;
