import React from 'react';
import Tooltip from '@mui/material/Tooltip';

type Props = {
  condensed?: boolean;
  status: string;
  statusObjects: any[];
}

const StatusStrip = (props: Props) => {

  const statusObject = props.statusObjects.find(statusObject => statusObject.key === props.status);

  return (
    <Tooltip followCursor={true} title={statusObject ? statusObject.value : ''} placement="right">
      <div className={`${statusObject && statusObject.bgColor} ${props.condensed ? 'min-w-12 w-12' : 'min-w-12 w-20'}`}/>
    </Tooltip>
  );
}

export default StatusStrip;
