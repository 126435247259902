import { BaseModel } from './base.model';

export class PaginationModel extends BaseModel {
  page: number = 0;
  pages: number = 0;
  perPage: number = 0;
  total: number = 0;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }
}
