import React, { useEffect, useState } from 'react';
import useEffectOnce from '../../hooks/useEffectOnce';
import ImagesService from '../../../services/images.service';
import ImagesViewer from '../ImagesViewer';
import MasonryImageList from '../MasonryImageList/MasonryImageList';
import { ImageHelper } from '../../helpers/imageHelper';
import { Skeleton } from '@mui/material';
import { ViewerImageModel } from '../../models/viewer-image.model';

type Props = {
  userName: string;
  action: string;
  ids: string;
  fieldName: string;
}

const ImagesComponent = (props: Props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [fieldImages, setFieldImages] = useState([]);
  const [images, setImages] = useState([] as ViewerImageModel[]);

  const [viewerOpened, setViewerOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffectOnce(() => {
    setIsLoading(true);
    ImagesService.getImages({ids: props.ids})
      .then((results) => {
        setFieldImages(results);
        setIsLoading(false);
      });
  }, [props.ids]);

  const onImageLoaded = (images: ViewerImageModel[]) => {
    setImages(images.map(image => new ViewerImageModel(image)));
  };

  useEffect(() => {
    if (!fieldImages) {
      return;
    }

    const viewerImages = fieldImages.map(image => new ViewerImageModel(image));
    ImageHelper.loadThumbnailsForImageViewer(viewerImages, onImageLoaded);
    setImages(viewerImages);
  }, [fieldImages]);

  const imageClickHandler = (index: number) => {
    setSelectedImage(index);
    setViewerOpened(true);
  };

  const viewerClosedHandler = () => {
    setViewerOpened(false);
  };

  return <>
    <ImagesViewer images={images} opened={viewerOpened} onViewerClose={viewerClosedHandler}
                  selectedImageIndex={selectedImage} />
    <div className={'flex flex-col items-start mb-5'}>
      <div className={'flex mb-8'}>
        <label className={`font-bold text-12 whitespace-nowrap mr-3`}>{props.userName}</label>
        <label className={`text-12 whitespace-nowrap mr-3`}>{`${props.action}`}</label>
        <label className={`font-bold text-12 whitespace-nowrap mr-3`}>{props.fieldName}</label>
      </div>
      {
        isLoading &&
        <ul className={`w-full overflow-x-auto flex flex-wrap gap-8 rounded`}>
          {props.ids && props.ids.split(',').map(item =>
            <Skeleton variant='rectangular' animation='wave' key={item}
                      className={`w-48 h-48 `} />,
          )}
        </ul>
      }
      {
        !!images && !!images.length &&
        <MasonryImageList
          images={images}
          className='flex flex-wrap gap-8 rounded'
          imageClassName='w-48 h-48 cursor-pointer'
          onImageClick={imageClickHandler}
        />
      }
    </div>
  </>;
};

export default ImagesComponent;