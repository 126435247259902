import React from 'react';
import Chip from '../../Chip/Chip';
import { InspectionStatusType } from '../../../globals';

type Props = {
  statusObjects: InspectionStatusType[];
  status: string;
  condensed: boolean;
  clickable: boolean;
}

const StatusCardElement = (props: Props) => {

  const statusObject = props.statusObjects.find(statusObject => statusObject.key === props.status);

  return (
    <div className={`w-full m-auto`}>
      <label
        className={`${props.clickable && 'cursor-pointer'} font-light whitespace-nowrap block ${props.condensed ? 'text-10' : 'text-11'}`}>
        Status
      </label>
      <div className={'w-full overflow-hidden'}>
        <Chip className={'my-auto font-medium text-white'}
              bgColor={statusObject ? statusObject.bgColor : ''}
              label={statusObject ? statusObject.value : ''}
        />
      </div>
    </div>
  );
}

export default StatusCardElement;
