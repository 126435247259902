// @flow
import Auth from '@aws-amplify/auth';
import { CognitoUserInterface } from '@aws-amplify/ui-components';
import { CognitoUserModel } from '../shared/models/cognito-user.model';

const getCognitoUser = async (): Promise<CognitoUserInterface> => {
  return await Auth.currentAuthenticatedUser();
};

const getUser = async (): Promise<CognitoUserModel | null> => {
  const user: CognitoUserInterface = await getCognitoUser();

  if (!user) {
    return null;
  }

  return {
    name: user.signInUserSession.idToken.payload.name,
    email: user.signInUserSession.idToken.payload.email,
    groups: user.signInUserSession.idToken.payload['cognito:groups'],
    role: user.signInUserSession.idToken.payload['custom:role'],
    sessionKey: user.signInUserSession.accessToken.payload.sub,
    lanId: user.signInUserSession.idToken.payload['custom:lan_id'],
  };
};

const getXCogToken = (): string => {
  const lastAuthUser = localStorage.getItem('CognitoIdentityServiceProvider.6qrp0rbkuhpcp73cmhu1ont3ip.LastAuthUser');
  const token = localStorage.getItem(`CognitoIdentityServiceProvider.6qrp0rbkuhpcp73cmhu1ont3ip.${lastAuthUser}.idToken`);
  return token ? token : '';
};

const CognitoUserService = {
  getCognitoUser,
  getUser,
  getXCogToken,
};

export default CognitoUserService;
