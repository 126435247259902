import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import FusePageSimple from '@fuse/core/FusePageSimple';
import InspectionsContent from "./InspectionsContent";
import React from 'react';

const Root = styled(FusePageSimple)(({theme}) => ({
    '& .FusePageSimple-header': {
        backgroundColor: theme.palette.background.paper,
        borderBottomWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.palette.divider,
    },
    '& .FusePageSimple-toolbar': {},
    '& .FusePageSimple-content': {},
    '& .FusePageSimple-sidebarHeader': {},
    '& .FusePageSimple-sidebarContent': {},
}));

const Inspections = () => {
    const {t} = useTranslation('inspections');

    return (
        <Root
            content={
                <div className="w-full  h-full overflow-hidden">
                    <InspectionsContent />
                </div>
            }
            scroll="content"
        />
    );
}

export default Inspections;
