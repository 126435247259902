import { BaseModel } from './base.model';
import { LocationModel } from './location.model';

export class MapPointModel extends BaseModel {
  id: string;
  location: LocationModel;
  color: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

  get childModels() {
    return {
      location: LocationModel,
    };
  }
}
