import { FileModel } from '../../models/file.model';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import { AttachmentHelper } from '../../helpers/attachment.helper';
import React, { useState } from 'react';

type Props = {
  file: FileModel;
  itemIcon?: JSX.Element | Function;
  onItemClick?: Function;
  onItemIconButtonClick?: Function;
  index: number;
  actionsAlwaysVisible?: boolean;
}

const AttachmentsListItem = ({
                               file,
                               itemIcon,
                               onItemClick,
                               onItemIconButtonClick,
                               index,
                               actionsAlwaysVisible,
                             }: Props) => {

  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const areActionsVisible = () => {
    return isHovered || isLoading || actionsAlwaysVisible;
  };

  const handleItemClicked = async (index: number) => {
    if (!onItemClick && !isLoading) {
      return;
    }

    setIsLoading(true);
    await onItemClick(file, index);
    setIsLoading(false);
  };

  const handleIconButtonClicked = async (event, index: number) => {
    if (!onItemIconButtonClick && isLoading) {
      return;
    }

    event.stopPropagation();

    setIsLoading(true);
    await onItemIconButtonClick(file, index);
    setIsLoading(false);
  };

  const getFileTooltip = (file: FileModel): string => {
    const fileKBSize = (file.size / 1024);

    const fileSize = (fileKBSize >= 1024) ?
      ((fileKBSize / 1024).toFixed(1) + ' MB') : (fileKBSize.toFixed(1) + ' kB');

    return file.originalName + ' (' + fileSize + ')';
  };


  return (
    <Tooltip placement='top-start' className={`my-auto`}
             followCursor={true} title={`${getFileTooltip(file)}`}>
      <FormControl variant='standard' key={file.name}
                   size={'small'} onClick={() => handleItemClicked(index)}
                   onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
        <OutlinedInput
          sx={{
            '&.Mui-focused': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid rgb(189 189 189)',
              },
            },
            '&.Mui-focused:hover': {
              '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid black',
              },
            },
          }}
          disabled={isLoading}
          type={'text'}
          classes={{
            input: 'whitespace-nowrap overflow-hidden truncate pointer-events-none',
          }}
          style={{
            userSelect: 'none',
            cursor: isLoading ? 'default' : 'pointer',
          }}
          value={file.originalName}
          startAdornment={
            <InputAdornment position='start' sx={{
              '&:hover': {
                cursor: isLoading ? 'default' : 'pointer',
              },
            }}>
              {AttachmentHelper.getAttachmentIcon(file.name)}
            </InputAdornment>
          }
          endAdornment={
            areActionsVisible() ?
              <InputAdornment position='end'>
                <IconButton
                  sx={{
                    '&:hover': {
                      backgroundColor: isLoading ? 'transparent' : 'default',
                      cursor: isLoading ? 'default' : 'pointer',
                    },
                  }}
                  onClick={(event) => handleIconButtonClicked(event, index)}
                  edge='end'
                >
                  {
                    isLoading ?
                      <CircularProgress className={'mr-4'} color={'inherit'} size={15} thickness={4} /> :
                      typeof itemIcon === 'function' ? itemIcon(file, index) : itemIcon}
                </IconButton>
              </InputAdornment> :
              <></>
          }
        />
      </FormControl>
    </Tooltip>
  );
};

export default AttachmentsListItem;