import React, { useContext } from 'react';
import EsriMapsContextLoader, { MapsLibraryContext } from './components/EsriMapsContextLoader';

type Props = {
  children?: JSX.Element[]
}

const Map = (props: Props) => {

  return (
    <EsriMapsContextLoader>
      {props.children}
    </EsriMapsContextLoader>
  );
};

export default Map;
