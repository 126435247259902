import { RegistryWidgetsType } from '@rjsf/utils';
import TextWidget from './widgets/text.widget';
import TextareaWidget from './widgets/textarea.widget';
import DateWidget from './widgets/date.widget';
import TimeWidget from './widgets/time.widget';
import SelectWidget from './widgets/select.widget';
import MultiSelectListWidget from './widgets/multi-select-list.widget';
import UserMultiSelectWidget from './widgets/user-multi-select.widget';
import UserSelectWidget from './widgets/user-select.widget';
import UserExternalIdSelectWidget from './widgets/user-external-id-select.widget';
import CreatableUserSelectWidget from './widgets/creatable-user-select.widget';
import DependencyInputWidget from './widgets/dependency-input.widget';
import DependencyTextInputWidget from './widgets/dependency-text-input.widget';
import DependencyDateWidget from './widgets/dependency-date.widget';
import DependencyMultiSelectWidget from './widgets/dependency-multi-select.widget';
import CheckboxesWidget from './widgets/checkboxes.widget';
import MultiSelectChipsWidget from './widgets/multi-select-chip.widget';
import RemedyWOSelectWidget from './widgets/remedy-wo-select.widget';
import SAPWOSelectWidget from './widgets/sap-wo-select.widget';
import MultiSelectWidget from './widgets/multi-select.widget';
import UserExternalIdMultiSelectWidget from './widgets/user-external-id-multi-select.widget';
import NumberWidget from './widgets/number.widget';
import DependencyOtherValueWidget from './widgets/dependency-other-value.widget';
import DependencyMultipleInputWidget from './widgets/dependency-multiple-input.widget';

const DefaultWidgets: RegistryWidgetsType = {
  TextWidget,
  TextareaWidget,
  DateWidget,
  TimeWidget,
  SelectWidget,
  MultiSelectListWidget,
  UserSelectWidget,
  UserExternalIdSelectWidget,
  CreatableUserSelectWidget,
  CheckboxesWidget,
  UserMultiSelectWidget,
  DependencyInputWidget,
  DependencyTextInputWidget,
  DependencyDateWidget,
  DependencyMultiSelectWidget,
  DependencyMultipleInputWidget,
  MultiSelectChipsWidget,
  RemedyWOSelectWidget,
  SAPWOSelectWidget,
  MultiSelectWidget,
  UserExternalIdMultiSelectWidget,
  NumberWidget,
  DependencyOtherValueWidget,
};

export default DefaultWidgets;
