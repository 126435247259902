import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { AssetViewModel } from '../../../models/base-view-models/asset-view.model';
import { GeneratorsAssetModel } from '../models/generators-asset.model';

export class GeneratorsAssetViewModel extends AssetViewModel {

  private asset: GeneratorsAssetModel;

  constructor(asset: GeneratorsAssetModel) {
    super(asset);
    this.asset = asset;
  }

  get componentHeaderTitle(): string {
    return `Equipment Number`;
  }

  get componentHeader(): string {
    return `${this.returnValue(this.asset.equipmentNumber)}`;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [
      this.getInfoCardField('Equipment Number', 'equipmentNumber'),
      this.getInfoCardField('Size', 'size'),
      this.getInfoCardField('Capacity Units', 'capacityUnits'),
      this.getInfoCardField('Tier Rating', 'tierRating'),
      this.getInfoCardField('Equipment Life', 'equipmentLife'),
      this.getInfoCardField('End Of Life', 'endOfLife'),
      this.getInfoCardField('Load', 'loadValue'),
      this.getInfoCardField('Phase', 'phase'),
      this.getInfoCardField('Condition Rating', 'conditionRating'),
      {
        label: 'Genset Make',
        value: this.asset.genset ? this.returnValue(this.asset.genset.make) : 'N/A',
        oldValue: this.comparingAssetGenset('make')
      },
      {
        label: 'Genset Model',
        value: this.asset.genset ? this.returnValue(this.asset.genset.model) : 'N/A',
        oldValue: this.comparingAssetGenset('model')
      },
      {
        label: 'Genset Serial #',
        value: this.asset.genset ? this.returnValue(this.asset.genset.serialNumber) : 'N/A',
        oldValue: this.comparingAssetGenset('serialNumber')
      },
      {
        label: 'Genset Special #',
        value: this.asset.genset ? this.returnValue(this.asset.genset.specNumber) : 'N/A',
        oldValue: this.comparingAssetGenset('specNumber')
      },
      {
        label: 'Engine Make',
        value: this.asset.engineDetails ? this.returnValue(this.asset.engineDetails.make) : 'N/A',
        oldValue: this.comparingAssetEngineDetails('make')
      },
      {
        label: 'Engine Model',
        value: this.asset.engineDetails ? this.returnValue(this.asset.engineDetails.model) : 'N/A',
        oldValue: this.comparingAssetEngineDetails('model')
      },
      {
        label: 'Engine Serial #',
        value: this.asset.engineDetails ? this.returnValue(this.asset.engineDetails.serialNumber) : 'N/A',
        oldValue: this.comparingAssetEngineDetails('serialNumber')
      },
      {
        label: 'Engine Special #',
        value: this.asset.engineDetails ? this.returnValue(this.asset.engineDetails.specNumber) : 'N/A',
        oldValue: this.comparingAssetEngineDetails('specNumber')
      },
      {
        label: 'Location',
        value: this.asset.location ? this.asset.location.locationString : 'N/A',
        colSpan: 'sm:col-span-2',
        oldValue: this.comparingAssetLocation()
      },
    ];
  }

  private getInfoCardField(name: string, field: string): InspectionCardItemModel {
    return {
      label: name,
      value: this.returnValue(this.asset[field]),
      oldValue: this.asset.comparingAsset && this.asset.comparingAsset[field] && (this.asset.comparingAsset[field] !== this.asset[field]) && this.returnValue(this.asset.comparingAsset[field])
    };
  };

  private comparingAssetLocation(): string {
    return this.asset.comparingAsset && (this.getComparingAssetLocation() !== (this.asset.location && this.asset.location.locationString)) && this.getComparingAssetLocation();
  };

  private getComparingAssetLocation(): string {
    return this.asset.comparingAsset && this.asset.comparingAsset.location ? `${this.returnValue(this.asset.comparingAsset.location['latitude'])}, ${this.returnValue(this.asset.comparingAsset.location['longitude'])}` : 'N/A';
  };

  get detailCardFields(): InspectionCardItemModel[] {
    return [
      {label: 'Size', value: this.returnValue(this.asset['size'])},
      {label: 'Capacity Units', value: this.returnValue(this.asset['capacityUnits'])},
      {label: 'Tier Rating', value: this.returnValue(this.asset['tierRating'])},
      {label: 'Equipment Life', value: this.returnValue(this.asset['equipmentLife'])},
      {label: 'End Of Life', value: this.returnValue(this.asset['endOfLife'])},
      {label: 'Load', value: this.returnValue(this.asset['loadValue'])},
      {label: 'Phase', value: this.returnValue(this.asset['phase'])},
      {label: 'Condition Rating', value: this.returnValue(this.asset['conditionRating'])},
      {label: 'Genset Make', value: this.returnValue(this.asset.genset && this.asset.genset.make)},
      {label: 'Genset Model', value: this.returnValue(this.asset.genset && this.asset.genset.model)},
      {label: 'Genset Serial #', value: this.returnValue(this.asset.genset && this.asset.genset.serialNumber)},
      {label: 'Genset Special #', value: this.returnValue(this.asset.genset && this.asset.genset.specNumber)},
      {label: 'Engine Make', value: this.returnValue(this.asset.engineDetails && this.asset.engineDetails.make)},
      {label: 'Engine Model', value: this.returnValue(this.asset.engineDetails && this.asset.engineDetails.model)},
      {label: 'Engine Serial #', value: this.returnValue(this.asset.engineDetails && this.asset.engineDetails.serialNumber)},
      {label: 'Engine Special #', value: this.returnValue(this.asset.engineDetails && this.asset.engineDetails.specNumber)},
      {label: 'Location', value: this.asset.location ? this.asset.location.locationString : 'N/A', colSpan: 'sm:col-span-2'},
      {label: 'Notes', value: this.returnValue(this.asset['notes']), colSpan: 'sm:col-span-2'},
    ];
  }

  get listItemFieldsClassName(): string {
    return ``;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {label: 'Capacity Units', value: this.returnValue(this.asset['capacityUnits'])},
      {label: 'Tier Rating', value: this.returnValue(this.asset['tierRating'])},
      {label: 'Equipment Life', value: this.returnValue(this.asset['equipmentLife'])},
    ];
  }

  private comparingAssetGenset(field: string): any {
    return this.asset.comparingAsset && this.asset.comparingAsset['genset'] && this.asset.comparingAsset['genset'][field] && this.asset['genset'] && this.asset['genset'][field] && (this.asset.comparingAsset['genset'][field].toString() !== this.asset['genset'][field].toString()) && this.asset.comparingAsset['genset'][field];
  };

  private comparingAssetEngineDetails(field: string): any {
    return this.asset.comparingAsset && this.asset.comparingAsset['engineDetails'] && this.asset.comparingAsset['engineDetails'][field] && this.asset['engineDetails'] && this.asset['engineDetails'][field] && (this.asset.comparingAsset['engineDetails'][field].toString() !== this.asset['engineDetails'][field].toString()) && this.asset.comparingAsset['engineDetails'][field];
  };

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get buttonText(): string {
    return '';
  }

  get dateTime(): string {
    return this.asset.createdAt ? moment(this.asset.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get linkTo(): string {
    return `/asset/${this.asset.id}`;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'Equipment Number', value: this.returnValue(this.asset.equipmentNumber)};
  }

}
