import React, { useRef, useState } from 'react';
import {
  Card, CircularProgress, IconButton, SvgIcon, Tooltip,
  Typography,
} from '@mui/material';
import PieChart from '../../../shared/components/PieChart/PieChart';
import ConditionsTable from './ConditionsTable';
import { ReactComponent as Download } from '../../../../assets/icons/material_download.svg';
import html2canvas from 'html2canvas';

type Props = {
  conditionsFound?: number;
  data: { condition: string, occurrence: number, workOrdersCreated: number, workOrdersCompleted: number }[];
  queryParams: any;
}

const ConditionsStatsCard = (props: Props) => {

  const [isDownloading, setIsDownloading] = useState(false);
  const chartRef = useRef(null);

  const timestamp = new Date().toISOString();

  const handleExportClick = () => {
    if (chartRef.current) {
      setIsDownloading(true);
      html2canvas(chartRef.current).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = imgData;
        link.download = `conditions-found-${timestamp}.png`;
        link.click();
      }).then(() => setIsDownloading(false));
    }
  };

  return (
    <Card className={'shadow overflow-hidden rounded-2xl px-22 p-16 pb-4 mb-2'}>
      <div className={'flex justify-between'}>
        <Typography className={'text-lg font-medium tracking-tight leading-6 truncate'}>
          Conditions Found
        </Typography>
        <Tooltip title={'Export Chart as PNG'} placement='top-start' followCursor={true}>
          <IconButton className={`ml-3`} onClick={handleExportClick} size={'small'}>
            {
              isDownloading ?
                <CircularProgress color={'inherit'} size={20} thickness={4}/> :
                <SvgIcon fontSize={'small'} className={'bg-white rounded'} color={'inherit'}>
                  <Download/>
                </SvgIcon>
            }
          </IconButton>
        </Tooltip>
      </div>

      <div className={'grid md:grid-cols-5 gap-4 md:mb-10'} ref={chartRef}>

        <ConditionsTable data={props.data} queryParams={props.queryParams} className={'overflow-x-auto md:col-span-2'}
                         conditionsFound={props.conditionsFound}/>

        <div className={'overflow-x-auto md:col-span-3'}>
          <PieChart data={props.data} queryParams={props.queryParams} conditionsFound={props.conditionsFound}/>
        </div>

      </div>
    </Card>
  );
};

export default ConditionsStatsCard;
