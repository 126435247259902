import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const GENERATORS_MASS_WORK_ORDER_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['condition', 'dueDate'],
  properties: {
    condition: {
      type: 'string',
      title: 'Condition',
    },
    externalId: {
      type: 'string',
      title: 'Remedy Work Order #',
    },
    sapExternalId: {
      type: 'string',
      title: 'SAP Work Order #',
    },
    scheduledStartDate: {
      type: 'string',
      title: 'Start Date',
    },
    assignedTo: {
      type: 'string',
      title: 'Assigned To',
    },
    workAssignee: {
      type: 'string',
      title: 'Work Assignee',
    },
    dueDate: {
      type: 'string',
      title: 'Due Date',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const GENERATORS_MASS_WORK_ORDER_FORM_UI_SCHEMA: UiSchema = {
    'ui:classNames':
      'w-full grid sm:grid-cols-2 lg:grid-cols-12 gap-x-12 gap-y-16',
    'ui:inline': true,
    condition: {
      'ui:widget': 'SelectWidget',
      'ui:classNames': 'w-full lg:col-span-4',
    },
    externalId: {
      'ui:placeholder': 'Remedy Work Order #',
      'ui:widget': 'RemedyWOSelectWidget',
      'ui:classNames': 'w-full lg:col-span-4',
    },
    sapExternalId: {
      'ui:placeholder': 'SAP Work Order #',
      'ui:widget': 'SAPWOSelectWidget',
      'ui:classNames': 'w-full lg:col-span-4',
    },
    scheduledStartDate: {
      'ui:placeholder': 'Start Date',
      'ui:widget': 'date',
      'ui:classNames': 'w-full lg:col-span-3',
    },
    assignedTo: {
      'ui:placeholder': 'Assigned To',
      'ui:widget': 'UserMultiSelectWidget',
      'ui:classNames': 'w-full sm:col-span-2 lg:col-span-3',
    },
    workAssignee: {
      'ui:placeholder': 'Work Assignee',
      'ui:widget': 'CreatableUserSelectWidget',
      'ui:classNames': 'w-full lg:col-span-3',
    },
    dueDate: {
      'ui:placeholder': 'Due Date',
      'ui:widget': 'date',
      'ui:classNames': 'w-full lg:col-span-3',
    },
    notes: {
      'ui:placeholder': 'Notes',
      'ui:widget': 'textarea',
      'ui:classNames': 'w-full sm:col-span-2 lg:col-span-12',
    },
    'ui:submitButtonOptions': {
      norender: true,
    },
  };
