export const FIBER_DUE_DATE_STATUS = {
  // planned: 'planned',
  onTime: 'on-time',
  overdue: 'overdue',
  upcoming: 'upcoming',
};

export const FIBER_DUE_DATE_STATUSES = [
  // {
  //   key: DUE_DATE_STATUS.planned,
  //   label: 'Planned',
  //   color: '#9E9E9E',
  // },
  {
    key: FIBER_DUE_DATE_STATUS.onTime,
    label: 'On Time',
    value: 'On Time',
    bgColor: 'bg-green-700',
    color: '#388E3C',
  },
  {
    key: FIBER_DUE_DATE_STATUS.overdue,
    label: 'Overdue',
    value: 'Overdue',
    bgColor: 'bg-red-700',
    color: '#D32F2F',
  },
  {
    key: FIBER_DUE_DATE_STATUS.upcoming,
    label: 'To be completed',
    value: 'To be completed',
    bgColor: 'bg-gray-400',
    color: '#BDBDBD',
  },
];