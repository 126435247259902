import { BaseModel } from '../base.model';
import { CommentModel } from '../comment.model';
import { FieldChangeModel } from '../field-change.model';

export class InspectionModel extends BaseModel {

  // TODO: Temp fix, remove property assetInspections later
  assetInspections?: any[];
  status?: string;
  type?: string;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}