import React from 'react';
import InspectionCard from '../InspectionCard/InspectionCard';
import Chip from '../Chip/Chip';
import CardHeading from '../CardHeading/CardHeading';

type Props = {
  asset: any;
  mapOpened: boolean;
}

const AssetCard = (props: Props) => {

  return (
    <InspectionCard
      linkTo={props.asset.linkTo}
      statusAreaElements={[

      ]}
      className={`${props.mapOpened ? '' : 'md:flex-row'}`}
      contentElements={props.asset.listItemFields}
      headingAreaElements={[
        <CardHeading
          key={'assetHeading'}
          condensed={false}
          heading={props.asset.listItemHeading}
        />
      ]}
      contentElementClassName={`w-full grid sm:grid-cols-5 gap-6 align-middle`}
      infoAreaChildren={[
        <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={props.asset.dateTime}
        />
      ]}
      actionAreaChildren={[]}
      smallView={props.mapOpened}
      headingSmallView={true}
    />
  );
}

export default AssetCard;
