import { StatusType } from '../../types';

export const TELECOM_TOWERS_ASSET_INSPECTION_STATUS = {
  noTroubleFound: 'no-trouble-found',
  conditionsFound: 'conditions-found',
  workOrderCreated: 'escalations-created',
  qaPassed: 'qa-passed',
  qaFailed: 'qa-failed',
  closed: 'closed',
};

export const TELECOM_TOWERS_ASSET_INSPECTION_STATUSES: StatusType[] = [
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.noTroubleFound,
    value: 'No Trouble Found',
    color: 'gray-400',
    bgColor: 'bg-gray-400',
    bgColorRGB: [189, 189, 189],
    buttonBgColor: '#BDBDBD',
  },
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.conditionsFound,
    value: 'Conditions Found',
    color: 'red-500',
    bgColor: 'bg-red-500',
    bgColorRGB: [244, 67, 54],
    buttonBgColor: '#F44336',
  },
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.workOrderCreated,
    value: 'Escalations Created',
    color: 'orange-600',
    bgColor: 'bg-orange-600',
    bgColorRGB: [251, 140, 0],
    buttonBgColor: '#FB8C00',
  },
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.qaPassed,
    value: 'QA/QC Passed',
    color: 'green-400',
    bgColor: 'bg-green-400',
    buttonBgColor: '#66BB6A',
    bgColorRGB: [102, 187, 106],
  },
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.qaFailed,
    value: 'QA/QC Failed',
    color: 'red-700',
    bgColor: 'bg-red-700',
    bgColorRGB: [211, 47, 47],
    buttonBgColor: '#D32F2F',
  },
  {
    key: TELECOM_TOWERS_ASSET_INSPECTION_STATUS.closed,
    value: 'Closed',
    color: 'green-600',
    bgColor: 'bg-green-600',
    buttonBgColor: '#43A047',
    bgColorRGB: [67, 160, 71],
  },
];
