import { UpsAssetModel } from './ups-asset.model';
import { UpsInspectionModel } from './ups-inspection.model';
import { UserModel } from '../../../models/user.model';
import { UpsWorkOrderModel } from './ups-work-order.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { CommentModel } from '../../../models/comment.model';
import { FileModel } from '../../../models/file.model';
import { AssetInspectionModel } from '../../../models/base-models/asset-inspection.model';
import { UPS_CONDITIONS_LIST } from '../globals';
import { LocationModel } from '../../../models/location.model';

export class UpsAssetInspectionModel extends AssetInspectionModel {
  id: string;
  type: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  asset: UpsAssetModel;
  snapshot: {
    asset: UpsAssetModel;
  };
  unitCorrectlyMounted: string;
  equipmentConnectedToUPSProtectedOutlets?: string; //UPS
  upsChassisGroundedToPrimaryGround?: string; //UPS
  issueCause: string;
  alarms: {
    onBattery?: string; //UPS
    fault?: string; //UPS
    lowBattery?: string; //UPS
    replaceBattery?: string; //UPS
    upsConnectedToNetguardian?: string; //UPS
    upsSelfTestRecording?: string; //UPS
    teleniumSetUp?: string; //UPS
    bypassSwitchInstalled?: string; //UPS
    filterAndAirIntakes?: string; //UPS
    anotherUPSInRoom?: string; //UPS
    visibleCorrosionBulgingLeaking?: string; //VRLA
    upsChassisBondedExpandableBattery?: string; //VRLA
  };
  attachments: FileModel[];
  additionalAttachments: FileModel[] = [];
  workOrders: UpsWorkOrderModel[] = [];
  inspection?: UpsInspectionModel;
  location: LocationModel;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];
  additionalConditions?: string[];
  correctiveFound?: string[];
  conditions: string[] = [];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);

    if (this['equipmentConnectedToUPSProtectedOutletsAttachments']) {
      this.additionalAttachments = this['equipmentConnectedToUPSProtectedOutletsAttachments'];
    }

    if (this.snapshot && this.snapshot.asset) {
      this.snapshot.asset = new UpsAssetModel(this.snapshot.asset);
    }

    this.updateConditions();
  }

  get childModels() {
    return {
      inspectors: UserModel,
      asset: UpsAssetModel,
      attachments: FileModel,
      additionalAttachments: FileModel,
      workOrders: UpsWorkOrderModel,
      inspection: UpsInspectionModel,
      changeHistory: FieldChangeModel,
      location: LocationModel,
      comments: CommentModel,
    };
  }

  updateConditions() {
    this.conditions = [
      ...Object.entries(
        this
      )
        .filter(([key, value]) => {
          if (typeof value === 'object') {
            return value.status === 'Unsatisfactory';
          }

          return value === 'Unsatisfactory';
        })
        .map(([key]) => key),
    ];

    if (this.alarms) {
      if (
        (!!this.alarms.onBattery  && this.alarms.onBattery  === 'Yes') ||
        (!!this.alarms.fault && this.alarms.fault === 'Yes') ||
        (!!this.alarms.lowBattery && this.alarms.lowBattery === 'Yes') ||
        (!!this.alarms.replaceBattery && this.alarms.replaceBattery === 'Yes')
      ) {
        this.conditions = [
          ...this.conditions,
          'Alarms Present'
        ];
      }

      this.conditions = [
        ...this.conditions,
        ...Object.entries(
          this.alarms
        )
          .filter(([key, value]) => {
            return value === 'Unsatisfactory';
          })
          .map(([key]) => key)
      ];
    }

    if (this.correctiveFound && this.correctiveFound.length > 0) {
      this.conditions = [
        ...this.conditions,
        ...this.correctiveFound,
      ];
    }

    if (this.additionalConditions && this.additionalConditions.length > 0) {
      this.conditions = [
        ...this.conditions,
        ...this.additionalConditions,
      ];
    }

    if (this.conditions.length) {
      this.conditions = this.conditions.map(condition => this.translateCondition(condition));
    }
  }

  private translateCondition(key: string): string {
    const matchingKey = UPS_CONDITIONS_LIST.find(item => item.key === key);

    return matchingKey ? matchingKey.value : key;
  }

  get correctiveActions(): any[] {
    if (!this.conditions) {
      return [];
    }

    return this.conditions.map((condition) => ({
      name: condition,
      workOrder: this.workOrders.find(
        (workOrder) => workOrder.condition === condition
      ),
    }));
  }
}
