import React from 'react';
import { Button, Tooltip } from "@mui/material";

type Props = {
  className?: string;
  isActive: boolean;
  onClick: any;
  size?: any;
  disabled?: boolean;
  hoverText?: string;
  text?: string;
  children: JSX.Element[] | JSX.Element;
}

const ToggleButton = (props: Props) => {

  return (
    <Tooltip followCursor={true} title={props.hoverText ? props.hoverText : ''} placement="top-start">
      <Button
        className={`${props.className}`}
        variant='contained'
        color={props.isActive ? 'primary' : 'inherit'}
        onClick={props.onClick}
        size={props.size ? props.size : 'small'}
        disabled={props.disabled}
      >
        {props.text}
        {props.children}
      </Button>
    </Tooltip>
  );
};

export default ToggleButton;
