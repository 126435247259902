import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const UPS_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['inspectionId', 'dueDate'],
  properties: {
    inspectionId: {
      type: 'string',
      title: 'Inspection ID/Remedy WO #',
    },
    dueDate: {
      type: 'string',
      title: 'Due Date',
    },
    address: {
      type: 'object',
      title: '',
      properties: {
        streetAddress: {
          type: 'string',
          title: 'Street Address',
        },
        city: {
          type: 'string',
          title: 'City',
        },
        state: {
          type: 'string',
          title: 'State',
        },
        zip: {
          type: 'string',
          title: 'ZIP',
        },
      },
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
  },
};

export const UPS_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames': 'w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  inspectionId: {
    'ui:placeholder': 'Inspection ID/Remedy WO #',
  },
  dueDate: {
    'ui:placeholder': 'Due Date',
    'ui:widget': 'date',
  },
  address: {
    streetAddress: {
      'ui:placeholder': 'Street Address',
      'ui:classNames': 'sm:col-span-2',
    },
    city: {
      'ui:placeholder': 'City',
    },
    state: {
      'ui:placeholder': 'State',
    },
    zip: {
      'ui:placeholder': 'ZIP',
    },
    'ui:classNames': 'sm:col-span-2 lg:col-span-4 grid sm:grid-cols-2 lg:grid-cols-5 gap-x-12 gap-y-16',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 lg:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};