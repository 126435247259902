import { AssetInspectionViewModel } from '../../../models/base-view-models/asset-inspection-view.model';
import { PassiveReflectorsAssetInspectionModel } from '../models/passive-reflectors-asset-inspection.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { InspectionHelper } from '../../../helpers/inspection.helper';

export class PassiveReflectorsAssetInspectionViewModel extends AssetInspectionViewModel {

  private assetInspection: PassiveReflectorsAssetInspectionModel;

  constructor(assetInspection: PassiveReflectorsAssetInspectionModel) {
    super(assetInspection);
    this.assetInspection = assetInspection;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [];
  }

  get detailCardFields(): InspectionCardItemModel[] {
    return [
      {
        label: 'Conditions',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
        colSpan: 'sm:col-span-2'
      },
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'Structure Condition',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Damaged Members',
        value: this.returnValue(this.assetInspection.structureCondition && InspectionHelper.getRatedDescriptionObj(this.assetInspection.structureCondition.damagedMembers)),
        colSpan: 'sm:col-span-2'
      },
      {
        label: 'Loose Members',
        value: this.returnValue(this.assetInspection.structureCondition && this.assetInspection.structureCondition.looseMembers),
      }, {
        label: 'Missing Members',
        value: this.returnValue(this.assetInspection.structureCondition && this.assetInspection.structureCondition.missingMembers),
      },
      {
        label: 'Loose and/or Missing Bolts and/or Nut Locking Devices',
        value: this.returnValue(this.assetInspection.structureCondition && this.assetInspection.structureCondition.looseOrMissingBolts),
      },
      {
        label: 'Connection Hardware',
        value: this.returnValue(this.assetInspection.structureCondition && this.assetInspection.structureCondition.connectionHardware),
      },
      {
        label: 'Visible cracks in weld connections including cracks underneath canister mounts for monopoles',
        value: this.returnValue(this.assetInspection.structureCondition && this.assetInspection.structureCondition.visibleCracksInWeldConnections),
      },
      {
        label: 'Structure Finish',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Paint and/or Galvanizing Condition',
        value: this.returnValue(this.assetInspection.structureFinish && this.assetInspection.structureFinish.paintAndGalvanizingCondition),
      },
      {
        label: 'Rust and/or Corrossion Condition including mounts and accessories',
        value: this.returnValue(this.assetInspection.structureFinish && InspectionHelper.getRatedDescriptionObj(this.assetInspection.structureFinish.rustAndCorrossionCondition)),
        colSpan: 'sm:col-span-2'
      },
      {
        label: 'FAA OR ICAO Color Marking Conditions',
        value: this.returnValue(this.assetInspection.structureFinish && this.assetInspection.structureFinish.colorMarkingConditions),
      },
      {
        label: 'Water Collection in Member (e.g. plugged drain holes)',
        value: this.returnValue(this.assetInspection.structureFinish && this.assetInspection.structureFinish.waterCollectionInMember),
      },
      {
        label: 'Lighting (External Portions of Compents Only)',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Conduit, Juntion Boxes, and Fasteners (Weather-tight and secure)',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.conduitJuntionBoxes),
      },
      {
        label: 'Drain vent and Openings',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.drainVentAndOpenings),
      },
      {
        label: 'Unobstructed',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.unobstructed),
      },
      {
        label: 'Wiring Condition',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.wiringCondition),
      },
      {
        label: 'Light Lenses',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.lightLenses),
      },
      {
        label: 'Bulb Condition',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.bulbCondition),
      },
      {
        label: 'Controllers (e.g. flasher, photo control, alarms)',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.controllers),
      },
      {
        label: 'Obstruction to lighting system',
        value: this.returnValue(this.assetInspection.lighting && this.assetInspection.lighting.obstructionToLightingsystem),
      },
      {
        label: 'Grounding',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Connections',
        value: this.returnValue(this.assetInspection.grounding && this.assetInspection.grounding.connections),
      },
      {
        label: 'Corrosion',
        value: this.returnValue(this.assetInspection.grounding && this.assetInspection.grounding.groundingCorrosion),
      },
      {
        label: 'Lightning Protection',
        value: this.returnValue(this.assetInspection.grounding && this.assetInspection.grounding.lightningProtection),
      },
      {
        label: 'Mounts, Antennas and Lines',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Antennas and Mount Conditions (e.g. proper tie-back of microwave dishes, damage to supporting structure at connections, loose or missing hardware, condition of antenna covers)',
        value: this.returnValue(this.assetInspection.antennasAndMount && this.assetInspection.antennasAndMount.antennasAndMountConditions),
        colSpan: 'sm:col-span-2'
      },
      {
        label: 'Feed Line Condition (e.g. seals, dents, jacket damage, grounding, properly secured/supported on the structure and mount, hanger condition, secured to waveguide ladder)',
        value: this.returnValue(this.assetInspection.antennasAndMount && this.assetInspection.antennasAndMount.feedLineCondition),
        colSpan: 'sm:col-span-2'
      },
      {
        label: 'Ice Shields, Walkways, Platforms, and Climbing Facilities',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Obstructions to climbing path or Safety Climb Systems',
        value: this.returnValue(this.assetInspection.iceShieldsWalkways && this.assetInspection.iceShieldsWalkways.obstructionsToClimbingPath),
      },
      {
        label: 'Defects, Deformations, Loose or Missing Hardware and Members',
        value: this.returnValue(this.assetInspection.iceShieldsWalkways && this.assetInspection.iceShieldsWalkways.defectsDeformations),
      },
      {
        label: 'Secured to Structure',
        value: this.returnValue(this.assetInspection.iceShieldsWalkways && this.assetInspection.iceShieldsWalkways.securedToStructure),
      },
      {
        label: 'GUYS',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Strand Condition (e.g. corrosion, breaks, nicks, kinks)',
        value: this.returnValue(this.assetInspection.guys && this.assetInspection.guys.strandCondition),
      },
      {
        label: 'Guy Hardware Conditions (e.g. turnbuckles, cable thimbles, ice clips, cable connectors)',
        value: this.returnValue(this.assetInspection.guys && this.assetInspection.guys.guyHardwareConditions),
      },
      {
        label: 'Concrete Foundation',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Ground condition (e.g. settlement, movement or earth cracks)',
        value: this.returnValue(this.assetInspection.concreteFoundation && InspectionHelper.getRatedDescriptionObj(this.assetInspection.concreteFoundation.concreteFoundationGroundCondition)),
        colSpan: 'sm:col-span-2'
      },
      {
        label: 'Anchorage Condition (e.g. base plate nuts tight, nut locking device, grout condition, anchorages, anchor rods)',
        value: this.returnValue(this.assetInspection.concreteFoundation && this.assetInspection.concreteFoundation.anchorageCondition),
      },
      {
        label: 'Concrete Condition (e.g. cracking, spalling, splitting, chipped or broken concrete, honeycombing, low spots to collect moisture)',
        value: this.returnValue(this.assetInspection.concreteFoundation && this.assetInspection.concreteFoundation.concreteCondition),
      },
      {
        label: 'Guyed Mast Anchor',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15 mt-4'
      },
      {
        label: 'Ground Condition (e.g. settlement, movement or earth cracks)',
        value: this.returnValue(this.assetInspection.guyedMastAnchor && this.assetInspection.guyedMastAnchor.groundCondition),
      },
      {
        label: 'Grade sloped away from anchor',
        value: this.returnValue(this.assetInspection.guyedMastAnchor && this.assetInspection.guyedMastAnchor.gradeSlopedAway),
      },
      {
        label: 'Corrosion',
        value: this.returnValue(this.assetInspection.guyedMastAnchor && this.assetInspection.guyedMastAnchor.corrosion),
      },
      {
        label: 'Anchor above Grade, Clear of Vegetation, Obstructions, Turnbuckles Free to Articulate',
        value: this.returnValue(this.assetInspection.guyedMastAnchor && this.assetInspection.guyedMastAnchor.anchorAboveGrade),
      },
      {
        label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
      },
    ];
  }

  get workOrderPageDetailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [];

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: 'Due Date',
      value: this.getDueDate,
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Inspection ID/Remedy WO #',
      value: this.assetInspection.inspection && this.assetInspection.inspection.inspectionId ? this.assetInspection.inspection.inspectionId : 'N/A',
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {
      label: 'Equipment Number',
      value: this.returnValue(this.assetInspection.asset.equipmentNumber)
    };
  }

  get linkTo(): string {
    return `/asset-inspection/${this.assetInspection.id}`;
  }

  get listItemFieldsClassName(): string {
    return `md:grid-cols-6`;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: 'Inspection ID/Remedy WO #',
        value: this.returnValue(this.assetInspection.inspection && this.assetInspection.inspection.inspectionId),
      },
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'City, State', value:
          (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.city) : 'N/A') + ', '
          + (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.state) : 'N/A'),
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      },
      {
        label: 'Due Date',
        value: this.getDueDate,
      },
    ];
  }

  get secondaryListItemFieldsClassName(): string {
    return `sm:grid-cols-4`;
  }

  get secondaryListItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
        colSpan: 'sm:col-span-2'
      },
    ];
  }


  get dateTime(): string {
    return this.assetInspection.createdAt ? moment(this.assetInspection.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get buttonText(): string {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get componentHeader(): string {
    return this.returnValue(this.assetInspection['name']);
  }

  get getDueDate() {
    return this.assetInspection.inspection && this.assetInspection.inspection.dueDate ? moment(`${this.assetInspection.inspection.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get assetInspectionStatusColor(): number[] {
    return InspectionHelper.getAssetInspectionStatusColor(this.assetInspection.status);
  }

  get previousNextListItem(): any {
    return InspectionHelper.getAssetInspectionPreviousNext(this.assetInspection);
  }

  get additionalAttachmentsTitle(): string {
    return '';
  }
}
