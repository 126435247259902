import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import Assets from "./Assets";

i18next.addResourceBundle('en', 'assetsPage', en);

const AssetsConfig = {
    settings: {
        layout: {
            config: {},
        },
    },
    routes: [
        {
            path: 'assets',
            element: <Assets />,
        },
    ],
};

export default AssetsConfig;
