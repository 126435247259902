export class URLHelper {

  static getUrlQuery(linkTo: string, queryParams: any = {}): string {
    if (!queryParams) {
      return `${linkTo}`;
    }

    const query = Object.keys(queryParams).reduce(function (query, item) {
      query.push(item + '=' + encodeURIComponent(queryParams[item]));
      return query;
    }, []).join('&');

    return `${linkTo}?${query}`;
  }

}
