import { WidgetProps } from '@rjsf/utils';
import UserMultiSelect from '../../UserMultiSelect/UserMultiSelect';

const UserMultiSelectWidget = (props: WidgetProps) => {    
  return (
    <UserMultiSelect
      id={"inspectors-select"}
      className={"w-full sm:col-span-2"}
      label={props.label}
      onChange={(event) => props.onChange(event.map((item) => ({ id: item })))}
      userIdKey={'id'}
      usersIds={
        props.value && props.value.length
          ? props.value.map((item) => item.id)
          : []
      }
    />
  );
};

export default UserMultiSelectWidget;
