import { TelecomTowersAssetInspectionModel } from './telecom-towers-asset-inspection.model';
import { AssetModel } from '../../../models/base-models/asset.model';
import { LocationModel } from '../../../models/location.model';

export class TelecomTowersAssetModel extends AssetModel {
  id: string;
  equipmentNumber?: string;
  type: string;
  towerType: string;
  towerManufacturer: string;
  towerTagInformation: string;
  structureHeight: string;
  topOfHighestAppurt: string;
  groundElevation: string;
  fccid: string;
  notes: string;
  assetInspections: TelecomTowersAssetInspectionModel[];
  comparingAsset: TelecomTowersAssetModel;
  location: LocationModel;

  get childModels() {
    return {
      location: LocationModel,
    };
  }

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
