import { InspectionViewModel } from '../../../models/base-view-models/inspection-view.model';
import { GeneratorsInspectionModel } from '../models/generators-inspection.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment';
import { CommentModel } from '../../../models/comment.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { UserModel } from '../../../models/user.model';
import { InspectionHelper } from '../../../helpers/inspection.helper';
import { GENERATORS_INSPECTION_STATUS } from '../statuses/generators-inspection.statuses';
import { GENERATORS_ASSET_INSPECTION_STATUS } from '../statuses/generators-asset-inspection.statuses';

export class GeneratorsInspectionViewModel extends InspectionViewModel {

  private inspection: GeneratorsInspectionModel;

  constructor(inspection: GeneratorsInspectionModel) {
    super(inspection);
    this.inspection = inspection;
  }

  get componentHeader(): string {
    return;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [];
  }

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {label: 'Inspection ID/Remedy WO #', value: this.returnValue(this.inspection.inspectionId)},
      {label: 'Inspection Type', value: this.returnValue(this.inspection.inspectionType)},
      {label: 'Due Date', value: this.getDueDate},
    ];

    fields.push({
      label: 'Address',
      value: this.inspection.address ? this.inspection.address.addressString : 'N/A',
      colSpan: 'sm:col-span-2',
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.inspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {
      label: 'Inspection ID/Remedy WO #',
      value: this.returnValue(this.inspection.inspectionId),
      info: this.inspection.assetInspections ? this.inspection.assetInspections.length.toString() + `${this.inspection.assetInspections.length === 1 ? ' Asset' : ' Assets'}` : '',
    };
  }

  get linkTo(): string {
    return `/inspection/${this.inspection.id}`;
  }

  get listItemFieldsClassName(): string {
    return `sm:grid-cols-6`;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: 'Inspection Type',
        value: this.returnValue(this.inspection.inspectionType),
      },
      {
        label: this.inspection.assignedTo && this.inspection.assignedTo.length === 1 ? 'Assignee' : 'Assignees',
        value: this.inspection.assignedTo && this.inspection.assignedTo.length ? this.inspection.assignedTo.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: this.inspection.inspectors && this.inspection.inspectors.length && this.inspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.inspection.inspectors && this.inspection.inspectors.length ? this.inspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'City, State',
        value: this.inspection.address ? `${this.returnValue(this.inspection.address.city)}, ${this.returnValue(this.inspection.address.state)}` : 'N/A',
      },
      {
        label: 'Due Date',
        value: this.getDueDate,
      },
    ];
  }

  get dateTime(): string {
    return this.inspection.createdAt ? moment(this.inspection.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get lastTimeStatusChanged(): string {
    if (!this.inspection.changeHistory || !this.inspection.changeHistory.length) {
      return;
    }
    const statusChanges = this.inspection.changeHistory.filter(changeHistoryField => changeHistoryField.field === 'status');

    if (!statusChanges || !statusChanges.length) {
      return;
    }

    const lastTimeStatusChanged = statusChanges.pop().changedAt;

    if (!lastTimeStatusChanged) {
      return;
    }

    return lastTimeStatusChanged;
  }

  get buttonText(): string {
    return `Download Report`;
  }

  get buttonAction(): Function {
    return;
    // return (setIsLoading) => {
    //   setIsLoading(true);
    //   return InspectionsService.getInspectionPdfReport(this.inspection.id).then(res => {
    //     setIsLoading(false);
    //   });
    // };
  }

  get headingItemFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [
      {
        label: this.inspection.assignedTo && this.inspection.assignedTo.length === 1 ? 'Assignee' : 'Assignees',
        value: this.inspection.assignedTo && this.inspection.assignedTo.length ? this.inspection.assignedTo.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {label: 'Inspection Type', value: this.returnValue(this.inspection.inspectionType)},
      {label: 'Due Date', value: this.getDueDate},
      {label: 'Completion Date', value: this.getCompletionDate},
      {
        label: 'Address',
        value: this.inspection.address ? this.inspection.address.addressString : 'N/A',
        colSpan: 'sm:col-span-3',
      },
    ];

    return fields;
  }

  get getDueDate() {
    return this.inspection.dueDate ? moment(`${this.inspection.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get getCompletionDate() {
    return this.inspection['completionDate'] ? moment(`${this.inspection['completionDate']}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get additionalItemField(): InspectionCardItemModel {
    return {label: 'Comments', value: this.returnValue(this.inspection.comment)};
  }

  get readyForQa(): boolean {
    if (!this.inspection.assetInspections || !this.inspection.assetInspections.length) {
      return true;
    }

    return !this.inspection.assetInspections.some(assetInspection => assetInspection.status === GENERATORS_ASSET_INSPECTION_STATUS.conditionsFound);
  }

  get changeLogHistory(): FieldChangeModel[] {
    return this.inspection.changeHistory;
  }

  get comments(): CommentModel[] {
    return this.inspection.comments;
  }

  get isCommentFormDisabled(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.closed;
  }

  get assignedTo(): UserModel[] {
    return this.inspection.assignedTo;
  }

  get unhandledConditions(): string[] {
    return InspectionHelper.getInspectionUnhandledConditions(this.inspection);
  }

  get viewModelId(): string {
    return this.inspection.id;
  }

  get isInProgress(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.inProgress;
  }

  get isWaitingQa(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.waitingQa;
  }

  get isInQa(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.inQa;
  }

  get isCompleted(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.completed;
  }

  get isClosed(): boolean {
    return this.inspection.status === GENERATORS_INSPECTION_STATUS.closed;
  }

  get isReadonly(): boolean {
    return this.inspection.readonly;
  }

  getProp(propertyName: string): any {
    return this.inspection[propertyName];
  }

}