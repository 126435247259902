import { DispatchingDocumentModel } from '../../../models/base-models/dispatching-document.model';
import { AddressModel } from './address.model';

export class FiberDispatchingDocumentModel extends DispatchingDocumentModel {
  inspectorExternalId: string;
  externalId: string;
  accountNumber: string;
  routeInfo: string;
  cableManufacturerInfo: string;
  cableDiameter: number;
  cableSerialNumber: string;
  address: AddressModel;
  comment: string;
  telecomWorkCenter: string;
  fireThreat: number;
  inspectionType: number;
  dueDate: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
