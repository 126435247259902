import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { TELECOM_TOWERS_FIELDS_RATED, TELECOM_TOWERS_FIELDS_STATUSES } from '../globals';

export const TELECOM_TOWERS_ASSET_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    inspectors: {
      type: 'string',
      title: 'Inspectors',
    },
    structureCondition: {
      type: 'object',
      title: 'Structure Condition',
      properties: {
        damagedMembers: {
          type: 'string',
          title: 'Damaged Members',
        },
        looseMembers: {
          type: 'string',
          title: 'Loose Members',
        },
        missingMembers: {
          type: 'string',
          title: 'Missing Members',
        },
        looseOrMissingBolts: {
          type: 'string',
          title: 'Loose and/or Missing Bolts and/or Nut Locking Devices',
        },
        visibleCracksInWeldConnections: {
          type: 'string',
          title: 'Visible cracks in weld connections including cracks underneath canister mounts for monopoles',
        },
        connectionHardware: {
          type: 'string',
          title: 'Connection Hardware',
        },
      }
    },
    structureFinish: {
      type: 'object',
      title: 'Structure Finish',
      properties: {
        paintAndGalvanizingCondition: {
          type: 'string',
          title: 'Paint and/or Galvanizing Condition',
        },
        colorMarkingConditions: {
          type: 'string',
          title: 'FAA OR ICAO Color Marking Conditions',
        },
        waterCollectionInMember: {
          type: 'string',
          title: 'Water Collection in Member',
        },
        rustAndCorrossionCondition: {
          type: 'string',
          title: 'Rust and/or Corrossion Condition including mounts and accessories',
        },
      }
    },
    lighting: {
      type: 'object',
      title: 'Lighting (External Portions of Compents Only)',
      properties: {
        conduitJuntionBoxes: {
          type: 'string',
          title: 'Conduit, Juntion Boxes, and Fasteners (Weather-tight and secure)',
        },
        drainVentAndOpenings: {
          type: 'string',
          title: 'Drain vent and Openings',
        },
        unobstructed: {
          type: 'string',
          title: 'Unobstructed',
        },
        wiringCondition: {
          type: 'string',
          title: 'Wiring Condition',
        },
        lightLenses: {
          type: 'string',
          title: 'Light Lenses',
        },
        bulbCondition: {
          type: 'string',
          title: 'Bulb Condition',
        },
        controllers: {
          type: 'string',
          title: 'Controllers',
        },
        obstructionToLightingsystem: {
          type: 'string',
          title: 'Obstruction to lighting system',
        },
      }
    },
    grounding: {
      type: 'object',
      title: 'Grounding',
      properties: {
        connections: {
          type: 'string',
          title: 'Connections',
        },
        groundingCorrosion: {
          type: 'string',
          title: 'Corrosion',
        },
        lightningProtection: {
          type: 'string',
          title: 'Lightning Protection',
        },
      }
    },
    antennasAndMount: {
      type: 'object',
      title: 'Mounts, Antennas and Lines',
      properties: {
        feedLineCondition: {
          type: 'string',
          title: 'Feed Line Condition',
        },
        antennasAndMountConditions: {
          type: 'string',
          title: 'Antennas and Mount Conditions',
        },
      }
    },
    iceShieldsWalkways: {
      type: 'object',
      title: 'Ice Shields, Walkways, Platforms, and Climbing Facilities',
      properties: {
        obstructionsToClimbingPath: {
          type: 'string',
          title: 'Obstructions to climbing path or Safety Climb Systems',
        },
        securedToStructure: {
          type: 'string',
          title: 'Secured to Structure',
        },
        defectsDeformations: {
          type: 'string',
          title: 'Defects, Deformations, Loose or Missing Hardware and Members',
        },
      }
    },
    guys: {
      type: 'object',
      title: 'GUYS',
      properties: {
        strandCondition: {
          type: 'string',
          title: 'Strand Condition',
        },
        guyHardwareConditions: {
          type: 'string',
          title: 'Guy Hardware Conditions',
        },
      }
    },
    concreteFoundation: {
      type: 'object',
      title: 'Concrete Foundation',
      properties: {
        concreteFoundationGroundCondition: {
          type: 'string',
          title: 'Ground condition',
        },
        anchorageCondition: {
          type: 'string',
          title: 'Anchorage Condition',
        },
        concreteCondition: {
          type: 'string',
          title: 'Concrete Condition',
        },
      }
    },
    guyedMastAnchor: {
      type: 'object',
      title: 'Guyed Mast Anchor',
      properties: {
        groundCondition: {
          type: 'string',
          title: 'Ground Condition',
        },
        gradeSlopedAway: {
          type: 'string',
          title: 'Grade sloped away from anchor',
        },
        corrosion: {
          type: 'string',
          title: 'Corrosion',
        },
        anchorAboveGrade: {
          type: 'string',
          title: 'Anchor above Grade, Clear of Vegetation, Obstructions, Turnbuckles Free to Articulate',
        },
      }
    },
    other: {
      type: 'object',
      title: 'Other',
      properties: {
        structureObstructionInterference: {
          type: 'string',
          title: 'Structure Obstruction/Interference',
        },
      }
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
  },
};

export const TELECOM_TOWERS_ASSET_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-16',
  'ui:inline': true,
  inspectors: {
    'ui:placeholder': 'Inspectors',
    'ui:widget': 'UserMultiSelectWidget',
    'ui:classNames': 'sm:col-span-2 lg:col-span-1',
  },
  structureCondition: {
    damagedMembers: {
      'ui:placeholder': 'Damaged Members',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    looseMembers: {
      'ui:placeholder': 'Loose Members',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    missingMembers: {
      'ui:placeholder': 'Missing Members',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    looseOrMissingBolts: {
      'ui:placeholder': 'Loose and/or Missing Bolts and/or Nut Locking Devices',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    visibleCracksInWeldConnections: {
      'ui:placeholder': 'Visible cracks in weld connections',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    connectionHardware: {
      'ui:classNames':
        'md:col-span-3 grid sm:grid-cols-3 gap-x-12 gap-y-16',
      'ui:placeholder': 'Connection Hardware',
      'ui:widget': 'DependencyMultipleInputWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
        ratedList: TELECOM_TOWERS_FIELDS_RATED
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  structureFinish: {
    paintAndGalvanizingCondition: {
      'ui:placeholder': 'Paint and/or Galvanizing Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    colorMarkingConditions: {
      'ui:placeholder': 'FAA OR ICAO Color Marking Conditions',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    waterCollectionInMember: {
      'ui:placeholder': 'Water Collection in Member',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    rustAndCorrossionCondition: {
      'ui:classNames':
        'md:col-span-3 grid sm:grid-cols-3 gap-x-12 gap-y-16',
      'ui:placeholder': 'Rust and/or Corrossion Condition including mounts and accessories',
      'ui:widget': 'DependencyMultipleInputWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
        ratedList: TELECOM_TOWERS_FIELDS_RATED
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  lighting: {
    conduitJuntionBoxes: {
      'ui:placeholder': 'Conduit, Juntion Boxes, and Fasteners (Weather-tight and secure)',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    drainVentAndOpenings: {
      'ui:placeholder': 'Drain vent and Openings',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    unobstructed: {
      'ui:placeholder': 'Unobstructed',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    wiringCondition: {
      'ui:placeholder': 'Wiring Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    lightLenses: {
      'ui:placeholder': 'Light Lenses',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    bulbCondition: {
      'ui:placeholder': 'Bulb Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    controllers: {
      'ui:placeholder': 'Controllers',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    obstructionToLightingsystem: {
      'ui:placeholder': 'Obstruction to lighting system',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  grounding: {
    connections: {
      'ui:placeholder': 'Connections',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    groundingCorrosion: {
      'ui:placeholder': 'Corrosion',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    lightningProtection: {
      'ui:classNames':
        'md:col-span-3 grid sm:grid-cols-3 gap-x-12 gap-y-16',
      'ui:placeholder': 'Lightning Protection',
      'ui:widget': 'DependencyMultipleInputWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
        ratedList: TELECOM_TOWERS_FIELDS_RATED
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  antennasAndMount: {
    feedLineCondition: {
      'ui:placeholder': 'Feed Line Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    antennasAndMountConditions: {
      'ui:classNames':
        'md:col-span-3 grid sm:grid-cols-3 gap-x-12 gap-y-16',
      'ui:placeholder': 'Antennas and Mount Conditions',
      'ui:widget': 'DependencyMultipleInputWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
        ratedList: TELECOM_TOWERS_FIELDS_RATED
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  iceShieldsWalkways: {
    obstructionsToClimbingPath: {
      'ui:placeholder': 'Obstructions to climbing path or Safety Climb Systems',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    securedToStructure: {
      'ui:placeholder': 'Secured to Structure',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    defectsDeformations: {
      'ui:classNames':
        'md:col-span-3 grid sm:grid-cols-3 gap-x-12 gap-y-16',
      'ui:placeholder': 'Defects, Deformations, Loose or Missing Hardware and Members',
      'ui:widget': 'DependencyMultipleInputWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
        ratedList: TELECOM_TOWERS_FIELDS_RATED
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  guys: {
    strandCondition: {
      'ui:placeholder': 'Strand Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    guyHardwareConditions: {
      'ui:placeholder': 'Guy Hardware Conditions',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  concreteFoundation: {
    concreteFoundationGroundCondition: {
      'ui:placeholder': 'Ground condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    anchorageCondition: {
      'ui:placeholder': 'Anchorage Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    concreteCondition: {
      'ui:placeholder': 'Concrete Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  guyedMastAnchor: {
    groundCondition: {
      'ui:placeholder': 'Ground Condition',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    gradeSlopedAway: {
      'ui:placeholder': 'Grade sloped away from anchor',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    corrosion: {
      'ui:placeholder': 'Corrosion',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    anchorAboveGrade: {
      'ui:placeholder': 'Anchor above Grade, Clear of Vegetation, Obstructions, Turnbuckles Free to Articulate',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  other: {
    structureObstructionInterference: {
      'ui:placeholder': 'Structure Obstruction/Interference',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: TELECOM_TOWERS_FIELDS_STATUSES,
      },
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-3 grid md:grid-cols-3 gap-x-12 gap-y-16',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-3',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};