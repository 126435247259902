import React from 'react';
import { Skeleton } from '@mui/material';
import Chip from '../../../shared/components/Chip/Chip';
import { FiberWorkOrderModel } from '../../../shared/types/fiber/models/fiber-work-order.model';
import StatusClosedTimeChip from '../../../shared/components/StatusClosedTimeChip/StatusClosedTimeChip';
import { WORK_ORDER_STATUSES, DUE_DATE_STATUSES, WORK_ORDER_STATUS } from '../../../shared/globals';
import StatusChip from '../../../shared/components/StatusChip/StatusChip';
import { DataComponentModel } from "../../../shared/models/interfaces/data-component.model";

type Props = {
  dataModel: DataComponentModel;
  // TODO: Remove workOrder from props
  workOrder: FiberWorkOrderModel;
  isLoading: boolean;
}

const WorkOrderHeader = (props: Props) => {

  return (
    !props.isLoading ?
      <div>
        <div className={'flex flex-wrap items-center gap-x-10 mb-4 sm:mb-0'}>
          <p className={'font-bold text-20 sm:text-28'}>{props.dataModel.componentHeader}</p>
          <span className={'flex flex-wrap gap-4 sm:gap-6'}>
            <StatusChip className={'h-20 text-white'} status={props.workOrder.status}
                      statusObjects={WORK_ORDER_STATUSES}/>
            <StatusChip className={'h-20 text-white'} status={props.workOrder['dueDateStatus']}
                      statusObjects={DUE_DATE_STATUSES}/>
          </span>
        </div>
        <div className={'flex items-start gap-x-4'}>
          <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
                label={props.dataModel.dateTime}
          />
          {
            !!props.dataModel.lastTimeStatusChanged && props.workOrder.status === WORK_ORDER_STATUS.completed &&
              <div className={'flex items-start'}>
                  <StatusClosedTimeChip lastTimeStatusChanged={props.dataModel.lastTimeStatusChanged}/>
              </div>
          }
        </div>
      </div>
      : (
        <div className={'block mb-2'}>
          <div className={'flex flex-wrap items-center gap-x-10 mb-4'}>
            <Skeleton variant='text' animation={'wave'} width={200} sx={{fontSize: '26px'}}/>
            <Skeleton variant='rounded' animation={'wave'} width={80} sx={{fontSize: '14px', borderRadius: '15px'}}/>
            <Skeleton variant='rounded' animation={'wave'} width={80} sx={{fontSize: '14px', borderRadius: '15px'}}/>
          </div>
          <Skeleton variant='rounded' animation={'wave'} width={110} sx={{fontSize: '13px', borderRadius: '15px'}}/>
        </div>
      )
  );
}

export default WorkOrderHeader;
