import * as React from 'react';
import { Box, FormLabel } from '@mui/material';

type Props = {
  label: string;
  className?: string;
  children: JSX.Element[] | JSX.Element;
}

const OutlinedBox = (props: Props) => {

  return (
    <Box className={`${props.className} relative overflow-visible border border-gray-400 rounded-md`}>
      <FormLabel className={'absolute'}
                 sx={{
                   marginLeft: "0.71em",
                   marginTop: "-0.71em",
                   paddingLeft: "0.44em",
                   paddingRight: '0.44em',
                   zIndex: 2,
                   backgroundColor: "#FFFFFF",
                   fontSize: "0.75em",
                   width: 'auto',
                 }}>
        {props.label}
      </FormLabel>
      <Box className={`sm:overflow-auto h-full`}>
        {props.children}
      </Box>
    </Box>
  );
}

export default OutlinedBox;