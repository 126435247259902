export class FieldChangeModel {
  changedBy: string;
  field: string;
  oldValue: any;
  newValue: any;
  changedAt: string;
  additionalFields: any;

  constructor(values: any = {}) {
    Object.assign(this, values)
  }
}