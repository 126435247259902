import { useEffect, useState } from 'react';
import Select from '../../Select/Select';
import DatePicker from '../../DatePicker/DatePicker';
import moment from 'moment/moment';

const DependencyDateWidget = (props: any) => {
  const [selectValue, setSelectValue] = useState('');
  const [dateInputValue, setDateInputValue] = useState(undefined);
  const [isFieldDisabled, setFieldDisabled] = useState(true);

  useEffect(() => {
    if (!props.value || !(typeof props.value === 'object')) {
      setSelectValue('');
      setDateInputValue(undefined);
      setFieldDisabled(true);
      return;
    }

    const {status, value} = props.value;

    setSelectValue(status ? status : '');
    setDateInputValue(value ? value : undefined);
    calculateIsEnabledDateField(status ? status : '');

  }, [props.value, props.options.optionsList]);

  const onSelectChangeHandler = (event: any) => {
    const newValue = event.target.value;
    calculateIsEnabledDateField(newValue);
    setSelectValue(newValue);
    const selectedOption = props.options.optionsList.find(
      (option) => option.value === newValue
    );

    props.onChange({
      status: newValue,
      value: selectedOption?.isDisabled ? undefined : dateInputValue,
    });
  };

  const onDateInputChangeHandler = (value: string) => {
    const newValue = value ? moment(value).format('YYYY-MM-DD') : undefined;
    setDateInputValue(newValue);

    props.onChange({
      status: selectValue,
      value: newValue,
    });
  };

  const calculateIsEnabledDateField = (selectedValue: string) => {
    const selectedOption = props.options.optionsList.find(
      (option) => option.value === selectedValue
    );

    setFieldDisabled(!!selectedOption?.isDisabled);
    selectedOption?.isDisabled && setDateInputValue(undefined);
  };

  return (
    <div className='col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16'>
      <Select
        value={selectValue}
        label={`${props.label ? props.label : ''} Status${
          props.required ? '*' : ''
        }`}
        className={`w-full`}
        onChange={(event: Event) => onSelectChangeHandler(event)}
        list={props.options.optionsList ? props.options.optionsList : []}
      />
      <DatePicker
        value={dateInputValue}
        label={`${props.label ? props.label : ''} Value`}
        onChange={(value: string) => onDateInputChangeHandler(value)}
        disabled={isFieldDisabled}
      />
    </div>
  );
};

export default DependencyDateWidget;
