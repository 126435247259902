import React, { useEffect, useState } from 'react';
import { FieldChangeModel } from '../../models/field-change.model';
import ChangeLogTimelineItem from './ChangeLogTimelineItem';
import { Timeline, timelineItemClasses } from '@mui/lab';
import { FieldChangeHelper } from '../../helpers/field-change.helper';
import Chip from '../Chip/Chip';
import moment from 'moment/moment';
import UsersService from '../../../services/users.service';
import { CircularProgress } from '@mui/material';

type Props = {
  className?: string;
  changeHistory?: FieldChangeModel[];
}

const ChangeLogTimeline = (props: Props) => {

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changedFieldHandler = {
    inspectionId: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Inspection ID/Remedy WO #');
    },
    inspectionType: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Inspection Type');
    },
    routeInfo: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Route Info');
    },
    accountNumber: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Account Number');
    },
    name: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Name');
    },
    comment: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Comment');
    },
    cableManufacturerInfo: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Cable Manufacturer Info');
    },
    cableDiameter: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Cable Diameter');
    },
    cableSerialNumber: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Cable Serial Number');
    },
    address: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Address');
    },
    city: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'City');
    },
    county: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'County');
    },
    barcode: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Barcode');
    },
    damageCause: (changeHistoryField, users) => {
      const oldValue = changeHistoryField.oldValue && changeHistoryField.oldValue.length ? changeHistoryField.oldValue[0] : '';
      const newValue = changeHistoryField.newValue && changeHistoryField.newValue.length ? changeHistoryField.newValue[0] : '';

      const fieldChange = {...changeHistoryField, oldValue: oldValue, newValue: newValue}
      return FieldChangeHelper.getTimelineItemParams(fieldChange, users, 'Damage Cause');
    },
    issueCause: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Issue Cause');
    },
    externalId: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Remedy Work Order #');
    },
    sapExternalId: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'SAP Work Order #');
    },
    scheduledStartDate: (changeHistoryField, users) => {
      const oldValue = changeHistoryField.oldValue && changeHistoryField.oldValue !== '' ? moment(changeHistoryField.oldValue).format('MM/DD/YYYY') : '';
      const newValue = changeHistoryField.newValue && changeHistoryField.newValue !== '' ? moment(changeHistoryField.newValue).format('MM/DD/YYYY') : '';

      const fieldChange = {...changeHistoryField, oldValue: oldValue, newValue: newValue}
      return FieldChangeHelper.getTimelineItemParams(fieldChange, users, 'Scheduled Start Date');
    },
    dueDate: (changeHistoryField, users) => {
      const oldValue = changeHistoryField.oldValue && changeHistoryField.oldValue !== '' ? moment(changeHistoryField.oldValue).format('MM/DD/YYYY') : '';
      const newValue = changeHistoryField.newValue && changeHistoryField.newValue !== '' ? moment(changeHistoryField.newValue).format('MM/DD/YYYY') : '';

      const fieldChange = {...changeHistoryField, oldValue: oldValue, newValue: newValue}
      return FieldChangeHelper.getTimelineItemParams(fieldChange, users, 'Due Date');
    },
    regulatorDate: (changeHistoryField, users) => {
      const oldValue = changeHistoryField.oldValue && changeHistoryField.oldValue !== '' ? moment(changeHistoryField.oldValue).format('MM/DD/YYYY') : '';
      const newValue = changeHistoryField.newValue && changeHistoryField.newValue !== '' ? moment(changeHistoryField.newValue).format('MM/DD/YYYY') : '';

      const fieldChange = {...changeHistoryField, oldValue: oldValue, newValue: newValue}
      return FieldChangeHelper.getTimelineItemParams(fieldChange, users, 'Regulator Date');
    },
    notes: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Notes');
    },
    conditions: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Conditions', 'removed', removedValues.join(', ')) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Conditions', 'added', addedValues.join(', ')) : ''
          }
        </>
      );
    },
    additionalConditions: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
              FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Conditions', 'removed', removedValues.join(', ')) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
              FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Conditions', 'added', addedValues.join(', ')) : ''
          }
        </>
      );
    },
    inspectedAttributes: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Inspected Attributes', 'removed', removedValues.join(', ')) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Inspected Attributes', 'added', addedValues.join(', ')) : ''
          }
        </>
      );
    },
    inspectors: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Inspectors', 'removed', FieldChangeHelper.getUsersNames(users, removedValues)) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Inspectors', 'added', FieldChangeHelper.getUsersNames(users, addedValues)) : ''
          }
        </>
      );
    },
    assignedTo: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getAssignedToTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'unassigned', FieldChangeHelper.getUsersNames(users, removedValues)) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getAssignedToTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'assigned', FieldChangeHelper.getUsersNames(users, addedValues)) : ''
          }
        </>
      );
    },
    workAssignee: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Work Assignee');
    },
    images: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getImagesTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Images', 'removed', removedValues.join()) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getImagesTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Images', 'added', addedValues.join()) : ''
          }
        </>
      );
    },
    workOrders: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return <>
        {
          !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getWorkOrderTimelineItemParams(changeHistoryField, users, 'deleted', removedValues && removedValues.length ? removedValues[removedValues.length-1] : '') : ''
        }
          {
            !!addedValues && !!addedValues.length ?
              FieldChangeHelper.getWorkOrderTimelineItemParams(changeHistoryField, users, 'created',addedValues && addedValues.length ? addedValues[addedValues.length-1] : '') : ''
          }
        </>;
    },
    attachments: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return <>
        {
          !!removedValues && !!removedValues.length ?
            FieldChangeHelper.getAttachmentsTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Attachments', 'removed', removedValues.join()) : ''
        }
        {
          !!addedValues && !!addedValues.length ?
            FieldChangeHelper.getAttachmentsTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Attachments', 'added', addedValues.join()) : ''
        }
      </>;
    },
    status: (changeHistoryField, users) => {
      return <>
        {
           FieldChangeHelper.getStatusTimelineItemParams(changeHistoryField, users)
        }
      </>
    },
    activityComment: (changeHistoryField, users) => {
      return <>
        {FieldChangeHelper.getActivityCommentTimelineItemParams(changeHistoryField, users, changeHistoryField.newValue)}
      </>;
    },
    generalInspection: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'General Inspection');
    },
    battery: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Battery');
    },
    engine: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Engine');
    },
    fluids: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Fluids');
    },
    control: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Control');
    },
    mounting: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Mounting');
    },
    generatorNoLoad: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Generator, No Load');
    },
    utilityPower: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Utility Power');
    },
    generatorWithLoad: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Generator With Load');
    },
    automaticTransferSwitches: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Automatic Transfer Switches');
    },
    alarms: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Alarms');
    },
    unitCorrectlyMounted: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Is the unit correctly mounted?');
    },
    equipmentConnectedToUPSProtectedOutlets: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Is all equipment connected to UPS protected outlets on the UPS?');
    },
    upsChassisGroundedToPrimaryGround: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Is the UPS chassis grounded to a primary ground to the main telecom ground buss?');
    },
    siteName: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Site Name');
    },
    siteNumber: (changeHistoryField, users) => {
      return FieldChangeHelper.getTimelineItemParams(changeHistoryField, users, 'Site Number');
    },
    structureCondition: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Structure Conditions');
    },
    structureFinish: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Structure Finish');
    },
    lighting: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Lighting');
    },
    grounding: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Grounding');
    },
    antennasAndMount: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Mounts, Antennas and lines');
    },
    iceShieldsWalkways: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Ice Shields, Walkways, Platforms, and Climbing Facilities');
    },
    guys: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'GUYS');
    },
    concreteFoundation: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Concrete Foundation');
    },
    guyedMastAnchor: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Guyed Mast Anchor');
    },
    other: (changeHistoryField, users) => {
      return FieldChangeHelper.getComplexObjectTimelineItemParams(changeHistoryField, users, 'Other');
    },
    correctiveFound: (changeHistoryField, users) => {
      const [removedValues, addedValues] = FieldChangeHelper.getRemovedAddedValues(changeHistoryField);

      return (
        <>
          {
            !!removedValues && !!removedValues.length ?
              FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: removedValues, newValue: []}, users, 'Conditions', 'removed', removedValues.join(', ')) : ''
          }
          {
            !!addedValues && !!addedValues.length ?
              FieldChangeHelper.getArrayTimelineItemParams({...changeHistoryField, oldValue: [], newValue: addedValues}, users, 'Conditions', 'added', addedValues.join(', ')) : ''
          }
        </>
      );
    },
    default: (changeHistoryField, users) => {
      const timelineDot = FieldChangeHelper.getActionTimelineDot('default');

      const user = users.find(user => user.id === changeHistoryField.changedBy);

      return (<ChangeLogTimelineItem timelineDot={timelineDot}>
        {FieldChangeHelper.stringField(changeHistoryField, user && user.name ? user.name : 'N/A')}
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={changeHistoryField.changedAt ? moment(changeHistoryField.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    UsersService.getUsers()
      .then((res) => {
        setUsers(res);
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      {
        isLoading &&
          <div className={'w-full p-12 text-center'}>
              <CircularProgress className={'m-auto'}/>
          </div>
      }
      {!isLoading && !!users && !!users.length &&
          <Timeline sx={{[`& .${timelineItemClasses.root}:before`]: {flex: 0, padding: 0,},}}
                    style={{paddingLeft: '3px', paddingRight: '0px', paddingTop: '0px'}}
                    className={`${props.className}`}>
            {!!props.changeHistory && !!props.changeHistory.length && props.changeHistory.map((fieldChange, index) => {
                return (
                  <span key={index}>
                     {changedFieldHandler[fieldChange.field] ? changedFieldHandler[fieldChange.field](fieldChange, users) : changedFieldHandler.default(fieldChange, users)}
                  </span>
                );
              }
            )}
          </Timeline>
      }
    </>
  );
};

export default ChangeLogTimeline;
