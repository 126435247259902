import { AxiosResponse } from 'axios';
import axiosInstance from './axiosInstance';
import { FormHelper } from '../../shared/helpers/form.helper';

const get = (endpoint: string, queryParams: any = {}, config: any = {}): Promise<AxiosResponse> => {
  return axiosInstance.get(endpoint, {
    params: queryParams,
    ...config,
  });
};

const post = (endpoint: string, body: any, queryParams: any = {}): Promise<AxiosResponse> => {
  return axiosInstance.post(endpoint, body, {
    params: queryParams,
  });
};

const postFormData = (endpoint: string, body: any, queryParams: any = {}): Promise<AxiosResponse> => {
  const formData = FormHelper.generateFormData(body);

  return axiosInstance.post(endpoint, formData, {
    params: queryParams,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const put = (endpoint: string, body: any, queryParams: any = {}): Promise<AxiosResponse> => {
  return axiosInstance.put(endpoint, body, {
    params: queryParams,
  });
};

const putFormData = (endpoint: string, body: any, queryParams: any = {}): Promise<AxiosResponse> => {
  const formData = FormHelper.generateFormData(body);

  return axiosInstance.put(endpoint, formData, {
    params: queryParams,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const remove = (endpoint: string, queryParams: any = {}): Promise<AxiosResponse> => {
  return axiosInstance.delete(endpoint, {
    params: queryParams,
  });
};

const ApiClient = {get, post, postFormData, put, putFormData, remove};

export default ApiClient;
