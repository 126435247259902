import * as React from "react";
import { useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { MultiSelectItemModel } from "../../models/multi-select-item.model";
import OutlinedBox from "../OutlinedBox/OutlinedBox";

type Props = {
  label: string;
  values: string[];
  id: string;
  items: MultiSelectItemModel[];
  onChange: Function;
  className?: string;
  isLoading?: boolean;
};

const MultiSelectList = (props: Props) => {
  const [checkedItems, setCheckedItems] = React.useState(props.values);

  useEffect(() => {
    setCheckedItems(props.values);
  }, [props.values]);

  const handleClick = (key: any) => () => {
    let newValues: string[];

    if (checkedItems.find((item) => item === key)) {
      newValues = checkedItems.filter((item) => item !== key);
    } else {
      newValues = [...checkedItems, key];
    }

    props.onChange(newValues);
  };

  return (
    <OutlinedBox label={props.label} className={`${props.className} `}>
      <List>
        {props.items.map((item) => {
          const labelId = `${props.id}-label-${item.key}`;
          return (
            <ListItem key={item.key} disablePadding>
              <ListItemButton
                role={undefined}
                onClick={handleClick(item.key)}
                dense
                className={"py-0"}
              >
                <ListItemIcon style={{ minWidth: "0px" }} className={"pr-5"}>
                  <Checkbox
                    edge="start"
                    className={"py-0"}
                    checked={!!props.values.find((value) => value === item.key)}
                    tabIndex={-1}
                    disableRipple
                    size={"small"}
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={`${item.value}`} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </OutlinedBox>
  );
};

export default MultiSelectList;
