import React, { memo, useContext, useEffect, useState } from 'react';
import useQueryParams from '../../shared/hooks/useQueryParams';
import InspectionsService from '../../services/inspections.service';
import { motion } from 'framer-motion';
import InspectionCard from '../../shared/components/InspectionCard/InspectionCard';
import { DUE_DATE_STATUSES, INSPECTION_STATUSES } from '../../shared/globals';
import { CircularProgress } from '@mui/material';
import { PaginationModel } from '../../shared/models/pagination.model';
import Pagination from '../../shared/components/Pagination/Pagination';
import LoadingButton from '../../shared/components/LoadingButton/LoadingButton';
import InspectionsFilterCard from '../inspections/components/InspectionsFilterCard';
import { InspectionParamsInterface } from '../../shared/models/query-params-interfaces/inspection-params.interface';
import CardHeading from '../../shared/components/CardHeading/CardHeading';
import StatusStrip from '../../shared/components/StatusStrip/StatusStrip';
import Chip from '../../shared/components/Chip/Chip';
import ToggleButton from '../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import DispatchInspectionModal from './components/DispatchInspectionModal';
import DepartmentContext from '../../DepartmentContext';
import { InspectionViewModel } from '../../shared/models/base-view-models/inspection-view.model';
import { InspectionViewModelFactory } from '../../shared/view-model-factories/inspection-view-model.factory';
import { InspectionModel } from '../../shared/models/base-models/inspection.model';

const downloadPdfReport = async (id: string, setDownloadingIds: Function) => {
  setDownloadingIds(prevState => [...prevState, id]);
  InspectionsService.getInspectionPdfReport(id).then(res => {
    setDownloadingIds(prevState => prevState.filter(downloadId => downloadId !== id));
  });
};

const InspectionsContent = () => {

  const {department, updateDepartment} = useContext(DepartmentContext);

  const [isLoading, setIsLoading] = useState(true);
  const [downloadingIds, setDownloadingIds] = useState([]);
  const [loadingCounter, setLoadingCounter] = useState(0);
  const [paginationData, setPaginationData] = useState(new PaginationModel());
  const [data, setData] = useState([]);
  const [dataView, setDataView] = useState([] as InspectionViewModel[]);

  const [openDispatchInspectionModal, setOpenDispatchInspectionModal] = useState(false);

  const [queryParams, setQueryParams] = useQueryParams({
    department: department,
    page: '1',
    'per-page': '100',
  } as InspectionParamsInterface);

  const loadData = (queryParams) => {
    setLoadingCounter((prev) => prev + 1);
    setIsLoading(true);

    InspectionsService.getInspections(queryParams)
      .then(([paginationData, results]) => {
        if (results.length) {
          updateDepartment(results[0].type);
        }

        setPaginationData(paginationData);
        setData(results);
        setDataView(new InspectionViewModelFactory().mapViewModels<InspectionViewModel, InspectionModel>(results));
        setLoadingCounter((prev) => prev - 1);
      });
  };

  useEffect(() => {
    if (loadingCounter > 0) {
      return;
    }

    setIsLoading(false);
  }, [loadingCounter]);

  useEffect(() => {
    loadData(queryParams);
  }, []);

  const onPageChangedHandler = (page: number) => {
    const updatedQueryParams = {...queryParams, page: page.toString()} as InspectionParamsInterface;
    setQueryParams(updatedQueryParams);

    loadData(updatedQueryParams);
  };

  const onFilterChangedHandler = (updatedQueryParams: InspectionParamsInterface) => {
    setQueryParams(updatedQueryParams);

    loadData(updatedQueryParams);
  };

  const onPerPageChangedHandler = (perPage: number) => {
    const updatedQueryParams = {...queryParams, 'per-page': perPage.toString()} as InspectionParamsInterface;
    setQueryParams(updatedQueryParams);

    loadData(updatedQueryParams);
  };

  const variantsItem = {
    hidden: {opacity: 0, y: 20},
    show: {opacity: 1, y: 0},
  };

  const openDispatchInspectionModalHandler = () => {
    setOpenDispatchInspectionModal(true);
  };

  const onDispatchInspectionModalCloseHandler = () => {
    setOpenDispatchInspectionModal(false);
  };

  const inspectionDispatchedHandler = (inspection: InspectionModel) => {
    const updatedData = [inspection, ...data];
    setData(updatedData);
    setDataView(new InspectionViewModelFactory().mapViewModels<InspectionViewModel, InspectionModel>(updatedData));
  };

  return (
    <div
      className={`h-full flex flex-col relative w-full p-12 lg:p-24 block 
      overflow-auto pointer-events-auto sm:overflow-hidden sm:pointer-events-none`}>
      <div
        className={`pointer-events-auto px-2`}>
        <InspectionsFilterCard
          queryParams={queryParams}
          onFilterChangedHandler={onFilterChangedHandler}
          csvDisabled={data.length === 0}
          className={'pointer-events-auto'}
          isLoading={isLoading}
          documentType={department}
        />
      </div>
      <div className={'pointer-events-auto flex justify-end mb-10'}>
        <ToggleButton
          isActive={true}
          size='small'
          onClick={openDispatchInspectionModalHandler}
          className={`bg-green-600 hover:bg-green-400 whitespace-nowrap pl-12 pr-16`}
          disabled={isLoading}
        >
          <span className={'flex items-center gap-x-4'}>
            {/*@ts-ignore*/}
            <FuseSvgIcon size={19}>material-outline:add</FuseSvgIcon>
            Dispatch Inspection
          </span>
        </ToggleButton>
      </div>
      <div className={`sm:overflow-auto sm:pointer-events-auto ${isLoading ? 'h-full' : 'h-auto'} px-2`}>
        {
          isLoading &&
          <div className={'w-full h-full flex text-center'}>
            <CircularProgress className={'m-auto'} size={52} thickness={4} />
          </div>
        }
        {
          !isLoading && data.length === 0 && <p className={'text-16'}>No Items Found</p>
        }
        {
          !isLoading && dataView && dataView.map((itemView: InspectionViewModel, index) => {
            // TODO: Refactor this code because it is tightly coupled with models of type Fiber

            // TODO: Remove inspectionStatus and inspectionDueDateStatus parameters
            // TODO: enable using department INSPECTION and DUE_DATE statuses list
            return (
              <motion.div key={index} variants={variantsItem} className='mb-8'>
                <InspectionCard
                  status={data[index].status}
                  statusObjects={INSPECTION_STATUSES}
                  linkTo={itemView.linkTo}
                  statusAreaElements={[
                    <StatusStrip
                      key={'statusComponent'}
                      status={data[index].dueDateStatus}
                      condensed={true}
                      statusObjects={DUE_DATE_STATUSES} />,
                  ]}
                  className={`md:flex-row`}
                  contentElements={itemView.listItemFields}
                  headingAreaElements={[
                    <CardHeading
                      key={'inspectionHeading'}
                      heading={itemView.listItemHeading}
                      className={'w-full'}
                    />
                  ]}
                  contentElementClassName={`w-full grid ${itemView.listItemFieldsClassName} gap-6 align-middle`}
                  infoAreaChildren={[
                    <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
                          label={itemView.dateTime}
                    />
                    // <DateChipCardElement key={'dateChip'} date={item.getDueDate} condensed={true} clickable={true} />
                  ]}
                  actionAreaChildren={[
                    <LoadingButton key={'button'} disabled={isLoading || !!itemView.isReadonly} size='small'
                                   onClick={() => downloadPdfReport(data[index].id, setDownloadingIds)} isLoading={downloadingIds.includes(data[index].id)}
                                   text={itemView.buttonText}
                                   className={`w-full whitespace-nowrap text-11 px-14`} />,
                  ]
                  }
                />
              </motion.div>
            );
          })
        }
        {
          !isLoading && data.length > 0 &&
          <Pagination page={paginationData.page} totalPages={paginationData.pages} perPage={paginationData.perPage}
                      totalItems={paginationData.total}
                      onPageChanged={onPageChangedHandler} onPerPageChanged={onPerPageChangedHandler}
                      className={'mt-12'} />
        }
      </div>
      {openDispatchInspectionModal &&
          <DispatchInspectionModal onClose={onDispatchInspectionModalCloseHandler} onSave={inspectionDispatchedHandler} documentType={department}/>
      }
    </div>
  );
};

export default memo(InspectionsContent);
