import React, { useEffect, useState } from 'react';
import MasonryImageList from '../MasonryImageList/MasonryImageList';
import { Typography } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ImagesViewer from '../ImagesViewer';
import { ViewerImageModel } from '../../models/viewer-image.model';
import AddAttachmentButton from './AddAttachmentButton';
import { FileModel } from '../../models/file.model';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import AttachmentsList from './AttachmentsList';
import { FileHelper } from '../../helpers/file.helper';
import { ImageHelper } from '../../helpers/imageHelper';

type Props = {
  attachments: FileModel[];
  onChanged: Function;
  className: string;
}

const ModelAttachments = (props: Props) => {

  const [attachmentsLoaded, setAttachmentsLoaded] = useState(false);

  const [images, setImages] = useState([] as ViewerImageModel[]);
  const [attachedFiles, setAttachedFiles] = useState([] as FileModel[]);
  const [newFiles, setNewFiles] = useState([]);

  const [viewerOpened, setViewerOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    if (attachmentsLoaded || !props.attachments) {
      return;
    }

    const viewerImages = FileHelper.getViewerImagesFromAttachments(props.attachments);
    ImageHelper.loadThumbnailsForImageViewer(viewerImages, onImageLoaded);
    setImages(viewerImages);

    const attachedFiles = FileHelper.getFilesFromAttachments(props.attachments);
    setAttachedFiles(attachedFiles);
    setAttachmentsLoaded(true);
  }, [props.attachments]);

  const onImageLoaded = (images: ViewerImageModel[]) => {
    setImages(images.map(image => new ViewerImageModel(image)));
  };

  const imageClickHandler = (index: number) => {
    setSelectedImage(index);
    setViewerOpened(true);
  };

  const viewerClosedHandler = () => {
    setViewerOpened(false);
  };

  const imageDeletedHandler = (deletedImage: ViewerImageModel) => {
    const attachments = props.attachments.filter(attachment => attachment.id !== deletedImage.id);

    setImages(prevState => prevState.filter(image => image.id !== deletedImage.id));
    props.onChanged({attachments});
  };

  const handleRemoveAttachment = (deletedFile: FileModel) => {
    const attachments = props.attachments.filter(attachment => attachment.id !== deletedFile.id);
    const attachedFiles = FileHelper.getFilesFromAttachments(attachments);

    setAttachedFiles(attachedFiles);
    props.onChanged({attachments});
  };

  const handleRemoveFile = (deletedFile: File, index: number) => {
    const files = newFiles.filter((file, i) => i !== index);

    setNewFiles(files);
    props.onChanged({files});
  };

  const handleFilesAdded = (filesAdded: File[]) => {
    const files = [...newFiles, ...filesAdded];

    setNewFiles(files);
    props.onChanged({files});
  };

  const hasImages = (): boolean => {
    return images.length > 0;
  };

  const hasAttachedFiles = (): boolean => {
    return attachedFiles.length > 0;
  };

  const hasFiles = (): boolean => {
    return newFiles.length > 0;
  };

  const attachmentsAdded = (): boolean => {
    return hasImages() || hasAttachedFiles() || hasFiles();
  };

  return (
    <>
      <ImagesViewer images={images} opened={viewerOpened} onViewerClose={viewerClosedHandler}
                    selectedImageIndex={selectedImage} />
      <div className={`${props.className}`}>
        <Typography className='text-lg font-semibold mb-8'>{'Attachments'}</Typography>
        <div className={`rounded-md p-16 pb-10 border border-gray-500 w-full gap-12 align-middle`}>

          {
            !attachmentsAdded() &&
            <p className={'text-14 mb-12'}>No Attachments added</p>
          }

          {
            hasImages() &&
            <MasonryImageList
              imageAction={imageDeletedHandler}
              /* @ts-ignore */
              imageActionIcon={<FuseSvgIcon style={{strokeWidth: '0.2'}} size={16}>material-outline:clear</FuseSvgIcon>}
              imageActionText={'Remove Photo'}
              images={images}
              onImageClick={imageClickHandler}
              actionsAlwaysVisible={true}
              className='flex flex-wrap gap-10 sm:gap-12 rounded mb-12'
              imageClassName='w-72 h-72 cursor-pointer'
            />
          }

          <div
            className={`gap-8 grid sm:grid-cols-2 md:grid-cols-3`}>
            <AttachmentsList files={attachedFiles} itemIcon={<ClearOutlinedIcon />}
                             onItemIconButtonClick={handleRemoveAttachment} actionsAlwaysVisible={true} />
            <AttachmentsList files={FileHelper.toFileModels(newFiles)} itemIcon={<ClearOutlinedIcon />}
                             onItemIconButtonClick={handleRemoveFile} actionsAlwaysVisible={true} />
            <AddAttachmentButton onFilesAdded={handleFilesAdded}
                                 className={`mt-12`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ModelAttachments;