import { FieldErrorProps } from '@rjsf/utils';

const FieldErrorTemplate = (props: FieldErrorProps) => {

  if (!props.errors || !props.errors.length) {
    return null;
  }

  return (
    <div className={'custom-error-template'}>
      <p className={'error-message text-12 text-red'} style={{ margin: '0px 2px' }}>
        {props.errors.join(', ')}
      </p>
    </div>
  );
};

export default FieldErrorTemplate;