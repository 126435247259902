import { CircularProgress, IconButton, Skeleton, Tooltip } from '@mui/material';
import * as React from 'react';
import { ViewerImageModel } from '../../models/viewer-image.model';
import { useEffect, useState } from 'react';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';

type Props = {
  image?: ViewerImageModel;
  className?: string;
  onImageClick: Function;
  onImageAction?: Function;
  onImageWatermarkAction?: Function;
  imageActionIcon?: JSX.Element | Function;
  imageActionText?: string;
  index: number;
  actionsAlwaysVisible?: boolean;
  hasWatermark?: boolean;
}

const SquareThumbnailImage = (props: Props) => {

  const [downloadingCounter, setDownloadingCounter] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isDownloadingWithWatermark, setIsDownloadingWithWatermark] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const areActionsVisible = () => {
    return isHovered || isDownloading || isDownloadingWithWatermark || props.actionsAlwaysVisible;
  };

  const onClickHandler = (index) => {
    if (!props.onImageClick) {
      return;
    }
    props.onImageClick(index);
  };

  const actionHandler = async () => {
    setDownloadingCounter((prev) => prev + 1);
    setIsDownloading(true);
    await props.onImageAction(props.image);
    setIsDownloading(false);
    setDownloadingCounter((prev) => prev - 1);
  };

  const actionWatermarkHandler = async () => {
    setDownloadingCounter((prev) => prev + 1);
    setIsDownloadingWithWatermark(true);
    await props.onImageWatermarkAction(props.image);
    setIsDownloadingWithWatermark(false);
    setDownloadingCounter((prev) => prev - 1);
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!props.hasWatermark) {
      return;
    }

    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  useEffect(() => {
    if (downloadingCounter > 0) {
      return;
    }

    setMenuOpened(false);
  }, [downloadingCounter]);

  const downloadMenu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'download'} onClick={() => actionHandler()} isLoading={isDownloading}
                  icon={'heroicons-outline:download'} text={'Download'} disabled={isDownloading}/>,
        <MenuItem key={'downloadWithWatermarks'} onClick={() => actionWatermarkHandler()} isLoading={isDownloadingWithWatermark}
                  icon={'heroicons-outline:download'} text={'Download with Watermark'} disabled={isDownloadingWithWatermark}/>,
      ]}
    />;

  useEffect(() => {
    if (areActionsVisible()) {
      return;
    }

    setMenuOpened(false);
  }, [areActionsVisible()]);

  return (
    <div className={`relative overflow-hidden block ${props.className}`} style={{paddingTop: '100%'}}
         onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
      {
        props.image?.thumbnailObj ? (
          !!props.onImageAction && areActionsVisible() ?
            (<div className='container' style={{zIndex: 100000}}>
              <img alt='s3' src={props.image.thumbnailObj} onClick={() => onClickHandler(props.index)}
                   className='w-full h-full object-cover absolute top-0 left-0 bottom-0 m-auto' />
              {!props.hasWatermark ?
                <Tooltip followCursor={true} title={props.imageActionText}
                         placement='top-start'
                         className={`my-auto`}
                >
                  <IconButton
                    style={{
                      maxWidth: '18px',
                      maxHeight: '18px',
                      minWidth: '18px',
                      minHeight: '18px',
                      width: '18px',
                      height: '18px',
                    }}
                    className={`bg-white hover:bg-gray-200 top-5 right-5 absolute shadow-2`}
                    onClick={() => actionHandler()}
                  >
                    {
                      isDownloading ?
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                          <CircularProgress color={'inherit'} size={10} thickness={4} />
                        </div> :
                        typeof props.imageActionIcon === 'function' ?
                          props.imageActionIcon(props.image, props.index) :
                          props.imageActionIcon
                    }
                  </IconButton>
                  {/*</Button>*/}
                </Tooltip> :
                <div>
                  <Tooltip followCursor={true} title={props.imageActionText}
                           placement='top-start'
                           className={`my-auto`}>
                    <IconButton
                      style={{
                        maxWidth: '18px',
                        maxHeight: '18px',
                        minWidth: '18px',
                        minHeight: '18px',
                        width: '18px',
                        height: '18px',
                      }}
                      className={`bg-white hover:bg-gray-200 top-5 right-5 absolute shadow-2`}
                      onClick={showMenu}
                    >
                      {typeof props.imageActionIcon === 'function' ?
                        props.imageActionIcon(props.image, props.index) :
                        props.imageActionIcon}
                    </IconButton>
                  </Tooltip>
                  {downloadMenu}
                </div>
              }
            </div>) :
            (<img alt='s3' src={props.image.thumbnailObj} onClick={() => onClickHandler(props.index)}
                  className='w-full h-full object-cover absolute top-0 left-0 bottom-0 m-auto' />)
        ) : (
          <Skeleton variant='rectangular' animation='wave'
                    className={`w-full h-full object-cover absolute top-0 left-0 bottom-0 m-auto`} />
        )
      }
    </div>
  );
};

export default SquareThumbnailImage;
