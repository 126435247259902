import { RJSFSchema, UiSchema } from "@rjsf/utils";
import {
  FIBER_CONDITIONS_LIST,
  ASSET_INSPECTION_TYPES,
  DAMAGE_CAUSES_LIST,
  INSPECTED_ATTRIBUTES_LIST,
} from '../globals';

export const FIBER_ASSET_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: "object",
  required: [],
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    inspectionType: {
      type: "string",
      title: "Inspection Type",
    },
    inspectors: {
      type: "string",
      title: "Inspectors",
    },
    damageCause: {
      type: "string",
      title: "Damage Cause",
    },
    barcode: {
      type: "string",
      title: "Barcode",
    },
    conditions: {
      type: "string",
      title: "Conditions",
    },
    inspectedAttributes: {
      type: "string",
      title: "Inspected Attributes",
    },
    comment: {
      type: "string",
      title: "Comment",
    },
  },
};

export const FIBER_ASSET_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  "ui:classNames":
    "w-full grid sm:grid-cols-2 md:grid-cols-6 gap-x-12 gap-y-16",
  "ui:inline": true,
  name: {
    "ui:placeholder": "Name",
    "ui:classNames": "md:col-span-2",
  },
  inspectionType: {
    "ui:placeholder": "Inspection Type",
    "ui:widget": "SelectWidget",
    "ui:classNames": "md:col-span-2",
    "ui:options": {
      optionsList: ASSET_INSPECTION_TYPES,
    },
  },
  inspectors: {
    "ui:placeholder": "Inspectors",
    "ui:widget": "UserMultiSelectWidget",
    "ui:classNames": "sm:col-span-2",
  },
  damageCause: {
    "ui:placeholder": "Damage Cause",
    "ui:classNames": "md:col-span-2",
    "ui:widget": "SelectWidget",
    "ui:options": {
      optionsList: DAMAGE_CAUSES_LIST,
    },
  },
  barcode: {
    "ui:placeholder": "Barcode",
    "ui:classNames": "md:col-span-2",
  },
  conditions: {
    "ui:placeholder": "Conditions",
    "ui:classNames": "md:col-span-3",
    "ui:widget": "MultiSelectListWidget",
    "ui:options": {
      optionsList: FIBER_CONDITIONS_LIST,
    },
  },
  inspectedAttributes: {
    "ui:placeholder": "Inspected Attributes",
    "ui:classNames": "md:col-span-3",
    "ui:widget": "MultiSelectListWidget",
    "ui:options": {
      optionsList: INSPECTED_ATTRIBUTES_LIST,
    },
  },
  comment: {
    "ui:placeholder": "Comment",
    "ui:widget": "textarea",
    "ui:classNames": "sm:col-span-2 md:col-span-6",
  },
  "ui:submitButtonOptions": {
    norender: true,
  },
};
