import i18next from 'i18next';
import React from 'react';
import en from './i18n/en';
import Asset from "./Asset";

i18next.addResourceBundle('en', 'assetPage', en);

const AssetConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: 'asset/:id',
      element: <Asset />,
    },
  ],
};

export default AssetConfig;
