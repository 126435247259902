import { motion } from 'framer-motion';
import InspectorsTable from './components/InspectorsTable';
import ItemsCard from './components/ItemsCard';
import React, { useEffect, useState } from 'react';
import DatePicker from '../../shared/components/DatePicker/DatePicker';
import useQueryParams from '../../shared/hooks/useQueryParams';
import moment from 'moment/moment';
import {
  DashboardParamsInterface
} from '../../shared/models/query-params-interfaces/dashboard-params.interface';
import ReportsService from '../../services/report.service';
import { StatsModel } from '../../shared/models/stats.model';
import {
  ReportParamsInterface,
} from '../../shared/models/query-params-interfaces/report-params.interface';
import { Card, CircularProgress } from '@mui/material';
import ConditionsStatsCard from './components/ConditionsStatsCard';
import DueDateReportingCard from './components/DueDateReportingCard';
import { InspectionHelper } from '../../shared/helpers/inspection.helper';

type Props = {
  department: string;
};

const DashboardContent = (props: Props) => {

  const [inspectionStatsLoading, setInspectionStatsLoading] = useState(false);
  const [workOrderStatsLoading, setWorkOrderStatsLoading] = useState(false);
  const [conditionStatsLoading, setConditionStatsLoading] = useState(false);

  const [stats, setStats] = useState({} as StatsModel);
  const [inspectorStats, setInspectorStats] = useState([]);
  const [conditionStats, setConditionStats] = useState([]);

  const [queryParams, setQueryParams] = useQueryParams({
    department: props.department,
    'start-date': moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD'),
    'end-date': moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD'),
  } as ReportParamsInterface);

  const loadInspectionStats = (queryParams: ReportParamsInterface) => {
    setInspectionStatsLoading(true);

    ReportsService.getInspectionStats(queryParams)
      .then((result) => {
        setStats(prevState => new StatsModel({...prevState, ...result}));
        setInspectorStats(prevState => (InspectionHelper.getInspectorStats(prevState, result.inspectorStats)));
        setInspectionStatsLoading(false);
      });
  };

  const loadWorkOrderStats = (queryParams: ReportParamsInterface) => {
    setWorkOrderStatsLoading(true);

    ReportsService.getWorkOrderStats(queryParams)
      .then((result) => {
        setStats(prevState => new StatsModel({...prevState, ...result}));
        setInspectorStats(prevState => (InspectionHelper.getInspectorStats(prevState, result.inspectorStats)));
        setConditionStats(prevState => (InspectionHelper.getConditionStats(prevState, result.conditionsList)));
        setWorkOrderStatsLoading(false);
      })
  };

  const loadConditionStats = (queryParams: ReportParamsInterface) => {
    setConditionStatsLoading(true);

    ReportsService.getConditionStats(queryParams)
      .then((result) => {
        setStats(prevState => new StatsModel({...prevState, ...result}));
        setInspectorStats(prevState => (InspectionHelper.getInspectorStats(prevState, result.inspectorStats)));
        setConditionStats(prevState => (InspectionHelper.getConditionStats(prevState, result.conditionsList)));
        setConditionStatsLoading(false);
      });
  };

  useEffect(() => {
    setStats({} as StatsModel);
    setInspectorStats([]);
    setConditionStats([]);

    loadInspectionStats(queryParams);
    loadWorkOrderStats(queryParams);
    loadConditionStats(queryParams);
  }, []);

  const updateFilterParam = (params: { name: string, value: string }[]): void => {
    const paramsModel = {
      ...queryParams,
    } as DashboardParamsInterface;
    params.forEach(param => paramsModel[param.name] = param.value);
    setQueryParams(paramsModel);

    setStats({} as StatsModel);
    setInspectorStats([]);
    setConditionStats([]);

    loadInspectionStats(paramsModel);
    loadWorkOrderStats(paramsModel);
    loadConditionStats(paramsModel);
  };

  const startDateChangedHandler = (date: Date) => {
    const params = [
      {name: 'start-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const endDateChangedHandler = (date: string) => {
    const params = [
      {name: 'end-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const item = {
    hidden: {opacity: 0, y: 20},
    show: {opacity: 1, y: 0},
  };

  return (
    <div className={'h-full w-full p-12 lg:p-24 overflow-auto'}>
      <Card
        className={'mx-2 mt-2 shadow rounded-2xl p-12'}>
        <motion.div className={'w-full grid sm:grid-cols-2 md:flex gap-x-12 gap-y-16 p-6'}
                    initial={{opacity: 0, y: -40}}
                    animate={{opacity: 1, y: 0, transition: {delay: 0.15}}}>
          <DatePicker
            label='Date From'
            value={queryParams['start-date']}
            onChange={startDateChangedHandler}
          />
          <DatePicker
            label='Date To'
            value={queryParams['end-date']}
            onChange={endDateChangedHandler}
          />
        </motion.div>
      </Card>

      <motion.div
        className='grid sm:grid-cols-3 md:grid-cols-6 gap-16 w-full mt-16 px-2'
        initial='hidden'
        animate='show'
        variants={item}
      >
        <ItemsCard
          label='Inspections'
          amount={stats.inspections}
          linkTo={`/inspections`}
          queryParams={queryParams}
          isLoading={inspectionStatsLoading}
        />
        <ItemsCard
          label='Inspected Assets'
          amount={stats.inspectedAssets}
          linkTo={`/asset-inspections`}
          queryParams={queryParams}
          isLoading={conditionStatsLoading}
        />
        <ItemsCard
          label='Conditions Found'
          amount={stats.conditionsFound}
          linkTo={`/asset-inspections`}
          queryParams={{...queryParams, 'condition-found': 'true'}}
          isLoading={conditionStatsLoading}
        />
        <ItemsCard
          className={'sm:col-span-2'}
          label='Escalations Created'
          amount={stats.escalationStats ? stats.escalationStats.escalationsCreated : 0}
          additionalDetails={stats.getEscalationInfoCardDetails}
          linkTo={`/escalations`}
          queryParams={queryParams}
          isLoading={workOrderStatsLoading || conditionStatsLoading}
        />
        <ItemsCard
          label='WOs Created'
          amount={stats.getWOsCreated}
          linkTo={`/escalations`}
          queryParams={queryParams}
          isLoading={workOrderStatsLoading}
        />
      </motion.div>

      <motion.div
        className={'w-full mt-16 overflow-hidden px-2'}
        initial='hidden'
        animate='show'
        variants={item}
      >
        <DueDateReportingCard report={stats.inspectionsReport} title={'Inspection Stats'}
                              pngTitle={'inspection-stats'} linkTo={'/inspections'} queryParams={queryParams}/>
      </motion.div>

      <motion.div
        className={'w-full mt-16 overflow-hidden px-2'}
        initial='hidden'
        animate='show'
        variants={item}
      >
        <DueDateReportingCard report={stats.workOrdersReport} title={'Escalation Stats'} pngTitle={'escalation-stats'}
                              linkTo={'/escalations'} queryParams={queryParams}/>
      </motion.div>

      {
        !conditionStatsLoading &&
          <motion.div
              className={'w-full mt-16 overflow-hidden px-2'}
              initial='hidden'
              animate='show'
              variants={item}
          >
              <ConditionsStatsCard data={conditionStats} queryParams={queryParams}
                                   conditionsFound={stats.conditionsFound}/>
          </motion.div>
      }

      {
        inspectionStatsLoading || workOrderStatsLoading || conditionStatsLoading ?
          <div className={'w-full mt-24 flex text-center'}>
            <CircularProgress className={'m-auto'} size={52} thickness={4}/>
          </div> :
          <motion.div
            className={'w-full mt-16 overflow-hidden px-2'}
            initial='hidden'
            animate='show'
            variants={item}
          >
            <InspectorsTable rows={inspectorStats} queryParams={queryParams}/>
          </motion.div>
      }
    </div>
  );
};

export default DashboardContent;
