import React, { useEffect, useState } from 'react';
import InspectionsService from '../../../services/inspections.service';
import { InspectionHelper } from '../../helpers/inspection.helper';
import Autocomplete from '@mui/material/Autocomplete';
import { CircularProgress, TextField } from '@mui/material';
import { FiberInspectionModel } from '../../types/fiber/models/fiber-inspection.model';

type Props = {
  error?: boolean;
  helperText?: string;
  inspectionId?: string;
  value: string;
  label: string;
  onChange: Function;
  className?: string;
  formLoadingHandler?: any;
  formLoadedHandler?: any;
  disabled?: boolean;
}

const SAPWOSelect = (props: Props) => {

  const [value, setValue] = useState(props.value);
  const [isLoading, setIsLoading] = useState(false);
  const [sapWOs, setSapWOs] = useState([]);

  const handleChange = (event, value) => {
    props.onChange(value);
    setValue(value);
  };

  useEffect(() => {
    if (!props.value) {
      return;
    }

    setValue(props.value);
  }, [props.value]);

  useEffect(() => {
    if (!props.inspectionId) {
      return;
    }

    !!props.formLoadingHandler && props.formLoadingHandler();
    setIsLoading(true);
    // TODO: Refactor later
    InspectionsService.getInspection(props.inspectionId)
      .then((res) => {
        setSapWOs(InspectionHelper.getInspectionSapWOs(res as FiberInspectionModel));
        setIsLoading(false);
        !!props.formLoadedHandler && props.formLoadedHandler();
      });
  }, [props.inspectionId]);

  return (
    <Autocomplete
      id='sap-external-id'
      freeSolo
      componentsProps={{
        popper: {
          sx: {
            zIndex: 10000
          }
        }
      }}
      disableClearable
      value={value}
      disabled={!!isLoading || props.disabled}
      size={'small'}
      options={sapWOs}
      className={`${props.className}`}
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          error={!!props.error}
          helperText={props.helperText}
          disabled={!!isLoading || props.disabled}
          onChange={(event) =>
            props.onChange(event)
          }
          FormHelperTextProps={{ style: { margin: '0px 2px' }}}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading && <div className={`mr-5`}>
                    <CircularProgress size={14} color={'inherit'} />
                </div>}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};


export default SAPWOSelect;
