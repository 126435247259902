import { WidgetProps } from '@rjsf/utils';
import { TextField } from '@mui/material';

const TextareaWidget = (props: WidgetProps) => {
  return (
    <TextField value={props.value ? props.value : ''} label={`${props.label}${props.required ? '*' : ''}`}
               placeholder={`${props.placeholder}${props.required ? '*' : ''}`}
               multiline variant='outlined' rows={3}
               className={`w-full ${props.className ? props.className : ''}`}
               onChange={(event) => props.onChange(event.target.value)}
    />
  );
};

export default TextareaWidget;