import ApiClient from './api-client/api-client';
import { PaginationModel } from '../shared/models/pagination.model';
import { PaginationHelper } from '../shared/helpers/pagination.helper';
import { BaseModel } from '../shared/models/base.model';
import { ModelMapper } from '../shared/model-mappers/model.mapper';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

const fetch = <T extends BaseModel>(endpoint: string, modelMapper: ModelMapper, queryParams: any = {}, config: any = {}): Promise<T> => {
  return ApiClient.get(endpoint, queryParams, config)
    .then(response => response.data)
    .then((result: any) => modelMapper.mapModel<T>(result));
};

const fetchList = <T extends BaseModel>(endpoint: string, modelMapper: ModelMapper, queryParams: any = {}, config: any = {}): Promise<[PaginationModel, T[]]> => {
  return ApiClient.get(endpoint, queryParams, config)
    .then(response => response.data)
    .then((results: any[]) => modelMapper.mapModels<T>(results))
    .then((results: T[]) => results.sort((a, b) => {
        return collator.compare(b.createdAt, a.createdAt);
      })
    )
    .then((results: T[]) => PaginationHelper.paginate(results, queryParams));
};

const update = <T extends BaseModel>(endpoint: string, body: any, modelMapper: ModelMapper, queryParams: any = {}): Promise<T> => {
  return ApiClient.put(endpoint, body, queryParams)
    .then(response => response.data)
    .then((result: any) => modelMapper.mapModel<T>(result));
};

const create = <T extends BaseModel>(endpoint: string, body: any, modelMapper: ModelMapper, queryParams: any = {}): Promise<T> => {
  return ApiClient.post(endpoint, body, queryParams)
    .then(response => response.data)
    .then((result: any) => modelMapper.mapModel<T>(result));
};

const remove = <T extends BaseModel>(endpoint: string, modelMapper: ModelMapper): Promise<T> => {
  return ApiClient.remove(endpoint)
    .then(response => response.data)
    .then((result: any) => modelMapper.mapModel<T>(result));
};

const BaseContextService = {
  fetch,
  fetchList,
  update,
  create,
  remove,
};

export default BaseContextService;