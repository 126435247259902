import { DispatchingDocumentModel } from '../../../models/base-models/dispatching-document.model';

export class GeneratorsDispatchingDocumentModel extends DispatchingDocumentModel {
  inspectionId: string;
  inspectorExternalId: string;
  inspectionType: number;
  dueDate: string;
  city: string;
  state: string;
  streetAddress: string;
  zip: string;
  size: string;
  capacityUnits: string;
  tierRating: number;
  comment: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
