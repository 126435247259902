import { Modal as MaterialModal, Button } from '@mui/material';
import Grow from '@mui/material/Grow';
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  title?: any;
  children: JSX.Element[] | JSX.Element;
  onClose: Function;
  footerContent?: JSX.Element;
  className?: string;
}

const Modal = (props: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <MaterialModal
      keepMounted
      open={true}
      onClose={handleClose}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
        className={`flex items-center overflow-hidden w-full sm:w-4/5 lg:w-3/5 h-full sm:h-4/5 ${props.className}`}>
        <Grow in={isOpen} onExited={handleClose}>
          <Box className={'w-full h-full shadow sm:rounded-md bg-white flex flex-col'}>
            <span className={'text-2xl font-bold px-24 pt-24 pb-16 flex justify-between items-center'}>
              {props.title}
              <div className={'flex items-center cursor-pointer'}>
                <Button style={{
                  maxWidth: '36px',
                  maxHeight: '36px',
                  minWidth: '36px',
                  minHeight: '36px',
                  width: '36px',
                  height: '36px',
                }} disabled={false} color={'inherit'} className={'bg-transparent'} onClick={() => handleClose()}>
                  {/*@ts-ignore*/}
                  <FuseSvgIcon size={27}>material-outline:clear</FuseSvgIcon>
                </Button>
              </div>
            </span>
            <div className='h-1 border-b-1 border-gray-500'></div>
            <div className={'h-full overflow-y-scroll'}>
              <div className={'h-auto'}>
                {props.children}
              </div>
            </div>
            <div className='h-1 border-b-1 border-gray-500'></div>
            {props.footerContent}
          </Box>
        </Grow>
      </div>
    </MaterialModal>
  );
};

export default Modal;
