import BaseContextService from './base-context.service';
import { InspectionModelMapper } from '../shared/model-mappers/inspection-model.mapper';
import { DispatchingDocumentModel } from '../shared/models/base-models/dispatching-document.model';
import { InspectionModel } from '../shared/models/base-models/inspection.model';

const DISPATCHING_ENDPOINT = '/dispatch-document';

const dispatchInspection = (dispatchingDocument: DispatchingDocumentModel, type: string): Promise<InspectionModel> => {
  return BaseContextService.create<DispatchingDocumentModel>(`${DISPATCHING_ENDPOINT}`, {...dispatchingDocument, type: type}, new InspectionModelMapper())
};

const DispatchingService = {
  dispatchInspection,
};

export default DispatchingService;


