import React from 'react';
import { showMessage } from 'app/store/fuse/messageSlice';

const NotificationMessage = (messageText: string, notificationType: any, autoHideDurationTime = 3000, verticalPos = 'bottom', horizontalPos = 'center') => {
  return (
    showMessage({
      message: messageText,
      autoHideDuration: autoHideDurationTime,
      anchorOrigin: {
        vertical: verticalPos,
        horizontal: horizontalPos
      },
      variant: notificationType,
      style: {
        width: '80%'
      }
    })
  );
}

export { NotificationMessage }




