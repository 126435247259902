import React, { useContext, useEffect, useState } from 'react';
import MultiSelect from '../../../shared/components/MultiSelect/MultiSelect';
import InspectorsContext from '../../../InspectorsContext';

type Props = {
  onChange: Function;
  className?: string;
  label: string;
  id: string;
  usersIds: string[];
  userIdKey?: string;
  formLoadingHandler?: any;
  formLoadedHandler?: any;
}

const UserMultiSelect = (props: Props) => {

  const {inspectors} = useContext(InspectorsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  const userIdKey = props.userIdKey ? props.userIdKey : 'externalId';

  useEffect(() => {
    if (!inspectors) {
      setUsers([]);
      setIsLoading(false);
      !!props.formLoadedHandler && props.formLoadedHandler();
      return;
    }

    setUsers(inspectors);
    setIsLoading(false);
    !!props.formLoadedHandler && props.formLoadedHandler();
  }, [inspectors]);

  useEffect(() => {
    if (!users || !users.length || !props.usersIds || !props.usersIds.length) {
      setSelectedUsers([]);
      return;
    }

    setSelectedUsers(props.usersIds.filter((paramsItem) => users.find((inspector) => inspector[userIdKey] === paramsItem)));
  }, [users, props.usersIds]);

  return (
    <MultiSelect
      isLoading={isLoading}
      id={props.id}
      className={props.className}
      label={props.label}
      onChange={props.onChange}
      values={selectedUsers}
      items={users && users.length ? users.map((inspector) => ({
        key: inspector[userIdKey],
        value: inspector.name,
      })) : []}
    />
  );
};

export default UserMultiSelect;
