import { WidgetProps } from '@rjsf/utils';
import Select from '../../Select/Select';

interface SelectWidgetProps extends WidgetProps {
  options: {
    optionsList: { key: string; value: string }[];
  };
}

const SelectWidget = (props: SelectWidgetProps) => {
  return (
    <Select
      value={props.value}
      label={`${props.label}${props.required ? '*' : ''}`}
      className={`w-full ${props.className ? props.className : ''}`}
      onChange={(event) => props.onChange(event.target.value)}
      list={props.options.optionsList ? props.options.optionsList : []}
      error={!!props.rawErrors}
    />
  );
};

export default SelectWidget;
