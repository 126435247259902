import { BaseModel } from '../base.model';

export class BaseViewModel {

  constructor(model: BaseModel) {
  }

  protected returnValue(value: string) {
    if (value === undefined || value === null) {
      return 'N/A';
    }

    return value;
  };

}