import {
  WORK_ORDER_STATUSES,
  InspectionStatusType,
  ASSET_INSPECTION_STATUSES, INSPECTION_STATUS,
  WORK_ORDER_STATUS, ASSET_INSPECTION_STATUS,
  CONDITIONS_LIST,
} from '../globals';
import { FiberInspectionModel } from '../types/fiber/models/fiber-inspection.model';
import { FiberInspectionViewModel } from '../types/fiber/view-models/fiber-inspection.view-model';
import { WorkOrderModel } from '../models/base-models/work-order.model';
import { FiberAssetInspectionViewModel } from '../types/fiber/view-models/fiber-asset-inspection.view-model';

export class InspectionHelper {

  static getWorkOrderStatusObject(status: string): InspectionStatusType {
    return WORK_ORDER_STATUSES.find(workOrderStatus => workOrderStatus.key === status);
  }

  static getWorkOrderStatusValue(status: string): string {
    const workOrderStatus = this.getWorkOrderStatusObject(status);

    return workOrderStatus ? workOrderStatus.value : '';
  }

  static getWorkOrderStatusColor(status: string): string {
    const workOrderStatus = this.getWorkOrderStatusObject(status);

    return workOrderStatus ? workOrderStatus.bgColor : '';
  }

  static getWorkOrderMapPointColor(status: string): number[] {
    const workOrderStatus = this.getWorkOrderStatusObject(status);

    return workOrderStatus ? workOrderStatus.bgColorRGB : [189, 189, 189];
  }

  static getAssetInspectionStatusColor(statusKey: string): number[] {
    if (!statusKey) {
      return [0, 0, 0];
    }

    const statusObject = ASSET_INSPECTION_STATUSES.find(status => status.key === statusKey);

    if (!statusObject) {
      return [0, 0, 0];
    }

    return statusObject.bgColorRGB;
  }

  static inspectionIsInQa(inspection: any, userId: string): boolean {
    if (!inspection.status || !inspection.assignedTo || !inspection.assignedTo.length) {
      return false;
    }

    const statusCanChange = (inspection.status === INSPECTION_STATUS.waitingQa || inspection.status === INSPECTION_STATUS.inQa || inspection.status === INSPECTION_STATUS.completed);

    const assigneeIds = inspection.assignedTo.map(assignee => assignee.id ? assignee.id : assignee);

    if (!assigneeIds || !assigneeIds.length) {
      return;
    }

    const userIsAssignee = assigneeIds.some(assigneeId => assigneeId === userId);

    return statusCanChange && userIsAssignee;
  }

  static inspectionWasInQa(inspection: any): boolean {
    if (!inspection || !inspection.assetInspections || !inspection.assetInspections.length) {
      return;
    }

    const assetInspectionStatuses = inspection.assetInspections.map(assetInspection => assetInspection.status);

    if (!assetInspectionStatuses || !assetInspectionStatuses.length) {
      return;
    }

    return !!assetInspectionStatuses.some(assetInspectionStatus => assetInspectionStatus === ASSET_INSPECTION_STATUS.qaPassed || assetInspectionStatus === ASSET_INSPECTION_STATUS.qaFailed);
  }

  static workOrderStatusCanChange(workOrder: WorkOrderModel, userId: string): boolean {
    if (!workOrder.status || !workOrder.assignedTo || !workOrder.assignedTo.length) {
      return false;
    }

    const statusCanChange = (workOrder.status !== WORK_ORDER_STATUS.waitingAssignment && workOrder.status !== WORK_ORDER_STATUS.closed);

    const assigneeIds = workOrder.assignedTo.map(assignee => assignee.id ? assignee.id : assignee);

    if (!assigneeIds || !assigneeIds.length) {
      return;
    }

    const userIsAssignee = assigneeIds.some(assigneeId => assigneeId === userId);

    return statusCanChange && userIsAssignee;
  }

  static userIsCreator(workOrder: WorkOrderModel, userId: string): boolean {
    if (!workOrder || !workOrder.createdBy || !workOrder.createdBy.id || !userId) {
      return false;
    }

    return workOrder.createdBy.id === userId;
  }

  static statusIsClosed(model: any): boolean {
    if (!model || !model.status) {
      return false;
    }

    return model.status === 'closed';
  }

  static getAssetInspectionPreviousNext(assetInspection: any): any {
    if (!assetInspection || !assetInspection.inspection || !assetInspection.inspection.assetInspections || !assetInspection.inspection.assetInspections.length) {
      return {previous: undefined, next: undefined};
    }

    const assetInspectionIds = assetInspection.inspection.assetInspections.map(assetInspection => assetInspection.id ? assetInspection.id : assetInspection);

    if (!assetInspectionIds || !assetInspectionIds.length) {
      return {previous: undefined, next: undefined}
    }

    const currentIndex = assetInspectionIds.indexOf(assetInspection.id);

    if (currentIndex < 0) {
      return {previous: undefined, next: undefined};
    }

    return {
      previous: assetInspectionIds[currentIndex - 1] ? `/asset-inspection/${assetInspectionIds[currentIndex - 1]}` : undefined,
      next: assetInspectionIds[currentIndex + 1] ? `/asset-inspection/${assetInspectionIds[currentIndex + 1]}` : undefined
    };
  }

  static getAssetInspectionBreadcrumbs(assetInspection: any): {label: string, linkTo: string}[] {
    if (!assetInspection) {
      return [];
    }

    // TODO: Refactor later
    const inspection = assetInspection.inspection;
    const inspectionInfo = !!inspection ? (!!inspection.routeInfo ? inspection.routeInfo : (!!inspection.inspectionId ? inspection.inspectionId : 'N/A')) : 'N/A';
    const inspectionLinkTo = new FiberInspectionViewModel(inspection).linkTo ? new FiberInspectionViewModel(inspection).linkTo : '';

    return [
      inspection && {label: 'Inspection - ' + inspectionInfo, linkTo: inspectionLinkTo},
      {label: 'Asset Inspection', linkTo: undefined}
    ];
  }

  static getWorkOrderBreadcrumbs(workOrder: WorkOrderModel): {label: string, linkTo: string}[] {
    if (!workOrder) {
      return [];
    }

    // TODO: Refactor later
    const inspection = workOrder.assetInspection && workOrder.assetInspection.inspection;
    const inspectionInfo = !!inspection ? (!!inspection.routeInfo ? inspection.routeInfo : (!!inspection.inspectionId ? inspection.inspectionId : 'N/A')) : 'N/A';
    const inspectionLinkTo = new FiberInspectionViewModel(inspection).linkTo ? new FiberInspectionViewModel(inspection).linkTo : '';
    const assetInspectionLinkTo = new FiberAssetInspectionViewModel(workOrder.assetInspection).linkTo ? new FiberAssetInspectionViewModel(workOrder.assetInspection).linkTo : '';

    return [
      inspection && {label: 'Inspection - ' + inspectionInfo, linkTo: inspectionLinkTo},
      workOrder.assetInspection && {label: 'Asset Inspection - ' + (workOrder.assetInspection && workOrder.assetInspection['name'] ? workOrder.assetInspection['name'] : 'N/A'), linkTo: assetInspectionLinkTo},
      {label: 'Escalation', linkTo: undefined}
    ];
  }

  static getInspectionRemedyWOs(inspection: FiberInspectionModel): string[] {
    if (!inspection || !inspection.assetInspections || !inspection.assetInspections.length) {
      return [];
    }

    const workOrders = inspection.assetInspections.map(assetInspection => assetInspection.workOrders ? assetInspection.workOrders : []).flat(1);

    if (workOrders && workOrders.length) {
      const workOrdersWithRemedyWO = workOrders.filter(workOrder => workOrder.externalId);

      if (workOrdersWithRemedyWO && workOrdersWithRemedyWO.length) {
        const remedyWOs = workOrdersWithRemedyWO.map(workOrder => workOrder.externalId);
        const universalRemedyWOs = [...new Set(remedyWOs)];

        if (universalRemedyWOs && universalRemedyWOs.length) {
          return universalRemedyWOs;
        }

      }
    }

    return [];
  }


  static getInspectionSapWOs(inspection: FiberInspectionModel): string[] {
    if (!inspection || !inspection.assetInspections || !inspection.assetInspections.length) {
      return [];
    }

    const workOrders = inspection.assetInspections.map(assetInspection => assetInspection.workOrders ? assetInspection.workOrders : []).flat(1);

    if (workOrders && workOrders.length) {
      const workOrdersWithSapWO = workOrders.filter(workOrder => workOrder.sapExternalId);

      if (workOrdersWithSapWO && workOrdersWithSapWO.length) {
        const remedyWOs = workOrdersWithSapWO.map(workOrder => workOrder.sapExternalId);
        const universalSapWOs = [...new Set(remedyWOs)];

        if (universalSapWOs && universalSapWOs.length) {
          return universalSapWOs;
        }

      }
    }

    return [];
  }

  static getInspectorStats(inspectorStats: any[], newValues: any[]): string[] {
    if (!inspectorStats || !inspectorStats.length) {
      return newValues;
    }

    if (!newValues || !newValues.length) {
      return inspectorStats;
    }

    for (let i = 0; i < inspectorStats.length; i++) {
      const inspectorExternalId = inspectorStats[i].inspectorExternalId;
      const matchingItem = newValues.find(item => item.inspectorExternalId === inspectorExternalId);

      if (matchingItem) {
        inspectorStats[i].WOsCreated += matchingItem.WOsCreated;
        inspectorStats[i].conditionsFound += matchingItem.conditionsFound;
        inspectorStats[i].escalationsCreated += matchingItem.escalationsCreated;
        inspectorStats[i].inspectedAssets += matchingItem.inspectedAssets;
        inspectorStats[i].inspections += matchingItem.inspections;
      }
    }

    return inspectorStats.sort((stat1, stat2) => {
      return stat2.inspections - stat1.inspections;
    });
  }

  static getConditionStats(conditionStats: any[], newValues: any[]): string[] {
    if (!conditionStats || !conditionStats.length) {
      return newValues;
    }

    if (!newValues || !newValues.length) {
      return conditionStats.sort((stat1, stat2) => {
        return stat2.occurrence - stat1.occurrence;
      });
    }

    const mergedStats = [];

    const conditionMap = new Map();

    for (const stat of conditionStats) {
      conditionMap.set(stat.condition, stat);
    }

    for (const stat of conditionStats) {
      const matchingItem = newValues.find(item => item.condition === stat.condition);

      if (matchingItem) {
        mergedStats.push({
          condition: stat.condition,
          occurrence: stat.occurrence + matchingItem.occurrence,
          workOrdersCreated: stat.workOrdersCreated + matchingItem.workOrdersCreated,
          workOrdersCompleted: stat.workOrdersCompleted + matchingItem.workOrdersCompleted,
          workOrdersNotCompleted: stat.workOrdersNotCompleted + matchingItem.workOrdersNotCompleted,
        });
        newValues = newValues.filter(item => item.condition !== stat.condition);
      } else {
        mergedStats.push(stat);
      }
    }
    for (const item of newValues) {
      mergedStats.push(item);
    }

    return mergedStats.sort((stat1, stat2) => {
      return stat2.occurrence - stat1.occurrence;
    });
  }

  static translateConditions(key) {
    const matchingKey = CONDITIONS_LIST.find(item => item.key === key);

    return matchingKey ? matchingKey.value : key;
  }

  // TODO: refactor this
  static getInspectionUnhandledConditions(inspection: any): string[] {
    if (!inspection.assetInspections || !inspection.assetInspections.length) {
      return [];
    }

    const conditions = inspection.assetInspections.map(assetInspection => {
      const assetInspectionConditions = assetInspection.conditions.map(condition => this.translateConditions(condition));
      if (!assetInspectionConditions || !assetInspectionConditions) {
        return [];
      }
      if (!assetInspection.workOrders || !assetInspection.workOrders.length) {
        return assetInspectionConditions;
      }
      return assetInspectionConditions.filter(condition =>
        !assetInspection.workOrders.some(workOrder => workOrder.condition === condition));
    });

    return conditions.flat();
  }

  static getUnhandledConditionAssetInspections(condition: string, inspection: any): string[] {
    const matchingConditionObj = CONDITIONS_LIST.find(item => item.value === condition);
    if (!inspection || !inspection.assetInspections || !inspection.assetInspections.length) {
      return [];
    }

    const conditionAssetInspections = inspection.assetInspections.filter(assetInspection =>
      assetInspection.conditions && assetInspection.conditions.length &&
      assetInspection.conditions.includes(matchingConditionObj ? matchingConditionObj.value : condition));

    if (!conditionAssetInspections || !conditionAssetInspections.length) {
      return [];
    }

    const unhandledAssetInspections = conditionAssetInspections.filter(assetInspection =>
      !assetInspection.workOrders || !assetInspection.workOrders.length ||
      !assetInspection.workOrders.some(workOrder => workOrder.condition === condition));

    if (!unhandledAssetInspections || !unhandledAssetInspections.length) {
      return [];
    }

    return unhandledAssetInspections.map(assetInspection => assetInspection.id);
  }

  static getInspectionReporting(inspectionsReport: any[]): any[] {
    if (!inspectionsReport || !inspectionsReport.length) {
      return;
    }

    let onTimeTotal = 0;
    let overdueTotal = 0;
    let upcomingTotal = 0;
    let pendingTotal = 0;
    let daysDiff = 0;

    inspectionsReport.forEach(item => {
      onTimeTotal += item.onTime ? item.onTime : 0;
      overdueTotal += item.overdue ? item.overdue : 0;
      upcomingTotal += item.upcoming ? item.upcoming : 0;
      pendingTotal += item.pending ? item.pending : 0;
      daysDiff = item.daysDiff;
    });

    const reportArray = [
      {key: 'on-time', title: 'On Time', total: onTimeTotal, textColor: 'text-green-800', bgColor: 'bg-green-50'},
      {key: 'overdue', title: 'Overdue', total: overdueTotal, textColor: 'text-red-800', bgColor: 'bg-red-50'},
    ];

    const hasUpcoming = !!inspectionsReport.some(obj => obj.hasOwnProperty('upcoming'));

    if (hasUpcoming) {
      reportArray.push({
        key: 'upcoming',
        title: 'Upcoming',
        total: upcomingTotal,
        textColor: 'text-gray-700',
        bgColor: 'bg-gray-200'
      });
    }

    if (hasUpcoming && upcomingTotal > 0) {
      reportArray.push({
        key: 'pending',
        title: daysDiff > 5 ? 'In 5 days' : (daysDiff < 1 ? 'Today' : ('In ' + daysDiff + (daysDiff === 1 ? ' day' : ' days'))),
        total: pendingTotal,
        textColor: pendingTotal > 0 ? 'text-orange-800' : 'text-gray-700',
        bgColor: pendingTotal > 0 ? 'bg-orange-50' : 'bg-gray-200'
      });
    }

    return reportArray;
  }

  static getGeneratorsObj(obj: any) {
    if (obj.value === undefined || obj.value === null) {
      return 'Status: ' + obj.status;
    }
    return 'Status: ' + obj.status + '; Value: ' + obj.value;
  }

  static getRatedDescriptionObj(obj: any) {
    if (obj.status !== 'Unsatisfactory') {
      return 'Status: ' + obj.status;
    }

    return `Status: ${obj.status};${obj.rated ?
      ` Rated: ${obj.rated};` : ''}${obj.discrepancyAndRemediation ?
      ` Discrepancy description and possible remediation: ${obj.discrepancyAndRemediation};` : ''}`;
  }
}
