import { motion } from 'framer-motion';
import React, { memo, useContext, useEffect, useState } from 'react';
import DetailsCard from '../../shared/components/DetailsCard/DetailsCard';
import { useParams } from 'react-router-dom';
import AssetHeader from './components/AssetHeader';
import AssetsService from '../../services/assets.service';
import { Card } from '@mui/material';
import AssetInspectionsDetailsCard from './components/AssetInspectionsDetailsCard';
import ToggleButton from '../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Menu from '../../shared/components/Menu/Menu';
import MenuItem from '../../shared/components/Menu/MenuItem';
import AssetModal from './components/AssetModal';
import { AssetViewModel } from '../../shared/models/base-view-models/asset-view.model';
import { AssetModel } from '../../shared/models/base-models/asset.model';
import { AssetViewModelFactory } from '../../shared/view-model-factories/asset-view-model.factory';
import DepartmentContext from '../../DepartmentContext';

const AssetContent = () => {

  const {updateDepartment} = useContext(DepartmentContext);
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [asset, setAsset] = useState(new AssetModel());
  const [assetView, setAssetView] = useState(null as AssetViewModel);

  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    AssetsService.getAsset(params.id)
      .then(result => {
        updateDepartment(result.type);

        setAsset(result);
        setAssetView(new AssetViewModelFactory().createViewModel<AssetViewModel, AssetModel>(result));
        setIsLoading(false);
      });
  }, []);

  const assetUpdatedHandler = (asset: AssetModel) => {
    setAsset(asset);
    setAssetView(new AssetViewModelFactory().createViewModel<AssetViewModel, AssetModel>(asset));
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const openModalHandler = () => {
    setOpenModal(true);
  };

  const onModalCloseHandler = () => {
    setOpenModal(false);
  };

  const menuButton =
    <ToggleButton
      disabled={isLoading}
      isActive={menuOpened}
      onClick={showMenu}
      className={'pl-16 pr-12 hover:bg-gray-200'}
      text={'Actions'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={16}>material-outline:arrow_drop_down</FuseSvgIcon>
    </ToggleButton>;

  const menu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'edit'} onClick={openModalHandler} setMenuOpened={setMenuOpened}
                  icon={'material-outline:edit'} text={'Edit Details'} />,
      ]}
    />;

  const assetDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Asset Details'} isLoading={isLoading}
      items={assetView ? assetView.detailCardFields : []}
      contentElementClassName={'w-full grid sm:grid-cols-2 md:grid-cols-3 gap-8 align-middle'}
    />;

  const assetInspectionsCard =
    <AssetInspectionsDetailsCard className={'lg:mb-16'} isLoading={isLoading} assetInspections={asset.assetInspections}/>;

  const mapCard =
    <Card className={`shadow overflow-hidden rounded-md lg:mb-16`}>
      {/*<Map>*/}
      {/*  <MapViewPadding top={0} left={0} right={0} bottom={0}/>*/}
      {/*  <Points points={mapPoints}/>*/}
      {/*</Map>*/}
    </Card>;

  return (
    <>
      <motion.div
        className='w-full h-full p-12 lg:p-24'
        initial='hidden'
        animate='show'
      >
        <motion.div className={'mt-2'}>
          <div className={'flex flex-col sm:flex-row sm:items-center justify-between gap-8 mt-2 px-3'}>
            <div>
              <AssetHeader dataModel={assetView} isLoading={isLoading}/>
            </div>
            <div>
              {menuButton}
              {menu}
            </div>
          </div>
          <div className='hidden lg:grid grid-cols-5 grid-flow-col gap-16 px-3 mt-8'>
            <div className='row-span-6 col-span-3'>
              {assetDetailsCard}
              {assetInspectionsCard}
            </div>
            <div className='hidden lg:block col-span-2'>
              {mapCard}
            </div>
          </div>
          <div className='flex flex-col lg:hidden gap-16 px-3 mt-8'>
            {assetDetailsCard}
            {assetInspectionsCard}
            {mapCard}
          </div>
        </motion.div>
      </motion.div>
      {openModal &&
          <AssetModal onClose={onModalCloseHandler} onSave={assetUpdatedHandler}
                      asset={asset} />
      }
    </>
  );
};

export default memo(AssetContent);
