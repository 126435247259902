import React, { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import AssetsService from '../../../services/assets.service';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import Form, { FormRef } from '../../../shared/components/Form/Form';
import { AssetModel } from '../../../shared/models/base-models/asset.model';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';
import { AssetFormFactory } from '../../../shared/form-factories/asset-form.factory';

type Props = {
  onSave: Function;
  onClose: Function;
  asset?: AssetModel;
}

const AssetModal = (props: Props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [asset, setAsset] = useState(new AssetModel());
  const [form, setForm] = useState(new BaseFormModel());

  const formRef = useRef<FormRef>();

  useEffect(() => {
    setAsset(props.asset);

    if (!props.asset) {
      return;
    }

    const formSchemas = new AssetFormFactory().createForm<BaseFormModel, AssetModel>(props.asset);

    setForm(formSchemas);
  }, [props.asset]);

  const handleSubmit = async (asset: AssetModel) => {

    setIsLoading(true);

    AssetsService.updateAsset(asset.id, asset)
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Changes saved successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage('Error saving changes', 'error'));
        setIsLoading(false);
      });
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  return (
    <Modal onClose={props.onClose} title={'Edit Asset'}
           className={'md:h-auto'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={'Save Changes'}
               />
             </div>
           }
    >
      {/*<AssetForm onChanged={assetChangedHandler} asset={props.asset} className={'px-24 py-16'} />*/}
      {!!form.schema && <Form schema={form.schema} className={'px-24 py-16'}
                              uiSchema={form.uiSchema} onSubmit={handleSubmit}
                              formData={asset} ref={formRef}/>}
    </Modal>
  );
};

export default AssetModal;
