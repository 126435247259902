import { AssetInspectionViewModel } from '../../../models/base-view-models/asset-inspection-view.model';
import { UpsAssetInspectionModel } from '../models/ups-asset-inspection.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { InspectionHelper } from '../../../helpers/inspection.helper';

export class UpsAssetInspectionViewModel extends AssetInspectionViewModel {

  private assetInspection: UpsAssetInspectionModel;

  constructor(assetInspection: UpsAssetInspectionModel) {
    super(assetInspection);
    this.assetInspection = assetInspection;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [];
  }

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [];

    if (this.assetInspection.issueCause) {
      fields.push({
        label: 'Issue Cause', value: this.returnValue(this.assetInspection.issueCause)
      });
    }

    if (this.assetInspection.correctiveFound && this.assetInspection.correctiveFound.length) {
      fields.push({
        label: 'Corrective Found', value: this.returnValue(this.assetInspection.correctiveFound.join(', '))
      });
    }

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Is the unit correctly mounted?',
      value: this.returnValue(this.assetInspection.unitCorrectlyMounted),
    });

    if (this.assetInspection.asset.assetType === 'UPS') {
      fields.push({
        label: 'Is all equipment connected to UPS protected outlets on the UPS?',
        value: this.returnValue(this.assetInspection.equipmentConnectedToUPSProtectedOutlets),
      });

      fields.push({
        label: 'Is the UPS chassis grounded to a primary ground to the main telecom ground buss?',
        value: this.returnValue(this.assetInspection.upsChassisGroundedToPrimaryGround),
      });
    }

    if (this.assetInspection.alarms) {
      fields.push({
        label: 'Alarms',
        value: '',
        colSpan: 'sm:col-span-2',
        className: 'text-15'
      });

      if (this.assetInspection.asset.assetType === 'UPS') {

        fields.push({
          label: 'On Battery',
          value: this.returnValue(this.assetInspection.alarms.onBattery),
        });

        fields.push({
          label: 'Fault',
          value: this.returnValue(this.assetInspection.alarms.fault),
        });

        fields.push({
          label: 'Low Battery',
          value: this.returnValue(this.assetInspection.alarms.lowBattery),
        });

        fields.push({
          label: 'Replace Battery',
          value: this.returnValue(this.assetInspection.alarms.replaceBattery),
        });

        fields.push({
          label: 'Is the UPS connected to a Netguardian?',
          value: this.returnValue(this.assetInspection.alarms.upsConnectedToNetguardian),
        });

        fields.push({
          label: 'UPS Self-Test Recording',
          value: this.returnValue(this.assetInspection.alarms.upsSelfTestRecording),
        });

        fields.push({
          label: 'Telenium Alarm Set Up',
          value: this.returnValue(this.assetInspection.alarms.teleniumSetUp),
        });

        fields.push({
          label: 'Is a bypass switch installed?',
          value: this.returnValue(this.assetInspection.alarms.bypassSwitchInstalled),
        });

        fields.push({
          label: 'Filters and Air Intakes',
          value: this.returnValue(this.assetInspection.alarms.filterAndAirIntakes),
        });

        fields.push({
          label: 'Is there another UPS in the room?',
          value: this.returnValue(this.assetInspection.alarms.anotherUPSInRoom),
        });
      }

      if (this.assetInspection.asset.assetType === 'VRLA Battery') {

        fields.push({
          label: 'Is there visible corrosion, bulging, leaking or other damage on the battery connections and batteries?',
          value: this.returnValue(this.assetInspection.alarms.visibleCorrosionBulgingLeaking),
        });

        fields.push({
          label: 'Is the UPS chassis bonded to the chassis of any expandable battery packs?',
          value: this.returnValue(this.assetInspection.alarms.upsChassisBondedExpandableBattery),
        });
      }
    }

    fields.push({
      label: 'Additional Conditions',
      value: this.returnValue(this.assetInspection.additionalConditions && this.assetInspection.additionalConditions.length ? this.assetInspection.additionalConditions.join(', ') : 'N/A'),
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get workOrderPageDetailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [];

    if (this.assetInspection.issueCause) {
      fields.push({
        label: 'Issue Cause', value: this.returnValue(this.assetInspection.issueCause)
      });
    }

    fields.push({
      label: 'Due Date',
      value: this.getDueDate,
    });

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {
      label: 'SAP ID',
      value: this.returnValue(this.assetInspection.asset && this.assetInspection.asset.externalId)
    };
  }

  get linkTo(): string {
    return `/asset-inspection/${this.assetInspection.id}`;
  }

  get listItemFieldsClassName(): string {
    return `md:grid-cols-5`;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'City, State', value:
          (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.city) : 'N/A') + ', '
          + (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.state) : 'N/A'),
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      },
      {
        label: 'Due Date',
        value: this.getDueDate,
      },
    ];
  }

  get secondaryListItemFieldsClassName(): string {
    return `sm:grid-cols-4`;
  }

  get secondaryListItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
        colSpan: 'sm:col-span-2'
      },
    ];
  }


  get dateTime(): string {
    return this.assetInspection.createdAt ? moment(this.assetInspection.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get buttonText(): string {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get componentHeader(): string {
    return this.returnValue(this.assetInspection['name']);
  }

  get getDueDate() {
    return this.assetInspection.inspection && this.assetInspection.inspection.dueDate ? moment(`${this.assetInspection.inspection.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get assetInspectionStatusColor(): number[] {
    return InspectionHelper.getAssetInspectionStatusColor(this.assetInspection.status);
  }

  get previousNextListItem(): any {
    return InspectionHelper.getAssetInspectionPreviousNext(this.assetInspection);
  }

  get additionalAttachmentsTitle(): string {
    return 'Equipment connected to UPS protected outlets attachments';
  }
}
