import React from 'react';
import { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TableContainer,
  TableSortLabel,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { URLHelper } from '../../../shared/helpers/url.helper';
import {
  CONDITIONS_TABLE_COLUMNS,
  PIE_CHART_COLORS,
  WORK_ORDER_COMPLETED_STATUSES,
  WORK_ORDER_NOT_COMPLETED_STATUSES,
} from '../../../shared/globals';
import StraightIcon from '@mui/icons-material/Straight';

type Props = {
  conditionsFound?: number;
  data: { condition: string, occurrence: number, workOrdersCreated: number, workOrdersCompleted: number }[];
  queryParams: any;
  className?: string;
};

const ConditionsTable = (props: Props) => {

  const navigate = useNavigate();

  const [tableData, setTableData] = useState(props.data);
  const [sortField, setSortField] = useState('occurrence');
  const [order, setOrder] = useState('desc');


  const handleSortingChange = (key: string) => {
    const sortOrder =
      key === sortField && order === 'desc' ? 'asc' : 'desc';
    setSortField(key);

    setOrder(sortOrder);
    handleSorting(key, sortOrder);
  };

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const sorted = [...tableData].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), 'en', {
            numeric: true,
          }) * (sortOrder === 'asc' ? 1 : -1)
        );
      });
      setTableData(sorted);
    }
  };

  const routeChange = (linkTo: string, queryParams) => {
    const path = URLHelper.getUrlQuery(linkTo, queryParams);

    navigate(path);
  }

  const tableCell = (key, index, linkTo, text, queryParams) =>
    <TableCell key={key} component="th" scope="row"
               onClick={() => routeChange(linkTo, queryParams)}
               className={`${index === 0 ? 'pl-0 text-left' : 'pr-7 text-right'} hover:bg-gray-300`}>
      <Typography className={'whitespace-nowrap text-13'}>{text}</Typography>
    </TableCell>;

  return (
    <div className={`${props.className} flex flex-col h-full justify-between`}>
      <TableContainer>
        <Table className={'w-full min-w-full'} size={'small'}>
          <TableHead>
            <TableRow>
              {CONDITIONS_TABLE_COLUMNS.map(({key, label}: any, index: any) => (
                <TableCell key={key} onClick={() => handleSortingChange(key)}
                           className={`${index === 0 ? 'pl-0 text-left' : 'pr-3 text-right'}`}>
                  <Typography
                    color='text.secondary'
                  >
                     <span
                       className={`flex items-center ${index === 0 ? 'justify-start' : 'justify-end'} cursor-pointer text-12 font-semibold whitespace-nowrap hover:bg-gray-300`}>
                       <TableSortLabel
                         active={sortField === key}
                         className={`${sortField === key ? '' : 'hidden'}`}
                         direction={sortField === key ? (order === 'desc' ? 'asc' : 'desc') : 'desc'}
                         onClick={() => handleSortingChange(key)}
                         IconComponent={StraightIcon}
                         sx={{
                           '& .MuiTableSortLabel-icon': {
                             padding: 0,
                             margin: 0,
                             height: '16px',
                             width: sortField === key ? '14px' : '0px'
                           },
                         }}
                       />
                       {label}
                     </span>
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((row: any, index: any) => (
              <TableRow key={index} className={'hover:bg-gray-100 cursor-pointer'}>
                {Object.entries(row).map(([key, value], objectItemIndex) => {
                    switch (key) {
                      case 'condition': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition': value.toString(),
                        };
                        return <TableCell key={key} component="th" scope="row"
                                          onClick={() => routeChange('/asset-inspections', queryParams)}
                                          className={`pl-3 text-left flex gap-x-6 hover:bg-gray-300`}>
                          <div
                            className={'my-auto'}
                            style={{
                              width: '10px',
                              height: '10px',
                              borderRadius: '50%',
                              backgroundColor: PIE_CHART_COLORS[index % PIE_CHART_COLORS.length],
                            }}
                          />
                          <Typography className={'whitespace-nowrap my-auto text-13'}>{value.toString()}</Typography>
                        </TableCell>;
                      }
                      case 'occurrence': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition': row['condition'],
                        };

                        const percentage = ((parseInt(value.toString()) / props.conditionsFound) * 100).toFixed(1);
                        const string = value.toString() + ' (' + percentage + '%)';

                        return tableCell(key, objectItemIndex, '/asset-inspections', string, queryParams);
                      }
                      case 'workOrdersCreated': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition': row['condition'],
                        };

                        return tableCell(key, objectItemIndex, '/escalations', value.toString(), queryParams);
                      }
                      case 'workOrdersCompleted': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition': row['condition'],
                          status: WORK_ORDER_COMPLETED_STATUSES
                        };

                        return tableCell(key, objectItemIndex, '/escalations', value.toString(), queryParams);
                      }
                      case 'workOrdersNotCompleted': {
                        const queryParams = {
                          ...props.queryParams,
                          'condition': row['condition'],
                          status: WORK_ORDER_NOT_COMPLETED_STATUSES
                        };

                        return tableCell(key, objectItemIndex, '/escalations', value.toString(), queryParams);
                      }
                    }
                  }
                )}
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>
    </div>
  );
}

export default ConditionsTable;
