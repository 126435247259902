import { RJSFSchema, UiSchema } from '@rjsf/utils';
import {
  PHASE_LIST,
  TIER_RATING_LIST,
  TRUE_FALSE_LIST,
  UPS_MANUFACTURER_LIST,
  VOLTAGE_TYPE_LIST,
  YES_NO_LIST
} from '../globals';

export const UPS_ASSET_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['externalId'],
  properties: {
    externalId: {
      type: 'string',
      title: 'SAP Equipment ID',
    },
    tierRating: {
      type: 'string',
      title: 'Tier Rating',
    },
    upsManufacturer: {
      type: 'string',
      title: 'UPS Manufacturer',
    },
    upsMake: {
      type: 'string',
      title: 'UPS Make',
    },
    upsModelNumber: {
      type: 'string',
      title: 'UPS Model #',
    },
    upsSerialNumber: {
      type: 'string',
      title: 'UPS Serial #',
    },
    partNumber: {
      type: 'string',
      title: 'Part #',
    },
    size: {
      type: 'number',
      title: 'Size (kVA)',
    },
    voltage: {
      type: 'string',
      title: 'Voltage',
    },
    voltageType: {
      type: 'string',
      title: 'Voltage Type',
    },
    phase: {
      type: 'string',
      title: 'Phase',
    },
    numberOfAirFilters: {
      type: 'number',
      title: '# of Air Filter',
    },
    numberOfFans: {
      type: 'number',
      title: 'Number of Fans',
    },
    totalChassisSlots: {
      type: 'string',
      title: 'Total Chassis Slots',
    },
    chassisSlotsUsed: {
      type: 'string',
      title: 'Total Chassis Slots Used',
    },
    supportedByGenerator: {
      type: 'string',
      title: 'Supported by Generator',
    },
    externalMaitenanceBypass: {
      type: 'string',
      title: 'External Maitenance Bypass',
    },
    sizeOfAirFilters: {
      type: 'string',
      title: 'Size of Air Filters',
    },
    loadValue: {
      type: 'string',
      title: 'Load',
    },
    manufacturedDate: {
      type: 'string',
      title: 'Manufactured Date',
    },
    rack: {
      type: 'string',
      title: 'Rack',
    },
    rackUnitNumber: {
      type: 'string',
      title: 'Rack Unit Number',
    },
    equipmentLife: {
      type: 'number',
      title: 'Equipment Life',
    },
    endOfLife: {
      type: 'string',
      title: 'End of Life',
    },
    conditionRating: {
      type: 'string',
      title: 'Condition Rating',
    },
    batteryAmpHours: {
      type: 'string',
      title: 'Battery Amp Hours',
    },
    batteryRuntime: {
      type: 'string',
      title: 'Battery Runtime',
    },
    monitoringPGE: {
      type: 'string',
      title: 'Monitoring PGE',
    },
    yearOfConstruction: {
      type: 'string',
      title: 'Year of Construction',
    },
    serialNumber: {
      type: 'number',
      title: 'Serial #',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const UPS_ASSET_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  externalId: {
    'ui:placeholder': 'SAP Equipment ID',
  },
  tierRating: {
    'ui:placeholder': 'Tier Rating',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: TIER_RATING_LIST,
    },
  },
  upsManufacturer: {
    'ui:placeholder': 'UPS Manufacturer',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_MANUFACTURER_LIST,
    },
  },
  upsMake: {
    'ui:placeholder': 'UPS Make',
  },
  upsModelNumber: {
    'ui:placeholder': 'UPS Model #',
  },
  upsSerialNumber: {
    'ui:placeholder': 'UPS Serial #',
  },
  partNumber: {
    'ui:placeholder': 'Part #',
  },
  size: {
    'ui:placeholder': 'Size',
    'ui:widget': 'NumberWidget',
  },
  voltage: {
    'ui:placeholder': 'Voltage',
    'ui:widget': 'NumberWidget',
  },
  voltageType: {
    'ui:classNames': 'sm:col-span-2 grid sm:grid-cols-2 gap-x-12 gap-y-16',
    'ui:placeholder': 'Voltage Type',
    'ui:widget': 'DependencyOtherValueWidget',
    'ui:options': {
      optionsList: VOLTAGE_TYPE_LIST,
    },
  },
  phase: {
    'ui:placeholder': 'Phase',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: PHASE_LIST,
    },
  },
  numberOfAirFilters: {
    'ui:placeholder': '# of Air Filters',
    'ui:widget': 'NumberWidget',
  },
  numberOfFans: {
    'ui:placeholder': 'Number of Fans',
    'ui:widget': 'NumberWidget',
  },
  totalChassisSlots: {
    'ui:placeholder': 'Total Chassis Slots',
  },
  chassisSlotsUsed: {
    'ui:placeholder': 'Chassis Slots Used',
  },
  supportedByGenerator: {
    'ui:placeholder': 'Supported By Generator',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: YES_NO_LIST,
    },
  },
  externalMaitenanceBypass: {
    'ui:placeholder': 'External Maitenance Bypass',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: YES_NO_LIST,
    },
  },
  sizeOfAirFilters: {
    'ui:placeholder': 'Size of Air Filters',
  },
  loadValue: {
    'ui:placeholder': 'Load',
  },
  manufacturedDate: {
    'ui:placeholder': 'Manufactured Date',
    'ui:widget': 'date',
  },
  rack: {
    'ui:placeholder': 'Rack',
  },
  rackUnitNumber: {
    'ui:placeholder': 'Rack Unit Number',
  },
  equipmentLife: {
    'ui:placeholder': 'Equipment Life',
    'ui:widget': 'NumberWidget',
  },
  endOfLife: {
    'ui:placeholder': 'End of Life',
    'ui:widget': 'NumberWidget',
  },
  conditionRating: {
    'ui:placeholder': 'Condition Rating',
  },
  batteryAmpHours: {
    'ui:placeholder': 'Battery Amp Hours',
  },
  batteryRuntime: {
    'ui:placeholder': 'Battery Runtime',
  },
  monitoringPGE: {
    'ui:placeholder': 'Monitoring PGE',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: TRUE_FALSE_LIST,
    },
  },
  yearOfConstruction: {
    'ui:placeholder': 'Year of Construction',
  },
  serialNumber: {
    'ui:placeholder': 'Serial #',
    'ui:widget': 'NumberWidget',
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};