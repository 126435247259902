import { Pagination as MuiPagination, Select } from '@mui/material';
import React from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { PER_PAGE_PREDEFINED_VALUES } from '../../globals';
import Card from '@mui/material/Card';

type Props = {
  page: number,
  totalPages: number,
  perPage: number,
  totalItems: number,
  onPageChanged: Function,
  onPerPageChanged: Function,
  className?: string,
  smallView?: boolean,
}

const Pagination = ({
                      page,
                      totalPages,
                      perPage,
                      onPageChanged,
                      totalItems,
                      onPerPageChanged,
                      className,
                      smallView,
                    }: Props) => {

  const onPageChangeHandler = (event: any, page: number) => {
    onPageChanged(page);
  };

  const onPerPageChangedHandler = (event: any) => {
    onPerPageChanged(event.target.value);
  };

  const itemsRangeTotal = () => {
    const firstItem = (page - 1) * perPage + 1;
    const lastItem = firstItem + perPage < totalItems ? firstItem + perPage - 1 : totalItems;

    return `${firstItem}-${lastItem} out of ${totalItems}`;
  };

  return (
    <Card
      className={`w-full flex flex-row flex-wrap gap-x-24 justify-center ${smallView ? 'gap-y-12 md:justify-center' : 'gap-y-16 md:justify-between'}
       items-center shadow rounded-2xl py-16 ${smallView ? 'px-8 md:px-16' : 'px-16'} mb-2 ${className}`}>
      <span className={`${!(totalPages > 1) && 'hidden'} md:block`}>
        {totalPages > 1 && <MuiPagination count={totalPages} shape='rounded' color='primary'
                                          hidePrevButton hideNextButton siblingCount={1}
                                          page={page} onChange={onPageChangeHandler} className={''}/>}
      </span>
      <div className={`flex ${smallView ? 'sm:flex-col gap-y-6 md:flex-row' : 'flex-row'} items-center`}>
        <p className={'mr-5 sm:mr-10 whitespace-nowrap'}>Results per page:</p>
        <FormControl className={'w-68 sm:w-80 mr-6 sm:mr-12'} size='small'>
          <Select
            value={perPage}
            id='per-page-select'
            onChange={onPerPageChangedHandler}
            SelectDisplayProps={{style: {display: 'flex', alignItems: 'center'}}}
          >
            {
              PER_PAGE_PREDEFINED_VALUES.map(predefinedValue => {
                return (
                  <MenuItem key={predefinedValue} value={predefinedValue}>{predefinedValue}</MenuItem>
                );
              })
            }
          </Select>
        </FormControl>
        <p className={'whitespace-nowrap'}>
          {
            itemsRangeTotal()
          }
        </p>
      </div>

    </Card>
  );
};

export default Pagination;
