import { motion } from 'framer-motion';
import React, { memo, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useQueryParams from '../../shared/hooks/useQueryParams';
import AssetsMap from '../../shared/components/AssetsMap/AssetsMap';
import { CircularProgress } from '@mui/material';
import { PaginationModel } from '../../shared/models/pagination.model';
import Pagination from '../../shared/components/Pagination/Pagination';
import AssetInspectionCard from './components/AssetInspectionCard';
import AssetInspectionsService from '../../services/asset-inspections.service';
import {
  AssetInspectionParamsInterface,
} from '../../shared/models/query-params-interfaces/asset-inspection-params.interface';
import AssetInspectionsFilterCard from '../asset-inspections/components/AssetInspectionsFilterCard';
import DepartmentContext from '../../DepartmentContext';
import { AssetInspectionViewModelFactory } from '../../shared/view-model-factories/asset-inspection-view-model.factory';
import { AssetInspectionViewModel } from '../../shared/models/base-view-models/asset-inspection-view.model';
import { AssetInspectionModel } from '../../shared/models/base-models/asset-inspection.model';
import { MapHelper } from '../../shared/helpers/map.helper';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});

const AssetInspectionsContent = () => {

  const {department, updateDepartment} = useContext(DepartmentContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loadingCounter, setLoadingCounter] = useState(0);

  const [paginationData, setPaginationData] = useState(new PaginationModel());
  const [data, setData] = useState([]);
  const [dataView, setDataView] = useState([] as AssetInspectionViewModel[]);

  const [refs, setRefs] = useState({} as any);
  const [clickedItemId, setClickedItemId] = useState([]);
  const [clickedPointId, setClickedPointId] = useState('');
  const [hoveredItemId, setHoveredItemId] = useState([]);
  const [hoveredPointId, setHoveredPointId] = useState('');
  const [mapPoints, setMapPoints] = useState([]);
  const [mapOpened, setMapOpened] = useState(false);

  const filterCardRef = useRef(null);
  const assetsContainerRef = useRef(null);

  const [filterCardHeight, setFilterCardHeight] = useState(0);
  const [assetsContainerWidth, setAssetsContainerWidth] = useState(0);

  const handleMapToggled = () => {
    if (!mapOpened) {
      loadMapPoints(queryParams);
    }

    if (mapOpened) {
      setClickedPointId('');
      setClickedItemId([]);
      setHoveredItemId([]);
      setHoveredPointId('');
    }

    setMapOpened(prevState => !prevState);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    department: department,
    page: '1',
    'per-page': '100',
  } as AssetInspectionParamsInterface);

  const loadAssets = (queryParams: AssetInspectionParamsInterface) => {
    setLoadingCounter((prev) => prev + 1);
    setIsLoading(true);

    AssetInspectionsService.getAssetInspections(queryParams)
      .then(([paginationData, results]) => {
        if (results.length) {
          updateDepartment(results[0].type);
        }

        const items = results
          .sort((assetInspection1, assetInspection2) => {
            if (assetInspection1.createdAt === assetInspection2.createdAt && !!assetInspection1['name'] && !!assetInspection2['name']) {
              return collator.compare(assetInspection1['name'], assetInspection2['name'])
            }
          });

        setRefs(
          items.reduce((acc, value) => {
            acc[value.id] = React.createRef();
            return acc;
          }, {})
        );

        setPaginationData(paginationData);
        setData(items);
        setDataView(new AssetInspectionViewModelFactory().mapViewModels<AssetInspectionViewModel, AssetInspectionModel>(items));

        setLoadingCounter((prev) => prev - 1);
      });
  };

  const loadMapPoints = (queryParams: AssetInspectionParamsInterface) => {
    const {page, 'per-page': perPage, ...filterParams} = queryParams;
    AssetInspectionsService.getMapPoints(filterParams)
      .then((results) => {
        setMapPoints(MapHelper.getAssetMapPoints(results));
      });
  };

  useEffect(() => {
    if (loadingCounter > 0) {
      return;
    }

    setIsLoading(false);
  }, [loadingCounter]);

  useEffect(() => {
    loadAssets(queryParams);
  }, []);

  useLayoutEffect(() => {
    setFilterCardHeight(filterCardRef.current.clientHeight);
    setAssetsContainerWidth(assetsContainerRef.current.clientWidth);
  }, []);

  const onPageChangedHandler = (page: number) => {
    const updatedQueryParams = {...queryParams, page: page.toString()} as AssetInspectionParamsInterface;
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);
  };

  const onPerPageChangedHandler = (perPage: number) => {
    const updatedQueryParams = {...queryParams, 'per-page': perPage.toString()} as AssetInspectionParamsInterface;
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);
  };

  const onFilterChangedHandler = (updatedQueryParams: AssetInspectionParamsInterface) => {
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);

    if (mapOpened) {
      loadMapPoints(updatedQueryParams);
    }
  };

  const onItemHoverHandler = (id: string) => {
    if (!id) {
      return;
    }

    setHoveredItemId([id]);
    setHoveredPointId(id);
  };

  const onPointClickHandler = (id: string) => {

    setClickedPointId(id);

    const assetInspections = data.filter(assetInspection => assetInspection.id === id);
    if (!assetInspections || !assetInspections.length) {
      setClickedItemId([]);
      return;
    }

    setClickedItemId(assetInspections.map(assetInspection => assetInspection.id));
    handleListScroll(assetInspections[0].id);
  }

  const onItemClickHandler = (id: string) => {
    if (!id) {
      return;
    }

    setClickedPointId(id);
    setClickedItemId([id]);

    handleListScroll(id);
  }

  const isListItemSelected = (listItemId) => {
    if (!clickedItemId.length) {
      return null;
    }

    return !!clickedItemId.some(clickedItemId => clickedItemId === listItemId);
  };

  const onNotSelectedCardClickHandler = (id: string) => {
    setClickedItemId([id]);
    setClickedPointId(id);
  };

  const handleListScroll = (id) => refs[id].current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });

  const variantsItem = {
    hidden: {opacity: 0, y: 20},
    show: {opacity: 1, y: 0},
  };

  return (
    <>
      <AssetsMap points={mapPoints} topPadding={filterCardHeight + 50} leftPadding={assetsContainerWidth / 2 + 50}
                 rightPadding={20} bottomPadding={20} clickedPointId={clickedPointId} mapOpened={mapOpened}
                 hoveredPointId={hoveredPointId} onPointClickHandler={onPointClickHandler}/>
      <div
        className={`h-full flex flex-col relative w-full p-12 lg:p-24 block
        overflow-auto pointer-events-auto sm:overflow-hidden sm:pointer-events-none`}>
        <div
          className={`px-2`}
          ref={filterCardRef}>
          <AssetInspectionsFilterCard
            queryParams={queryParams}
            onFilterChangedHandler={onFilterChangedHandler}
            mapOpened={mapOpened}
            onMapToggled={handleMapToggled}
            csvDisabled={data.length === 0}
            className={'pointer-events-auto'}
            isLoading={isLoading}
            statusFilterClassName={`${mapOpened && 'w-full sm:w-2/5'}`}
            documentType={department}
          />
        </div>
        <motion.div
          className={`sm:overflow-auto sm:pointer-events-auto rounded-lg ${isLoading ? 'h-full' : 'h-auto'} ${mapOpened && 'w-full sm:w-2/5'} px-2`}
          transition={{type: 'all'}} ref={assetsContainerRef} onMouseLeave={() => {
          setHoveredItemId([]);
          setHoveredPointId('');
        }}>
          {
            isLoading &&
              <div className={'w-full h-full flex text-center'}>
                  <CircularProgress className={'m-auto'} size={52} thickness={4}/>
              </div>
          }
          {
            !isLoading && data.length === 0 && <p className={'text-16'}>No Items Found</p>
          }
          {
            !isLoading && dataView && dataView
              .map((itemView: AssetInspectionViewModel, index) => {
                const assetInspectionData = data[index];
                return (
                  <motion.div key={index} variants={variantsItem} className='mb-8' onMouseEnter={() => {
                    onItemHoverHandler(assetInspectionData.id)
                  }} ref={refs[assetInspectionData.id]}>
                    <AssetInspectionCard
                      dataModel={itemView}
                      isHovered={mapOpened && !clickedPointId && hoveredItemId && !!hoveredItemId.some(hoveredItemId => hoveredItemId === assetInspectionData.id)}
                      assetInspectionData={assetInspectionData}
                      mapOpened={mapOpened}
                      isSelected={isListItemSelected(assetInspectionData.id)}
                      onHoveredCardClickHandler={onItemClickHandler}
                      onNotSelectedCardClickHandler={onNotSelectedCardClickHandler}
                      contentElementClassName={`${mapOpened ? 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3' : `sm:grid-cols-3 ${itemView.listItemFieldsClassName}`}`}
                    />
                  </motion.div>
                );
              })
          }
          {
            !isLoading && data.length > 0 &&
              <Pagination page={paginationData.page} totalPages={paginationData.pages} perPage={paginationData.perPage}
                          totalItems={paginationData.total} smallView={mapOpened}
                          onPageChanged={onPageChangedHandler} onPerPageChanged={onPerPageChangedHandler}
                          className={'mt-12'}/>
          }
        </motion.div>
      </div>
    </>
  );
};

export default memo(AssetInspectionsContent);
