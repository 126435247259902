import { TYPE_CONFIG } from '../types/type-config';

export class TypeHelper {

  static getTypeConfig(object: any) {
    return TYPE_CONFIG.find((dataType) => dataType.type === object.type);
  }

  static getTypeConfigByType(type: string) {
    return TYPE_CONFIG.find((dataType) => dataType.type === type);
  }
}