import { WidgetProps } from "@rjsf/utils";
import MultiSelectList from "../../MultiSelectList/MultiSelectList";
import Select from "../../Select/Select";

interface SelectWidgetProps extends WidgetProps {
  options: {
    optionsList: { key: string; value: string }[];
  };
}

const MultiSelectListWidget = (props: SelectWidgetProps) => {
  return (
    <MultiSelectList
      className={"w-full h-full sm:h-160 md:h-256 md:col-span-3"}
      id={"conditions"}
      values={props.value ? props.value : []}
      label={`${props.label}${props.required ? "*" : ""}`}
      items={props.options.optionsList ? props.options.optionsList : []}
      onChange={(event) => props.onChange(event)}
    />
  );
};

export default MultiSelectListWidget;
