import React, { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import AssetInspectionsService from '../../../services/asset-inspections.service';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import ModelAttachments from '../../../shared/components/ModelAttachments/ModelAttachments';
import Form, { FormRef } from '../../../shared/components/Form/Form';
import { AssetInspectionModel } from '../../../shared/models/base-models/asset-inspection.model';
import { AssetInspectionFormFactory } from '../../../shared/form-factories/asset-inspection-form.factory';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';

type Props = {
  onSave: Function;
  onClose: Function;
  assetInspection?: AssetInspectionModel;
}

const AssetInspectionModal = (props: Props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [assetInspection, setAssetInspection] = useState(new AssetInspectionModel());

  const [form, setForm] = useState(new BaseFormModel());
  const formRef = useRef<FormRef>();

  useEffect(() => {
    setAssetInspection(props.assetInspection);

    if (!props.assetInspection) {
      return;
    }

    const formSchemas = new AssetInspectionFormFactory().createForm<BaseFormModel, AssetInspectionModel>(props.assetInspection);

    setForm(formSchemas);
  }, [props.assetInspection]);

  const handleSubmit = async (updatedAssetInspection: AssetInspectionModel) => {
    if (updatedAssetInspection['damageCause']) {
      updatedAssetInspection['damageCause'] = Array.isArray(updatedAssetInspection['damageCause']) ? updatedAssetInspection['damageCause'] : [updatedAssetInspection['damageCause']];
    }

    setIsLoading(true);

    AssetInspectionsService.updateAssetInspection(assetInspection.id, new AssetInspectionModel({...updatedAssetInspection, attachments: assetInspection.attachments, files: assetInspection['files']}))
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Changes saved successfully', 'success'));
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error saving changes';
        dispatch(NotificationMessage(message, 'error'));
        setIsLoading(false);
      });
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  const assetInspectionChangedHandler = (assetInspectionChanges) => {
    setAssetInspection(prevState => new AssetInspectionModel({...prevState, ...assetInspectionChanges}));
  };

  return (
    <Modal onClose={props.onClose} title={'Edit Asset Inspection'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={'Save Changes'}
               />
             </div>
           }
    >
      {/* <AssetInspectionForm assetInspection={props.assetInspection} className={'px-24 py-16'}
                      onChanged={assetInspectionChangedHandler}
      /> */}
      {!!form.schema && <Form schema={form.schema} className={'px-24 py-16'}
                              uiSchema={form.uiSchema} onSubmit={handleSubmit}
                              formData={assetInspection} ref={formRef}/>}
      <ModelAttachments attachments={assetInspection.attachments} onChanged={assetInspectionChangedHandler}
                        className={`w-full mb-20 px-24`}
      />
    </Modal>
  );
};

export default AssetInspectionModal;
