import React, { useContext, useEffect, useState } from 'react';
import Select from '../Select/Select';
import InspectorsContext from '../../../InspectorsContext';

type Props = {
  onChange: Function;
  className?: string;
  label: string;
  id: string;
  userExternalId: string;
  userIdKey?: string;
  formLoadingHandler?: any;
  formLoadedHandler?: any;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const UserSelect = (props: Props) => {

  const {inspectors} = useContext(InspectorsContext);

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);

  const userIdKey = props.userIdKey ? props.userIdKey : 'externalId';

  useEffect(() => {
    if (!inspectors) {
      setUsers([]);
      setIsLoading(false);
      !!props.formLoadedHandler && props.formLoadedHandler();
      return;
    }

    setUsers(inspectors);
    setIsLoading(false);
    !!props.formLoadedHandler && props.formLoadedHandler();
  }, [inspectors]);

  return (
    <Select
      id={props.id}
      className={props.className}
      label={props.label}
      onChange={props.onChange}
      value={props.userExternalId}
      list={users && users.length ? users.map((inspector) => ({
        key: inspector[userIdKey],
        value: inspector.name,
      })) : []}
      disabled={isLoading}
      isLoading={isLoading}
      required={props.required}
      error={props.error}
      helperText={props.helperText}
    />
  );
};

export default UserSelect;
