/**
 * Authorization Roles
 */
const authRoles = {
  admin: ['Admin'],
  inspector: ['Admin', 'Inspector'],
  onlyGuest: [],
};

export default authRoles;
