import React, { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import InspectionsService from '../../../services/inspections.service';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import Form, { FormRef } from '../../../shared/components/Form/Form';
import { InspectionModel } from '../../../shared/models/base-models/inspection.model';
import { InspectionFormFactory } from '../../../shared/form-factories/inspection-form.factory';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';

type Props = {
  onSave: Function;
  onClose: Function;
  inspection?: InspectionModel;
}

const InspectionModal = (props: Props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [inspection, setInspection] = useState(new InspectionModel());
  const [form, setForm] = useState(new BaseFormModel());

  const formRef = useRef<FormRef>();

  useEffect(() => {
    setInspection(props.inspection);

    if (!props.inspection) {
      return;
    }

    const formSchemas = new InspectionFormFactory().createForm<BaseFormModel, InspectionModel>(props.inspection);

    setForm(formSchemas);
  }, [props.inspection]);

  const handleSubmit = async (inspection: InspectionModel) => {

    setIsLoading(true);

    InspectionsService.updateInspection(inspection.id, inspection)
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Changes saved successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage('Error saving changes', 'error'));
        setIsLoading(false);
      });
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  return (
    <Modal onClose={props.onClose} title={'Edit Inspection'}
           className={'sm:h-3/5 md:h-auto md:w-3/5 lg:w-1/2'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={'Save Changes'}
               />
             </div>
           }
    >
      {/*<InspectionForm onChanged={inspectionChangedHandler} inspection={props.inspection} className={'px-24 py-16'} />*/}
      {!!form.schema && <Form schema={form.schema} className={'px-24 py-16'}
                              uiSchema={form.uiSchema} onSubmit={handleSubmit}
                              formData={inspection} ref={formRef}/>}
    </Modal>
  );
};

export default InspectionModal;
