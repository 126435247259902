import React, { useEffect, useState } from 'react';
import Modal from '../../../shared/components/Modal/Modal';
import { CircularProgress, Typography } from '@mui/material';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import ChangeLogTimeline from '../../../shared/components/ChangeLogTimeline/ChangeLogTimeline';
import { FieldChangeModel } from '../../../shared/models/field-change.model';
import CommentForm from '../../../shared/components/ChangeLogTimeline/CommentForm';
import { ChangelogDataInterface } from '../../../shared/models/data-model-interfaces/changelog-data.interface';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
});

type Props = {
  onClose: Function;
  dataModel: ChangelogDataInterface;
  isLoading?: boolean;
  commentAddedHandler?: Function;
}

const ChangeLogModal = (props: Props) => {

  const [changeHistory, setChangeHistory] = useState([]);

  useEffect(() => {
    if (!props.dataModel) {
      setChangeHistory([]);
    }

    if (!props.dataModel.comments || !props.dataModel.comments.length) {
      setChangeHistory(props.dataModel.changeLogHistory);
      return;
    }

    const changeHistoryComments = props.dataModel.comments.map(comment => new FieldChangeModel(
      {
        changedBy: comment.createdBy ? (comment.createdBy.id ? comment.createdBy.id : comment.createdBy) : '',
        field: 'activityComment',
        oldValue: '',
        newValue: comment.text,
        changedAt: comment.createdAt,
      }));

    if (!props.dataModel.changeLogHistory || !props.dataModel.changeLogHistory.length) {
      setChangeHistory([...changeHistoryComments].sort((field1, field2) => {
        return collator.compare(field1.changedAt, field2.changedAt);
      }));
      return;
    }

    setChangeHistory([...props.dataModel.changeLogHistory, ...changeHistoryComments].sort((field1, field2) => {
      return collator.compare(field1.changedAt, field2.changedAt);
    }));
  }, [props.dataModel]);

  return (
    <Modal onClose={props.onClose} title={<Typography className={`flex text-xl font-bold`}>
      {/*@ts-ignore*/}
      <FuseSvgIcon size={22} className={'mr-8 mt-3'}>material-outline:history</FuseSvgIcon>
      {'Change Log'}
    </Typography>}
           className={'sm:h-3/5 md:h-3/5 md:w-3/5 lg:w-2/5'}
    >
      <div className={'px-24 pb-24 pt-12'}>
        {
          props.isLoading &&
          <div className={'w-full p-12 text-center'}>
            <CircularProgress className={'m-auto'} />
          </div>
        }
        {
          !props.isLoading && (!changeHistory || (!!changeHistory && changeHistory.length === 0)) &&
          <p className={'text-14'}>N/A - No Changes Were Made</p>
        }
        {
          !props.isLoading && !!changeHistory && !!changeHistory.length &&
          <ChangeLogTimeline changeHistory={changeHistory} />
        }
        {
          !props.isLoading &&
          <CommentForm isLoading={props.isLoading} commentAddedHandler={props.commentAddedHandler}
                       formIsDisabled={props.dataModel && props.dataModel.isCommentFormDisabled} />
        }
      </div>
    </Modal>
  );
};

export default ChangeLogModal;
