import {styled} from '@mui/material/styles';
import {useSelector} from "react-redux";
import {selectFuseNavbar} from "app/store/fuse/navbarSlice";

const Root = styled('div')(({theme}) => ({
    '& > .logo-icon': {
        transition: theme.transitions.create(['width', 'height'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
    '& > .badge': {
        transition: theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
        }),
    },
}));

function Logo() {
    const navbar = useSelector(selectFuseNavbar);

    return (
        <Root className="flex items-center">
            {
                navbar.open ?
                    <img className="ml-3 logo-icon h-32" src="assets/images/logo/logo-text-hor-light.svg" alt="logo"/>
                    : <img className="ml-3 logo-icon h-32" src="assets/images/logo/logo-text-hor-dark.svg" alt="logo"/>
            }
        </Root>
    );
}

export default Logo;
