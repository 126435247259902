import ApiClient from './api-client/api-client';
import { PaginationModel } from '../shared/models/pagination.model';
import AssetInspectionsService from './asset-inspections.service';
import { CorrectiveActionModel } from '../shared/models/corrective-action.model';
import { FileHelper } from '../shared/helpers/file.helper';
import { CommentModel } from '../shared/models/comment.model';
import { WorkOrderModel } from '../shared/models/base-models/work-order.model';
import BaseContextService from './base-context.service';
import { WorkOrderModelMapper } from '../shared/model-mappers/work-order-model.mapper';
import { WorkOrderParamsInterface } from '../shared/models/query-params-interfaces/work-order-params.interface';
import { WorkOrderMapModel } from '../shared/models/map-assets/work-order-map.model';

const WORK_ORDERS_ENDPOINT = '/work-orders';

const getWorkOrders = (queryParams: WorkOrderParamsInterface = {}): Promise<[PaginationModel, WorkOrderModel[]]> => {
  return BaseContextService.fetchList<WorkOrderModel>(WORK_ORDERS_ENDPOINT, new WorkOrderModelMapper(), queryParams);
};

const getMapPoints = (queryParams: WorkOrderParamsInterface = {}): Promise<WorkOrderMapModel[]> => {
  return ApiClient.get(`${WORK_ORDERS_ENDPOINT}/map-points`, queryParams)
    .then(response => response.data)
    .then(data => data.map(workOrder => new WorkOrderMapModel(workOrder)));
};

const getWorkOrder = (id: string, queryParams: WorkOrderParamsInterface = {}): Promise<WorkOrderModel> => {
  return BaseContextService.fetch<WorkOrderModel>(`${WORK_ORDERS_ENDPOINT}/${id}`, new WorkOrderModelMapper(), queryParams);
};

const updateWorkOrder = (id: string, workOrder: WorkOrderModel): Promise<WorkOrderModel> => {
  const queryParams = {expand: 'assetInspection'};
  const files = workOrder['files'];
  FileHelper.prepareModelsFilesForUpload(workOrder);
  return BaseContextService.update<WorkOrderModel>(`${WORK_ORDERS_ENDPOINT}/${id}`, workOrder, new WorkOrderModelMapper(), queryParams)
    .then(data => FileHelper.uploadFiles(data, files));
};

const deleteWorkOrder = (id: string) => {
  return ApiClient.remove(`${WORK_ORDERS_ENDPOINT}/${id}`);
};

const saveWorkOrder = (assetInspectionId: string, workOrder: WorkOrderModel): Promise<WorkOrderModel> => {
  if (workOrder.id) {
    return updateWorkOrder(workOrder.id, workOrder);
  } else {
    return AssetInspectionsService.createWorkOrder(assetInspectionId, workOrder);
  }
};

const createCorrectiveAction = (workOrderId: string, correctiveAction: CorrectiveActionModel): Promise<CorrectiveActionModel> => {
  const files = correctiveAction['files'];
  FileHelper.prepareModelsFilesForUpload(correctiveAction);
  return ApiClient.post(`${WORK_ORDERS_ENDPOINT}/${workOrderId}/corrective-actions`, correctiveAction)
    .then(response => response.data)
    .then(data => FileHelper.uploadFiles(data, files))
    .then(data => new CorrectiveActionModel(data));
};

const updateWorkOrderStatus = (id: string, body: any): Promise<WorkOrderModel> => {
  return BaseContextService.update<WorkOrderModel>(`${WORK_ORDERS_ENDPOINT}/${id}/status`, body, new WorkOrderModelMapper());
};

const addComment = (workOrderId: string, body: any): Promise<CommentModel> => {
  return ApiClient.post(`${WORK_ORDERS_ENDPOINT}/${workOrderId}/comments`, body)
    .then(response => response.data)
    .then(data => new CommentModel(data));
};

const WorkOrdersService = {
  getWorkOrder,
  getWorkOrders,
  updateWorkOrder,
  deleteWorkOrder,
  saveWorkOrder,
  getMapPoints,
  createCorrectiveAction,
  updateWorkOrderStatus,
  addComment
};

export default WorkOrdersService;