import { BaseModel } from '../base.model';
import { CorrectiveActionModel } from '../corrective-action.model';

export class WorkOrderModel extends BaseModel {

  // TODO: Temp fix, remove properties later
  inspection?: any;
  assetInspectionId?: string;
  attachments: any[];
  type?: string;
  condition?: string;
  notes?: string;
  changeHistory?: any[];
  assignedTo?: any[];
  assetInspection?: any;
  routeInfo?: string;
  comments?: any[];
  status?: any;
  correctiveActions?: CorrectiveActionModel[];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}