import React, { useEffect, useState } from 'react';
import { Card, Typography } from '@mui/material';
import Button from '../../../shared/components/Button/Button';
import AssetInspectionSkeleton from './AssetInspectionSkeleton';
import Chip from '../../../shared/components/Chip/Chip';
import { ASSET_INSPECTION_STATUSES } from '../../../shared/globals';
import StatusChip from '../../../shared/components/StatusChip/StatusChip';
import { FiberAssetInspectionModel } from '../../../shared/types/fiber/models/fiber-asset-inspection.model';
import moment from 'moment';

type Props = {
  // TODO: enable for other types
  assetInspections: FiberAssetInspectionModel[];
  isLoading: boolean;
  className?: string;
}

const AssetInspectionsDetailsCard = (props: Props) => {

  const [assetInspections, setAssetInspections] = useState([]);

  useEffect(() => {
    if (!props.assetInspections) {
      setAssetInspections([]);
    }

    setAssetInspections(props.assetInspections);
  }, [props.assetInspections]);

  return (
    <Card className={`shadow overflow-hidden rounded-md px-24 pb-24 pt-16 ${props.className}`}>
      <Typography className='text-xl font-bold mb-20'>Asset Inspections</Typography>
      {
        props.isLoading &&
          <div className={`border border-gray-400 border-b-0 rounded-md gap-y-6 flex flex-col`}>
              <AssetInspectionSkeleton />
              <AssetInspectionSkeleton />
              <AssetInspectionSkeleton />
          </div>
      }
      {
        !props.isLoading && assetInspections && assetInspections.length === 0 &&
          <p className={'text-14'}>N/A - No Items Found</p>
      }
      {
        !props.isLoading && assetInspections && assetInspections.length > 0 &&
          <div className={'flex flex-col border border-gray-400 rounded-md'}>
            {assetInspections.map((assetInspection: FiberAssetInspectionModel, index) =>
              <div
                className={`${index !== assetInspections.length - 1 ? 'border-b' : ''} border-gray-400 flex flex-col sm:flex-row justify-between gap-6 py-12 px-20 align-middle`}
                key={assetInspection.id}>
                <div className={`w-full gap-12 flex align-middle`}>
                  <div className={`w-full m-auto`}>
                          <span className={`font-semibold secondary block flex gap-x-6`}>
                            {assetInspection.inspectionType ? assetInspection.inspectionType : 'N/A'}
                            <StatusChip className={'h-20 text-white'} status={assetInspection.status}
                                        statusObjects={ASSET_INSPECTION_STATUSES} />
                          </span>
                  </div>
                </div>
                <div className={'flex items-center justify-end gap-x-6'}>
                  <Chip className='my-auto font-semibold' bgColor={'bg-gray-300'}
                        label={assetInspection.createdAt ? moment(assetInspection.createdAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
                  />
                  <Button
                    onClick={() => {
                      window.open(`asset-inspection/${assetInspection.id}`);
                    }}
                    text={'More Details'}
                    color={'primary'}
                    className={'whitespace-nowrap text-10'}
                    disabled={false}
                    hoverText={'Open Asset Inspection Page'}
                  />
                </div>
              </div>,
            )}
          </div>
      }
    </Card>
  );
};

export default AssetInspectionsDetailsCard;
