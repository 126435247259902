import { PaginationModel } from '../models/pagination.model';
import { QueryParamsInterface } from '../models/interfaces/query-params.interface';

export class PaginationHelper {
  static paginate<T>(results: T[], queryParams: QueryParamsInterface = {}): [PaginationModel, T[]] {
    const page = queryParams.page ? parseInt(queryParams.page) : 0;
    const perPage = queryParams['per-page'] ? parseInt(queryParams['per-page']) : 0;
    const total = results.length;
    const pages = Math.ceil(total / perPage);

    const firstElement = (page - 1) * perPage;
    const lastElement = firstElement + perPage;

    const paginationResult = new PaginationModel({page, pages, perPage, total});

    return [paginationResult, results.slice(firstElement, lastElement)];
  }
}
