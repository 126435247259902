import React from 'react';
import { Breadcrumbs as MaterialBreadcrumbs } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {
  breadcrumbs?: { label: string; linkTo: string }[];
  className?: string;
}

const Breadcrumbs = (props: Props) => {

  return (
    <>
      {
        !!props.breadcrumbs && !!props.breadcrumbs.length &&
          <MaterialBreadcrumbs aria-label="breadcrumb" className={`${props.className}`}>
            {props.breadcrumbs.map((breadcrumb, index) =>
              breadcrumb.linkTo ?
                <Link key={index} color="inherit" to={`${breadcrumb.linkTo}`}
                      style={{textDecoration: 'none', color: 'black'}}>
                  {breadcrumb.label}
                </Link> : <label key={index} style={{color: 'black'}}>{breadcrumb.label}</label>
            )}
          </MaterialBreadcrumbs>
      }
    </>
  );
};

export default Breadcrumbs;
