import { AssetInspectionViewModel } from '../../../models/base-view-models/asset-inspection-view.model';
import { GeneratorsAssetInspectionModel } from '../models/generators-asset-inspection.model';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment/moment';
import { InspectionHelper } from '../../../helpers/inspection.helper';

export class GeneratorsAssetInspectionViewModel extends AssetInspectionViewModel {

  private assetInspection: GeneratorsAssetInspectionModel;

  constructor(assetInspection: GeneratorsAssetInspectionModel) {
    super(assetInspection);
    this.assetInspection = assetInspection;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [];
  }

  get detailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [];

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      colSpan: 'col-span-2'
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Regulator Date',
      value: this.returnValue(this.assetInspection.regulatorDate && moment(`${this.assetInspection.regulatorDate}`, 'YYYYMMDD').format('MM/DD/YYYY')),
    });

    fields.push({
      label: 'General Inspection',
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'System Visual Inspection/Overal Condition',
      value: this.returnValue(this.assetInspection.generalInspection && this.assetInspection.generalInspection.systemVisualInspectionOveralCondition),
    });

    fields.push({
      label: 'Hourmeter',
      value: this.returnValue(this.assetInspection.generalInspection && this.assetInspection.generalInspection.hourmeter),
    });

    fields.push({
      label: 'Battery',
      value: '',
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Battery Voltage Type',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.voltageType),
    });

    fields.push({
      label: 'Battery Charger Voltage',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.batteryChargerVoltage && InspectionHelper.getGeneratorsObj(this.assetInspection.battery.batteryChargerVoltage)),
    });

    fields.push({
      label: 'Battery Charger Amperage',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.batteryChargerAmperage && InspectionHelper.getGeneratorsObj(this.assetInspection.battery.batteryChargerAmperage)),
    });

    fields.push({
      label: 'Battery Charge Alternator Voltage',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.batteryChargeAlternatorVoltage && InspectionHelper.getGeneratorsObj(this.assetInspection.battery.batteryChargeAlternatorVoltage)),
    });

    fields.push({
      label: 'Battery Charge Alternator Amperage',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.batteryChargeAlternatorAmperage && InspectionHelper.getGeneratorsObj(this.assetInspection.battery.batteryChargeAlternatorAmperage)),
    });

    fields.push({
      label: 'Battery Age',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.batteryAge && InspectionHelper.getGeneratorsObj({status: this.assetInspection.battery.batteryAge['status'], value: this.assetInspection.battery.batteryAge['value'] && moment(this.assetInspection.battery.batteryAge['value']).format('MM/DD/YYYY')})),
    });

    fields.push({
      label: 'Checked Battery Level',
      value: this.returnValue(this.assetInspection.battery && this.assetInspection.battery.checkedBatteryLevel && InspectionHelper.getGeneratorsObj(this.assetInspection.battery.checkedBatteryLevel)),
    });

    fields.push({
      label: 'Engine', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Fuel System Type',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.fuelSystemType),
    });

    fields.push({
      label: 'Fuel Hoses/Piping Regulator, etc.',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.fuelHosesPipingRegulator),
    });

    fields.push({
      label: 'Fuel Pressure',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.fuelPressure && InspectionHelper.getGeneratorsObj(this.assetInspection.engine.fuelPressure)),
    });

    fields.push({
      label: 'Fuel Level',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.fuelLevel),
    });

    fields.push({
      label: 'Day Tank Operation',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.dayTankOperation),
    });

    fields.push({
      label: 'Oil Pressure',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.oilPressure && InspectionHelper.getGeneratorsObj(this.assetInspection.engine.oilPressure)),
    });

    fields.push({
      label: 'Coolant Temperature',
      value: this.returnValue(this.assetInspection.engine &&  this.assetInspection.engine.coolantTemperature && InspectionHelper.getGeneratorsObj(this.assetInspection.engine.coolantTemperature)),
    });

    fields.push({
      label: 'RPM',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.RPM),
    });

    fields.push({
      label: 'Ignition System (Cap, Rotor, Plugs, etc.)',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.ignitionSystem),
    });

    fields.push({
      label: 'Cooling System (Coolant, Belts, Hoses, etc.)',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.coolingSystem),
    });

    fields.push({
      label: 'Coolant Freeze Point',
      value: this.returnValue(this.assetInspection.engine &&  this.assetInspection.engine.coolantFreezePoint && InspectionHelper.getGeneratorsObj(this.assetInspection.engine.coolantFreezePoint)),
    });

    fields.push({
      label: 'Block Heater Operation)',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.blockHeaterOperation),
    });

    fields.push({
      label: 'Exhaust System (Piping, Muffler, Flex, etc.)',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.exhaustSystem),
    });

    fields.push({
      label: 'Inspect for Leaks of Any Type',
      value: this.returnValue(this.assetInspection.engine && this.assetInspection.engine.inspectLeaksAnyType),
    });

    fields.push({
      label: 'Generator, No Load - Voltage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'L1-L2',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L1L2),
    });

    fields.push({
      label: 'L2-L3',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L2L3),
    });

    fields.push({
      label: 'L3-L1',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L3L1),
    });

    fields.push({
      label: 'L1-N',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L1N),
    });

    fields.push({
      label: 'L2-N',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L2N),
    });

    fields.push({
      label: 'L3-N',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.voltage && this.assetInspection.generatorNoLoad.voltage.L3N),
    });

    fields.push({
      label: 'Generator, No Load - Amperage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'A',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.amperage && this.assetInspection.generatorNoLoad.amperage.A),
    });

    fields.push({
      label: 'B',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.amperage && this.assetInspection.generatorNoLoad.amperage.B),
    });

    fields.push({
      label: 'C',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.amperage && this.assetInspection.generatorNoLoad.amperage.C),
    });

    fields.push({
      label: 'Generator, No Load - Frequency',
      value: this.returnValue(this.assetInspection.generatorNoLoad && this.assetInspection.generatorNoLoad.frequency),
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Fluids', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Engine Oil',
      value: this.returnValue(this.assetInspection.fluids && this.assetInspection.fluids.engineOil && this.assetInspection.fluids.engineOil.length && this.assetInspection.fluids.engineOil.join(', ')),
    });

    fields.push({
      label: 'Oil, Fuel, Coolant Filters',
      value: this.returnValue(this.assetInspection.fluids && this.assetInspection.fluids.oilFuelCoolantFilters && this.assetInspection.fluids.oilFuelCoolantFilters.length && this.assetInspection.fluids.oilFuelCoolantFilters.join(', ')),
    });

    fields.push({
      label: 'Air Filters',
      value: this.returnValue(this.assetInspection.fluids && this.assetInspection.fluids.airFilters && this.assetInspection.fluids.airFilters.length && this.assetInspection.fluids.airFilters.join(', ')),
    });

    fields.push({
      label: 'IGN Points, Cond, Spark Plugs',
      value: this.returnValue(this.assetInspection.fluids && this.assetInspection.fluids.IGNPointsCondSparkPlugs && this.assetInspection.fluids.IGNPointsCondSparkPlugs.length && this.assetInspection.fluids.IGNPointsCondSparkPlugs.join(', ')),
    });

    fields.push({
      label: 'Control', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Meters, Lamps, Gauges',
      value: this.returnValue(this.assetInspection.control && this.assetInspection.control.metersLampsGauges),
    });

    fields.push({
      label: 'Circuit Boards and Relays',
      value: this.returnValue(this.assetInspection.control && this.assetInspection.control.circuitBoardsandRelays),
    });

    fields.push({
      label: 'Control Mounts',
      value: this.returnValue(this.assetInspection.control && this.assetInspection.control.controlMounts),
    });

    fields.push({
      label: 'Mounting', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Secured To Level Surface',
      value: this.returnValue(this.assetInspection.mounting && this.assetInspection.mounting.securedToLevelSurface),
    });

    fields.push({
      label: 'Proper Vibration Isolation',
      value: this.returnValue(this.assetInspection.mounting && this.assetInspection.mounting.properVibrationIsolation),
    });

    fields.push({
      label: 'Weather Enclosure Condition',
      value: this.returnValue(this.assetInspection.mounting && this.assetInspection.mounting.weatherEnclosureCondition),
    });

    fields.push({
      label: 'Automatic Transfer Switches', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Windings-Exciter, Rotor, Stator',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.windingsExciterRotorStator),
    });

    fields.push({
      label: 'Brushes, Slip Rings, etc',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.brushesSlipRings),
    });

    fields.push({
      label: 'All Conductors Properly Sized and Connected',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.allConductorsProperlySizedAndConnected),
    });

    fields.push({
      label: 'Breaker Protecting ATS on Utility and Gen',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.breakerProtectingATSOnUtilityAndGen),
    });

    fields.push({
      label: 'All Necessary Calibrations & Adjustments Complete',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.allNecessaryCalibrationsAndAdjustmentsComplete),
    });

    fields.push({
      label: 'Exercise Clock Day',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.exerciseClock && this.assetInspection.automaticTransferSwitches.exerciseClock.day),
    });

    fields.push({
      label: 'Exercise Clock Time',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.exerciseClock && this.assetInspection.automaticTransferSwitches.exerciseClock.time),
    });

    fields.push({
      label: 'Exercise Clock To',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.exerciseClock && this.assetInspection.automaticTransferSwitches.exerciseClock.to),
    });

    fields.push({
      label: '',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.multiSelection && this.assetInspection.automaticTransferSwitches.multiSelection.length && this.assetInspection.automaticTransferSwitches.multiSelection.join(', ')),
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: 'Sec To Engine Start',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.secToEngineStart),
    });

    fields.push({
      label: 'Sec To Transfer',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.secToTransfer),
    });

    fields.push({
      label: 'Min To Retransfer',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.minToRetransfer),
    });

    fields.push({
      label: 'Sec TDN',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.secTDN),
    });

    fields.push({
      label: 'Min Delay Stop',
      value: this.returnValue(this.assetInspection.automaticTransferSwitches && this.assetInspection.automaticTransferSwitches.minDelayStop),
    });

    fields.push({
      label: 'Utility Power - Voltage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'L1-L2',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L1L2),
    });

    fields.push({
      label: 'L2-L3',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L2L3),
    });

    fields.push({
      label: 'L3-L1',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L3L1),
    });

    fields.push({
      label: 'L1-N',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L1N),
    });

    fields.push({
      label: 'L2-N',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L2N),
    });

    fields.push({
      label: 'L3-N',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.voltage && this.assetInspection.utilityPower.voltage.L3N),
    });

    fields.push({
      label: 'Utility Power - Amperage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'A',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.amperage && this.assetInspection.utilityPower.amperage.A),
    });

    fields.push({
      label: 'B',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.amperage && this.assetInspection.utilityPower.amperage.B),
    });

    fields.push({
      label: 'C',
      value: this.returnValue(this.assetInspection.utilityPower && this.assetInspection.utilityPower.amperage && this.assetInspection.utilityPower.amperage.C),
    });

    fields.push({
      label: 'Generator With Load - Voltage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'L1-L2',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L1L2),
    });

    fields.push({
      label: 'L2-L3',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L2L3),
    });

    fields.push({
      label: 'L3-L1',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L3L1),
    });

    fields.push({
      label: 'L1-N',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L1N),
    });

    fields.push({
      label: 'L2-N',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L2N),
    });

    fields.push({
      label: 'L3-N',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.voltage && this.assetInspection.generatorWithLoad.voltage.L3N),
    });

    fields.push({
      label: 'Generator With Load - Amperage - Voltage', 
      value: '', 
      colSpan: 'sm:col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'A',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.amperage && this.assetInspection.generatorWithLoad.amperage.A),
    });

    fields.push({
      label: 'B',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.amperage && this.assetInspection.generatorWithLoad.amperage.B),
    });

    fields.push({
      label: 'C',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.amperage && this.assetInspection.generatorWithLoad.amperage.C),
    });

    fields.push({
      label: 'Generator With Load - Frequency',
      value: this.returnValue(this.assetInspection.generatorWithLoad && this.assetInspection.generatorWithLoad.frequency),
      colSpan: 'sm: col-span-2',
      className: 'text-15'
    });

    fields.push({
      label: 'Additional Conditions',
      value: this.returnValue(this.assetInspection.additionalConditions && this.assetInspection.additionalConditions.length ? this.assetInspection.additionalConditions.join(', ') : 'N/A'),
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get workOrderPageDetailCardFields(): InspectionCardItemModel[] {
    const fields: InspectionCardItemModel[] = [];

    fields.push({
      label: 'Conditions',
      value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      colSpan: 'sm:col-span-2'
    });

    fields.push({
      label: 'Due Date',
      value: this.getDueDate,
    });

    fields.push({
      label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
      value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
    });

    fields.push({
      label: 'Inspection ID/Remedy WO #',
      value: this.assetInspection.inspection && this.assetInspection.inspection.inspectionId ? this.assetInspection.inspection.inspectionId : 'N/A',
    });

    fields.push({
      label: 'Comment', value: this.returnValue(this.assetInspection.comment), colSpan: 'sm:col-span-2',
    });

    return fields;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'Equipment Name', value: this.returnValue(this.assetInspection.name)};
  }

  get linkTo(): string {
    return `/asset-inspection/${this.assetInspection.id}`;
  }

  get listItemFieldsClassName(): string {
    return `md:grid-cols-6`;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: 'Inspection ID/Remedy WO #',
        value:  this.returnValue(this.assetInspection.inspection && this.assetInspection.inspection.inspectionId),
      },
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'City, State', value:
          (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.city) : 'N/A') + ', '
          + (this.assetInspection.inspection && this.assetInspection.inspection.address ? this.returnValue(this.assetInspection.inspection.address.state) : 'N/A'),
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
      },
      {
        label: 'Due Date',
        value: this.getDueDate,
      },
    ];
  }

  get secondaryListItemFieldsClassName(): string {
    return `sm:grid-cols-4`;
  }

  get secondaryListItemFields(): InspectionCardItemModel[] {
    return [
      {
        label: this.assetInspection.inspectors && this.assetInspection.inspectors.length === 1 ? 'Inspector' : 'Inspectors',
        value: this.assetInspection.inspectors && this.assetInspection.inspectors.length ? this.assetInspection.inspectors.map(inspector => inspector.name).join(', ') : 'N/A',
      },
      {
        label: 'Condition Found',
        value: this.assetInspection.conditions && this.assetInspection.conditions.length ? this.assetInspection.conditions.join('; ') : 'No Trouble Found',
        colSpan: 'sm:col-span-2'
      },
    ];
  }


  get dateTime(): string {
    return this.assetInspection.createdAt ? moment(this.assetInspection.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get buttonText(): string {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get componentHeader(): string {
    return this.returnValue(this.assetInspection['name']);
  }

  get getDueDate() {
    return this.assetInspection.inspection && this.assetInspection.inspection.dueDate ? moment(`${this.assetInspection.inspection.dueDate}`, 'YYYYMMDD').format('MM/DD/YYYY') : 'N/A';
  };

  get assetInspectionStatusColor(): number[] {
    return InspectionHelper.getAssetInspectionStatusColor(this.assetInspection.status);
  }

  get previousNextListItem(): any {
    return InspectionHelper.getAssetInspectionPreviousNext(this.assetInspection);
  }

  get additionalAttachmentsTitle(): string {
    return '';
  }
}
