import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '../../../shared/components/Dialog/Dialog';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import UserMultiSelect from '../../../shared/components/UserMultiSelect/UserMultiSelect';
import { INSPECTION_STATUS } from '../../../shared/globals';
import InspectionsService from '../../../services/inspections.service';
import { InspectionModel } from '../../../shared/models/base-models/inspection.model';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import { TextField } from '@mui/material';

type Props = {
  onSave: Function;
  onClose: Function;
  inspection?: InspectionModel;
}

const InspectionToInProgressDialog = (props: Props) => {

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [inspectionAssigneeIds, setInspectionAssigneeIds] = useState([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (!props.inspection) {
      return;
    }

    if (!props.inspection.changeHistory || !props.inspection.changeHistory.length) {
      return;
    }
    const changeField = props.inspection.changeHistory.filter(changeHistoryField => changeHistoryField.field === 'assignedTo');

    if (!changeField || !changeField.length) {
      return;
    }
    setInspectionAssigneeIds(changeField.pop().oldValue);
  }, [props.inspection]);

  const handleSubmit = async () => {
    setIsLoading(true);

    InspectionsService.updateInspectionStatus(props.inspection.id, {
      status: INSPECTION_STATUS.inProgress,
      assignedTo: inspectionAssigneeIds,
      comment: comment
    })
      .then(res => {
        props.onSave(res, res.comments);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Inspection status set to In progress', 'success'));
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error saving changes';
        dispatch(NotificationMessage(message, 'error'));
        setIsLoading(false);
      });
  };

  const handleCommentChange = (e) => {
    setComment(e.target.value);
  };


  const formLoadingHandler = () => {
    setIsLoading(true);
  }

  const formLoadedHandler = () => {
    setIsLoading(false);
  }

  const assigneesChangedHandler = (values: string[]) => {
    setInspectionAssigneeIds(values);
  };

  const handleClose = () => {
    props.onClose();
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <Dialog
      title={'Mark QA/QC as Failed?'}
      text={'This action will change Inspection status to \'In Progress\'. Please reassign Inspection:'}
      actionsContent={[
        <LoadingButton
          key={'cancelAction'}
          disabled={false}
          color={'inherit'}
          onClick={handleClose}
          text={'Cancel'}
        />,
        <LoadingButton
          key={'submitAction'}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
          text={'Confirm'}
        />
      ]} onClose={props.onClose}>
      <div className={'w-full flex flex-col gap-y-16'}>
        <UserMultiSelect
          id={'inspectors-select'}
          className={'w-full'}
          label='Assigned To'
          onChange={assigneesChangedHandler}
          userIdKey={'id'}
          usersIds={inspectionAssigneeIds}
          formLoadingHandler={formLoadingHandler}
          formLoadedHandler={formLoadedHandler}
        />
        <TextField
          label='Comment'
          rows={3}
          placeholder='Comment'
          multiline variant='outlined'
          className={'w-full'}
          value={comment}
          onChange={handleCommentChange}
        />
      </div>
    </Dialog>
  );
};

export default InspectionToInProgressDialog;
