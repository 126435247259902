import { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import { useDispatch } from 'react-redux';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import ModelAttachments from '../../../shared/components/ModelAttachments/ModelAttachments';
import { InspectionHelper } from '../../../shared/helpers/inspection.helper';
import AssetInspectionsService from '../../../services/asset-inspections.service';
import { WorkOrderModel } from '../../../shared/models/base-models/work-order.model';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';
import { MassWorkOrderFormFactory } from '../../../shared/form-factories/mass-work-order-form.factory';
import Form, { FormRef } from '../../../shared/components/Form/Form';

type Props = {
  onSave: Function;
  onClose: Function;
  inspection?: any;
}

const MassWorkOrderModal = (props: Props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [conditionsList, setConditionsList] = useState([]);
  const [workOrder, setWorkOrder] = useState(new WorkOrderModel({}));

  const [form, setForm] = useState(new BaseFormModel());
  const formRef = useRef<FormRef>();

  useEffect(() => {
    if (!props.inspection) {
      return;
    }

    const workOrderData = {
      type: props.inspection.type,
    };

    if (props.inspection['routeInfo']) {
      workOrderData['routeInfo'] = props.inspection['routeInfo'];
    }

    if (props.inspection['inspectionId']) {
      workOrderData['inspectionId'] = props.inspection['inspectionId'];
    }

    const newWorkOrder = new WorkOrderModel(workOrderData)
    setWorkOrder(newWorkOrder);

    const conditionsFound = InspectionHelper.getInspectionUnhandledConditions(props.inspection);

    setConditionsList([...new Set(conditionsFound)]);

    const formSchemas = new MassWorkOrderFormFactory().createForm<BaseFormModel, WorkOrderModel>(newWorkOrder);
    setForm(formSchemas);
  }, [props.inspection]);

  const handleSubmit = (updatedWorkOrder: any) => {
    const assetInspectionIds = InspectionHelper.getUnhandledConditionAssetInspections(updatedWorkOrder.condition, props.inspection);
    if (!assetInspectionIds || !assetInspectionIds.length) {
      return;
    }

    setIsLoading(true);

    AssetInspectionsService.createMassWorkOrder(assetInspectionIds, {...updatedWorkOrder, files: workOrder['files']})
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Escalations created successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage('Error saving Escalations', 'error'));
        setIsLoading(false);
      });
  };

  const workOrderAttachmentsChangedHandler = (workOrderChanges) => {
    setWorkOrder(prevState => new WorkOrderModel({...prevState, ...workOrderChanges}));
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  return (
    <Modal onClose={props.onClose} title={'Create Escalations'}
           className={'sm:h-3/5 md:h-auto md:w-3/5 lg:w-1/2'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={'Submit'}
               />
             </div>
           }
    >

      {!!form.schema && <Form schema={form.schema}
                              className={'px-24 py-16'}
                              uiSchema={{
                                ...form.uiSchema,
                                condition: {
                                  ...form.uiSchema.condition,
                                  'ui:options': {
                                    optionsList: conditionsList && conditionsList.length
                                      ? conditionsList.map((condition) => ({
                                        key: condition,
                                        value: condition,
                                      })) : [],
                                  },
                                },
                              }}
                              onSubmit={handleSubmit}
                              formData={workOrder} ref={formRef}/>}
      <ModelAttachments attachments={workOrder.attachments} onChanged={workOrderAttachmentsChangedHandler}
                        className={`w-full mb-20 px-24`}
      />
    </Modal>
  );
};

export default MassWorkOrderModal;
