import { BaseModel } from '../models/base.model';
import { BaseViewModel } from '../models/base-view-models/base-view.model';
import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const DATA_TYPE = {
  fiber: 'fiber',
  ups: 'ups',
  generators: 'generators',
  telecomTowers: 'telecom-towers',
  passiveReflectors: 'passive-reflectors',
};

export type StatusType = {
  key: string,
  value: string,
  color: string,
  bgColor: string,
  bgColorRGB: number[],
  buttonBgColor: string,
};

export type DataType = {
  type: string,
  InspectionModel: typeof BaseModel,
  AssetInspectionModel: typeof BaseModel,
  AssetModel: typeof BaseModel,
  WorkOrderModel: typeof BaseModel,
  DispatchingDocumentModel?: typeof BaseModel,
  InspectionViewModel: typeof BaseViewModel,
  AssetInspectionViewModel: typeof BaseViewModel,
  AssetViewModel: typeof BaseViewModel,
  WorkOrderViewModel: typeof BaseViewModel,
  InspectionForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  AssetInspectionForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  SecondaryAssetInspectionForm?: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  AssetForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  SecondaryAssetForm?: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  DispatchingForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  WorkOrderForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  MassWorkOrderForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  InspectionsFilterForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  AssetInspectionsFilterForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  WorkOrdersFilterForm: {
    schema: RJSFSchema,
    uiSchema: UiSchema
  },
  inspectionStatuses: StatusType[],
  assetInspectionStatuses: StatusType[],
  workOrderStatuses: StatusType[],
};