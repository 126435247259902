import { CircularProgress, IconButton, SvgIcon, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MasonryImageList from '../MasonryImageList/MasonryImageList';
import Typography from '@mui/material/Typography';
import { ViewerImageModel } from '../../models/viewer-image.model';
import { FileModel } from '../../models/file.model';
import { ReactComponent as Download } from '../../../../assets/icons/material_download.svg';
import CheckBoxOutlineBlank from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxOutlined from '@mui/icons-material/CheckBoxOutlined';
import IndeterminateCheckBoxOutlined from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import FilesService from '../../../services/files.service';
import { NotificationMessage } from '../NotificationMessage/NotificationMessage';
import { useDispatch } from 'react-redux';
import AttachmentsList from '../ModelAttachments/AttachmentsList';
import { ImageHelper } from '../../helpers/imageHelper';
import ImagesViewer from '../ImagesViewer';
import { FileHelper } from '../../helpers/file.helper';
import Menu from '../Menu/Menu';
import MenuItem from '../Menu/MenuItem';

type Props = {
  attachments: FileModel[];
  isLoading?: boolean;
  title?: string;
  className?: string;
  smallView?: boolean;
  masonryImagesClassName?: string;
  viewerOpenedHandler?: Function;
  viewerClosedHandler?: Function;
  modelId?: any;
  isTitleSeparated?: boolean;
}

const AttachmentsComponent = (props: Props) => {

  const dispatch = useDispatch();

  const [images, setImages] = useState([]);
  const [viewerOpened, setViewerOpened] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const [attachedFiles, setAttachedFiles] = useState([] as FileModel[]);

  const [isHovered, setIsHovered] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState([]);

  const [downloadingCounter, setDownloadingCounter] = useState(0);
  const [isBulkDownloading, setIsBulkDownloading] = useState(false);
  const [isBulkDownloadingWithWatermarks, setIsBulkDownloadingWithWatermarks] = useState(false);

  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!props.attachments || !props.attachments.length) {
      setImages([]);
      setAttachedFiles([]);
      return;
    }

    const viewerImages = FileHelper.getViewerImagesFromAttachments(props.attachments);
    ImageHelper.loadThumbnailsForImageViewer(viewerImages, onImageLoaded);
    setImages(viewerImages);

    const attachedFiles = FileHelper.getFilesFromAttachments(props.attachments);
    setAttachedFiles(attachedFiles);
  }, [props.attachments]);

  const onImageLoaded = (images: ViewerImageModel[]) => {
    setImages(images.map(image => new ViewerImageModel(image)));
  };

  const imageClickHandler = (index: number) => {
    setSelectedImage(index);
    setViewerOpened(true);
    !!props.viewerOpenedHandler && props.viewerOpenedHandler();
  };

  const viewerClosedHandler = () => {
    setSelectedImage(null);
    setViewerOpened(false);
    !!props.viewerClosedHandler && props.viewerClosedHandler();
  };

  const hasAttachments = () => {
    return props.attachments && props.attachments.length;
  };

  const hasImages = () => {
    return images.length;
  };

  const handleFileDownload = async (file: FileModel) => {
    return FilesService.downloadFile(file)
      .catch((err) => {
        dispatch(NotificationMessage('Error downloading file, please try again', 'error'));
      });
  };

  const handleFileDownloadWithWatermark = async (file: FileModel) => {
    return FilesService.downloadFileWithWatermark(file, props.modelId)
      .catch((err) => {
        dispatch(NotificationMessage('Error downloading file, please try again', 'error'));
      });
  };

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const areAttachmentsSelected = () => {
    return selectedAttachments.length > 0;
  };

  const handleSelectButtonClicked = () => {
    if (!areAttachmentsSelected()) {
      setSelectedAttachments(props.attachments);
      return;
    }
    setSelectedAttachments([]);
  };

  const handleItemCheckboxClicked = (file: FileModel) => {
    if (selectedAttachments.some(attachment => attachment.id === file.id)) {
      setSelectedAttachments(prevState => prevState.filter(attachment => attachment.id !== file.id));
      return;
    }

    setSelectedAttachments(prevState => [...prevState, file]);
  };

  const mainCheckBoxIcon = () => {
    if (!areAttachmentsSelected()) {
      return <CheckBoxOutlineBlank fontSize={'small'} />;
    }
    if (selectedAttachments.length === props.attachments.length) {
      return <CheckBoxOutlined fontSize={'small'} />;
    }

    return <IndeterminateCheckBoxOutlined fontSize={'small'} />;
  };

  const mainCheckBoxText = () => {
    if (!areAttachmentsSelected()) {
      return 'Select All';
    }

    if (selectedAttachments.length === props.attachments.length) {
      return 'Clear selected';
    }

    return 'Clear selected';
  };

  const checkBoxItemIcon = (file: FileModel) => {
    if (!areAttachmentsSelected()) {
      return <CheckBoxOutlineBlank fontSize={'small'} className={'bg-white rounded'} />;
    }

    if (selectedAttachments.some(attachment => attachment.id === file.id)) {
      return <CheckBoxOutlined fontSize={'small'} className={'bg-white rounded'} />;
    }

    return <CheckBoxOutlineBlank fontSize={'small'} className={'bg-white rounded'} />;
  };

  const areActionsVisible = () => {
    return isHovered || areAttachmentsSelected();
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const downloadIcon = () => {
    return (
      <SvgIcon fontSize={'small'} className={'bg-white rounded'} color={'inherit'}>
        <Download />
      </SvgIcon>
    );
  };

  const itemIcon = (file: FileModel) => {
    if (!areActionsVisible()) {
      return null;
    }

    if (!areAttachmentsSelected()) {
      return downloadIcon();
    }

    return checkBoxItemIcon(file);
  };

  const handleItemClick = (file: FileModel) => {
    if (!areAttachmentsSelected()) {
      return handleFileDownload(file);
    }

    return handleItemCheckboxClicked(file);
  };

  const handleItemWatermarkClick = (file: FileModel) => {
    if (!areAttachmentsSelected()) {
      return handleFileDownloadWithWatermark(file);
    }

    return handleItemCheckboxClicked(file);
  };

  const bulkDownloadButtonText = () => {
    return areAttachmentsSelected() ? 'Download selected attachments' : 'Download all attachments';
  };

  const handleBulkDownload = () => {
    const files = areAttachmentsSelected() ? selectedAttachments : props.attachments;

    setDownloadingCounter((prev) => prev + 1);
    setIsBulkDownloading(true);
    FilesService.downloadFiles(files)
      .then(res => {
        setIsBulkDownloading(false);
        setDownloadingCounter((prev) => prev - 1);
      })
      .catch((err) => {
        dispatch(NotificationMessage('Error downloading file, please try again', 'error'));
        setIsBulkDownloading(false);
        setDownloadingCounter((prev) => prev - 1);
      });
  };

  const handleBulkDownloadWithWatermarks = () => {
    const files = areAttachmentsSelected() ? selectedAttachments : props.attachments;

    setDownloadingCounter((prev) => prev + 1);
    setIsBulkDownloadingWithWatermarks(true);
    FilesService.downloadFilesWithWatermarks(props.modelId, files)
      .then(res => {
        setIsBulkDownloadingWithWatermarks(false);
        setDownloadingCounter((prev) => prev - 1);
      })
      .catch((err) => {
        dispatch(NotificationMessage('Error downloading file, please try again', 'error'));
        setIsBulkDownloadingWithWatermarks(false);
        setDownloadingCounter((prev) => prev - 1);
      });
  };

  useEffect(() => {
    if (downloadingCounter > 0) {
      return;
    }

    setMenuOpened(false);
  }, [downloadingCounter]);

  const downloadMenu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'download'} onClick={handleBulkDownload} isLoading={isBulkDownloading}
                  icon={'heroicons-outline:download'} text={'Download'} disabled={isBulkDownloading}/>,
        <MenuItem key={'downloadWithWatermarks'} onClick={handleBulkDownloadWithWatermarks} isLoading={isBulkDownloadingWithWatermarks}
                  icon={'heroicons-outline:download'} text={'Download with Watermarks'} disabled={isBulkDownloadingWithWatermarks}/>,
      ]}
    />;

  return (
    <>
      <ImagesViewer images={images} opened={viewerOpened} onViewerClose={viewerClosedHandler}
                    selectedImageIndex={selectedImage} />
      <div className={`${props.className}`}
           onMouseOver={handleMouseOver} onMouseLeave={handleMouseOut}>
        {
          hasAttachments() ?
            <span>
              <Typography
                className={`${!!props.title && !!props.isTitleSeparated ? 'text-xl font-bold ' : 'hidden'}`}>{props.title}</Typography>
              <span
                className={`h-28 flex flex-row items-center ${!!props.smallView ? 'mb-6' : 'mb-12'} justify-between`}>
                <span className={'flex flex-row items-center'}>
                  <Typography className={`${!!props.title && !props.isTitleSeparated ? 'text-xl font-bold ' : 'hidden'}`}>{props.title}</Typography>
                  {
                    areActionsVisible() && !props.isLoading &&
                      <IconButton className={`${!!props.title && !props.isTitleSeparated ? 'ml-3' : ''} rounded-lg px-6 py-4`}
                                  onClick={handleSelectButtonClicked}>
                        {mainCheckBoxIcon()}
                          <label className={'text-13 ml-2 cursor-pointer text-gray-800'}>
                            {mainCheckBoxText()}
                          </label>
                      </IconButton>
                  }
                </span>
                {
                  !props.modelId && (areActionsVisible() || isBulkDownloading) && !props.isLoading &&
                    <Tooltip title={bulkDownloadButtonText()} placement='top-start' followCursor={true}>
                        <IconButton className={`ml-3`} onClick={handleBulkDownload}>
                          {
                            isBulkDownloading ?
                              <CircularProgress color={'inherit'} size={20} thickness={4}/> :
                              downloadIcon()
                          }
                        </IconButton>
                    </Tooltip>
                }
                {
                  !!props.modelId && ((areActionsVisible() || menuOpened) || isBulkDownloading) && !props.isLoading &&
                    <div>
                        <Tooltip title={bulkDownloadButtonText()} placement='top-start' followCursor={true}>
                            <IconButton className={`ml-3`} onClick={showMenu}>
                              {downloadIcon()}
                            </IconButton>
                        </Tooltip>
                      {downloadMenu}
                    </div>
                }
              </span>
            </span>
            :
            <span className={`h-28 flex flex-row items-center ${!!props.smallView ? 'mb-6' : 'mb-12'} justify-between`}>
             <span className={'flex flex-row items-center'}>
              <Typography className={`${!!props.title ? 'text-xl font-bold ' : 'hidden'}`}>{props.title}</Typography>
            </span>
          </span>
        }
        {props.isLoading &&
            <div className={'w-full p-12 text-center'}>
                <CircularProgress className={'m-auto'} />
            </div>
        }
        {
          !props.isLoading && images.length > 0 &&
            <MasonryImageList
                images={images}
                onImageClick={imageClickHandler}
                imageActionIcon={itemIcon}
                imageAction={handleItemClick}
                imageWatermarkAction={handleItemWatermarkClick}
                actionsAlwaysVisible={areAttachmentsSelected()}
                imageActionText={'Download Image'}
                className={`flex gap-8 ${!!props.smallView ? '' : 'lg:grid lg:grid-cols-3 sm:gap-10 md:gap-12'} rounded`}
                imageClassName={` ${props.masonryImagesClassName} cursor-pointer`}
                hasWatermark={!!props.modelId && (!selectedAttachments || !selectedAttachments.length)}
            />
        }
        <div className={`gap-8 grid sm:grid-cols-2 ${hasImages() ? 'mt-12' : ''} ${!!props.smallView && 'sm:grid-cols-4'}`}>
          <AttachmentsList files={attachedFiles} itemIcon={itemIcon}
                           actionsAlwaysVisible={areAttachmentsSelected()}
                           onItemClick={handleItemClick}
                           onItemIconButtonClick={handleItemClick} />
        </div>
        {
          !props.isLoading && !hasAttachments() &&
            <p className={`mt-10`}>No Attachments Added</p>
        }
      </div>
    </>
  );
};

export default AttachmentsComponent;
