import { WidgetProps } from '@rjsf/utils';
import TimePicker from '../../TimePicker/TimePicker';

const TimeWidget = (props: WidgetProps) => {
  return (
    <TimePicker
      value={!!props.value ? props.value : null}
      label={`${props.label}${props.required ? '*' : ''}`}
      onChange={(value) => props.onChange(!!value ? value : undefined)}
    />
  );
};

export default TimeWidget;