import { DOCUMENT_TYPES } from "../globals";

export class AttachmentHelper {

  static getAttachmentIcon(attachmentName: string): JSX.Element {
    const attachmentType = attachmentName.split('.').pop();

    const document = DOCUMENT_TYPES.find((document) => document.key.find(item => item === attachmentType));

    return document ? document.value : this.getAttachmentIcon('other');
  }

  static getAttachmentType(attachmentName: string): string {
    const attachmentType = attachmentName.split('.').pop();

    const document = DOCUMENT_TYPES.find((document) => document.key.find(item => item === attachmentType));

    return document ? document.type : 'other';
  }

}
