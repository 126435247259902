import { BaseModel } from './base.model';

export class CommentModel extends BaseModel {
  text: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
