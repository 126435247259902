import { StatusType } from '../../types';

export const PASSIVE_REFLECTORS_INSPECTION_STATUS = {
  dispatched: 'dispatched',
  inProgress: 'in-progress',
  waitingQa: 'waiting-qa',
  inQa: 'in-qa',
  completed: 'completed',
  closed: 'closed',
};

export const PASSIVE_REFLECTORS_INSPECTION_STATUSES: StatusType[] = [
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.dispatched,
    value: 'Dispatched',
    color: 'gray-400',
    bgColor: 'bg-gray-400',
    bgColorRGB: [189, 189, 189],
    buttonBgColor: '#BDBDBD',
  },
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.inProgress,
    value: 'In Progress',
    color: 'orange-600',
    bgColor: 'bg-orange-600',
    bgColorRGB: [251, 140, 0],
    buttonBgColor: '#FB8C00',
  },
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.waitingQa,
    value: 'Waiting QA/QC',
    color: 'orange-800',
    bgColor: 'bg-orange-800',
    bgColorRGB: [239, 108, 0],
    buttonBgColor: '#EF6C00',
  },
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.inQa,
    value: 'In QA/QC',
    color: 'blue-400',
    bgColor: 'bg-blue-400',
    bgColorRGB: [66, 165, 245],
    buttonBgColor: '#42A5F5',
  },
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.completed,
    value: 'Completed',
    color: 'green-400',
    bgColor: 'bg-green-400',
    buttonBgColor: '#66BB6A',
    bgColorRGB: [102, 187, 106],
  },
  {
    key: PASSIVE_REFLECTORS_INSPECTION_STATUS.closed,
    value: 'Closed',
    color: 'green-600',
    bgColor: 'bg-green-600',
    buttonBgColor: '#43A047',
    bgColorRGB: [67, 160, 71],
  },
];
