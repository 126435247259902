import { UserModel } from './user.model';

export class BaseModel {

  id: string;
  createdAt: string;
  createdBy: UserModel;
  updatedAt: string;
  updatedBy: UserModel;

  constructor(attributes?: any) {
  }

  get initialValues() {
    return {};
  }

  get childModels() {
    return {};
  }

  get baseChildModels() {
    return {
      createdBy: UserModel,
      updatedBy: UserModel,
    };
  }

  get unsafeApiAttributes() {
    return ['id', 'defaultImageUrl'];
  }

  setAttributes(input: any = {}): this {
    const attributes = input ? input : {};

    const childModels = {...this.childModels, ...this.baseChildModels};

    Object.assign(this, attributes);

    for (const attribute of Object.keys(childModels)) {
      const Model = childModels[attribute];
      let value = attributes[attribute];

      if (value !== undefined) {
        if (this.isArray(value) && value.length > 0 && typeof value[0] !== 'string') {
          value = value.map(item => new Model(item));
        } else if (this.isObject(value)) {
          value = new Model(value);
        }
        this[attribute] = value;
      }
    }

    return this;
  }

  private isArray(a: any) {
    return Array.isArray(a);
  }

  private isObject(o: any) {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
  }

  protected returnValue(value: string) {
    return value ? value : 'N/A';
  };
}
