import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { DRAFT_GENERATORS_INSPECTION_TYPES, DRAFT_TIER_RATING_LIST } from '../globals';

export const GENERATORS_DISPATCHING_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['inspectionId', 'inspectorExternalId', 'inspectionType', 'streetAddress', 'city', 'state', 'zip'],
  properties: {
    inspectionId: {
      type: 'string',
      title: 'Inspection ID/Remedy WO #',
    },
    inspectorExternalId: {
      type: 'string',
      title: 'Inspector',
    },
    inspectionType: {
      type: 'string',
      title: 'Inspection Type',
    },
    dueDate: {
      type: 'string',
      title: 'Due Date',
    },
    streetAddress: {
      type: 'string',
      title: 'Street Address',
    },
    city: {
      type: 'string',
      title: 'City',
    },
    state: {
      type: 'string',
      title: 'State',
    },
    zip: {
      type: 'string',
      title: 'ZIP',
    },
    size: {
      type: 'number',
      title: 'Size',
    },
    capacityUnits: {
      type: 'string',
      title: 'Capacity Units',
    },
    tierRating: {
      type: 'string',
      title: 'Tier Rating',
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
  },
};

export const GENERATORS_DISPATCHING_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames': 'w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  inspectionId: {
    'ui:placeholder': 'Inspection ID/Remedy WO #',
  },
  inspectorExternalId: {
    'ui:placeholder': 'Inspector',
    'ui:widget': 'UserExternalIdSelectWidget',
  },
  inspectionType: {
    'ui:placeholder': 'Inspection Type',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: DRAFT_GENERATORS_INSPECTION_TYPES,
    },
  },
  dueDate: {
    'ui:placeholder': 'Due Date',
    'ui:widget': 'date',
  },
  streetAddress: {
    'ui:placeholder': 'Street Address',
  },
  city: {
    'ui:placeholder': 'City',
  },
  state: {
    'ui:placeholder': 'State',
  },
  zip: {
    'ui:placeholder': 'ZIP',
  },
  size: {
    'ui:placeholder': 'Size',
    'ui:widget': 'NumberWidget',
  },
  capacityUnits: {
    'ui:placeholder': 'Capacity Units',
  },
  tierRating: {
    'ui:placeholder': 'Tier Rating',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: DRAFT_TIER_RATING_LIST,
    },
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 lg:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};