import React from 'react';
import { FieldChangeModel } from '../models/field-change.model';
import {
  CHANGE_LOG_STATUSES,
  TIMELINE_DOT_TYPES,
  TimelineDotType,
} from '../globals';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Chip from '../components/Chip/Chip';
import moment from 'moment';
import { UserModel } from '../models/user.model';
import ChangeLogTimelineItem from '../components/ChangeLogTimeline/ChangeLogTimelineItem';
import ImagesComponent from '../components/ChangeLogTimelineComponents/ImagesComponent';
import WorkOrderComponent from '../components/ChangeLogTimelineComponents/WorkOrderComponent';
import StatusComponent from '../components/ChangeLogTimelineComponents/StatusComponent';
import AssignedToComponent from '../components/ChangeLogTimelineComponents/AssignedToComponent';
import TimelineAttachmentsComponent from '../components/ChangeLogTimelineComponents/TimelineAttachmentsComponent';
import { OBJECT_KEYS_LIST, SECONDARY_OBJECT_KEYS_LIST } from '../globals';

const className = 'text-12 mr-3 whitespace-nowrap';
const arrayValuesClassName = 'text-12 mr-3';
const valuesClassName = `text-12 font-400 px-5 py-2`;

export class FieldChangeHelper {

  static stringField(fieldChange: FieldChangeModel, userName: string): JSX.Element {
    if (this.getValue(fieldChange.newValue) && this.getValue(fieldChange.oldValue)) {
      return <div className={'flex flex-col items-start mb-5'}>
        <div className={'flex flex-wrap mb-8'}>
          <label className={`font-bold ${className}`}>{userName}</label>
          <label className={`${className}`}>{`changed field`}</label>
          <label className={`font-bold ${className}`}>{fieldChange.field}</label>
        </div>

        <div className={'flex flex-col md:flex-row items-start md:items-center'}>
          <div className={'flex bg-blue-gray-50 rounded'}>
            <label className={`${valuesClassName}`}>{fieldChange.oldValue}</label>
          </div>
          {/*@ts-ignore*/}
          <FuseSvgIcon size={15} className={'my-auto mx-3'}>material-outline:arrow_right_alt</FuseSvgIcon>
          <div className={'flex bg-blue-gray-50 rounded'}>
            <label className={`${valuesClassName}`}>{fieldChange.newValue}</label>
          </div>
        </div>
      </div>;
    }

    if (!this.getValue(fieldChange.oldValue)) {
      return <div className={'flex flex-col items-start mb-5'}>
        <div className={'flex flex-wrap mb-8'}>
          <label className={`font-bold ${className}`}>{userName}</label>
          <label className={`${className}`}>added value to</label>
          <label className={`font-bold ${className}`}>{fieldChange.field}</label>
        </div>
        <div className={'flex bg-blue-gray-50 rounded'}>
          <label className={`${valuesClassName}`}>{fieldChange.newValue}</label>
        </div>
      </div>;
    }

    if (!this.getValue(fieldChange.newValue)) {
      return <div className={'flex flex-col items-start mb-5'}>
        <div className={'flex flex-wrap mb-8'}>
          <label className={`font-bold ${className}`}>{userName}</label>
          <label className={`${className}`}>cleared</label>
          <label className={`font-bold ${className}`}>{fieldChange.field}</label>
        </div>
        <div className={'flex bg-blue-gray-50 rounded'}>
          <label className={`${valuesClassName}`}>{fieldChange.oldValue}</label>
        </div>
      </div>;
    }
  }

  static changedStringField(fieldChange: FieldChangeModel, userName: string): JSX.Element {
    return <div className={'flex flex-col items-start mb-5'}>
      <div className={'flex flex-wrap mb-8'}>
        <label className={`font-bold ${className}`}>{userName}</label>
        <label className={`${className}`}>{`changed field`}</label>
        <label className={`font-bold ${className}`}>{fieldChange.field}</label>
      </div>
      <div className={'flex flex-col md:flex-row items-start md:items-center'}>
        {!!fieldChange.oldValue ? <div className={'flex bg-blue-gray-50 rounded'}>
          <label className={`${valuesClassName}`}>{fieldChange.oldValue}</label>
        </div> : <></>
        }
        {/*@ts-ignore*/}
        {!!fieldChange.oldValue && !!fieldChange.newValue && <FuseSvgIcon size={15} className={'my-auto mx-3'}>material-outline:arrow_right_alt</FuseSvgIcon>}
        {!!fieldChange.newValue ? <div className={'flex bg-blue-gray-50 rounded'}>
          <label className={`${valuesClassName}`}>{fieldChange.newValue}</label>
        </div> : <></>
        }
      </div>
    </div>;
  }

  static arrayField(userName: string, action: string, values: string, field: string): JSX.Element {
    return <div className={'flex flex-wrap items-start mb-5'}>
      <label className={`font-bold ${className}`}>{userName}</label>
      <label className={`${className}`}>{action}</label>
      <label className={`font-bold ${arrayValuesClassName}`}>{values}</label>
      <label className={`${className}`}>{`${action === 'added' ? 'to' : 'from'}`}</label>
      <label className={`font-bold ${className}`}>{field}</label>
    </div>;
  }

  static usersField(userName: string, action: string, values: string): JSX.Element {
    return <div className={'flex flex-wrap items-start mb-5'}>
      <label className={`font-bold ${className}`}>{userName}</label>
      <label className={`${className}`}>{action}</label>
      <label className={`font-bold ${arrayValuesClassName}`}>{values}</label>
    </div>;
  }

  static getValue(value: any): boolean {
    return (value ? (Array.isArray(value) ? (value.length > 0) : (value !== '')) : false);
  }

  static getFieldChangeTimelineDot(fieldChange: FieldChangeModel): TimelineDotType {
    let action;

    if (this.getValue(fieldChange.newValue) && this.getValue(fieldChange.oldValue)) {
      action = 'changed';
    }

    if (!this.getValue(fieldChange.oldValue)) {
      action = 'added';
    }

    if (!this.getValue(fieldChange.newValue)) {
      action = 'removed';
    }
    return TIMELINE_DOT_TYPES.find(timelineDot => timelineDot.action === action);
  }

  static getActionTimelineDot(action: string): TimelineDotType {
    return TIMELINE_DOT_TYPES.find(timelineDot => timelineDot.action === action);
  }

  static getTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        {FieldChangeHelper.stringField(fieldChange, user && user.name ? user.name : 'N/A')}
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getChangedTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        {FieldChangeHelper.changedStringField(fieldChange, user && user.name ? user.name : 'N/A')}
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getArrayTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string, action: string, value: string): any {
    const timelineDot = FieldChangeHelper.getActionTimelineDot(action);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        {FieldChangeHelper.arrayField(user && user.name ? user.name : 'N/A', action, value, fieldChange.field)}
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getUsersTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string, action: string, actionText: string, value: string): any {
    const timelineDot = FieldChangeHelper.getActionTimelineDot(action);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        {FieldChangeHelper.usersField(user && user.name ? user.name : 'N/A', actionText, value)}
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getWorkOrderTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], action: string, value: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <WorkOrderComponent changeHistoryField={changeHistoryField} userName={user && user.name ? user.name : 'N/A'}
                            id={value} action={action}/>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={changeHistoryField.changedAt ? moment(changeHistoryField.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getActivityCommentTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], value: string): any {
    const timelineDot = FieldChangeHelper.getActionTimelineDot('activityComment');
    const user = users.find(user => user.id === changeHistoryField.changedBy);

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <div className={'flex flex-col items-start mb-5'}>
          <div className={'flex flex-wrap mb-8'}>
            <label className={`font-bold ${className}`}>{user ? user.name : 'N/A'}</label>
            <label className={`${className}`}>commented:</label>
          </div>
          <div className={'flex bg-blue-gray-50 rounded'}>
            <label className={`${valuesClassName}`}>{value}</label>
          </div>
        </div>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={changeHistoryField.changedAt ? moment(changeHistoryField.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getImagesTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string, action: string, value: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <ImagesComponent userName={user && user.name ? user.name : 'N/A'} action={action} ids={value} fieldName={name}/>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getAttachmentsTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string, action: string, value: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    const fieldChange = {...changeHistoryField, field: name};

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <TimelineAttachmentsComponent userName={user && user.name ? user.name : 'N/A'} action={action} ids={value}
                                      fieldName={name}/>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={fieldChange.changedAt ? moment(fieldChange.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getStatusTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[]): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <StatusComponent changeHistoryField={changeHistoryField} users={users}/>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={changeHistoryField.changedAt ? moment(changeHistoryField.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getAssignedToTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], action: string, value: string): any {
    const timelineDot = FieldChangeHelper.getFieldChangeTimelineDot(changeHistoryField);

    const user = users.find(user => user.id === changeHistoryField.changedBy);

    return (
      <ChangeLogTimelineItem timelineDot={timelineDot}>
        <AssignedToComponent changeHistoryField={changeHistoryField} users={users} value={value} action={action}
                             userName={user && user.name}/>
        <Chip className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={changeHistoryField.changedAt ? moment(changeHistoryField.changedAt).format('MM/DD/YYYY hh:mm A') : 'N/A'}
        />
      </ChangeLogTimelineItem>
    );
  }

  static getAddressTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[]): any {
    const oldAddress = changeHistoryField.oldValue ? `${this.returnValue(changeHistoryField.oldValue.streetAddress)}, ${this.returnValue(changeHistoryField.oldValue.city)}, ${this.returnValue(changeHistoryField.oldValue.county)}` : undefined;
    const newAddress = changeHistoryField.newValue ? `${this.returnValue(changeHistoryField.newValue.streetAddress)}, ${this.returnValue(changeHistoryField.newValue.city)}, ${this.returnValue(changeHistoryField.newValue.county)}` : undefined;

    changeHistoryField.oldValue = ((oldAddress && (oldAddress === 'N/A, N/A, N/A')) ? undefined : oldAddress);
    changeHistoryField.newValue = ((newAddress && (newAddress === 'N/A, N/A, N/A')) ? undefined : newAddress);

    return this.getTimelineItemParams(changeHistoryField, users, 'Address');
  }

  static getComplexObjectTimelineItemParams(changeHistoryField: FieldChangeModel, users: UserModel[], name: string): any {
    const updatedChangeHistoryField = this.getComplexObjectValue(changeHistoryField);

    return this.getChangedTimelineItemParams(updatedChangeHistoryField, users, name);
  }

  static getRemovedAddedValues(changeHistoryField: FieldChangeModel): [string[], string[]] {
    if (this.getValue(changeHistoryField.newValue) && this.getValue(changeHistoryField.oldValue)) {
      const removedValues = changeHistoryField.oldValue.filter(oldValue => !changeHistoryField.newValue.some(newValue => newValue === oldValue));
      const addedValues = changeHistoryField.newValue.filter(newValue => !changeHistoryField.oldValue.some(oldValue => oldValue === newValue));
      return [removedValues, addedValues];
    }

    if (!this.getValue(changeHistoryField.newValue)) {
      return [changeHistoryField.oldValue, []];
    }

    if (!this.getValue(changeHistoryField.oldValue)) {
      return [[], changeHistoryField.newValue];
    }
  }

  static getUsersNames(users: UserModel[], selectedUsers: string[]): string {
    const userObjects = users.filter(user => selectedUsers.some(id => id === user.id));
    return userObjects && userObjects.length ? userObjects.map(user => user.name).join(', ') : 'N/A';
  }

  static getStatusObject(status: string): any {
    return CHANGE_LOG_STATUSES.find(changeLogStatus => changeLogStatus.key === status);
  }

  static getComplexObjectValue(changeHistoryField: FieldChangeModel): FieldChangeModel {

    const oldValue = this.findObjectsDifferences(changeHistoryField.newValue, changeHistoryField.oldValue);
    const newValue = this.findObjectsDifferences(changeHistoryField.oldValue, changeHistoryField.newValue);

    return {...changeHistoryField, oldValue: this.processObject(oldValue), newValue: this.processObject(newValue)};
  }

  static findObjectsDifferences = (obj1, obj2) => {
    const result = {};

    for (const key in obj1) {
      if (obj1.hasOwnProperty(key)) {
        if (obj2 && obj2.hasOwnProperty(key)) {
          const obj1Key = Array.isArray(obj1[key]) ? (!!obj1[key].length ? obj1[key].join(', ') : 'none') : obj1[key];
          const obj2Key = Array.isArray(obj2[key]) ? (!!obj2[key].length ? obj2[key].join(', ') : 'none') : obj2[key];

          if (typeof obj1Key === 'object' && obj1Key !== null && typeof obj2Key === 'object' && obj2Key !== null) {

            const nestedDiff = this.findObjectsDifferences(obj1Key, obj2Key);
            if (!!Object.keys(nestedDiff).length) {
              result[key] = nestedDiff;
            }
          } else if (obj1Key !== obj2Key) {
            result[key] = obj2Key;
          }
        }
      }
    }

    // Include values from obj2 that are not present in obj1
    for (const key in obj2) {
      const obj2Key = Array.isArray(obj2[key]) ? (!!obj2[key].length ? obj2[key].join(', ') : 'none') : obj2[key];
      if (obj2.hasOwnProperty(key) && ((obj1 && obj1[key] === undefined) || !obj1)) {
        result[key] = obj2Key;
      }
    }

    return result;
  }

  static processObject = (obj) => {
    if (obj && typeof obj === 'object') {
      return Object.entries(obj)
        .map(([key, value]) => {
          const matchingKey = OBJECT_KEYS_LIST.find(item => item.key === key);
          if (!matchingKey) {
            return;
          }

          const displayKey = matchingKey ? matchingKey.value : key;
          if (!displayKey) {
            return;
          }

          if (key === 'batteryAge') {
            if (!value['status']) {
              return `${displayKey}: ${
                value['value'] ? moment(value['value']).format('MM/DD/YYYY') : ''
              }`;
            }

            if (!value['value']) {
              return `${displayKey}: ${value['status'] ? value['status'] : ''}`;
            }

            return `${displayKey}: ${value['status']}${
              value['value'] ? `, ${moment(value['value']).format('MM/DD/YYYY')}` : ''
            }`;
          }

          if (typeof value === 'object' && !!value && 'status' in value && ('rated' in value || 'discrepancyAndRemediation' in value)) {
            return `${displayKey}: status - ${value['status']};${value['rated'] ?
              ` rated - ${value['rated']};` : ''}${value['discrepancyAndRemediation'] ?
              ` discrepancy description and possible remediation - ${value['discrepancyAndRemediation']};` : ''}`;
          }

          if (typeof value === 'object' && !!value && 'status' in value && 'value' in value) {
            return `${displayKey}: ${value['status']}${value['value'] ? `, ${value['value']}` : ''}`;
          }

          if (typeof value === 'object' && !!value && !('status' in value) && 'value' in value) {
            return `${displayKey} Value: ${value['value']}`;
          }

          if (typeof value === 'object' && !!value && 'status' in value && !('value' in value)) {
            return `${displayKey} Status: ${value['status']}`;
          }

          if (typeof value === 'object' && SECONDARY_OBJECT_KEYS_LIST.some(item => item.key in value)) {
            return Object.entries(value)
              .map(([secondaryKey, secondaryValue]) => {
                const secondaryMatchingKey = SECONDARY_OBJECT_KEYS_LIST.find(item => item.key === secondaryKey);
                if (!secondaryMatchingKey) {
                  return;
                }

                const secondaryDisplayKey = secondaryMatchingKey ? secondaryMatchingKey.value : secondaryKey;
                if (!secondaryDisplayKey) {
                  return;
                }

                return `${secondaryDisplayKey}: ${secondaryValue}`;
              })
              .join('; ');
          }

          return `${displayKey}: ${value}`;
        })
        .join('; ');
    }
    return obj;
  };

  static returnValue(value: string) {
    return value ? value : 'N/A';
  };
}
