import { MapAssetInterface } from '../interfaces/map-asset.interface';
import { MapPointModel } from '../map-point.model';
import { FiberAssetInspectionModel } from '../../types/fiber/models/fiber-asset-inspection.model';
import { InspectionHelper } from '../../helpers/inspection.helper';

export class AssetInspectionMapModel extends FiberAssetInspectionModel implements MapAssetInterface {
  getMapPoint(): MapPointModel {
    if (!this.location || !this.location.longitude || !this.location.latitude) {
      return;
    }

    // TODO: get color directly from model
    return new MapPointModel({
      id: this.id,
      location: this.location,
      color: InspectionHelper.getAssetInspectionStatusColor(this.status)
    });
  }
}
