import { FilterFormFactory } from './filter-form.factory';
import { TypeHelper } from '../helpers/type.helper';
import { BaseFormModel } from '../models/base-form-models/base-form.model';

export class InspectionsFilterFormFactory implements FilterFormFactory {

  createFormByType<T extends BaseFormModel>(type: string): T {
    const form = TypeHelper.getTypeConfigByType(type).InspectionsFilterForm;

    return form as T;
  }

}