import React from 'react';
import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';

type Props = {
  item: InspectionCardItemModel;
  condensed: boolean;
  clickable: boolean;
}

const InspectionCardElement = (props: Props) => {

  return (
    <div className={`w-full m-auto col-span-1 ${props.item.colSpan}`}>
      <label className={`${props.clickable && 'cursor-pointer'} font-light whitespace-nowrap block ${props.condensed ? 'text-10' : 'text-11'} sm:truncate`}>
        {props.item.label}
      </label>
      <span
           className={`${props.clickable && 'cursor-pointer'} font-semibold sm:whitespace-nowrap sm:truncate block ${props.condensed && 'text-11'}`}>
        {props.item.value}
      </span>
    </div>
  );
}

export default InspectionCardElement;
