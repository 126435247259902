import { TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CorrectiveActionModel } from '../../../shared/models/corrective-action.model';

type Props = {
  correctiveAction: CorrectiveActionModel;
  onChanged: Function;
  className?: string;
}

const CorrectiveActionForm = (props: Props) => {

  const [correctiveAction, setCorrectiveAction] = useState(new CorrectiveActionModel());

  const {register, handleSubmit, watch, formState: {errors}, getValues, control, reset} = useForm<CorrectiveActionModel>();

  const onSubmit: SubmitHandler<CorrectiveActionModel> = data => console.log(data);

  useEffect(() => {
    reset(props.correctiveAction);
    setCorrectiveAction(props.correctiveAction);
  }, [props.correctiveAction]);

  // useEffect(() => {
  //   props.onChanged(getValues());
  // }, [watch()]);

  const handleFormChange = () => {
    props.onChanged(getValues());
  };

  return (
    <div className={`${props.className}`}>
      <Typography className='text-lg font-semibold mb-8'>{'Corrective Action Info'}</Typography>

      <form onSubmit={handleSubmit(onSubmit)} onChange={handleFormChange}>
        <div className={'w-full grid grid-cols-1 gap-x-12 gap-y-16'}>
          {/* @ts-ignore */}
          <Controller
            name='notes'
            control={control}
            render={({field: {onChange, value}}) => (
              <TextField
                label='Notes'
                rows={3}
                placeholder='Notes'
                multiline variant='outlined'
                className={'w-full'}
                value={value ? value : ''}
                onChange={onChange}
              />
            )}
          />
        </div>
      </form>
    </div>
  );
};

export default CorrectiveActionForm;
