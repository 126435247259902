import React, { useState } from 'react';
import useEffectOnce from '../../hooks/useEffectOnce';
import { Skeleton } from '@mui/material';
import { FileModel } from '../../models/file.model';
import FilesService from '../../../services/files.service';
import AttachmentsComponent from '../../../shared/components/AttachmentsComponent/AttachmentsComponent';

type Props = {
  userName: string;
  action: string;
  ids: string;
  fieldName: string;
}

const TimelineAttachmentsComponent = (props: Props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState([new FileModel()]);

  useEffectOnce(() => {
    setIsLoading(true);
    FilesService.getByIds({ids: props.ids})
      .then((results) => {
        setAttachments(results);
        setIsLoading(false);
      });
  }, [props.ids]);

  return <>
    {
      isLoading &&
        <div className={'flex flex-wrap items-start mb-5'}>
            <Skeleton variant='text' animation={'wave'} width={300} sx={{fontSize: '12px'}} className={'my-auto'}/>
        </div>
    }
    {
      !isLoading &&
        <div className={'w-full'}>
            <div className={'flex flex-wrap items-start mb-5'}>
                <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>{props.userName}</label>
                <label className={`text-12 mr-3 whitespace-nowrap`}>{props.action}</label>
                <label className={`font-bold text-12 mr-3 whitespace-nowrap`}>{`Attachments: `}</label>
            </div>
            <div className={`mt-8 mb-4`}>
                <AttachmentsComponent attachments={attachments} smallView={true} masonryImagesClassName={'w-64 h-64 sm:w-64 sm:h-64 md:w-64 md:h-64 lg:w-64 lg:h-64'} />
            </div>
        </div>
    }
  </>;
}

export default TimelineAttachmentsComponent;