import { WidgetProps } from '@rjsf/utils';
import { TextField } from '@mui/material';

const TextWidget = (props: WidgetProps) => {
  return (
    <TextField size={'small'} value={props.value ? props.value : ''}
               label={`${props.label}${props.required ? '*' : ''}`}
               error={!!props.rawErrors}
               placeholder={`${props.placeholder}${props.required ? '*' : ''}`}
               className={`w-full ${props.className ? props.className : ''}`}
               onChange={(event) => props.onChange(!!event.target.value ? event.target.value : '')}
    />
  );
};

export default TextWidget;