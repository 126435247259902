import React from 'react';
import { IconButton } from '@mui/material';
import PlaceIcon from '@mui/icons-material/Place';
import { InspectionCardHeadingModel } from '../../models/inspection-card-heading.model';

type Props = {
  heading: InspectionCardHeadingModel;
  cardIsHovered?: boolean;
  onButtonClickHandler?: Function;
  className?: string;
  condensed?: boolean;
}

const CardHeading = (props: Props) => {

  const onButtonClickHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.onButtonClickHandler();
  }

  return (
    <span>
      <span className={`flex pl-2`}>
      <span className={`${props.cardIsHovered && 'mr-5'} ${props.className}`}>
        <label className={`text-10 cursor-pointer block whitespace-nowrap`}>
          {props.heading && props.heading.label}
        </label>
         <label className={`cursor-pointer block text-blue font-bold text-12`}>
            {props.heading && props.heading.value}
          </label>
      </span>
        {props.cardIsHovered &&
            <IconButton
                onClick={onButtonClickHandler}
                size={'small'}
            >
                <PlaceIcon sx={{width: 20, height: 20}}/>
            </IconButton>
        }
    </span>
      {/*{!props.condensed &&*/}
      {/*    <span className={`cursor-pointer block whitespace-nowrap font-medium ${props.condensed && 'text-10'}`}>*/}
      {/*     {props.heading.info}*/}
      {/*   </span>*/}
      {/*}*/}
    </span>
  );
}

export default CardHeading;
