import { InspectionCardItemModel } from '../../../models/inspection-card-item.model';
import { InspectionCardHeadingModel } from '../../../models/inspection-card-heading.model';
import moment from 'moment';
import { AssetViewModel } from '../../../models/base-view-models/asset-view.model';
import { FiberAssetModel } from '../models/fiber-asset.model';

export class FiberAssetViewModel extends AssetViewModel {

  private asset: FiberAssetModel;

  constructor(asset: FiberAssetModel) {
    super(asset);
    this.asset = asset;
  }

  get componentHeaderTitle(): string {
    return `SAP Equipment ID`;
  }

  get componentHeader(): string {
    return `${this.returnValue(this.asset.externalId)}`;
  }

  get infoCardFields(): InspectionCardItemModel[] {
    return [
      this.getInfoCardField('SAP Equipment ID', 'externalId'),
      this.getInfoCardField('Equipment', 'assetType'),
      this.getInfoCardField('Telecom Work Center', 'telecomWorkCenter'),
      {
        label: 'Placement',
        value: this.asset['placement'] ? this.returnValue(this.asset['placement']['type']) : 'N/A',
        oldValue: this.comparingAssetPlacement('type')
      },
      {
        label: this.asset['placement'] ? this.returnValue(this.asset['placement']['type']) : 'N/A',
        value: this.asset['placement'] && this.asset['placement']['material'] && this.asset['placement']['material'].length ? this.asset['placement']['material'].join(', ') : 'N/A',
        oldValue: this.comparingAssetPlacement('material') && this.comparingAssetPlacement('material').length ? this.comparingAssetPlacement('material').join(', ') : this.comparingAssetPlacement('material')
      },
      this.getInfoCardField('Fire Threat', 'fireThreat'),
      this.getInfoCardField('Pole Type', 'poleType'),
      {
        label: 'Location',
        value: this.asset.location ? this.asset.location.locationString : 'N/A',
        colSpan: 'sm:col-span-2',
        oldValue: this.comparingAssetLocation()
      },
    ];
  }

  private getInfoCardField(name: string, field: string): InspectionCardItemModel {
    return {
      label: name,
      value: this.returnValue(this.asset[field]),
      oldValue: this.asset.comparingAsset && this.asset.comparingAsset[field] && (this.asset.comparingAsset[field] !== this.asset[field]) && this.returnValue(this.asset.comparingAsset[field])
    };
  };

  private comparingAssetPlacement(field: string): any {
    return this.asset.comparingAsset && this.asset.comparingAsset['placement'] && this.asset.comparingAsset['placement'][field] && this.asset['placement'] && this.asset['placement'][field] && (this.asset.comparingAsset['placement'][field].toString() !== this.asset['placement'][field].toString()) && this.asset.comparingAsset['placement'][field];
  };

  private comparingAssetLocation(): string {
    return this.asset.comparingAsset && (this.getComparingAssetLocation() !== (this.asset.location && this.asset.location.locationString)) && this.getComparingAssetLocation();
  };

  private getComparingAssetLocation(): string {
    return this.asset.comparingAsset && this.asset.comparingAsset.location ? `${this.returnValue(this.asset.comparingAsset.location['latitude'])}, ${this.returnValue(this.asset.comparingAsset.location['longitude'])}` : 'N/A';
  };

  get detailCardFields(): InspectionCardItemModel[] {
    return [
      {label: 'Equipment', value: this.returnValue(this.asset['assetType'])},
      {label: 'Telecom Work Center', value: this.returnValue(this.asset['telecomWorkCenter'])},
      {label: 'Placement', value: this.asset['placement'] ? this.returnValue(this.asset['placement']['type']) : 'N/A'},
      {
        label: this.asset['placement'] ? this.returnValue(this.asset['placement']['type']) : 'N/A',
        value: this.asset['placement'] && this.asset['placement']['material'] && this.asset['placement']['material'].length ? this.asset['placement']['material'].join(', ') : 'N/A'
      },
      {label: 'Fire Threat', value: this.returnValue(this.asset['fireThreat'])},
      {label: 'Pole Type', value: this.returnValue(this.asset['poleType'])},
      {label: 'Location', value: this.asset.location ? this.asset.location.locationString : 'N/A', colSpan: 'sm:col-span-2'},
      {label: 'Notes', value: this.returnValue(this.asset['notes']), colSpan: 'sm:col-span-2'},
    ];
  }

  get listItemFieldsClassName(): string {
    return ``;
  }

  get listItemFields(): InspectionCardItemModel[] {
    return [
      {label: 'Route Info', value: this.returnValue(this.asset['routeInfo'])},
      {label: 'Equipment', value: this.returnValue(this.asset['assetType'])},
      {label: 'Fire Threat', value: this.returnValue(this.asset['fireThreat'])},
    ];
  }

  get additionalItemField(): InspectionCardItemModel {
    return undefined;
  }

  get buttonAction(): Function {
    return undefined;
  }

  get buttonText(): string {
    return '';
  }

  get dateTime(): string {
    return this.asset.createdAt ? moment(this.asset.createdAt).format('MM/DD/YYYY, hh:mm A') : 'N/A';
  }

  get headingItemFields(): InspectionCardItemModel[] {
    return [];
  }

  get linkTo(): string {
    return `/asset/${this.asset.id}`;
  }

  get listItemHeading(): InspectionCardHeadingModel {
    return {label: 'SAP Equipment ID', value: this.returnValue(this.asset.externalId)};
  }

}
