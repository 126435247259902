import Typography from '@mui/material/Typography';
import React, { useContext } from 'react';
import { Card, Skeleton } from '@mui/material';
import { URLHelper } from '../../../shared/helpers/url.helper';
import { useNavigate } from 'react-router-dom';
import { DEPARTMENTS } from '../../../shared/globals';
import DepartmentContext from '../../../DepartmentContext';
import { DepartmentHelper } from '../../../shared/helpers/department.helper';

type Props = {
  label: string;
  stats: any;
  isLoading?: boolean;
  additionalDetails?: any;
  className?: string;
  linkTo?: string;
  queryParams?: any;
}

const AllItemsCard = (props: Props) => {

  const navigate = useNavigate();
  const {updateDepartment} = useContext(DepartmentContext);

  const routeChange = (e, department) => {
    updateDepartment(department);
    e.preventDefault();
    const queryParams = {
      department: department,
      ...props.queryParams,
    };

    const path = URLHelper.getUrlQuery(props.linkTo, queryParams);

    navigate(path);
  }

  return (
    <Card className={`flex flex-auto shadow rounded-2xl overflow-hidden ${props.className}`}>
      <div>
        <div className={'flex items-start justify-between m-16 mb-0'}>
          <Typography className={'text-lg font-medium tracking-tight leading-6 truncate'}>
            {props.label}
          </Typography>
        </div>
        {!!props.isLoading ?
          <div className={'mx-16 mb-12 mt-2 text-center'}>
            <Skeleton variant='text' animation={'wave'} width={100} sx={{fontSize: '50px'}}/>
            <div className={'grid grid-cols-2 sm:grid-cols-5 gap-4'}>
              <Skeleton variant='text' animation={'wave'} width={90} sx={{fontSize: '45px'}}/>
              <Skeleton variant='text' animation={'wave'} width={90} sx={{fontSize: '45px'}}/>
              <Skeleton variant='text' animation={'wave'} width={90} sx={{fontSize: '45px'}} className={'hidden sm:flex'}/>
              <Skeleton variant='text' animation={'wave'} width={90} sx={{fontSize: '45px'}} className={'hidden sm:flex'}/>
              <Skeleton variant='text' animation={'wave'} width={90} sx={{fontSize: '45px'}} className={'hidden sm:flex'}/>
            </div>
          </div> :
          <div className={'mb-12 mx-12'}>
            <div className={'my-8 flex gap-x-8 sm:gap-x-12'}>
              <Typography className={'text-7xl font-bold tracking-tighter leading-tight'}>
                {props.stats ? props.stats.all : 'N/A'}
              </Typography>

              {props.additionalDetails && props.additionalDetails.length ?
                <div className={'flex flex-wrap'}>
                  {props.additionalDetails.map(item =>
                    <div key={item.key}
                         className={`flex flex-col mt-3 pt-2 px-8 rounded-lg ${item.textColor}`}>
                      <label className={'text-12 font-medium tracking-tight leading-6 whitespace-nowrap'}>
                        {item.label}
                      </label>
                      <label
                        className={'text-20 font-bold tracking-tighter leading-tight whitespace-nowrap'}>
                        {item.value}
                      </label>
                    </div>
                  )}
                </div>
                : <></>
              }
            </div>
            <div className={'grid grid-cols-2 sm:grid-cols-5 gap-4'}>
              {DEPARTMENTS.filter(department => !DepartmentHelper.isAllDepartments(department.key)).map((department) => (
                <div key={department.key}
                     className={'pt-6 pb-4 px-6 rounded-lg bg-gray-50 hover:bg-gray-100 cursor-pointer'}
                     onClick={(event) => routeChange(event, department.key)}>
                  <p className={'text-12 font-medium tracking-tight'}>{department.value}</p>
                  <p className={'text-20 font-semibold tracking-tighter leading-tight whitespace-nowrap'}>
                    {props.stats ? props.stats[department.key] : 'N/A'}
                  </p>
                </div>
              ))}
            </div>
          </div>
        }
      </div>
    </Card>
  );
};

export default AllItemsCard;
