import { motion } from 'framer-motion';
import React, { memo, useEffect, useLayoutEffect, useRef, useState } from 'react';
import useQueryParams from '../../shared/hooks/useQueryParams';
import AssetsFilterCard from './components/AssetsFilterCard';
import AssetsMap from '../../shared/components/AssetsMap/AssetsMap';
import { CircularProgress } from '@mui/material';
import { PaginationModel } from '../../shared/models/pagination.model';
import Pagination from '../../shared/components/Pagination/Pagination';
import AssetCard from '../../shared/components/AssetCard/AssetCard';
import { DEPARTMENT } from '../../shared/globals';
import AssetService from '../../services/assets.service';
import { FiberAssetModel } from '../../shared/types/fiber/models/fiber-asset.model';
import { MapHelper } from '../../shared/helpers/map.helper';
import { AssetInspectionMapModel } from '../../shared/models/map-assets/asset-inspection-map.model';
import {
  AssetParamsInterface,
} from '../../shared/models/query-params-interfaces/asset-params.interface';

const collator = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base'
});

const AssetsContent = () => {

  const [isLoading, setIsLoading] = useState(true);
  const [paginationData, setPaginationData] = useState(new PaginationModel());
  const [models, setModels] = useState([]);
  const [mapPoints, setMapPoints] = useState([]);
  const [mapOpened, setMapOpened] = useState(false);

  const filterCardRef = useRef(null);
  const assetsContainerRef = useRef(null);

  const [filterCardHeight, setFilterCardHeight] = useState(0);
  const [assetsContainerWidth, setAssetsContainerWidth] = useState(0);

  const handleMapToggled = () => {
    setMapOpened(!mapOpened);
  };

  const [queryParams, setQueryParams] = useQueryParams({
    page: '1',
    'per-page': '100',
  } as AssetParamsInterface);

  const loadAssets = (queryParams: AssetParamsInterface) => {
    setIsLoading(true);

    AssetService.getAssets(queryParams)
      .then(([paginationData, results]) => {
        setPaginationData(paginationData);
        setModels(results);
        // const mapAssets = results.map(assetInspection => new AssetInspectionMapModel(assetInspection));
        // setMapPoints(MapHelper.getAssetMapPoints(mapAssets));
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadAssets(queryParams);
  }, []);

  useLayoutEffect(() => {
    setFilterCardHeight(filterCardRef.current.clientHeight);
    setAssetsContainerWidth(assetsContainerRef.current.clientWidth);
  }, []);

  const onPageChangedHandler = (page: number) => {
    const updatedQueryParams = {...queryParams, page: page.toString()} as AssetParamsInterface;
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);
  };

  const onPerPageChangedHandler = (perPage: number) => {
    const updatedQueryParams = {...queryParams, 'per-page': perPage.toString()} as AssetParamsInterface;
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);
  };

  const onFilterChangedHandler = (updatedQueryParams: AssetParamsInterface) => {
    setQueryParams(updatedQueryParams);

    loadAssets(updatedQueryParams);
  };

  const variantsItem = {
    hidden: {opacity: 0, y: 20},
    show: {opacity: 1, y: 0},
  };

  return (
    <>
      {/*{*/}
      {/*  mapOpened &&*/}
      {/*    <AssetsMap points={mapPoints} topPadding={filterCardHeight + 50} leftPadding={assetsContainerWidth / 2 + 50}*/}
      {/*               rightPadding={20} bottomPadding={20}/>*/}
      {/*}*/}
      <div
        className={`h-full flex flex-col relative w-full p-12 lg:p-24 block
        overflow-auto pointer-events-auto lg:overflow-hidden lg:pointer-events-none`}>
        <div
          className={`px-2`}
          ref={filterCardRef}>
          <AssetsFilterCard
            queryParams={queryParams}
            onFilterChangedHandler={onFilterChangedHandler}
            mapOpened={mapOpened}
            onMapToggled={handleMapToggled}
            csvDisabled={models.length === 0}
            className={'pointer-events-auto'}
          />
        </div>
        <motion.div
          className={`lg:overflow-auto rounded-lg lg:pointer-events-auto ${isLoading ? 'h-full' : 'h-auto'} ${mapOpened && 'w-full lg:w-2/5'} px-2`}
          transition={{type: 'all'}} ref={assetsContainerRef}
        >
          {
            isLoading &&
              <div className={'w-full h-full flex text-center'}>
                  <CircularProgress className={'m-auto'} size={52} thickness={4}/>
              </div>
          }
          {
            !isLoading && models.length === 0 && <p className={'text-16'}>No Items Found</p>
          }
          {
            !isLoading && models && models
              .map((item: FiberAssetModel, index) => {
                return (
                  <motion.div key={index} variants={variantsItem} className='mb-8'>
                    <AssetCard
                      asset={item}
                      mapOpened={mapOpened}
                    />
                  </motion.div>
                );
              })
          }
          {
            !isLoading && models.length > 0 &&
              <Pagination page={paginationData.page} totalPages={paginationData.pages} perPage={paginationData.perPage}
                          totalItems={paginationData.total} smallView={mapOpened}
                          onPageChanged={onPageChangedHandler} onPerPageChanged={onPerPageChangedHandler}
                          className={'mt-12'}/>
          }
        </motion.div>
      </div>
    </>
  );
};

export default memo(AssetsContent);
