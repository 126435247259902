import { useEffect, useRef, useState } from 'react';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import Modal from '../../../shared/components/Modal/Modal';
import WorkOrdersService from '../../../services/work-orders.service';
import { useDispatch } from 'react-redux';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import ModelAttachments from '../../../shared/components/ModelAttachments/ModelAttachments';
import Form, { FormRef } from '../../../shared/components/Form/Form';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';
import { WorkOrderFormFactory } from '../../../shared/form-factories/work-order-form.factory';
import { WorkOrderModel } from '../../../shared/models/base-models/work-order.model';

type Props = {
  workOrder: WorkOrderModel;
  onSave: Function;
  onClose: Function;
}

const WorkOrderModal = (props: Props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [workOrder, setWorkOrder] = useState(new WorkOrderModel());

  const [form, setForm] = useState(new BaseFormModel());
  const formRef = useRef<FormRef>();

  useEffect(() => {
    setWorkOrder(props.workOrder);

    if (!props.workOrder) {
      return;
    }

    const formSchemas = new WorkOrderFormFactory().createForm<BaseFormModel, WorkOrderModel>(props.workOrder);

    setForm(formSchemas);
  }, [props.workOrder]);

  const handleSubmit = async (updatedWorkOrder: WorkOrderModel) => {
    setIsLoading(true);

    WorkOrdersService.saveWorkOrder(workOrder.assetInspectionId, new WorkOrderModel({...updatedWorkOrder, attachments: workOrder.attachments, files: workOrder['files']}))
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage(workOrder.id ? 'Changes saved successfully' : 'Escalation created successfully', 'success'));
      })
      .catch(err => {
        dispatch(NotificationMessage(workOrder.id ? 'Error saving changes' : 'Error saving Escalation', 'error'));
        setIsLoading(false);
      });
  };

  const workOrderAttachmentsChangedHandler = (workOrderChanges) => {
    setWorkOrder(prevState => new WorkOrderModel({...prevState, ...workOrderChanges}));
  };

  const handleSaveChangesButtonClicked = () => {
    formRef.current.submit();
  };

  return (
    <Modal onClose={props.onClose} title={workOrder.id ? 'Edit Escalation' : 'Create Escalation'}
           className={'md:h-4/5'}
           footerContent={
             <div className={`w-full items-center justify-end flex px-24 py-16`}>
               <LoadingButton
                 disabled={isLoading}
                 isLoading={isLoading}
                 onClick={handleSaveChangesButtonClicked}
                 text={workOrder.id ? 'Save Changes' : 'Submit'}
               />
             </div>
           }
    >
      {!!form.schema && <Form schema={form.schema} className={'px-24 py-16'}
                              uiSchema={form.uiSchema} onSubmit={handleSubmit}
                              formData={workOrder} ref={formRef}/>}
      <ModelAttachments attachments={workOrder.attachments} onChanged={workOrderAttachmentsChangedHandler}
                        className={`w-full mb-20 px-24`}
      />
    </Modal>
  );
};

export default WorkOrderModal;
