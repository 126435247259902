import { FormFactory } from './form.factory';
import { TypeHelper } from '../helpers/type.helper';
import { BaseModel } from '../models/base.model';
import { BaseFormModel } from '../models/base-form-models/base-form.model';

export class AssetInspectionFormFactory implements FormFactory {

  createForm<T extends BaseFormModel, M extends BaseModel>(model: M): T {
    const typeConfig = TypeHelper.getTypeConfig(model);

    // TODO: refactor this
    if (model['type'] === 'ups' && !!model['asset'] && !!model['asset']['assetType'] && model['asset']['assetType'] === 'VRLA Battery') {
      return typeConfig.SecondaryAssetInspectionForm as T
    }

    return typeConfig.AssetInspectionForm as T;
  }

  createFormByType<T extends BaseFormModel>(type: string): T {
    return;
  }

}