export const TELECOM_TOWERS_CONDITIONS_LIST = [
  {
    value: 'Structure Condition: Damaged Members',
    key: 'damagedMembers',
  },
  {
    value: 'Structure Condition: Loose Members',
    key: 'looseMembers',
  },
  {
    value: 'Structure Condition: Missing Members',
    key: 'missingMembers',
  },
  {
    value: 'Structure Condition: Loose and/or Missing Bolts and/or Nut Locking Devices',
    key: 'looseOrMissingBolts',
  },
  {
    value: 'Structure Condition: Connection Hardware',
    key: 'connectionHardware',
  },
  {
    value: 'Structure Condition: Visible cracks in weld connections',
    key: 'visibleCracksInWeldConnections',
  },
  {
    value: 'Structure Finish: Paint and/or Galvanizing Condition',
    key: 'paintAndGalvanizingCondition',
  },
  {
    value: 'Structure Finish: Rust and/or Corrossion Condition',
    key: 'rustAndCorrossionCondition',
  },
  {
    value: 'Structure Finish: FAA OR ICAO Color Marking Conditions',
    key: 'colorMarkingConditions',
  },
  {
    value: 'Structure Finish: Water Collection in Member',
    key: 'waterCollectionInMember',
  },
  {
    value: 'Lighting: Conduit, Juntion Boxes, and Fasteners',
    key: 'conduitJuntionBoxes',
  },
  {
    value: 'Lighting: Drain vent and Openings',
    key: 'drainVentAndOpenings',
  },
  {
    value: 'Lighting: Unobstructed',
    key: 'unobstructed',
  },
  {
    value: 'Lighting: Wiring Condition',
    key: 'wiringCondition',
  },
  {
    value: 'Lighting: Light Lenses',
    key: 'lightLenses',
  },
  {
    value: 'Lighting: Bulb Condition',
    key: 'bulbCondition',
  },
  {
    value: 'Lighting: Controllers',
    key: 'controllers',
  },
  {
    value: 'Lighting: Obstruction to lighting system',
    key: 'obstructionToLightingsystem',
  },
  {
    value: 'Grounding: Connections',
    key: 'connections',
  },
  {
    value: 'Grounding: Corrosion',
    key: 'groundingCorrosion',
  },
  {
    value: 'Grounding: Lightning Protection',
    key: 'lightningProtection',
  },
  {
    value: 'Antennas and Mount Conditions',
    key: 'antennasAndMountConditions',
  },
  {
    value: 'Mounts, Antennas and Lines: Feed Line Condition',
    key: 'feedLineCondition',
  },
  {
    value: 'Ice Shields, Walkways, Platforms, and Climbing Facilities: Obstructions to climbing path or Safety Climb Systems',
    key: 'obstructionsToClimbingPath',
  },
  {
    value: 'Ice Shields, Walkways, Platforms, and Climbing Facilities: Defects, Deformations, Loose or Missing Hardware and Members',
    key: 'defectsDeformations',
  },
  {
    value: 'Ice Shields, Walkways, Platforms, and Climbing Facilities: Secured to Structure',
    key: 'securedToStructure',
  },
  {
    value: 'GUYS: Strand Condition',
    key: 'strandCondition',
  },
  {
    value: 'GUYS: Guy Hardware Conditions',
    key: 'guyHardwareConditions',
  },
  {
    value: 'Concrete Foundation: Ground Condition',
    key: 'concreteFoundationGroundCondition',
  },
  {
    value: 'Concrete Foundation: Anchorage Condition',
    key: 'anchorageCondition',
  },
  {
    value: 'Concrete Foundation: Concrete Condition',
    key: 'concreteCondition',
  },
  {
    value: 'Guyed Mast Anchor: Ground Condition',
    key: 'groundCondition',
  },
  {
    value: 'Guyed Mast Anchor: Grade sloped away',
    key: 'gradeSlopedAway',
  },
  {
    value: 'Guyed Mast Anchor: Corrosion',
    key: 'corrosion',
  },
  {
    value: 'Guyed Mast Anchor: Anchor above Grade, Clear of Vegetation, Obstructions, Turnbuckles Free to Articulate',
    key: 'anchorAboveGrade',
  },
  {
    value: 'Other: Structure Obstruction/Interference',
    key: 'structureObstructionInterference',
  },
];

export const TELECOM_TOWERS_OBJECT_KEYS_LIST = [
  {
    value: 'Damaged Members',
    key: 'damagedMembers',
  },
  {
    value: 'Loose Members',
    key: 'looseMembers',
  },
  {
    value: 'Missing Members',
    key: 'missingMembers',
  },
  {
    value: 'Loose and/or Missing Bolts and/or Nut Locking Devices',
    key: 'looseOrMissingBolts',
  },
  {
    value: 'Connection Hardware',
    key: 'connectionHardware',
  },
  {
    value: 'Visible cracks in weld connections including cracks underneath canister mounts for monopoles',
    key: 'visibleCracksInWeldConnections',
  },
  {
    value: 'Paint and/or Galvanizing Condition',
    key: 'paintAndGalvanizingCondition',
  },
  {
    value: 'Rust and/or Corrossion Condition',
    key: 'rustAndCorrossionCondition',
  },
  {
    value: 'FAA OR ICAO Color Marking Conditions',
    key: 'colorMarkingConditions',
  },
  {
    value: 'Water Collection in Member',
    key: 'waterCollectionInMember',
  },
  {
    value: 'Conduit, Juntion Boxes, and Fasteners',
    key: 'conduitJuntionBoxes',
  },
  {
    value: 'Drain vent and Openings',
    key: 'drainVentAndOpenings',
  },
  {
    value: 'Unobstructed',
    key: 'unobstructed',
  },
  {
    value: 'Wiring Condition',
    key: 'wiringCondition',
  },
  {
    value: 'Light Lenses',
    key: 'lightLenses',
  },
  {
    value: 'Bulb Condition',
    key: 'bulbCondition',
  },
  {
    value: 'Controllers',
    key: 'controllers',
  },
  {
    value: 'Obstruction to lighting system',
    key: 'obstructionToLightingsystem',
  },
  {
    value: 'Connections',
    key: 'connections',
  },
  {
    value: 'Corrosion',
    key: 'groundingCorrosion',
  },
  {
    value: 'Lightning Protection',
    key: 'lightningProtection',
  },
  {
    value: 'Antennas and Mount Conditions',
    key: 'antennasAndMountConditions',
  },
  {
    value: 'Feed Line Condition',
    key: 'feedLineCondition',
  },
  {
    value: 'Obstructions to climbing path or Safety Climb Systems',
    key: 'obstructionsToClimbingPath',
  },
  {
    value: 'Defects, Deformations, Loose or Missing Hardware and Members',
    key: 'defectsDeformations',
  },
  {
    value: 'Secured to Structure',
    key: 'securedToStructure',
  },
  {
    value: 'Strand Condition',
    key: 'strandCondition',
  },
  {
    value: 'Guy Hardware Conditions',
    key: 'guyHardwareConditions',
  },
  {
    value: 'Ground Condition',
    key: 'concreteFoundationGroundCondition',
  },
  {
    value: 'Anchorage Condition',
    key: 'anchorageCondition',
  },
  {
    value: 'Concrete Condition',
    key: 'concreteCondition',
  },
  {
    value: 'Ground Condition',
    key: 'groundCondition',
  },
  {
    value: 'Grade sloped away',
    key: 'gradeSlopedAway',
  },
  {
    value: 'Corrosion',
    key: 'corrosion',
  },
  {
    value: 'Anchor above Grade, Clear of Vegetation, Obstructions, Turnbuckles Free to Articulate',
    key: 'anchorAboveGrade',
  },
  {
    value: 'Structure Obstruction/Interference',
    key: 'structureObstructionInterference',
  },
];

export const TELECOM_TOWERS_FIELDS_STATUSES = [
  {
    value: 'Satisfactory',
    key: 'Satisfactory',
    isDisabled: true,
  },
  {
    value: 'Unsatisfactory',
    key: 'Unsatisfactory',
    isDisabled: false,
  },
  {
    value: 'N/A',
    key: 'N/A',
    isDisabled: true,
  },
];

export const TELECOM_TOWERS_FIELDS_RATED = [
  {
    value: 'Critical',
    key: 'Critical',
  },
  {
    value: 'Non Critical',
    key: 'Non Critical',
  },
];

