import { FileModel } from './file.model';

export class ImageModel extends FileModel {

  thumbnailName: string;

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
