import * as React from 'react';
import ImageListItem from '@mui/material/ImageListItem';
import SquareThumbnailImage from '../Image/SquareThumbnailImage';
import { ViewerImageModel } from '../../models/viewer-image.model';

type Props = {
  className?: string;
  imageClassName?: string;
  images?: ViewerImageModel[];
  selectedImage?: number;
  onImageClick?: Function;
  selectedItemStyle?: string;
  unselectedItemStyle?: string;
  imageAction?: Function;
  imageWatermarkAction?: Function;
  imageActionIcon?: JSX.Element | Function;
  imageActionText?: string;
  actionsAlwaysVisible?: boolean;
  hasWatermark?: boolean;
}

const MasonryImageList = (props: Props) => {

  const isImageSelected = (index) => {
    return props.selectedImage === index ? props.selectedItemStyle : props.unselectedItemStyle;
  };

  return (
    <ul className={`w-full overflow-x-auto ${props.className}`}>
      {
        props.images && props.images?.map((image, index) => (
          <ImageListItem key={image.name + index}>
            <SquareThumbnailImage image={image} onImageAction={props.imageAction}
                                  onImageWatermarkAction={props.imageWatermarkAction}
                                  imageActionIcon={props.imageActionIcon}
                                  imageActionText={props.imageActionText} index={index}
                                  actionsAlwaysVisible={props.actionsAlwaysVisible}
                                  className={`grid-colspan-1 overflow-hidden rounded ${props.imageClassName} ${isImageSelected(index)}`}
                                  onImageClick={props.onImageClick}
                                  hasWatermark={!!props.hasWatermark}
            />
          </ImageListItem>
        ))
      }
    </ul>
  );
};

export default MasonryImageList;
