import React from 'react';
import Dialog from '../../../shared/components/Dialog/Dialog';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import { Typography } from "@mui/material";
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';

type Props = {
  onClose: Function;
}

const InspectionFailedToQaDialog = (props: Props) => {

  const handleClose = () => {
    props.onClose();
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <Dialog
      title={<Typography className={`flex text-xl`}>
        {/*@ts-ignore*/}
        <FuseSvgIcon size={21} className={'mr-7 mt-3'}>material-outline:error_outline</FuseSvgIcon>
        {'Inspection not ready for QA/QC'}
      </Typography>}
      text={'This Inspection has some Asset Inspections that are still in the status \'Conditions Found\'.' +
        ' You need to create Escalation for each condition before sending Inspection to QA/QC.'}
      actionsContent={[
        <LoadingButton
          key={'submitAction'}
          disabled={false}
          onClick={handleClose}
          text={'OK'}
        />
      ]} onClose={props.onClose}>
      <div className={'w-full'}>
      </div>
    </Dialog>
  );
};

export default InspectionFailedToQaDialog;
