export const GENERATORS_FIELDS_STATUSES = [
    {
        value: 'Satisfactory',
        key: 'Satisfactory',
        isDisabled: false,
    },
    {
        value: 'Unsatisfactory',
        key: 'Unsatisfactory',
        isDisabled: false,
    },
    {
        value: 'N/A',
        key: 'N/A',
        isDisabled: true,
    },
];

export const GENERATORS_INSPECTION_TYPES = [
    {
        key: 'Annual',
        value: 'Annual',
    },
    {
        key: 'Semi-Annual',
        value: 'Semi-Annual',
    },
    {
        key: 'Monthly',
        value: 'Monthly',
    },
];

export const FLUIDS_LIST = [
    {
        key: 'Checked',
        value: 'Checked',
    },
    {
        key: 'Changed',
        value: 'Changed',
    },
];

export const MULTISELECTION_LIST = [
    {
        key: 'Exercise',
        value: 'Exercise',
    },
    {
        key: 'Load',
        value: 'Load',
    },
    {
        key: 'No Load',
        value: 'No Load',
    },
    {
        key: 'Phase Rotation Matched',
        value: 'Phase Rotation Matched',
    },
    {
        key: 'ABC',
        value: 'ABC',
    },
    {
        key: 'BCA',
        value: 'BCA',
    },
];

export const TIER_RATING_LIST = [
    {
        key: '02',
        value: '02',
    },
    {
        key: '03',
        value: '03',
    },
    {
        key: 'NA',
        value: 'NA',
    },
];

export const GENSET_MAKE_LIST = [
    {
        key: 'Cummins',
        value: 'Cummins',
    },
    {
        key: 'Generac',
        value: 'Generac',
    },
    {
        key: 'Koehler',
        value: 'Koehler',
    },
    {
        key: 'Mnfctasset',
        value: 'Mnfctasset',
    },
    {
        key: 'Onan',
        value: 'Onan',
    },
    {
        key: 'Unknown',
        value: 'Unknown',
    },
];

export const DAYS_LIST = [
    {
        value: 'Mon',
        key: 'Mon',
    },
    {
        value: 'Tues',
        key: 'Tues',
    },
    {
        value: 'Wed',
        key: 'Wed',
    },
    {
        value: 'Thurs',
        key: 'Thurs',
    },
    {
        value: 'Fri',
        key: 'Fri',
    },
    {
        value: 'Sat',
        key: 'Sat',
    },
    {
        value: 'Sun',
        key: 'Sun',
    },
];

export const DRAFT_GENERATORS_INSPECTION_TYPES = [
    {
        key: 1,
        value: 'Annual',
    },
    {
        key: 2,
        value: 'Semi-Annual',
    },
    {
        key: 3,
        value: 'Monthly',
    },
];

export const DRAFT_TIER_RATING_LIST = [
    {
        key: 1,
        value: '02',
    },
    {
        key: 2,
        value: '03',
    },
    {
        key: 3,
        value: 'NA',
    },
];

export const GENERATORS_CONDITIONS_LIST = [
      {
          value: 'System Visual Inspection Overal Condition',
          key: 'systemVisualInspectionOveralCondition',
      },
      {
          value: 'Battery Charger Voltage',
          key: 'batteryChargerVoltage',
      },
      {
          value: 'Battery Charger Amperage',
          key: 'batteryChargerAmperage',
      },
      {
          value: 'Battery Charge Alternator Voltage',
          key: 'batteryChargeAlternatorVoltage',
      },
      {
          value: 'Battery Charge Alternator Amperage',
          key: 'batteryChargeAlternatorAmperage',
      },
      {
          value: 'Battery Age',
          key: 'batteryAge',
      },
      {
          value: 'Checked Battery Level',
          key: 'checkedBatteryLevel',
      },
      {
          value: 'Fuel Hoses/Piping Regulator',
          key: 'fuelHosesPipingRegulator',
      },
      {
          value: 'Fuel Pressure',
          key: 'fuelPressure',
      },
      {
          value: 'Day Tank Operation',
          key: 'dayTankOperation',
      },
      {
          value: 'Oil Pressure',
          key: 'oilPressure',
      },
      {
          value: 'Coolant Temperature',
          key: 'coolantTemperature',
      },
      {
          value: 'Ignition System',
          key: 'ignitionSystem',
      },
      {
          value: 'Cooling System',
          key: 'coolingSystem',
      },
      {
          value: 'Coolant Freeze Point',
          key: 'coolantFreezePoint',
      },
      {
          value: 'Block Heater Operation',
          key: 'blockHeaterOperation',
      },
      {
          value: 'Exhaust System',
          key: 'exhaustSystem',
      },
      {
          value: 'Inspect for Leaks of Any Type',
          key: 'inspectLeaksAnyType',
      },
      {
          value: 'Meters, Lamps, Gauges',
          key: 'metersLampsGauges',
      },
      {
          value: 'Circuit Boards and Relays',
          key: 'circuitBoardsandRelays',
      },
      {
          value: 'Control Mounts',
          key: 'controlMounts',
      },
      {
          value: 'Secured To Level Surface',
          key: 'securedToLevelSurface',
      },
      {
          value: 'Proper Vibration Isolation',
          key: 'properVibrationIsolation',
      },
      {
          value: 'Weather Enclosure Condition',
          key: 'weatherEnclosureCondition',
      },
      {
          value: 'Windings-Exciter, Rotor, Stator',
          key: 'windingsExciterRotorStator',
      },
      {
          value: 'Brushes, Slip Rings, etc',
          key: 'brushesSlipRings',
      },
      {
          value: 'All Conductors Properly Sized and Connected',
          key: 'allConductorsProperlySizedAndConnected',
      },
      {
          value: 'Breaker Protecting ATS on Utility and Gen',
          key: 'breakerProtectingATSOnUtilityAndGen',
      },
      {
          value: 'All Necessary Calibrations & Adjustments Complete',
          key: 'allNecessaryCalibrationsAndAdjustmentsComplete',
      },
  ];

export const GENERATORS_OBJECT_KEYS_LIST = [
    {
        value: 'System Visual Inspection Overal Condition',
        key: 'systemVisualInspectionOveralCondition',
    },
    {
        value: 'Hourmeter',
        key: 'hourmeter',
    },
    {
        value: 'Voltage Type',
        key: 'voltageType',
    },
    {
        value: 'Battery Charger Voltage',
        key: 'batteryChargerVoltage',
    },
    {
        value: 'Battery Charger Amperage',
        key: 'batteryChargerAmperage',
    },
    {
        value: 'Battery Charge Alternator Voltage',
        key: 'batteryChargeAlternatorVoltage',
    },
    {
        value: 'Battery Charge Alternator Amperage',
        key: 'batteryChargeAlternatorAmperage',
    },
    {
        value: 'Battery Age',
        key: 'batteryAge',
    },
    {
        value: 'Checked Battery Level',
        key: 'checkedBatteryLevel',
    },
    {
        value: 'Fuel System Type',
        key: 'fuelSystemType',
    },
    {
        value: 'Fuel Hoses/Piping Regulator',
        key: 'fuelHosesPipingRegulator',
    },
    {
        value: 'Fuel Pressure',
        key: 'fuelPressure',
    },
    {
        value: 'Fuel Level',
        key: 'fuelLevel',
    },
    {
        value: 'Day Tank Operation',
        key: 'dayTankOperation',
    },
    {
        value: 'Oil Pressure',
        key: 'oilPressure',
    },
    {
        value: 'Coolant Temperature',
        key: 'coolantTemperature',
    },
    {
        value: 'RPM',
        key: 'RPM',
    },
    {
        value: 'Ignition System',
        key: 'ignitionSystem',
    },
    {
        value: 'Cooling System',
        key: 'coolingSystem',
    },
    {
        value: 'Coolant Freeze Point',
        key: 'coolantFreezePoint',
    },
    {
        value: 'Block Heater Operation',
        key: 'blockHeaterOperation',
    },
    {
        value: 'Exhaust System',
        key: 'exhaustSystem',
    },
    {
        value: 'Inspect for Leaks of Any Type',
        key: 'inspectLeaksAnyType',
    },
    {
        value: 'Engine Oil',
        key: 'engineOil',
    },
    {
        value: 'Oil, Fuel, Coolant Filters',
        key: 'oilFuelCoolantFilters',
    },
    {
        value: 'Air Filters',
        key: 'airFilters',
    },
    {
        value: 'IGN Points, Cond, Spark Plugs',
        key: 'IGNPointsCondSparkPlugs',
    },
    {
        value: 'Meters, Lamps, Gauges',
        key: 'metersLampsGauges',
    },
    {
        value: 'Circuit Boards and Relays',
        key: 'circuitBoardsandRelays',
    },
    {
        value: 'Control Mounts',
        key: 'controlMounts',
    },
    {
        value: 'Secured To Level Surface',
        key: 'securedToLevelSurface',
    },
    {
        value: 'Proper Vibration Isolation',
        key: 'properVibrationIsolation',
    },
    {
        value: 'Weather Enclosure Condition',
        key: 'weatherEnclosureCondition',
    },
    {
        value: 'Voltage',
        key: 'voltage',
    },
    {
        value: 'Amperage',
        key: 'amperage',
    },
    {
        value: 'Frequency',
        key: 'frequency',
    },
    {
        value: 'Windings-Exciter, Rotor, Stator',
        key: 'windingsExciterRotorStator',
    },
    {
        value: 'Brushes, Slip Rings, etc',
        key: 'brushesSlipRings',
    },
    {
        value: 'All Conductors Properly Sized and Connected',
        key: 'allConductorsProperlySizedAndConnected',
    },
    {
        value: 'Breaker Protecting ATS on Utility and Gen',
        key: 'breakerProtectingATSOnUtilityAndGen',
    },
    {
        value: 'All Necessary Calibrations & Adjustments Complete',
        key: 'allNecessaryCalibrationsAndAdjustmentsComplete',
    },
    {
        value: 'Exercise Clock',
        key: 'exerciseClock',
    },
    {
        value: 'Multiselection',
        key: 'multiSelection',
    },
    {
        value: 'Sec To Engine Start',
        key: 'secToEngineStart',
    },
    {
        value: 'Sec To Transfer',
        key: 'secToTransfer',
    },
    {
        value: 'Min To Retransfer',
        key: 'minToRetransfer',
    },
    {
        value: 'Sec TDN',
        key: 'secTDN',
    },
    {
        value: 'Min Delay Stop',
        key: 'minDelayStop',
    },
    {
        value: 'Street Address',
        key: 'streetAddress',
    },
    {
        value: 'City',
        key: 'city',
    },
    {
        value: 'State',
        key: 'state',
    },
    {
        value: 'County',
        key: 'county',
    },
    {
        value: 'ZIP',
        key: 'zip',
    },
];

export const GENERATORS_SECONDARY_OBJECT_KEYS_LIST = [
    {
        value: 'Amperage A',
        key: 'A',
    },
    {
        value: 'Amperage B',
        key: 'B',
    },
    {
        value: 'Amperage C',
        key: 'C',
    },
    {
        value: 'Voltage L1-L2',
        key: 'L1L2',
    },
    {
        value: 'Voltage L2-L3',
        key: 'L2L3',
    },
    {
        value: 'Voltage L3-L1',
        key: 'L3L1',
    },
    {
        value: 'Voltage L1-N',
        key: 'L1N',
    },
    {
        value: 'Voltage L2-N',
        key: 'L2N',
    },
    {
        value: 'Voltage L3-N',
        key: 'L3N',
    },
    {
        value: 'Exercise Clock Day',
        key: 'day',
    },
    {
        value: 'Exercise Clock Time',
        key: 'time',
    },
    {
        value: 'Exercise Clock To',
        key: 'to',
    },
];