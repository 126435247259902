import React from 'react';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import {
  TimelineItem as MaterialTimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot
} from '@mui/lab'

type Props = {
  className?: string;
  children: JSX.Element[];
  timelineDot: any;
  users?: any;
}

const ChangeLogTimelineItem = (props: Props) => {

  return (
    <MaterialTimelineItem className={'overflow-hidden'}>
      <TimelineSeparator>
        <TimelineDot color={props.timelineDot && props.timelineDot.color} variant="outlined" className={'p-2 border-2'}>
          {/*@ts-ignore*/}
          <FuseSvgIcon size={10} className={'rounded-xl'} style={{fill: props.timelineDot && props.timelineDot.iconColor}}>{props.timelineDot && props.timelineDot.icon}</FuseSvgIcon>
        </TimelineDot>
        <TimelineConnector/>
      </TimelineSeparator>
      <TimelineContent className={'sm:flex justify-between items-start pl-6 pt-11 pr-0'}>
        {props.children}
      </TimelineContent>
    </MaterialTimelineItem>
  );
};

export default ChangeLogTimelineItem;
