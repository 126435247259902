import React from 'react';
import { Card } from '@mui/material';
import DetailsCardElement from './DetailsCardElement';
import DetailsCardElementSkeleton from './DetailsCardElementSkeleton';
import { InspectionCardItemModel } from '../../models/inspection-card-item.model';
import CardTitle from './CardTitle';

type Props = {
  title: string;
  items: InspectionCardItemModel[];
  className?: string;
  isLoading: boolean;
  linkTo?: string;
  hoverText?: string;
  contentElementClassName: string;
}

const DetailsCard = (props: Props) => {

  return (
    <Card className={`shadow overflow-hidden rounded-md ${props.className} px-24 pb-24 pt-16`}>
      <CardTitle title={props.title} linkTo={props.linkTo} hoverText={props.hoverText} />
      <div className={`${props.contentElementClassName}`}>
        {props.isLoading &&
            <>
                <DetailsCardElementSkeleton />
                <DetailsCardElementSkeleton />
                <DetailsCardElementSkeleton />
                <DetailsCardElementSkeleton />
                <DetailsCardElementSkeleton />
                <DetailsCardElementSkeleton />

            </>
        }
        {
          !props.isLoading && props.items && props.items?.map((item, index) => (
            <DetailsCardElement key={item.label + index} item={item} />
          ))
        }
      </div>
    </Card>
  );
};

export default DetailsCard;
