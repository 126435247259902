import { UserModel } from '../../../models/user.model';
import { UpsAssetInspectionModel } from './ups-asset-inspection.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { FileModel } from '../../../models/file.model';
import { CorrectiveActionModel } from '../../../models/corrective-action.model';
import { LocationModel } from '../../../models/location.model';
import { CommentModel } from '../../../models/comment.model';
import { WorkOrderModel } from '../../../models/base-models/work-order.model';

export class UpsWorkOrderModel extends WorkOrderModel {
  id: string;
  code: string;
  externalId: string;
  sapExternalId: string;
  type: string;
  correctiveAction: string;
  condition: string;
  status: string;
  assignedTo: UserModel[];
  workAssignee: string;
  routeInfo: string;
  notes: string;
  scheduledStartDate: string;
  attachments: FileModel[];
  assetInspection?: UpsAssetInspectionModel;
  assetInspectionId: string;
  assetIds: string[];
  dueDate: string;
  correctiveActions?: CorrectiveActionModel[];
  location: LocationModel;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  };

  get childModels() {
    return {
      assetInspection: UpsAssetInspectionModel,
      attachments: FileModel,
      correctiveActions: CorrectiveActionModel,
      location: LocationModel,
      changeHistory: FieldChangeModel,
      comments: CommentModel,
    };
  }

}