import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const TELECOM_TOWERS_ASSET_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    equipmentNumber: {
      type: 'string',
      title: 'Equipment Number',
    },
    towerType: {
      type: 'string',
      title: 'Tower Type',
    },
    towerManufacturer: {
      type: 'string',
      title: 'Tower Manufacturer',
    },
    towerTagInformation: {
      type: 'string',
      title: 'Tower Tag Information',
    },
    structureHeight: {
      type: 'number',
      title: 'Structure Height',
    },
    topOfHighestAppurt: {
      type: 'number',
      title: 'Top of Highest Appurt. (AGL)',
    },
    groundElevation: {
      type: 'number',
      title: 'Ground Elevation',
    },
    fccid: {
      type: 'string',
      title: 'FCCID',
    },
    notes: {
      type: 'string',
      title: 'Notes',
    },
  },
};

export const TELECOM_TOWERS_ASSET_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames': 'w-full grid sm:grid-cols-2 md:grid-cols-3 gap-x-12 gap-y-16',
  'ui:inline': true,
  equipmentNumber: {
    'ui:placeholder': 'Equipment Number',
  },
  towerType: {
    'ui:placeholder': 'Tower Type',
  },
  towerManufacturer: {
    'ui:placeholder': 'Tower Manufacturer',
  },
  towerTagInformation: {
    'ui:placeholder': 'Tower Tag Information',
  },
  structureHeight: {
    'ui:placeholder': 'Structure Height',
    'ui:widget': 'NumberWidget',
  },
  topOfHighestAppurt: {
    'ui:placeholder': 'Top of Highest Appurt. (AGL)',
    'ui:widget': 'NumberWidget',
  },
  groundElevation: {
    'ui:placeholder': 'Ground Elevation',
    'ui:widget': 'NumberWidget',
  },
  fccid: {
    'ui:placeholder': 'FCCID',
  },
  notes: {
    'ui:placeholder': 'Notes',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-3',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};