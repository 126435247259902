import ApiClient from './api-client/api-client';
import { PaginationModel } from '../shared/models/pagination.model';
import {
  AssetInspectionParamsInterface,
} from '../shared/models/query-params-interfaces/asset-inspection-params.interface';
import FileDownload from 'js-file-download';
import { CommentModel } from '../shared/models/comment.model';
import { FileHelper } from '../shared/helpers/file.helper';
import BaseContextService from './base-context.service';
import { AssetInspectionModel } from '../shared/models/base-models/asset-inspection.model';
import { AssetInspectionModelMapper } from '../shared/model-mappers/asset-inspection-model.mapper';
import { WorkOrderModel } from '../shared/models/base-models/work-order.model';
import { WorkOrderModelMapper } from '../shared/model-mappers/work-order-model.mapper';
import { InspectionModel } from '../shared/models/base-models/inspection.model';
import { InspectionModelMapper } from '../shared/model-mappers/inspection-model.mapper';
import { AssetInspectionMapModel } from '../shared/models/map-assets/asset-inspection-map.model';

const ASSET_INSPECTIONS_ENDPOINT = '/asset-inspections';

const getAssetInspections = (queryParams: AssetInspectionParamsInterface = {}): Promise<[PaginationModel, AssetInspectionModel[]]> => {
  return BaseContextService.fetchList<AssetInspectionModel>(ASSET_INSPECTIONS_ENDPOINT, new AssetInspectionModelMapper(), queryParams);
};

const getAssetInspection = (id: string, queryParams: AssetInspectionParamsInterface = {}): Promise<AssetInspectionModel> => {
  return BaseContextService.fetch<AssetInspectionModel>(`${ASSET_INSPECTIONS_ENDPOINT}/${id}`, new AssetInspectionModelMapper(), queryParams);
};

const getMapPoints = (queryParams: AssetInspectionParamsInterface = {}): Promise<AssetInspectionMapModel[]> => {
  return ApiClient.get(`${ASSET_INSPECTIONS_ENDPOINT}/map-points`, queryParams)
    .then(response => response.data)
    .then(data => data.map(assetInspection => new AssetInspectionMapModel(assetInspection)));
};

const getCsvReport = (queryParams: AssetInspectionParamsInterface = {}): Promise<void> => {
  return ApiClient.get(`${ASSET_INSPECTIONS_ENDPOINT}/csv-report`, queryParams, {responseType: 'blob'})
    .then(response => FileDownload(response.data, `fieldnav-csv-${new Date().toISOString()}.csv`));
};

const updateAssetInspection = (id: string, assetInspection: AssetInspectionModel): Promise<AssetInspectionModel> => {
  const files = assetInspection['files'];
  FileHelper.prepareModelsFilesForUpload(assetInspection);
  return BaseContextService.update<AssetInspectionModel>(`${ASSET_INSPECTIONS_ENDPOINT}/${id}`, assetInspection, new AssetInspectionModelMapper())
    .then(data => FileHelper.uploadFiles(data, files));
};

const createWorkOrder = (assetInspectionId: string, workOrder: WorkOrderModel): Promise<WorkOrderModel> => {
  const files = workOrder['files'];
  FileHelper.prepareModelsFilesForUpload(workOrder);
  return BaseContextService.create<WorkOrderModel>(`${ASSET_INSPECTIONS_ENDPOINT}/${assetInspectionId}/work-orders`, workOrder, new WorkOrderModelMapper())
    .then(data => FileHelper.uploadFiles(data, files))
};

const createMassWorkOrder = (assetInspectionIds: string[], workOrder: WorkOrderModel): Promise<InspectionModel> => {
  const files = workOrder['files'];
  FileHelper.prepareModelsFilesForUpload(workOrder);
  return BaseContextService.create<WorkOrderModel>(`${ASSET_INSPECTIONS_ENDPOINT}/mass-work-order`, {assetInspectionIds: assetInspectionIds, ...workOrder}, new InspectionModelMapper())
    .then(data => FileHelper.uploadFiles(data, files))
};

const updateAssetInspectionStatus = (id: string, body: any): Promise<AssetInspectionModel> => {
  return BaseContextService.update<any>(`${ASSET_INSPECTIONS_ENDPOINT}/${id}/status`, body, new AssetInspectionModelMapper());
};

const addComment = (assetInspectionId: string, body: any): Promise<CommentModel> => {
  return ApiClient.post(`${ASSET_INSPECTIONS_ENDPOINT}/${assetInspectionId}/comments`, body)
    .then(response => response.data)
    .then(data => new CommentModel(data));
};

const AssetInspectionsService = {
  createWorkOrder,
  createMassWorkOrder,
  getAssetInspections,
  getAssetInspection,
  updateAssetInspection,
  getCsvReport,
  getMapPoints,
  updateAssetInspectionStatus,
  addComment
};

export default AssetInspectionsService;
