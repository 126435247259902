import { RJSFSchema, UiSchema } from '@rjsf/utils';

export const FIBER_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: ['routeInfo', 'externalId', 'accountNumber', 'dueDate'],
  properties: {
    routeInfo: {
      type: 'string',
      title: 'Route Info',
    },
    externalId: {
      type: 'string',
      title: 'Remedy Work Order #',
    },
    accountNumber: {
      type: 'string',
      title: 'Account Number',
    },
    dueDate: {
      type: 'string',
      title: 'Due Date',
    },
    cableManufacturerInfo: {
      type: 'string',
      title: 'Cable Manufacturer Info',
    },
    cableDiameter: {
      type: 'number',
      title: 'Cable Diameter',
    },
    cableSerialNumber: {
      type: 'string',
      title: 'Cable Serial Number',
    },
    address: {
      type: 'object',
      title: '',
      properties: {
        streetAddress: {
          type: 'string',
          title: 'Street Address',
        },
        city: {
          type: 'string',
          title: 'City',
        },
        county: {
          type: 'string',
          title: 'County',
        },
      },
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
  },
};

export const FIBER_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  routeInfo: {
    'ui:placeholder': 'Route Info',
  },
  externalId: {
    'ui:placeholder': 'Remedy Work Order #',
  },
  accountNumber: {
    'ui:placeholder': 'Account Number',
  },
  dueDate: {
    'ui:placeholder': 'Due Date',
    'ui:widget': 'date',
  },
  cableManufacturerInfo: {
    'ui:placeholder': 'Cable Manufacturer Info',
  },
  cableDiameter: {
    'ui:placeholder': 'Cable Diameter',
    'ui:widget': 'NumberWidget',
  },
  cableSerialNumber: {
    'ui:placeholder': 'Cable Serial Number',
    'ui:classNames': 'sm:col-span-2',
  },
  address: {
    streetAddress: {
      'ui:placeholder': 'Street Address',
      'ui:classNames': 'sm:col-span-2',
    },
    city: {
      'ui:placeholder': 'City',
    },
    county: {
      'ui:placeholder': 'County',
    },
    'ui:classNames':
      'sm:col-span-2 lg:col-span-4 grid sm:grid-cols-2 lg:grid-cols-4 gap-x-12 gap-y-16',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 lg:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};
