import { motion } from 'framer-motion';
import React, { memo, useContext, useEffect, useState } from 'react';
import AttachmentsCard from '../../shared/components/AttachmentsCard/AttachmentsCard';
import DetailsCard from '../../shared/components/DetailsCard/DetailsCard';
import { useParams } from 'react-router-dom';
import AssetInspectionHeader from './components/AssetInspectionHeader';
import WorkOrderDetailsCard from './components/WorkOrderDetailsCard';
import AssetInspectionsService from '../../services/asset-inspections.service';
import { FiberAssetInspectionModel } from '../../shared/types/fiber/models/fiber-asset-inspection.model';
import ToggleButton from '../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import Menu from '../../shared/components/Menu/Menu';
import MenuItem from '../../shared/components/Menu/MenuItem';
import AssetInspectionModal from './components/AssetInspectionModal';
import ChangeLogCard from '../../shared/components/ChangeLogCard/ChangeLogCard';
import LoadingButton from '../../shared/components/LoadingButton/LoadingButton';
import { NotificationMessage } from '../../shared/components/NotificationMessage/NotificationMessage';
import { useDispatch } from 'react-redux';
import { InspectionHelper } from '../../shared/helpers/inspection.helper';
import UsersService from '../../services/users.service';
import PreviousNextNavigator from '../../shared/components/PreviousNextNavigator/PreviousNextNavigator';
import Breadcrumbs from '../../shared/components/Breadcrumbs/Breadcrumbs';
import BreadcrumbsSkeleton from './components/BreadcrumbsSkeleton';
import { ASSET_INSPECTION_STATUS } from '../../shared/globals';
import { AssetInspectionModel } from '../../shared/models/base-models/asset-inspection.model';
import { AssetInspectionViewModel } from '../../shared/models/base-view-models/asset-inspection-view.model';
import { InspectionViewModelFactory } from '../../shared/view-model-factories/inspection-view-model.factory';
import { InspectionViewModel } from '../../shared/models/base-view-models/inspection-view.model';
import { InspectionModel } from '../../shared/models/base-models/inspection.model';
import { AssetInspectionViewModelFactory } from '../../shared/view-model-factories/asset-inspection-view-model.factory';
import { AssetModel } from '../../shared/models/base-models/asset.model';
import { AssetViewModel } from '../../shared/models/base-view-models/asset-view.model';
import { AssetViewModelFactory } from '../../shared/view-model-factories/asset-view-model.factory';
import DepartmentContext from '../../DepartmentContext';

const AssetInspectionContent = () => {

  const {updateDepartment} = useContext(DepartmentContext);
  const params = useParams();
  const dispatch = useDispatch();
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [assetInspection, setAssetInspection] = useState(new AssetInspectionModel());
  const [assetInspectionView, setAssetInspectionView] = useState(null as AssetInspectionViewModel);
  const [inspectionView, setInspectionView] = useState(null as InspectionViewModel);
  const [assetView, setAssetView] = useState(null as AssetViewModel);

  const [assetInspectionClosed, setAssetInspectionClosed] = useState(true);
  const [assetInspectionStatusCanChange, setAssetInspectionStatusCanChange] = useState(false);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');

  const [comments, setComments] = useState([]);

  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [openModal, setOpenModal] = useState(false);

  const [navigateEnabled, setNavigateEnabled] = useState(true);

  const navigateEnabledHandler = () => {
    setNavigateEnabled(true);
  };

  const navigateDisabledHandler = () => {
    setNavigateEnabled(false);
  };

  useEffect(() => {
    setIsLoading(true);
    AssetInspectionsService.getAssetInspection(params.id)
      .then(result => {
        updateDepartment(result.type);
        result.asset.comparingAsset = result.snapshot.asset;

        setAssetInspection(result);
        setAssetInspectionView(new AssetInspectionViewModelFactory().createViewModel<AssetInspectionViewModel, AssetInspectionModel>(result));
        setInspectionView(new InspectionViewModelFactory().createViewModel<InspectionViewModel, InspectionModel>(result.inspection));
        setAssetView(new AssetViewModelFactory().createViewModel<AssetViewModel, AssetModel>(result.asset));
        setComments(result.comments ? result.comments : []);
        setIsLoading(false);
        setBreadcrumbs(InspectionHelper.getAssetInspectionBreadcrumbs(result));
      });
  }, [params.id]);

  const assetInspectionUpdatedHandler = (assetInspection: any) => {
    assetInspection.asset.comparingAsset = assetInspection.snapshot.asset;
    setAssetInspection(assetInspection);
    setAssetInspectionView(new AssetInspectionViewModelFactory().createViewModel<AssetInspectionViewModel, AssetInspectionModel>(assetInspection));
  };

  useEffect(() => {
    if (!assetInspection.inspection || !assetInspection.inspection['assignedTo']) {
      return;
    }

    const currentUser = UsersService.getCurrentUser();

    setAssetInspectionStatusCanChange(InspectionHelper.inspectionIsInQa(assetInspection.inspection, currentUser ? currentUser.id : ''));
    setAssetInspectionClosed(InspectionHelper.statusIsClosed(assetInspection));
  }, [assetInspection.inspection]);

  const statusChangeHandler = async (status: string) => {
    setStatusChangeLoading(true);
    setLoadingStatus(status);

    AssetInspectionsService.updateAssetInspectionStatus(params.id, {status: status})
      .then(res => {
        setAssetInspection(res);
        setAssetInspectionView(new AssetInspectionViewModelFactory().createViewModel<AssetInspectionViewModel, AssetInspectionModel>(res));
        setStatusChangeLoading(false);
        setLoadingStatus('');
        setMenuOpened(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Status changed successfully', 'success'));
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error saving changes';
        dispatch(NotificationMessage(message, 'error'));
        setStatusChangeLoading(false);
        setLoadingStatus('');
        setMenuOpened(false);
      });
  };

  const commentAddedHandler = async (commentText: string) => {
    const body = {
      text: commentText,
    };

    return AssetInspectionsService.addComment(assetInspection.id, body)
      .then((res) => {
        dispatch(NotificationMessage('Comment added successfully', 'success'));
        setComments(comments => [
          ...comments,
          res,
        ]);
      })
      .catch(() => {
        dispatch(NotificationMessage('Error saving comment', 'error'));
      });
  };

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const openModalHandler = () => {
    setOpenModal(true);
    navigateDisabledHandler();
  };

  const onModalCloseHandler = () => {
    setOpenModal(false);
    setNavigateEnabled(true);
    navigateEnabledHandler();
  };

  const menuButton =
    <ToggleButton
      disabled={isLoading || assetInspectionClosed}
      isActive={menuOpened}
      onClick={showMenu}
      className={'pl-16 pr-12 hover:bg-gray-200'}
      text={'Actions'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={16}>material-outline:arrow_drop_down</FuseSvgIcon>
    </ToggleButton>;

  const menu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'edit'} onClick={openModalHandler} setMenuOpened={setMenuOpened}
                  icon={'material-outline:edit'} text={'Edit Details'} disabled={isLoading} />,
        <MenuItem key={'sendToQAFailed'} onClick={() => statusChangeHandler(ASSET_INSPECTION_STATUS.qaFailed)}
                  disabled={statusChangeLoading || isLoading}
                  icon={'material-outline:close'} text={'Mark as QA/QC Failed'}
                  isLoading={loadingStatus === ASSET_INSPECTION_STATUS.qaFailed}
                  className={`${assetInspectionStatusCanChange && (assetInspection.status !== ASSET_INSPECTION_STATUS.qaFailed) ? '' : 'hidden'}`}
        />,
      ]}
    />;

  const inspectionDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Inspection Details'} isLoading={isLoading}
      items={inspectionView ? inspectionView.detailCardFields : []}
      linkTo={inspectionView ? inspectionView.linkTo : ''} hoverText={'Open Inspection Page'}
      contentElementClassName={'w-full grid sm:grid-cols-2 gap-8 align-middle'}
    />;

  const assetInspectionDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Asset Inspection Details'} isLoading={isLoading}
      items={assetInspectionView ? assetInspectionView.detailCardFields : []}
      contentElementClassName={'w-full grid sm:grid-cols-2 gap-8 align-middle'}
    />;

  const assetDetailsCard =
    <DetailsCard
      className={'lg:mb-16'} title={'Asset Info'} isLoading={isLoading}
      items={assetView ? assetView.infoCardFields : []}
      linkTo={assetView ? assetView.linkTo : ''} hoverText={'Open Asset Page'}
      contentElementClassName={'w-full grid sm:grid-cols-2 gap-8 align-middle'}
    />;

  const workOrderCard =
    <WorkOrderDetailsCard className={'lg:mb-16'} isLoading={isLoading} assetInspection={assetInspection}
                          setAssetInspection={setAssetInspection} setAssetInspectionView={setAssetInspectionView} assetInspectionClosed={assetInspectionClosed}
                          onModalOpenHandler={navigateDisabledHandler} onModalClosedHandler={navigateEnabledHandler}
    />;

  const changeLogCard =
    <ChangeLogCard className={''} changeHistory={assetInspection.changeHistory}
                   commentFormIsDisabled={assetInspectionClosed}
                   isLoading={isLoading} title={'Change Log'} comments={comments}
                   commentAddedHandler={commentAddedHandler}
                   formOnFocusHandler={navigateDisabledHandler} formOnFocusLeftHandler={navigateEnabledHandler}
    />;

  const attachmentsCard =
    <AttachmentsCard attachments={assetInspection.attachments} isLoading={isLoading} className={'lg:mb-16'}
                     modelId={{assetInspectionId: assetInspection.id}}
                     viewerOpenedHandler={navigateDisabledHandler} viewerClosedHandler={navigateEnabledHandler} />;

  const additionalAttachmentsCard = assetInspection['additionalAttachments'] && assetInspection['additionalAttachments'].length ?
    <AttachmentsCard attachments={assetInspection['additionalAttachments']} isLoading={isLoading}
                     modelId={{assetInspectionId: assetInspection.id}} title={assetInspectionView.additionalAttachmentsTitle}
                     viewerOpenedHandler={navigateDisabledHandler} viewerClosedHandler={navigateEnabledHandler} /> : <></>;

  return (
    <>
      <motion.div
        className='w-full h-full p-12 lg:p-24'
        initial='hidden'
        animate='show'
      >
        {!isLoading && breadcrumbs && breadcrumbs.length &&
          <Breadcrumbs breadcrumbs={breadcrumbs} className={'mb-4 px-3'} />}
        {
          !!isLoading &&
          <BreadcrumbsSkeleton />
        }
        <motion.div>
          <div className={'flex flex-col md:flex-row md:items-center justify-between gap-8 mt-2 px-3'}>
            <AssetInspectionHeader
              dataModel={assetInspectionView}
              assetInspection={assetInspection as FiberAssetInspectionModel}
              isLoading={isLoading} />
            <div className={'flex flex-wrap gap-8'}>
              <PreviousNextNavigator
                modelName={'Asset Inspection'}
                previousNextItem={assetInspectionView ? assetInspectionView.previousNextListItem : {}}
                navigateEnabled={navigateEnabled}
              />
              {assetInspectionStatusCanChange &&
                <LoadingButton
                  size='small'
                  onClick={() => statusChangeHandler(ASSET_INSPECTION_STATUS.qaPassed)}
                  isLoading={loadingStatus === ASSET_INSPECTION_STATUS.qaPassed}
                  className={`bg-green-600 hover:bg-green-400 whitespace-nowrap pl-14`}
                  text={'Mark as QA/QC Passed'}
                  disabled={statusChangeLoading || isLoading || assetInspectionClosed || (assetInspection.status === ASSET_INSPECTION_STATUS.qaPassed)}
                >
                  {/*@ts-ignore*/}
                  <FuseSvgIcon size={19}>material-outline:done</FuseSvgIcon>
                </LoadingButton>
              }
              <div>
                {menuButton}
                {menu}
              </div>
            </div>
          </div>
          <div className='hidden lg:grid grid-cols-3 grid-flow-col gap-16 px-3 mt-8'>
            <div className='row-span-6 col-span-2'>
              {inspectionDetailsCard}
              {assetInspectionDetailsCard}
              {workOrderCard}
              {changeLogCard}
            </div>
            <div className='hidden lg:block col-span-1'>
              {assetDetailsCard}
              {attachmentsCard}
              {additionalAttachmentsCard}
            </div>
          </div>
          <div className='flex flex-col lg:hidden gap-16 px-3 mt-8'>
            {inspectionDetailsCard}
            {assetInspectionDetailsCard}
            {workOrderCard}
            {changeLogCard}
            {assetDetailsCard}
            {attachmentsCard}
            {additionalAttachmentsCard}
          </div>
        </motion.div>
      </motion.div>
      {openModal &&
        <AssetInspectionModal onClose={onModalCloseHandler} onSave={assetInspectionUpdatedHandler}
                              assetInspection={assetInspection} />
      }
    </>
  );
};

export default memo(AssetInspectionContent);
