import { FormFactory } from './form.factory';
import { TypeHelper } from '../helpers/type.helper';
import { BaseModel } from '../models/base.model';
import { BaseFormModel } from '../models/base-form-models/base-form.model';

export class MassWorkOrderFormFactory implements FormFactory {
  createForm<T extends BaseFormModel, M extends BaseModel>(model: M): T {
    const form = TypeHelper.getTypeConfig(model).MassWorkOrderForm;

    return form as T;
  }

  createFormByType<T extends BaseFormModel>(type: string): T {
    return;
  }
}
