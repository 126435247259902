import FuseUtils from '@fuse/utils';
import FuseLoading from '@fuse/core/FuseLoading';
import { Navigate } from 'react-router-dom';
import settingsConfig from 'app/configs/settingsConfig';
import SignInConfig from '../main/sign-in/SignInConfig';
import SignOutConfig from '../main/sign-out/SignOutConfig';
import DashboardConfig from "../main/dashboard/DashboardConfig";
import AssetsConfig from "../main/assets/AssetsConfig";
import React from 'react';
import AssetConfig from "../main/asset/AssetConfig";
import InspectionsConfig from "../main/inspections/InspectionsConfig";
import InspectionConfig from "../main/inspection/InspectionConfig";
import WorkOrderConfig from "../main/work-order/WorkOrderConfig";
import WorkOrdersConfig from "../main/work-orders/WorkOrdersConfig";
import AssetInspectionsConfig from "../main/asset-inspections/AssetInspectionsConfig";
import AssetInspectionConfig from "../main/asset-inspection/AssetInspectionConfig";

const routeConfigs = [SignInConfig, SignOutConfig, DashboardConfig, InspectionsConfig, InspectionConfig, AssetInspectionsConfig, AssetInspectionConfig, AssetsConfig, AssetConfig, WorkOrdersConfig, WorkOrderConfig];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(routeConfigs, settingsConfig.defaultAuth),
  {
    path: '/',
    element: <Navigate to="/dashboard" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'inspections',
    element: <Navigate to="/inspections" />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'inspection/:id',
    element: <Navigate to='/inspection/:id' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'asset-inspections',
    element: <Navigate to='/asset-inspections' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'asset-inspection/:id',
    element: <Navigate to='/asset-inspection/:id' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'assets',
    element: <Navigate to='/assets' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'asset/:id',
    element: <Navigate to='/asset/:id' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'escalations',
    element: <Navigate to='/escalations' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'escalation/:id',
    element: <Navigate to='/escalation/:id' />,
    auth: settingsConfig.defaultAuth,
  },
  {
    path: 'loading',
    element: <FuseLoading />,
  },
  {
    path: '*',
    element: <Navigate to='/' />,
  },
];

export default routes;
