import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { ISSUE_CAUSE_LIST, UPS_CORRECTIVE_FOUND_LIST, UPS_FIELDS_STATUSES } from '../globals';

export const VRLA_BATTERY_ASSET_INSPECTION_FORM_SCHEMA: RJSFSchema = {
  type: 'object',
  required: [],
  properties: {
    inspectors: {
      type: 'string',
      title: 'Inspectors',
    },
    issueCause: {
      type: 'string',
      title: 'Issue Cause',
    },
    correctiveFound: {
      type: 'string',
      title: 'Corrective Found',
    },
    unitCorrectlyMounted: {
      type: 'string',
      title: 'Is the unit correctly mounted?',
    },
    alarms: {
      type: 'object',
      title: 'Alarms',
      properties: {
        visibleCorrosionBulgingLeaking: {
          type: 'string',
          title: 'Is there visible corrosion, bulging, leaking or other damage on the battery connections and batteries?',
        },
        upsChassisBondedExpandableBattery: {
          type: 'string',
          title: 'Is the UPS chassis bonded to the chassis of any expandable battery packs?',
        },
      },
    },
    comment: {
      type: 'string',
      title: 'Comment',
    },
    additionalConditions: {
      type: 'string',
      title: 'Additional Conditions',
    },
  },
};

export const VRLA_BATTERY_ASSET_INSPECTION_FORM_UI_SCHEMA: UiSchema = {
  'ui:classNames':
    'w-full grid sm:grid-cols-2 md:grid-cols-4 gap-x-12 gap-y-16',
  'ui:inline': true,
  inspectors: {
    'ui:placeholder': 'Inspectors',
    'ui:widget': 'UserMultiSelectWidget',
    'ui:classNames': 'sm:col-span-2 lg:col-span-1',
  },
  issueCause: {
    'ui:placeholder': 'Issue Cause',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: ISSUE_CAUSE_LIST,
    },
    'ui:classNames': 'sm:col-span-2',
  },
  correctiveFound: {
    'ui:placeholder': 'Corrective found',
    'ui:widget': 'MultiSelectWidget',
    'ui:options': {
      optionsList: UPS_CORRECTIVE_FOUND_LIST,
    },
  },
  unitCorrectlyMounted: {
    'ui:placeholder': 'Is the unit correctly mounted?',
    'ui:widget': 'SelectWidget',
    'ui:options': {
      optionsList: UPS_FIELDS_STATUSES,
    },
  },
  alarms: {
    visibleCorrosionBulgingLeaking: {
      'ui:placeholder': 'Is there visible corrosion, bulging, leaking or other damage on the battery connections and batteries?',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: UPS_FIELDS_STATUSES,
      },
      'ui:classNames': 'sm:col-span-2',
    },
    upsChassisBondedExpandableBattery: {
      'ui:placeholder': 'Is the UPS chassis bonded to the chassis of any expandable battery packs?',
      'ui:widget': 'SelectWidget',
      'ui:options': {
        optionsList: UPS_FIELDS_STATUSES,
      },
      'ui:classNames': 'sm:col-span-2',
    },
    'ui:classNames':
      'sm:col-span-2 md:col-span-4 grid md:grid-cols-4 gap-x-12 gap-y-16',
  },
  additionalConditions: {
    'ui:widget': 'MultiSelectChipsWidget',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  comment: {
    'ui:placeholder': 'Comment',
    'ui:widget': 'textarea',
    'ui:classNames': 'sm:col-span-2 md:col-span-4',
  },
  'ui:submitButtonOptions': {
    norender: true,
  },
};