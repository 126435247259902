import { DataComponentModel } from '../interfaces/data-component.model';
import { InspectionCardItemModel } from '../inspection-card-item.model';
import { InspectionCardHeadingModel } from '../inspection-card-heading.model';
import { BaseViewModel } from './base-view.model';
import { AssetModel } from '../base-models/asset.model';

export abstract class AssetViewModel extends BaseViewModel implements DataComponentModel {

  protected constructor(asset: AssetModel) {
    super(asset);
  }

  get lastTimeStatusChanged(): string {
    throw new Error('Method not implemented.');
  }

  get readyForQa(): boolean {
    throw new Error('Method not implemented.');
  }

  abstract get additionalItemField(): InspectionCardItemModel;

  abstract get buttonAction(): Function;

  abstract get buttonText(): string;

  abstract get componentHeaderTitle(): string;

  abstract get componentHeader(): string;

  abstract get dateTime(): string;

  abstract get detailCardFields(): InspectionCardItemModel[];

  abstract get headingItemFields(): InspectionCardItemModel[];

  abstract get infoCardFields(): InspectionCardItemModel[];

  abstract get linkTo(): string;

  abstract get listItemFieldsClassName(): string;

  abstract get listItemFields(): InspectionCardItemModel[];

  abstract get listItemHeading(): InspectionCardHeadingModel;

  get secondaryListItemFields(): InspectionCardItemModel[]{
    throw new Error('Method not implemented.');
  }

  get secondaryListItemFieldsClassName(): string{
    throw new Error('Method not implemented.');
  }

  get previousNextListItem(): any {
    throw new Error('Method not implemented.');
  }

  get additionalAttachmentsTitle(): any {
    throw new Error('Method not implemented.');
  }
}