import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Dialog from '../../../shared/components/Dialog/Dialog';
import { NotificationMessage } from '../../../shared/components/NotificationMessage/NotificationMessage';
import UserMultiSelect from '../../../shared/components/UserMultiSelect/UserMultiSelect';
import { INSPECTION_STATUS } from '../../../shared/globals';
import InspectionsService from '../../../services/inspections.service';
import { InspectionModel } from '../../../shared/models/base-models/inspection.model';
import LoadingButton from '../../../shared/components/LoadingButton/LoadingButton';
import UsersService from '../../../services/users.service';
import useEffectOnce from '../../../shared/hooks/useEffectOnce';
import { InspectionHelper } from '../../../shared/helpers/inspection.helper';

type Props = {
  onSave: Function;
  onClose: Function;
  inspection?: InspectionModel;
}

const InspectionToQaDialog = (props: Props) => {

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [inspectionAssigneeIds, setInspectionAssigneeIds] = useState([]);

  useEffectOnce(() => {

    setIsLoading(true);
    UsersService.getUserByEternalId('m5rf')
      .then((res) => {
        setInspectionAssigneeIds([res.id]);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (!props.inspection) {
      return;
    }

    setInspectionAssigneeIds([]);
  }, [props.inspection]);

  const handleSubmit = async () => {
    setIsLoading(true);

    const inspectionWasInQa = InspectionHelper.inspectionWasInQa(props.inspection);

    InspectionsService.updateInspectionStatus(props.inspection.id, {
      status: !inspectionWasInQa ? INSPECTION_STATUS.waitingQa : INSPECTION_STATUS.inQa,
      assignedTo: inspectionAssigneeIds
    })
      .then(res => {
        props.onSave(res);
        props.onClose();
        setIsLoading(false);
      })
      .then(() => {
        dispatch(NotificationMessage('Inspection sent to QA/QC', 'success'));
      })
      .catch(err => {
        const message = err.message ? err.message : 'Error saving changes';
        dispatch(NotificationMessage(message, 'error'));
        setIsLoading(false);
      });
  };

  const formLoadingHandler = () => {
    setIsLoading(true);
  }

  const formLoadedHandler = () => {
    setIsLoading(false);
  }

  const assigneesChangedHandler = (values: string[]) => {
    setInspectionAssigneeIds(values);
  };

  const handleClose = () => {
    props.onClose();
    setTimeout(() => props.onClose(), 225);
  };

  return (
    <Dialog
      title={'Send Inspection to QA/QC?'}
      text={'This action will change Inspection status to \'Waiting QA/QC\'. Please reassign Inspection:'}
      actionsContent={[
        <LoadingButton
          key={'cancelAction'}
          disabled={false}
          color={'inherit'}
          onClick={handleClose}
          text={'Cancel'}
        />,
        <LoadingButton
          key={'submitAction'}
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
          text={'Confirm'}
        />
      ]} onClose={props.onClose}>
      <div className={'w-full'}>
        <UserMultiSelect
          id={'inspectors-select'}
          className={'w-full'}
          label='Assigned To'
          onChange={assigneesChangedHandler}
          userIdKey={'id'}
          usersIds={inspectionAssigneeIds}
          formLoadingHandler={formLoadingHandler}
          formLoadedHandler={formLoadedHandler}
        />
      </div>
    </Dialog>
  );
};

export default InspectionToQaDialog;
