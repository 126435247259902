import {createContext, useState} from 'react';

const MapContext = createContext();

export const MapProvider = ({children}) => {
    const [mapOpened, setMapOpened] = useState(false);

    const updateMapOpened = () => {
        setMapOpened(!mapOpened);
    };

    return (
        <MapContext.Provider value={{mapOpened, updateMapOpened}}>
            {children}
        </MapContext.Provider>
    );
};

export default MapContext;
