import React from 'react';
import { CorrectiveActionModel } from '../../../shared/models/corrective-action.model';
import Chip from '../../../shared/components/Chip/Chip';
import AttachmentsComponent from '../../../shared/components/AttachmentsComponent/AttachmentsComponent';

type Props = {
  correctiveAction: CorrectiveActionModel;
  className: string;
}

const CorrectiveActionItem = ({correctiveAction, className}: Props) => {


  return (
    <div
      className={`w-full border-gray-400 py-10 px-12 align-middle justify-end ${className}`}>
      <div className={'flex justify-between align-top'}>
        <span
          className={'font-semibold whitespace-nowrap'}>{correctiveAction.createdBy ? correctiveAction.createdBy.name : 'N/A'}</span>
        <Chip key={'dateTimeChip'} className='my-auto mt-0 font-semibold' bgColor={'bg-gray-300'}
              label={correctiveAction.dateTime}
        />
      </div>
      <span className={`block ${correctiveAction.notes && 'my-4'}`}>{correctiveAction.notes}</span>
      <div className={`mt-8 mb-4`}>
        <AttachmentsComponent attachments={correctiveAction.attachments} smallView={true} masonryImagesClassName={'w-80 h-80 sm:w-80 sm:h-80 md:w-80 md:h-80 lg:w-80 lg:h-80'} />
      </div>
    </div>
  );

};

export default CorrectiveActionItem;