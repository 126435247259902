import { ModelMapper } from './model.mapper';
import { TypeHelper } from '../helpers/type.helper';
import { BaseModel } from '../models/base.model';

export class WorkOrderModelMapper implements ModelMapper {

  mapModel<T extends BaseModel>(object: any): T {
    const Model = TypeHelper.getTypeConfig(object).WorkOrderModel;

    return new Model(object) as T;
  }

  mapModels<T extends BaseModel>(objects: any[]): T[] {
    return objects.map((object) => this.mapModel<T>(object));
  }

}