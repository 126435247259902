import { FiberAssetModel } from './fiber-asset.model';
import { FiberInspectionModel } from './fiber-inspection.model';
import { UserModel } from '../../../models/user.model';
import { FiberWorkOrderModel } from './fiber-work-order.model';
import { FieldChangeModel } from '../../../models/field-change.model';
import { LocationModel } from '../../../models/location.model';
import { CommentModel } from '../../../models/comment.model';
import { FileModel } from '../../../models/file.model';
import { AssetInspectionModel } from '../../../models/base-models/asset-inspection.model';

export class FiberAssetInspectionModel extends AssetInspectionModel {
  id: string;
  type: string;
  inspectionType: string;
  inspectors: UserModel[];
  comment: string;
  status: string;
  asset: FiberAssetModel;
  snapshot: {
    asset: FiberAssetModel;
  };
  conditions: string[];
  inspectedAttributes: string[];
  damageCause: string[];
  barcode: string;
  attachments: FileModel[];
  workOrders: FiberWorkOrderModel[] = [];
  inspection?: FiberInspectionModel;
  location: LocationModel;
  comments?: CommentModel[];
  changeHistory?: FieldChangeModel[];

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);

    if (this.snapshot && this.snapshot.asset) {
      this.snapshot.asset = new FiberAssetModel(this.snapshot.asset);
    }
  }

  get childModels() {
    return {
      inspectors: UserModel,
      asset: FiberAssetModel,
      attachments: FileModel,
      workOrders: FiberWorkOrderModel,
      inspection: FiberInspectionModel,
      changeHistory: FieldChangeModel,
      location: LocationModel,
      comments: CommentModel,
    };
  }

  get correctiveActions(): any[] {
    if (!this.conditions) {
      return [];
    }

    return this.conditions.map((condition) => ({
      name: condition,
      workOrder: this.workOrders.find(
        (workOrder) => workOrder.condition === condition),
    }));
  }
}
