import { GeneratorsAssetInspectionModel } from './generators-asset-inspection.model';
import { AssetModel } from '../../../models/base-models/asset.model';
import { LocationModel } from '../../../models/location.model';

export class GeneratorsAssetModel extends AssetModel {
  id: string;
  equipmentNumber?: string;
  type: string;
  size: string;
  capacityUnits: string;
  tierRating: string;
  equipmentLife: string;
  endOfLife: string;
  loadValue: string;
  phase: string;
  conditionRating: string;
  genset: {
    make: string;
    model: string;
    serialNumber: string;
    specNumber: string;
  };
  engineDetails: {
    make: string;
    model: string;
    serialNumber: string;
    specNumber: string;
  };
  notes: string;
  assetInspections: GeneratorsAssetInspectionModel[];
  comparingAsset: GeneratorsAssetModel;
  location: LocationModel;

  get childModels() {
    return {
      location: LocationModel,
    };
  }

  constructor(attributes?: any) {
    super();
    this.setAttributes(attributes);
  }

}
