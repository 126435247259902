import React, { useEffect, useState } from 'react';
import { Card, FormControlLabel, Switch } from '@mui/material';
import ToggleButton from '../../../shared/components/ToggleButton/ToggleButton';
import FuseSvgIcon from '@fuse/core/FuseSvgIcon';
import { ASSET_INSPECTION_STATUSES, DUE_DATE_STATUSES } from '../../../shared/globals';
import moment from 'moment';
import Search from '../../../shared/components/Search/Search';
import SearchIcon from '@mui/icons-material/Search';
import { motion } from 'framer-motion';
import Menu from '../../../shared/components/Menu/Menu';
import MenuItem from '../../../shared/components/Menu/MenuItem';
import { AssetInspectionParamsInterface } from '../../../shared/models/query-params-interfaces/asset-inspection-params.interface';
import AssetInspectionsService from '../../../services/asset-inspections.service';
import StatusFilter from '../../../shared/components/StatusFilter/StatusFilter';
import { StringHelper } from '../../../shared/helpers/string.helper';
import DueDateStatusFilter from '../../../shared/components/DueDateStatusFilter/DueDateStatusFilter';
import { BaseFormModel } from '../../../shared/models/base-form-models/base-form.model';
import { AssetInspectionsFilterFormFactory } from '../../../shared/filter-form-factories/asset-inspections-filter-form.factory';
import Form from '../../../shared/components/Form/Form';

const exportToCSV = async (queryParams: AssetInspectionParamsInterface, setIsExporting: Function, setMenuOpened: any) => {
  setIsExporting(true);

  AssetInspectionsService.getCsvReport(queryParams).then(res => {
    setMenuOpened(false);
    setIsExporting(false);
  });
};

type Props = {
  mapOpened: boolean;
  onMapToggled: any;
  onFilterChangedHandler: Function;
  queryParams: AssetInspectionParamsInterface;
  csvDisabled: boolean;
  className?: string;
  isLoading?: boolean;
  statusFilterClassName?: string;
  documentType: string;
}

const AssetInspectionsFilterCard = (props: Props) => {

  const [isExporting, setIsExporting] = useState(false);

  const [filterOpened, setFilterOpened] = useState(true);
  const [menuOpened, setMenuOpened] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const paramDueDateStatus = props.queryParams['due-date-status'] ? props.queryParams['due-date-status'].split(',') : [];
  const selectedDueDateStatuses = paramDueDateStatus.filter((paramsItem) => DUE_DATE_STATUSES.find((status) => status.key == paramsItem));

  const paramStatus = props.queryParams.status ? props.queryParams.status.split(',') : [];
  const selectedStatuses = paramStatus.filter((paramsItem) => ASSET_INSPECTION_STATUSES.find((status) => status.key == paramsItem));

  const [conditionFound, setConditionFound] = useState(StringHelper.stringToBoolean(props.queryParams['condition-found']));

  const [form, setForm] = useState(new BaseFormModel());

  const [formData, setFormData] = useState<{}>({
    ...props.queryParams,
    'condition': props.queryParams.condition ? props.queryParams.condition.split(',') : [],
    'inspector': props.queryParams.inspector ? props.queryParams.inspector.split(',') : []
  });

  useEffect(() => {
    if (!props.documentType) {
      return;
    }

    const formSchemas = new AssetInspectionsFilterFormFactory().createFormByType<BaseFormModel>(props.documentType);

    if (!formSchemas) {
      return;
    }

    setForm(formSchemas);
  }, [props.documentType]);

  const handleFormChange = (updatedFormData: any) => {
    if (updatedFormData['start-date'] && updatedFormData['start-date'] !== formData['start-date']) {
      startDateChangedHandler(updatedFormData['start-date']);
    }

    if (updatedFormData['end-date'] && updatedFormData['end-date'] !== formData['end-date']) {
      endDateChangedHandler(updatedFormData['end-date']);
    }

    if (updatedFormData['inspector'] && JSON.stringify(updatedFormData['inspector']) !== JSON.stringify(formData['inspector'])) {
      inspectorChangedHandler(updatedFormData['inspector']);
    }

    if (updatedFormData['condition'] && JSON.stringify(updatedFormData['condition']) !== JSON.stringify(formData['condition'])) {
      specificConditionChangedHandler(updatedFormData['condition']);
    }

    setFormData(updatedFormData);
  };

  useEffect(() => {
    setFormData({
      ...props.queryParams,
      'condition': props.queryParams.condition ? props.queryParams.condition.split(',') : [],
      'inspector': props.queryParams.inspector ? props.queryParams.inspector.split(',') : []
    })
  }, [props.queryParams]);


  const handleShowFilterToggled = () => {
    setFilterOpened(prevState => !prevState);
  };

  const updateFilterParam = (params: { name: string, value: string }[]): void => {
    const paramsModel = {
      ...props.queryParams,
      page: '1',
    } as AssetInspectionParamsInterface;
    params.forEach(param => paramsModel[param.name] = param.value);
    props.onFilterChangedHandler(paramsModel);
  };

  const searchStringChangeHandler = (searchString: string) => {
    let params = [];

    const regex = /dueDateStart:(.*?);dueDateEnd:(.*?);(.*$)/;
    const match = searchString.match(regex);

    if (!match) {
      params = [
        {name: 'due-date-start', value: ''},
        {name: 'due-date-end', value: ''},
        {name: 'search', value: searchString},
      ];
    }

    if (match && match.length === 4) {
      params = [
        {name: 'due-date-start', value: match[1]},
        {name: 'due-date-end', value: match[2]},
        {name: 'search', value: match[3]},
      ];
    }

    updateFilterParam(params);
  };

  const specificConditionChangedHandler = (values: string[]) => {
    const params = [
      {name: 'condition', value: values.toString()},
    ];

    updateFilterParam(params);
  };

  const startDateChangedHandler = (date: Date) => {
    const params = [
      {name: 'start-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const endDateChangedHandler = (date: string) => {
    const params = [
      {name: 'end-date', value: date ? moment(date).format('YYYY-MM-DD') : ''},
    ];

    updateFilterParam(params);
  };

  const inspectorChangedHandler = (values: string[]) => {
    const params = [
      {name: 'inspector', value: values.toString()},
    ];

    updateFilterParam(params);
  };

  const statusChangedHandler = (values: string[]) => {
    if (values.sort().join() === ASSET_INSPECTION_STATUSES.map(status => status.key).sort().join()) {

      updateFilterParam([{name: 'status', value: ''}]);

      return;
    }

    const params = [
      {name: 'status', value: values.toString()},
    ];

    updateFilterParam(params);
  };

  const dueDateStatusChangedHandler = (values: string[]) => {
    if (values.sort().join() === DUE_DATE_STATUSES.map(status => status.key).sort().join()) {

      updateFilterParam([{name: 'due-date-status', value: ''}]);

      return;
    }

    const params = [
      {name: 'due-date-status', value: values.toString()},
    ];

    updateFilterParam(params);
  };

  const conditionFoundChangedHandler = () => {
    const params = [
      {name: 'condition-found', value: StringHelper.booleanToString(!conditionFound)},
    ];
    updateFilterParam(params);
    setConditionFound(!conditionFound);
  };

  const openFilterButton =
    <ToggleButton
      onClick={handleShowFilterToggled}
      isActive={!filterOpened}
      className={'p-8'}
      hoverText={!filterOpened ? 'Show Filter' : 'Show Search Bar'}
    >
      {!filterOpened ?
        /*@ts-ignore*/
        <FuseSvgIcon size={24}>heroicons-outline:filter</FuseSvgIcon> : <SearchIcon />
      }
    </ToggleButton>;

  const openMapButton =
    <ToggleButton
      onClick={props.onMapToggled}
      isActive={props.mapOpened}
      className={'p-8'}
      hoverText={props.mapOpened ? 'Close Map' : 'Open Map'}
    >
      {/*@ts-ignore*/}
      <FuseSvgIcon size={24}>heroicons-outline:map</FuseSvgIcon>
    </ToggleButton>;

  const showMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
    setMenuOpened(true);
  };

  const handleMenuClosed = () => {
    setMenuAnchorEl(null);
    setMenuOpened(false);
  };

  const filterMenuButton =
    <ToggleButton
      isActive={menuOpened}
      onClick={showMenu}
      className={'p-8'}
      hoverText={'Open Menu'}>
      {/*@ts-ignore*/}
      <FuseSvgIcon size={24}>heroicons-outline:cog</FuseSvgIcon>
    </ToggleButton>;

  const filterMenu =
    <Menu
      open={menuOpened}
      anchorEl={menuAnchorEl}
      onClose={handleMenuClosed}
      menuItems={[
        <MenuItem key={'optionExport'} onClick={() => exportToCSV(props.queryParams, setIsExporting, setMenuOpened)}
                  isLoading={isExporting} icon={'heroicons-outline:download'} text={'Export Assets CSV'}
                  disabled={props.isLoading} />,
      ]}
    />;

  const showFilterButton =
    <ToggleButton
      onClick={handleShowFilterToggled}
      isActive={!filterOpened}
      className={`p-8`}
      hoverText={!filterOpened ? 'Uncollapse' : 'Collapse'}
    >
      {/*@ts-ignore*/}
      {!filterOpened ? <FuseSvgIcon size={24}>material-outline:unfold_more</FuseSvgIcon> : <FuseSvgIcon size={24}>material-outline:unfold_less</FuseSvgIcon>}
    </ToggleButton>;

  const searchString = () => {
    const dueDateStart = props.queryParams['due-date-start'];
    const dueDateEnd = props.queryParams['due-date-end'];

    const dueDateString = dueDateStart && dueDateEnd ? `dueDateStart:${dueDateStart};` + `dueDateEnd:${dueDateEnd};` : '';
    const search = props.queryParams.search ? props.queryParams.search : '';

    return `${dueDateString}` + `${search}`;
  }

  const searchBar = (className: string) => {
    return <motion.div className={'flex-auto w-full my-auto ' + className}
                       initial={{opacity: 0, y: -40}}
                       animate={{opacity: 1, y: 0, transition: {delay: 0.15}}}>
      <Search placeholder={'Search Assets'} value={searchString()}
              onChange={searchStringChangeHandler}
              className={'w-full'}/>
    </motion.div>;
  };

  return (
    <>
      <Card
        className={`shadow rounded-2xl ${!filterOpened ? 'h-72 mb-10' : 'lg:h-72'} overflow-hidden mt-2 p-12 gap-12 flex justify-between items-center ${props.className}`}>
        <div className={`gap-x-8 gap-y-16 p-4 flex flex-auto items-center ${filterOpened && 'flex-col lg:flex-row'}`}>
          <div className={'hidden lg:flex'}>
            {openFilterButton}
          </div>
          {
            !filterOpened &&
            searchBar('hidden lg:flex')
          }
          <div className={'w-full flex flex-row gap-8 lg:hidden'}>
            {searchBar(``)}
            <div className={`flex gap-6 ${!filterOpened && 'hidden'}`}>
                <span className={`h-40 w-1 mr-3 bg-gray-500 rounded`}
                      style={{transition: 'height 0.1s ease-out'}}/>
              {showFilterButton}
            </div>
          </div>
          <motion.div className={`flex flex-col lg:flex-row justify-between w-full ${!filterOpened && 'hidden'}`}
                      initial={{opacity: 0, y: 40}}
                      animate={{opacity: 1, y: 0, transition: {delay: 0.15}}}>
            {!!form.schema && <Form schema={form.schema}
                                    uiSchema={form.uiSchema}
                                    formData={formData}
                                    onChange={handleFormChange}
            />
            }
            <div
              className={`flex w-auto lg:hidden items-center justify-end gap-x-6 pt-12`}>
              <div className={'flex flex-wrap justify-end'}>
                <FormControlLabel
                  checked={conditionFound}
                  className={'whitespace-nowrap text-10 my-auto mr-0'}
                  onChange={conditionFoundChangedHandler}
                  control={<Switch color='primary' size='small' className={'mr-0'}/>}
                  label='Has conditions'
                  labelPlacement='start'
                />
                <DueDateStatusFilter statuses={DUE_DATE_STATUSES}
                                     selectedStatuses={selectedDueDateStatuses}
                                     onChange={dueDateStatusChangedHandler}
                                     className={'flex sm:flex-row gap-x-2 justify-end'}/>
              </div>

              <span className={`${!filterOpened ? 'h-40' : 'h-40'} w-1 mr-3 bg-gray-500 rounded`}
                    style={{transition: 'height 0.1s ease-out'}}/>
              <div>
                {filterMenuButton}
                {filterMenu}
              </div>
              <div className='hidden sm:block'>
                {openMapButton}
              </div>
            </div>
          </motion.div>
          <div className={`${!filterOpened ? 'h-40 flex' : 'hidden lg:flex h-40'} gap-6 items-center justify-end`}>

          <span className={`${!filterOpened ? 'h-40' : 'h-40'} w-1 mr-3 bg-gray-500 rounded`}
                style={{transition: 'height 0.1s ease-out'}}/>

            <div>
              {filterMenuButton}
              {filterMenu}
            </div>

            <div className='hidden sm:block'>
              {openMapButton}
            </div>

            <div className={`lg:hidden ${filterOpened && 'hidden'}`}>
              {showFilterButton}
            </div>

          </div>
        </div>
      </Card>
      <Card
        className={`shadow rounded-2xl p-12 flex my-10 overflow-x-auto pointer-events-auto w-full justify-between ${props.statusFilterClassName}`}>
        <StatusFilter statuses={ASSET_INSPECTION_STATUSES} selectedStatuses={selectedStatuses}
                      onChange={statusChangedHandler}/>
        <div className={`hidden lg:flex w-full justify-end`}>
          <FormControlLabel
            checked={conditionFound}
            className={'flex whitespace-nowrap text-10 pr-4'}
            onChange={conditionFoundChangedHandler}
            control={<Switch color='primary' size='small'/>}
            label='Has conditions'
            labelPlacement='start'
          />
          <DueDateStatusFilter statuses={DUE_DATE_STATUSES} selectedStatuses={selectedDueDateStatuses}
                               onChange={dueDateStatusChangedHandler} className={'flex flex-row gap-x-2 justify-end'}/>
        </div>

      </Card>
    </>
  );
};

export default AssetInspectionsFilterCard;
