import { WidgetProps } from '@rjsf/utils';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useEffect, useState } from 'react';

interface CheckboxesWidgetProps extends WidgetProps {
  options: {
    optionsList: { key: string; value: string }[];
  };
}

const CheckboxesWidget = (props: CheckboxesWidgetProps) => {

  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    if (!props.value) {
      return;
    }

    setStatuses(props.value);
  }, [props.value]);

  const statusChangeHandler = (key: string) => {
    let newValues: string[];


    if (!!statuses.find(item => item === key)) {
      newValues = statuses.filter(item => item !== key);
    } else {
      newValues = [...statuses, key];
    }

    setStatuses(newValues);
    props.onChange(newValues);
  };

  return (
    <>
      <label className={'text-13'}>{props.label}</label>
      <FormGroup className={`pl-0 flex flex-row gap-x-8 my-2 justify-start`}>
        {
          !!props.options.optionsList.length && props.options.optionsList.map(option =>
            <FormControlLabel
              key={option.key}
              className={'whitespace-nowrap text-10 gap-x-1 mx-0'}
              value={option.key}
              control={<Checkbox disableRipple color='primary' size={'small'}
                                 style={{padding: 0}}/>}
              label={option.value}
              labelPlacement='start'
              defaultChecked={false}
              checked={statuses && !!statuses.length && statuses.includes(option.key)}
              onChange={() => statusChangeHandler(option.key)}
            />
          )
        }
      </FormGroup>
    </>
  );
};

export default CheckboxesWidget;