import { MapPointModel } from '../models/map-point.model';
import { MapAssetInterface } from '../models/interfaces/map-asset.interface';

export class MapHelper {

  static getAssetMapPoints(assets: MapAssetInterface[]): MapPointModel[] {
    const assetMapPoints = assets.map(asset => asset.getMapPoint());
    return assetMapPoints.filter(item => !!item);
  }

}
